import { GridCellParams } from '@mui/x-data-grid-pro';
import { AdGroup } from '@local-types/group';
import { useGetAllBidStrategies } from '@components/hooks/apis/bidStrategy';
import { BidStrategy } from '@local-types/bidStrategy';

export const AdGroupBidStrategyCellRenderer = (
  params: GridCellParams<AdGroup>
) => {
  const { data } = useGetAllBidStrategies();
  const bidStrategy = data?.find(
    (strategy: BidStrategy) => strategy.id === params.row.bid_strategy
  );

  return <span>{bidStrategy?.display_name ?? '-'}</span>;
};
