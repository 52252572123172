import { useFormContext } from 'react-hook-form';
import { fields } from '../constants';
import { useContext, useState } from 'react';
import { formatCreatives } from '../utils';
import { WizardPageContext } from '@components/containers/WizardPage/constants';
import { useGetCreativeLineItems } from '@apis/creatives';
import { useGetStaticCreativeLineItems } from '@apis/displays';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';

export const useCreative = ({ adGroup, isDisplay }) => {
  const { localAssets } = useContext(WizardPageContext);
  const [showMore, setShowMore] = useState(false);
  const { watch } = useFormContext();
  const { releaseCreativeStartEndDatetime } = useLDFlags();

  const { data: additionalData } = !adGroup?.temporary
    ? isDisplay
      ? useGetStaticCreativeLineItems(adGroup?.id)
      : useGetCreativeLineItems(adGroup?.id)
    : { data: [] };

  const creatives = watch(fields.creatives.path) ?? [];
  const [adGroupStartDate, adGroupEndDate] = [
    watch(fields.startDate.path),
    watch(fields.endDate.path),
  ];

  return {
    creatives: formatCreatives(
      creatives,
      additionalData,
      adGroupStartDate,
      adGroupEndDate,
      localAssets
    ),
    showMore,
    setShowMore,
    showDetails: releaseCreativeStartEndDatetime,
  };
};
