import React from 'react';
import { Stack, Typography } from '@mui/material';
import { CreativeAdGroup } from '@local-types';
import { formatDateTime } from './utils';
import moment from 'moment';

export const CreativeDetails = ({ creative }: { creative: CreativeAdGroup }) => {
  return (
    <Stack>
      <Typography variant="h5">{creative.name}</Typography>
      <Typography variant="body2" sx={{ color: 'grey.2' }}>
        {creative.id}&nbsp;|&nbsp;
        {formatDateTime(moment(creative.start_date)) ?? 'No Start Date'}
        &nbsp;-&nbsp;
        {formatDateTime(moment(creative.end_date)) ?? 'No End Date'}
      </Typography>
    </Stack>
  );
};
