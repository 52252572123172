import { UseGetReportingHomeDataProps } from '@components/containers/ReportsPage/types';
import useReportingHomeData from './reportingHome';
import { useContext } from 'react';
import AdvertiserContext, { COST_MODEL } from '@components/AdvertiserContext';

const useRoas = ({ dateFilter }: UseGetReportingHomeDataProps) => {
  const adContext = useContext(AdvertiserContext);
  const disable = adContext.cost_model === COST_MODEL.CPA;
  return useReportingHomeData({ dateFilter, endpoint: 'roas', disable });
}

export default useRoas;
