import { Updating, UpdateError, UpdateSuccess, PartialSuccess } from './';
import { useEffect } from 'react';
import { useBulkEdit } from '../../context';
import { useBulkUpdate } from '@components/hooks/apis/bulk';
import { BulkEditFieldValue } from '../../constants';
import { MockAdGroup } from '@components/tests/__mocks__';
import { Stack } from '@mui/material';

interface ApplyEditsProps {
  ids: number[];
  adGroups: MockAdGroup[];
  fieldValues: BulkEditFieldValue;
}

export const ApplyEdits = ({
  ids = [],
  adGroups = [],
  fieldValues = {},
}: ApplyEditsProps) => {
  const { setUpdateResult, setUpdateState, updateError, updateResult } =
    useBulkEdit();

  const { trigger: updateBulk, isMutating: isUpdating } = useBulkUpdate({
    onSuccess: data => {
      setUpdateResult(data);
      setUpdateState(false, null);
    },
    onError: error => {
      setUpdateState(false, error);
    },
  });

  useEffect(() => {
    handleApplyEdits();
  }, []);

  const handleApplyEdits = async () => {
    setUpdateState(true, null);

    try {
      await updateBulk({
        type: 'LINEITEM',
        select: {
          id__in: ids,
        },
        fields: fieldValues,
      });
    } catch (error) {
      setUpdateState(
        false,
        error instanceof Error ? error : new Error(String(error))
      );
    }
  };

  const adGroupCount = ids.length;
  const filteredAdGroups = adGroups.filter(adGroup =>
    ids.includes(adGroup.id)
  );

  const status = () => {
    if (!updateResult || updateError) return 'ERROR';
    if (updateResult.results.every(result => result.status === 'SUCCESS')) {
      return 'SUCCESS';
    }
    if (updateResult.results.every(result => result.status === 'ERROR')) {
      return 'ERROR';
    }
    return 'PARTIAL_SUCCESS';
  };

  const renderContent = () => {
    if (isUpdating) return <Updating adGroupCount={adGroupCount} />;
    switch (status()) {
      case 'ERROR':
        return <UpdateError />;
      case 'PARTIAL_SUCCESS':
        return (
          <PartialSuccess
            results={updateResult?.results || []}
            adGroups={filteredAdGroups}
          />
        );
      case 'SUCCESS':
        return <UpdateSuccess adGroupCount={adGroupCount} />;
    }
  };

  return (
    <Stack sx={{ minHeight: '20vh', justifyContent: 'center' }}>
      {renderContent()}
    </Stack>
  );
};
