import {
  adGroupBoundingError,
  endDateValidation,
  endTimeValidation,
  getBoundingDates,
  getDate,
  requiredMessage,
  startDateSimpleValidation,
  startTimeSimpleValidation,
} from '@components/CampaignWizard/validations';
import * as yup from 'yup';
import moment from 'moment';
import { Campaign } from '@local-types';

interface DateTimeValidationOptions {
  min: moment.Moment | null;
  max: moment.Moment | null;
}

export const getSchema = (alreadyStarted: boolean, campaign?: Campaign) =>
  yup
    .object()
    .shape(
      alreadyStarted ? getActiveSchema(campaign) : getInactiveSchema(campaign)
    );

const activeEndDateValidation = ({ min, max }: DateTimeValidationOptions) =>
  yup
    .mixed()
    .nullable()
    .when({
      is: (value: moment.Moment | null) => !!value,
      then: yup
        .mixed()
        .test(
          'is-date',
          'Start date must be a valid date',
          value => moment.isMoment(value) && value.isValid()
        )
        .test('in-bound-min', adGroupBoundingError, function (value) {
          if (!min) return true;
          return moment(value).isSameOrAfter(moment(min));
        })
        .test('in-bound-max', adGroupBoundingError, function (value) {
          if (!max) return true;
          return moment(value).isSameOrBefore(moment(max));
        }),
    });

const activeEndTimeValidation = (
  endDateFieldName: string,
  { min, max }: DateTimeValidationOptions
) =>
  yup
    .mixed()
    .nullable()
    .when(endDateFieldName, {
      is: (value: moment.Moment | null) => !!value,
      otherwise: yup.mixed().nullable(),
      then: yup
        .mixed()
        .required(requiredMessage)
        .test('in-bound-min', adGroupBoundingError, function (value) {
          if (!min) return true;

          return getDate(this.parent[endDateFieldName], value).isSameOrAfter(
            moment(min)
          );
        })
        .test('in-bound-max', adGroupBoundingError, function (value) {
          if (!max) return true;

          return getDate(this.parent[endDateFieldName], value).isSameOrBefore(
            moment(max)
          );
        }),
    });

export const getActiveSchema = (campaign?: Campaign) => {
  const { minEndDate, maxEndDate } = getBoundingDates(
    campaign?.start_date,
    campaign?.end_date
  );

  return {
    startDate: yup.mixed().nullable(),
    startTime: yup.mixed().nullable(),
    endDate: activeEndDateValidation({
      min: minEndDate,
      max: maxEndDate,
    }),
    endTime: activeEndTimeValidation('endDate', {
      min: minEndDate,
      max: maxEndDate,
    }),
  };
};

export const getInactiveSchema = (campaign?: Campaign) => {
  const { minStartDate, maxStartDate, minEndDate, maxEndDate } =
    getBoundingDates(campaign?.start_date, campaign?.end_date);

  return {
    startDate: startDateSimpleValidation('endDate', {
      min: minStartDate,
      max: maxStartDate,
    }),
    startTime: startTimeSimpleValidation('startDate', 'endDate', 'endTime', {
      min: minStartDate,
      max: maxStartDate,
    }),
    endDate: endDateValidation('startDate', null, {
      min: minEndDate,
      max: maxEndDate,
    }),
    endTime: endTimeValidation('startDate', 'startTime', 'endDate', {
      min: minEndDate,
      max: maxEndDate,
    }),
  };
};
