import React from 'react'
import ReportsPage from './ReportsPage';
import ReportsV2 from './ReportsV2';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LookerFiltersContextProvider } from '@providers/LookerFiltersContext';

const ReportsPageContainer = () => {
  const { reportsPageV2 } = useFlags();

  return (
    <LookerFiltersContextProvider>
      {reportsPageV2 ? <ReportsV2 /> : <ReportsPage />}
    </LookerFiltersContextProvider>
  );
}

export default ReportsPageContainer;
