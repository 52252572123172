import { useWizardRedirect } from '@components/hooks/wizardRedirect';
import { useDomain } from '@hooks/domain';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import clsx from 'clsx';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { Status, RoutePaths } from '@constants';
import LazyLoadingObserver from '../../LazyLoadingObserver';
import { useGetCampaigns } from '../../hooks/apis/campaigns';
import { useSort } from '../../hooks/sort';
import SortableTableHead from '../../ui/SortableTableHead';
import { formatToUSD } from '../../util';
import { useGetTableHeaders } from './hooks/useGetTableHeaders';
import { classes } from './styles';
import { formatEndDate } from './utils';
import { CampaignArchiveRenderer } from '@components/containers/CampaignsPage/renderers/CampaignArchiveRenderer';
import {
  CampaignPauseActivateRenderer
} from '@components/containers/CampaignsPage/renderers/CampaignPauseActivateRenderer';

const CampaignsTable = ({
  handleManageBudget,
  handleDeleteDraft,
  handleDuplicateCampaign,
  handleDraftCampaign,
  handleManageCampaign,
  handleArchiveUnarchiveCampaign,
  handlePauseActive,
  urlQuery,
}) => {
  const domain = useDomain();
  const [currentAnchorEl, setCurrentAnchorEl] = useState(null);
  const [targetMenu, setTargetMenu] = useState(null);
  const [selected, setSelected] = useState([]);

  const { order, orderBy, handleRequestSort, getComparator, stableSort } =
    useSort();

  const headers = useGetTableHeaders();

  const {
    items: campaigns,
    isLoading,
    isValidating,
    size,
    setSize,
    hasMore,
  } = useGetCampaigns();

  const { isWizardEnabled } = useWizardRedirect();

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = campaigns.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleCloseMenu = () => {
    setCurrentAnchorEl(null);
    setTargetMenu(null);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const handleOpenMenu = (event, rowId) => {
    setCurrentAnchorEl(event.currentTarget);
    setTargetMenu(null);
    setTargetMenu(rowId);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const sortedCampaigns = stableSort(
    campaigns,
    getComparator(order, orderBy),
  );

  return (
    <TableContainer>
      <Table>
        <SortableTableHead
          headCells={headers}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          onSelectAll={handleSelectAllClick}
        />

        <TableBody>
          {sortedCampaigns.map((campaign, index) => {
            const {
              id,
              status,
              name,
              stats_cache: stats,
              start_date,
              end_date,
              daily_budget,
              experiment_type,
            } = campaign;
            const isItemSelected = isSelected(id);
            const labelId = `active-campaigns-table-checkbox-${index}`;

            return (
              <TableRow
                hover
                onClick={event => handleClick(event, id)}
                role="checkbox"
                tabIndex={-1}
                key={`${campaign.id}-${index}`}
                aria-checked={isItemSelected}
                selected={isItemSelected}
                data-testid="campaign-row"
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    size="small"
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell>{campaign.id}</TableCell>
                <TableCell
                  className={classes.tableCell}
                  component="th"
                  id={labelId}
                  scope="row"
                  align="left"
                >
                  <Link
                    className={classes.link}
                    data-testid="campaign-link"
                    to={
                      status === Status.DRAFT
                        ? isWizardEnabled
                          ? {
                              pathname: generatePath(
                                RoutePaths.CAMPAIGN_REWORK,
                                { campaignId: campaign.id },
                              ),
                              search: urlQuery.toString(),
                            }
                          : generatePath(RoutePaths.OLD_CAMPAIGN_EDIT, {
                              id: campaign.id,
                            })
                        : generatePath(RoutePaths.AD_GROUPS, {
                            campaignId: campaign.id,
                          })
                    }
                  >
                    {name}
                  </Link>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  {moment(start_date).format('l')}
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  {formatEndDate(end_date)}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="left"
                >{`$${daily_budget}`}</TableCell>
                <TableCell
                  className={clsx(classes.tableCell, classes.stats)}
                  align="left"
                >
                  {stats && stats.total_spend != null
                    ? formatToUSD(stats.total_spend)
                    : '-'}
                </TableCell>
                {domain.default && (
                  <TableCell
                    className={clsx(classes.tableCell, classes.stats)}
                    align="left"
                  >
                    {stats && stats.budget_remaining != null
                      ? formatToUSD(stats.budget_remaining)
                      : '-'}
                  </TableCell>
                )}
                <TableCell
                  className={clsx(classes.tableCell, classes.stats)}
                  align="left"
                >
                  {stats && stats.action_count != null
                    ? stats.action_count
                    : '-'}
                </TableCell>
                <TableCell
                  className={clsx(classes.tableCell, classes.stats)}
                  align="left"
                >
                  {stats && stats.cost_per_visit !== null
                    ? formatToUSD(stats.cost_per_visit)
                    : '-'}
                </TableCell>
                <TableCell
                  data-testid="campaign-status-chip"
                  className={classes.height}
                  align="left"
                >
                  {status === Status.DRAFT && (
                    <Chip
                      label="Draft"
                      className={classes.draft}
                      data-testid="draft-campaign"
                    />
                  )}
                  {status === Status.ACTIVE && (
                    <Chip
                      label="Active"
                      className={classes.active}
                      data-testid="active-campaign"
                    />
                  )}
                  {status === Status.INACTIVE && (
                    <Chip
                      label="Inactive"
                      className={classes.paused}
                      data-testid="inactive-campaign"
                    />
                  )}
                  {status === Status.ARCHIVED && (
                    <Chip
                      label="Archived"
                      className={classes.archived}
                      data-testid="archived-campaign"
                    />
                  )}
                </TableCell>
                {status === Status.DRAFT ? (
                  <TableCell
                    className={clsx(classes.font, classes.height)}
                    align="left"
                  >
                    <Button
                      className={classes.manageBtn}
                      disableRipple
                      endIcon={<ExpandMoreIcon />}
                      onClick={event => handleOpenMenu(event, campaign.id)}
                    >
                      Continue
                      <Divider
                        className={classes.divider}
                        flexItem
                        orientation="vertical"
                      />
                    </Button>

                    <Menu
                      anchorEl={currentAnchorEl}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      open={targetMenu === id}
                      onClick={handleCloseMenu}
                    >
                      <MenuItem onClick={() => handleDraftCampaign(id)}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                          <CreateOutlinedIcon
                            fontSize="small"
                            className={classes.menuIcon}
                          />
                          Edit
                        </Box>
                      </MenuItem>

                      <MenuItem onClick={() => handleDeleteDraft(campaign)}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                          <DeleteIcon
                            fontSize="small"
                            className={classes.menuIcon}
                          />
                          Delete
                        </Box>
                      </MenuItem>
                    </Menu>
                  </TableCell>
                ) : (
                  <TableCell
                    className={clsx(classes.font, classes.height)}
                    align="left"
                  >
                    <Button
                      className={classes.manageBtn}
                      disableRipple
                      endIcon={<ExpandMoreIcon />}
                      onClick={event => handleOpenMenu(event, id)}
                    >
                      Manage
                      <Divider
                        className={classes.divider}
                        flexItem
                        orientation="vertical"
                      />
                    </Button>

                    <Menu
                      anchorEl={currentAnchorEl}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      open={targetMenu === id}
                      onClick={handleCloseMenu}
                    >
                      {!experiment_type && (
                        <MenuItem
                          onClick={async () => {
                            handleCloseMenu();
                            handleManageCampaign(campaign);
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                            <CreateOutlinedIcon
                              fontSize="small"
                              className={classes.menuIcon}
                            />
                            Manage Campaign
                          </Box>
                        </MenuItem>
                      )}

                      <MenuItem
                        onClick={() => handleDuplicateCampaign(campaign)}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                          <FileCopyOutlinedIcon
                            fontSize="small"
                            className={classes.menuIcon}
                          />
                          Duplicate Campaign
                        </Box>
                      </MenuItem>

                      {!experiment_type && status != Status.ARCHIVED && (
                        <MenuItem
                          onClick={() => handleManageBudget(campaign)}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                            <AttachMoneyIcon
                              fontSize="small"
                              className={classes.menuIcon}
                            />
                            Edit Budget
                          </Box>
                        </MenuItem>
                      )}
                      {(status === Status.ACTIVE ||
                        status === Status.INACTIVE) && (
                        <MenuItem onClick={() => handlePauseActive(campaign)}>
                          <CampaignPauseActivateRenderer status={status} />
                        </MenuItem>
                      )}
                      {(status === Status.INACTIVE ||
                        status === Status.ARCHIVED) && (
                        <MenuItem
                          onClick={() =>
                            handleArchiveUnarchiveCampaign(campaign)
                          }
                        >
                          <CampaignArchiveRenderer status={status} />
                        </MenuItem>
                      )}
                    </Menu>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
        <LazyLoadingObserver
          handleScrollToBottom={() => {
            if (hasMore) {
              setSize(size + 1);
            }
          }}
          isLazyLoadingFinished={!isLoading && !isValidating}
        />
      </Table>
    </TableContainer>
  );
};

CampaignsTable.propTypes = {
  handleManageBudget: PropTypes.func.isRequired,
  handleDeleteDraft: PropTypes.func.isRequired,
  handleDuplicateCampaign: PropTypes.func.isRequired,
  handleDraftCampaign: PropTypes.func.isRequired,
  handleManageCampaign: PropTypes.func.isRequired,
  handleArchiveUnarchiveCampaign: PropTypes.func.isRequired,
  handlePauseActive: PropTypes.func.isRequired,
  urlQuery: PropTypes.object.isRequired,
};

export default CampaignsTable;
