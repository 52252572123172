import { Stack, styled, Typography } from '@mui/material';

// Define the styled component for the header
export const HeaderBoxStyled = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  background: theme.palette.blue[1],
  borderRadius: '10px 10px 2px 2px',
  fontFamily: theme.typography.fontFamily,
  justifyContent: 'center',
  padding: '16px 24px 15px 24px',
  width: '100%',
}));

// Styled container for the icon and text
export const IconTextContainerStyled = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
}));

// Styled Typography for the "tvSc" part of the text
export const TypographyStyled = styled(Typography)({
  marginLeft: '5px',
});

// Styled span for the "iQ" part with a heavier font weight
export const BoldTextStyled = styled('span')({
  fontWeight: 700,
});

export const InfoBoxStyled = styled(Stack, {
  shouldForwardProp: prop => prop !== 'trackingIsSetUp' && prop !== 'isLocked',
})<{
  trackingIsSetUp?: boolean;
  isLocked?: boolean;
}>(({ theme, trackingIsSetUp, isLocked }) => ({
  alignItems: 'left',
  backgroundColor: !trackingIsSetUp && isLocked
    ? theme.palette.grey[11]
    : theme.palette.green[10], // Green background if tracking is set up
  clear: 'both',
  color: !trackingIsSetUp && isLocked
    ? theme.palette.text.primary
    : theme.palette.green[3], // Green text if tracking is set up
  padding: theme.spacing(1),
  paddingLeft: '15px',
  width: '100%',
}));

export const InfoTextBoxStyled = styled(Stack)(({ theme }) => ({
  color: theme.palette.grey[3],
}));

export const InfoTextTypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[3],
  fontSize: '0.75rem',
}));
