import { Typography, type TypographyProps } from '@mui/material';
import { ReactNode } from 'react';

interface MediumTypographyProps extends TypographyProps {
  text: string | ReactNode;
}

export const MediumTypography = ({
  text,
  ...props
}: MediumTypographyProps) => {
  return (
    <Typography variant="body2" fontWeight="fontWeightMedium" {...props}>
      {text}
    </Typography>
  );
};
