import React from 'react';
import { TableRightRowVirtualized } from '../TableRightRowVirtualized';
import { GroupedData, TableData } from '../../types';
import { TableRightProps } from '../types';
import { VirtualizedRowType, VirtualizedTypeProperty } from '../../constants';
import type { TableRowProps } from 'react-virtualized';

export const useVirtualizedRenderer = <Value, Data extends TableData<Value>>({
  isTargeted,
  renderCell,
  renderGroup,
  PlaceholderCell,
  PlaceholderGroup,
  isRemoveAllHovered,
  onChange,
  settings,
  onUpdate,
}: Pick<
  TableRightProps<Value, Data>,
  | 'renderCell'
  | 'renderGroup'
  | 'PlaceholderCell'
  | 'PlaceholderGroup'
  | 'isRemoveAllHovered'
  | 'onChange'
  | 'settings'
  | 'onUpdate'
> & {
  isTargeted: boolean;
}) => {
  const renderElement = ({ key, index, style, rowData }: TableRowProps) => {
    return rowData[VirtualizedTypeProperty] === VirtualizedRowType.GROUP ? (
      renderGroup ? (
        renderGroup({
          key,
          data: rowData as GroupedData<Value>,
          style,
          type: 'right',
        })
      ) : (
        <PlaceholderGroup
          style={style}
          key={key}
          type={'right'}
          data={rowData as GroupedData<Value>}
        />
      )
    ) : (
      <TableRightRowVirtualized
        key={key}
        data={rowData as Value}
        index={index}
        style={style}
        isRemoveAllHovered={isRemoveAllHovered}
        onChange={onChange}
        onUpdate={onUpdate}
        renderCell={renderCell}
        isTargeted={isTargeted}
        PlaceholderCell={PlaceholderCell}
        settings={settings}
      />
    );
  };

  return renderElement;
};
