import { TableTab } from '../types';

export const useAdvanced = ({
  tabs,
  selectedTab,
}: {
  tabs: TableTab[];
  selectedTab: string;
}) => {
  const tab = tabs.find(tab => tab.value === selectedTab)!;

  return {
    tab,
  };
};
