export const menuItems = [
  { value: 'Level_1', label: 'Level 1 - Novice' },
  { value: 'Level_2', label: 'Level 2 - Emerging' },
  { value: 'Level_3', label: 'Level 3 - Advanced' },
  { value: 'Level_4', label: 'Level 4 - Expert' },
];

export const performanceCheckboxItems = [
  { name: 'campaignPerformance', label: 'Campaign Performance' },
  { name: 'pathToPurchase', label: 'Path to Purchase' },
  { name: 'postConversions', label: 'Post Conversions' },
];

export const measurementCheckboxItems = [
  { name: 'spikeLift', label: 'Spike Lift' },
  { name: 'alwaysOnIncrementality', label: 'Always-on Incrementality' },
];

export const CPM_DEFAULTS = {
  LEVEL_1: {
    measurementLevel: 'Level_1',
    campaignPerformance: true,
    pathToPurchase: false,
    postConversions: false,
    spikeLift: false,
    alwaysOnIncrementality: false,
  },
  LEVEL_2: {
    measurementLevel: 'Level_2',
    campaignPerformance: true,
    pathToPurchase: true,
    postConversions: false,
    spikeLift: true,
    alwaysOnIncrementality: false,
  },
  LEVEL_3: {
    measurementLevel: 'Level_3',
    campaignPerformance: true,
    pathToPurchase: true,
    postConversions: false,
    spikeLift: true,
    alwaysOnIncrementality: false,
  },
  LEVEL_4: {
    measurementLevel: 'Level_4',
    campaignPerformance: true,
    pathToPurchase: true,
    postConversions: false,
    spikeLift: true,
    alwaysOnIncrementality: false,
  },
};

export const CPA_DEFAULTS = {
  LEVEL_1: {
    measurementLevel: 'Level_1',
    campaignPerformance: true,
    pathToPurchase: false,
    postConversions: false,
    spikeLift: false,
    alwaysOnIncrementality: false,
  },
  LEVEL_2: {
    measurementLevel: 'Level_2',
    campaignPerformance: true,
    pathToPurchase: false,
    postConversions: false,
    spikeLift: true,
    alwaysOnIncrementality: false,
  },
  LEVEL_3: {
    measurementLevel: 'Level_3',
    campaignPerformance: true,
    pathToPurchase: false,
    postConversions: false,
    spikeLift: true,
    alwaysOnIncrementality: false,
  },
  LEVEL_4: {
    measurementLevel: 'Level_4',
    campaignPerformance: true,
    pathToPurchase: false,
    postConversions: false,
    spikeLift: true,
    alwaysOnIncrementality: false,
  },
};
