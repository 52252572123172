import React, { useContext } from "react";
import { Box, IconButton, ListItem, ListItemButton, ListItemText } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AdvertiserContext from "@components/AdvertiserContext";

interface RoutesSectionProps {
  label: string;
  routes: { key: string, path: string, label: string | React.ReactNode }[];
  active: string;
  handleRouteChange: (key: string, path: string) => void;
  collapsible?: boolean;
  isCollapsed?: boolean;
  toggleCollapse?: () => void;
  showSubtext?: (value: unknown) => boolean;
  subtext?: string;
}
export const LIST_ITEM_HEIGHT = 32;

const RoutesSection = ({
  label,
  routes,
  active,
  handleRouteChange,
  collapsible = false,
  isCollapsed = false,
  toggleCollapse = () => { },
  showSubtext = () => false,
  subtext,
}: RoutesSectionProps) => {
  const adContext = useContext(AdvertiserContext);

  return (
    <Box sx={{ marginBottom: '8px' }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          fontSize: '16px',
          padding: '4px 16px',
          fontWeight: 700,
          marginBottom: '4px',
          color: '#13171A',
        }}
      >
        {label}
        {collapsible && (
          <IconButton onClick={toggleCollapse}>
            {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        )}
      </Box>
      {
        !isCollapsed && (
          <Box>
            {routes.map(({ key, path, label }) => {
              const isActive = active === key;
              return (
                <ListItem key={key} disablePadding={true}>
                  <ListItemButton
                    id={key}
                    disableRipple={true}
                    sx={{
                      fontSize: '16px',
                      height: `${LIST_ITEM_HEIGHT}px`,
                      zIndex: 1,
                      position: 'relative',
                      '&:hover': {
                        backgroundColor: 'inherit',
                      },
                      '&:hover .hover-box': {
                        visibility: 'visible',
                        transform: 'scaleX(1)',
                      },
                    }}
                    onClick={() => handleRouteChange(key, path)}
                  >
                    <Box
                      className="hover-box"
                      sx={{
                        position: 'absolute',
                        width: 'calc(100% - 16px)',
                        height: '100%',
                        backgroundColor: '#daf1ff',
                        visibility: isActive ? 'visible' : 'hidden',
                        transform: isActive ? 'scaleX(1)' : 'scaleX(0)',
                        transformOrigin: 'left',
                        transition: 'all 0.2s ease-in-out',
                        zIndex: -1,
                        borderRadius: '2px 0 0 2px',
                      }}
                    />
                    <ListItemText
                      sx={{ paddingLeft: '12px' }}
                      primaryTypographyProps={{
                        fontSize: '16px',
                        color: isActive ? '#13171A' : '#5C6B73',
                        fontWeight: isActive ? 500 : 400,
                      }}
                      primary={label}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
            {showSubtext(adContext.cost_model) && subtext && (
              <Box fontSize="8px" marginLeft="24px" marginTop="8px">
                <sup>*</sup>
                {subtext}
              </Box>
            )}
          </Box>
        )
      }
    </Box >
  );
};

export default RoutesSection;
