import { styled } from '@mui/material';
import { TextFieldStyled } from '../styles';
import { SearchOutlined } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SearchFieldStyled = styled(TextFieldStyled)(({ theme }) => ({
  background: theme.palette.common.white,
  width: '100%',
  maxWidth: theme.spacing(41.25),
  minWidth: theme.spacing(20),
  marginRight: theme.spacing(4.25),

  '& .MuiOutlinedInput-root': {
    height: theme.spacing(4.25),

    '& input': {
      fontSize: '0.75rem',
      color: theme.palette.text.primary,
    },

    '& fieldset': {
      borderColor: `${theme.palette.grey['9']} !important`, // Default border color
    },

    '&:hover fieldset': {
      borderColor: `${theme.palette.grey['9']} !important`, // Prevent hover color change
    },

    '&.Mui-focused fieldset': {
      borderColor: `${theme.palette.blue['6']} !important`, // Prevent active color change
    },
  },
  '.MuiInputBase-input': {
    color: theme.palette.grey['5'],
  },
}));

// Define the styled component for the search icon
export const StyledSearchIcon = styled(SearchOutlined)(({ theme }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  color: theme.palette.grey['2'],
}));

// Define the styled component for the clear icon (FontAwesome "x" icon)
export const StyledClearIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  color: theme.palette.grey[6],
  cursor: 'pointer',
}));
