import React from 'react';
import { EmbedLookerChart } from "@components/EmbedLookerChart";
import ReportsLookerPage from "../components/ReportsLookerPage";
import { DASHBOARD_NAMES, LOOKER_CHARTS } from '../constants';

interface PostConversionsProps {
  setInExplore: React.Dispatch<React.SetStateAction<boolean>>;
  inExplore: boolean;
  internal?: boolean;
}

const PostConversions = ({ setInExplore, inExplore, internal }: PostConversionsProps) =>
  <ReportsLookerPage
    tabs={{
      id: LOOKER_CHARTS.POST_CONVERSIONS.id,
      title: LOOKER_CHARTS.POST_CONVERSIONS.title,
      content: () => {
        return (
          <EmbedLookerChart
            id={LOOKER_CHARTS.POST_CONVERSIONS.id}
            dashboard={DASHBOARD_NAMES.POST_CONVERSIONS}
            setInExplore={setInExplore}
            internal={internal}
          />)
      }
    }}
    setInExplore={setInExplore}
    inExplore={inExplore}
  />;

export default PostConversions;
