import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useAdGroupsTable } from '@components/containers/AdGroupsIndexPage/AdGroupsTableContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWavePulse } from '@fortawesome/pro-solid-svg-icons';
import { IconButton, Tooltip } from '@mui/material';
import { AdGroup } from '@local-types/group';

export const AdGroupRealtimeDeliveryCellRenderer = (
  params: GridCellParams<AdGroup>
) => {
  const { handleOpenRealTimeModal } = useAdGroupsTable();
  const { beeswax_lid, active, delivery_signal } = params.row;

  const iconColor = active
    ? delivery_signal === true
      ? '#2e7d32'
      : delivery_signal === false
      ? '#d32f2f'
      : '#9e9e9e'
    : '#9e9e9e';

  return (
    <Tooltip
      title="This indicates if your ad group is delivering in the last hour. Click to see realtime delivery graph."
      arrow
    >
      <IconButton
        disabled={!beeswax_lid}
        color="secondary"
        aria-label="open the realtime delivery graph modal"
        onClick={event => handleOpenRealTimeModal(event, params.row)}
        sx={{
          padding: '4px',
          color: iconColor,
          '&.Mui-disabled': {
            color: '#9e9e9e',
          },
        }}
      >
        <FontAwesomeIcon icon={faWavePulse} fontSize="18" />
      </IconButton>
    </Tooltip>
  );
};
