import { FilterValue } from './types';

type TableType = 'campaigns' | 'adGroups' | 'creatives';

const TABLE_PARAM_MAPPING: Record<TableType, Record<string, string>> = {
  campaigns: {
    campaignIds: 'ids',
    adGroupIds: 'lineitem_ids'
  },
  adGroups: {
    campaignIds: 'campaign_ids',
    adGroupIds: 'ids'
  },
  creatives: {
    campaignIds: 'campaign_ids',
    adGroupIds: 'lineitem_ids'
  }
};

const transformFilterValue = (value: FilterValue): string | undefined => {
  if (value === null) return undefined;
  if (Array.isArray(value)) return value.join(',');
  return String(value);
};

export const mapFilterKeysToQueryParams = (
  filters: Record<string, FilterValue>,
  table: TableType
): Record<string, string> => {
  const mapping = TABLE_PARAM_MAPPING[table];
  const result: Record<string, string> = {};

  Object.entries(filters).forEach(([key, value]) => {
    // Skip null values
    if (value === null) return;

    // Transform the value
    const transformedValue = transformFilterValue(value);
    if (transformedValue === undefined) return;

    // If key exists in mapping, use mapped key, otherwise use original key
    const mappedKey = mapping[key] || key;
    result[mappedKey] = transformedValue;
  });

  return result;
};
