const USER_GETS_VERTICAL_CAMPAIGN = 'USER_GETS_VERTICAL_CAMPAIGN';
const USER_GETS_VERTICAL_CAMPAIGN_DRAGS = 'USER_GETS_VERTICAL_CAMPAIGN_DRAGS';
const USER_GETS_VERTICAL_INVENTORY_AVAILABILITY =
  'USER_GETS_VERTICAL_INVENTORY_AVAILABILITY';
const USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS =
  'USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS';
const SYNC_CHECKS_ENABLED = 'SYNC_CHECKS_ENABLED';
const USER_GETS_VERTICAL_INVENTORY_RECOMMENDATIONS =
  'USER_GETS_VERTICAL_INVENTORY_RECOMMENDATIONS';
const USER_GETS_VERTICAL_PACING = 'USER_GETS_VERTICAL_PACING';
const USER_GETS_VERTICAL_CAMPAIGN_QUICKSTART =
  'USER_GETS_VERTICAL_CAMPAIGN_QUICKSTART';
const TARGETING_OVERRIDE_THRU_QUERY_PARAM_FEATURE =
  'TARGETING_OVERRIDE_THRU_QUERY_PARAM_FEATURE';
const LARGE_ASSET_UPLOAD_FEATURE = 'LARGE_ASSET_UPLOAD_FEATURE';
const USER_GETS_APP_BUNDLE_TARGETING = 'releaseCmAppbundleTargeting';
const USER_GETS_DOMAIN_LIST_TARGETING = 'releaseCmDomainlistTargeting';

export const Flags = {
  USER_GETS_VERTICAL_CAMPAIGN,
  USER_GETS_VERTICAL_CAMPAIGN_DRAGS,
  USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS,
  USER_GETS_VERTICAL_INVENTORY_AVAILABILITY,
  SYNC_CHECKS_ENABLED,
  USER_GETS_VERTICAL_INVENTORY_RECOMMENDATIONS,
  USER_GETS_VERTICAL_PACING,
  USER_GETS_VERTICAL_CAMPAIGN_QUICKSTART,
  TARGETING_OVERRIDE_THRU_QUERY_PARAM_FEATURE,
  LARGE_ASSET_UPLOAD_FEATURE,
  USER_GETS_APP_BUNDLE_TARGETING,
  USER_GETS_DOMAIN_LIST_TARGETING,
};

export const DefaultFlags = {
  [USER_GETS_VERTICAL_CAMPAIGN]: false,
  [USER_GETS_VERTICAL_CAMPAIGN_DRAGS]: false,
  [USER_GETS_VERTICAL_INVENTORY_AVAILABILITY]: false,
  [USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS]: false,
  [SYNC_CHECKS_ENABLED]: false,
  [USER_GETS_VERTICAL_INVENTORY_RECOMMENDATIONS]: false,
  [USER_GETS_VERTICAL_PACING]: false,
  [USER_GETS_VERTICAL_CAMPAIGN_QUICKSTART]: false,
  [TARGETING_OVERRIDE_THRU_QUERY_PARAM_FEATURE]: false,
  [LARGE_ASSET_UPLOAD_FEATURE]: false,
  [USER_GETS_APP_BUNDLE_TARGETING]: false,
  [USER_GETS_DOMAIN_LIST_TARGETING]: false,
};
