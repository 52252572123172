import React from 'react';
import { Typography } from '@mui/material';
import { TableGroupCellStyledVirtualized } from './styles';
import { PlaceholderGroupProps } from './types';

export const PlaceholderGroupVirtualized = <Data,>({
  data,
  ...props
}: PlaceholderGroupProps<Data>) => {
  return (
    <TableGroupCellStyledVirtualized {...props}>
      <Typography variant="caption" color="grey.1">
        {data.groupName}
      </Typography>
    </TableGroupCellStyledVirtualized>
  );
};
