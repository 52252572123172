import { Stack, Theme, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

interface DateTimeWithArrowProps {
  text: string;
  theme: Theme;
}

export const DateTimeWithArrow = ({
  text,
  theme,
}: DateTimeWithArrowProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        paddingRight: theme.spacing(4),
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="body2" sx={{ textDecoration: 'line-through' }}>
        {text}
      </Typography>
      <FontAwesomeIcon
        icon={faArrowRight}
        fontSize="20px"
        color={theme.palette.grey[9]}
      />
    </Stack>
  );
};
