import { escapeRegExp, first, flatMap, groupBy, isObject, map } from 'lodash';
import {
  GroupedData,
  HightlightedElement,
  IncludableData,
  TableTabSettings,
} from './types';

export const toHighlightedContent = (value: string, searchString: string) => {
  if (!searchString) return value;

  const searchReg = new RegExp(escapeRegExp(searchString.toLowerCase()), 'i');
  const searchIndexExec = searchReg.exec(value.toLowerCase());

  if (!searchIndexExec) {
    return [
      {
        value,
        stressed: false,
      },
    ];
  }

  return [
    {
      value: value.slice(0, searchIndexExec.index),
      stressed: true,
    },
    {
      value: value.slice(
        searchIndexExec.index,
        searchIndexExec.index + searchString.length
      ),
      stressed: false,
    },
    {
      value: value.slice(searchIndexExec.index + searchString.length),
      stressed: true,
    },
  ].filter(v => v.value);
};

export const fromHighlightedContent = (
  value: string | HightlightedElement[]
) => {
  if (Array.isArray(value)) {
    return value.reduce((acc, curr) => acc + curr.value, '');
  }

  return value;
};

export const makeEven = <Data>(
  data: Data[],
  minRowsAmount: number
): Data extends GroupedData<infer T>
  ? GroupedData<T | null>[]
  : (Data | null)[] => {
  const isGroup = data.length && isObject(data[0]) && 'data' in data[0];
  const dataLength = isGroup
    ? data.reduce(
        (acc, curr) => acc + (curr as GroupedData<Data>).data.length,
        0
      )
    : data.length;

  const actualAmount = Math.max(dataLength, minRowsAmount);

  return (
    dataLength >= actualAmount
      ? data
      : isGroup
      ? [
          ...data.slice(0, data.length - 1),
          {
            ...data[data.length - 1],
            data: [
              ...(data[data.length - 1] as GroupedData<Data>).data,
              ...Array(actualAmount - dataLength).fill(null),
            ],
          },
        ]
      : [...data, ...Array(actualAmount - dataLength).fill(null)]
  ) as Data extends GroupedData<infer T>
    ? GroupedData<T | null>[]
    : (Data | null)[];
};

export const groupDataByFields = <Data>(data: Data[], field: keyof Data) => {
  return Object.entries(groupBy(data, field)).map(([groupName, data]) => ({
    groupName,
    data,
  }));
};

export const transformFromGroupedData = <Value>(
  data: GroupedData<Value>[]
): Value[] => {
  const dataList = data ?? [];
  const additionalProps = {};

  const transformedData: GroupedData<Value>[] = dataList.map(item => ({
    ...item,
    ...additionalProps,
  }));

  if (transformedData.length) {
    if (transformedData[0].groupName) {
      return transformedData.reduce(
        (acc, curr) => [...acc, ...curr.data],
        [] as Value[]
      );
    }

    return [...transformedData] as Value[];
  }

  return [...transformedData] as Value[];
};

export const transformToIncludable = <Data>(
  value: Data,
  selected: IncludableData<Data>[],
  settings: TableTabSettings
) => {
  const additionalProps = {
    ...(settings.includable && !settings.separateInclExcl
      ? {
          included: first(selected)?.included ?? false,
        }
      : {}),
  };

  return {
    ...value,
    ...additionalProps,
  };
};

export const fromGroup = <Data>(data: GroupedData<Data>) =>
  flatMap(map(data, 'data'));
