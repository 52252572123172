import { Theme } from '@mui/material';
import React from 'react';

const DialogContext = React.createContext<{
  setDialog: (
    params: {
      isOpen: boolean;
      title: string | React.ReactNode;
      content: string | React.ReactNode;
      actions: string | React.ReactNode;
      onClose: () => void;
      showDefaultCloseButton: boolean;
      dialogStylesOverride: (theme: Theme) => Record<string, unknown>;
    } | null
  ) => void;
}>({
  setDialog: () => {},
});

DialogContext.displayName = 'DialogContext';

export default DialogContext;
