import { Themes } from '@constants/themes';

export const Copies = {
  [Themes.DEFAULT]: {
    addAll: 'Add all',
    excludeAll: 'Exclude all',
    includeAll: 'Include all',
    removeAll: 'Remove all',
  },
  [Themes.NBCU]: {
    addAll: 'Add All',
    excludeAll: 'Exclude All',
    includeAll: 'Include All',
    removeAll: 'Remove All',
  },
} as const;

export const defaultTableSettings = {
  includable: false,
  separateInclExcl: false,
} as const;

export const addType = {
  Single: 'single',
  All: 'all',
} as const;

export const addAction = {
  Add: 'add',
  Remove: 'remove',
  Update: 'update',
} as const;

export const VirtualizedRowType = {
  ROW: 'ROW',
  EMPTY_ROW: 'EMPTY_ROW',
  GROUP: 'GROUP',
} as const;

export const VirtualizedTypeProperty = Symbol('type');
