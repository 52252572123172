import React from 'react';
import { VirtualizedSelectionElementRowStyled } from '../styles';
import { Stack, StackProps } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { TableLeftRowProps } from './types';
import { TableData, VirtualizedData, WithId } from '../types';
import { VirtualizedRowType, VirtualizedTypeProperty } from '../constants';

export const TableLeftRowVirtualized = <
  Value,
  Data extends TableData<Value>
>({
  data,
  index,
  isTargeted,
  renderCell,
  PlaceholderCell,
  onChange,
  ...props
}: TableLeftRowProps<Value, Data> & Omit<StackProps, 'onChange'>) => {
  const typedData = data as VirtualizedData<Value>;
  const isEmpty =
    !typedData ||
    typedData[VirtualizedTypeProperty] === VirtualizedRowType.EMPTY_ROW;
  return (
    <VirtualizedSelectionElementRowStyled
      {...props}
      flexDirection="row"
      alignItems="center"
      className={!isEmpty ? 'hover-row' : ''}
      targeted={!!(!isEmpty && isTargeted)}
      highlighted={!!(index % 2)}
      key={(data as WithId<Value>)?.id ?? `placeholder-${index}`}
      onClick={() => {
        if (data) {
          onChange(data);
        }
      }}
    >
      <Stack
        sx={{
          flex: 1,
          flexDirection: 'column',
        }}
      >
        {!isEmpty ? (
          renderCell({ data: typedData })
        ) : (
          <PlaceholderCell data={typedData} type="left" />
        )}
      </Stack>
      <Stack>
        <AddCircleOutlineIcon
          className="hover-icon"
          color="primary"
          sx={{
            display: !isEmpty || !isTargeted ? 'none' : 'block',
          }}
        />
      </Stack>
    </VirtualizedSelectionElementRowStyled>
  );
};
