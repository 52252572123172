import { styled } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';

export const StyledAccordion = styled(MuiAccordion)(({ theme }) => ({
  background: theme.palette.common.offWhite,
  boxShadow: 'none',
  margin: 0,
  padding: 0,
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
  '& + &': {
    borderTop: `1px solid ${theme.palette.grey[10]}`,
  },
  '&:first-of-type': {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  '&:last-of-type': {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  }
})); 
