import { ReactNode } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import moment from 'moment-timezone';

export const DateTimeCellRenderer = (
  params: Partial<GridCellParams>,
  adGroupDate?: string | null,
  fallbackText = '-' as ReactNode,
) => {
  const dateFormat = 'MM/DD/YYYY h:mm a';

  if (!params.value || typeof params.value !== 'string') {
    if (adGroupDate) return moment(adGroupDate).format(dateFormat);

    return fallbackText ?? null;
  }

  const date = moment(params.value);
  if (!date.isValid()) {
    return fallbackText ?? null;
  }
  return date.format(dateFormat);
};
