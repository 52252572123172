import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { generatePath } from 'react-router-dom';
import { Display, LineItem } from '@local-types';
import { RoutePaths } from '@constants/routes';
import { DataGridLink } from '@v2/components/ui/DataGrid/DataGridLink';

export const DisplayAdGroupCellRenderer = (
  params: GridCellParams<Display>
) => {
  const { staticdisplaylineitem_set, id: displayId } = params.row;
  const campaign = (staticdisplaylineitem_set?.[0] as LineItem)?.campaign ?? 0;
  const campaignId =
    typeof campaign === 'string'
      ? campaign?.split('/campaigns/')[1]?.slice(0, -1)
      : (campaign as number);

  const adGroupName = (staticdisplaylineitem_set?.[0] as LineItem)?.name;

  if (!campaignId || !adGroupName || !displayId) return <span>-</span>;

  return (
    <DataGridLink to={generatePath(RoutePaths.AD_GROUPS, { campaignId })}>
      {adGroupName}
    </DataGridLink>
  );
};
