import { GridCellParams } from '@mui/x-data-grid';
import {
  convertISOToDateTime,
  getBidStrategyLabels,
  getCellColors,
  getCellVisibility,
} from '../utils';
import { Stack, useTheme } from '@mui/material';
import { MediumTypography } from '../components';

export const toCellRenderer = (params: GridCellParams) => {
  const {
    startDateVisible,
    endDateVisible,
    bidStrategyVisible,
    bidStrategyEventVisible,
    cpmVisible,
  } = getCellVisibility(params);

  const isoStartDate = params.row.intended_edits.start_date;
  const isoEndDate = params.row.intended_edits.end_date;
  const cpm = params.row.intended_edits.cpm;

  const [start_date, start_time] = convertISOToDateTime(isoStartDate);
  const [end_date, end_time_base] = isoEndDate
    ? convertISOToDateTime(isoEndDate)
    : ['No end date', 'No end time'];
  const end_time = isoEndDate ? `${end_time_base} (ET)` : end_time_base;

  const theme = useTheme();

  const [
    startColor,
    endColor,
    bidStrategyColor,
    bidStrategyEventColor,
    cpmColor,
  ] = getCellColors(params, theme);

  const [bidStrategyLabel, bidStrategyEventLabel] = getBidStrategyLabels(
    params,
    'to'
  );

  return (
    <Stack spacing={theme.spacing(3)}>
      {startDateVisible && (
        <>
          <MediumTypography text={start_date} color={startColor} />
          <MediumTypography text={`${start_time} (ET)`} color={startColor} />
        </>
      )}
      {endDateVisible && (
        <>
          <MediumTypography text={end_date} color={endColor} />
          <MediumTypography text={end_time} color={endColor} />
        </>
      )}
      {bidStrategyVisible && (
        <MediumTypography
          text={bidStrategyLabel || 'None'}
          color={bidStrategyColor}
        />
      )}
      {bidStrategyEventVisible && (
        <MediumTypography
          text={bidStrategyEventLabel || 'None'}
          color={bidStrategyEventColor}
        />
      )}
      {cpmVisible && <MediumTypography text={`$${cpm}`} color={cpmColor} />}
    </Stack>
  );
};
