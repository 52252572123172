import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Typography, Tabs, Tab, Stack } from '@mui/material';
import { useRBACContext } from '@providers/RBACContext';
import { useFlags } from '@hooks';
import { mapFieldsBack } from '@utils/mapFields';
import {
  useGetOrganization,
  useGetNBCUOrganization,
  usePatchOrganization,
  usePutNBCUOrganization,
} from '@apis/organizations';

import { GeneralInfoForm, AdminForm } from './components';
import {
  AdminFieldMappings,
  AdminReverseFieldMappings,
  GeneralInfoFieldMappings,
  GeneralInfoReverseFieldMappings,
  mapGeneralInfoFields,
  mapAdminFields,
} from './helpers';
import { TabValues } from './constants';
import AdvertiserContext from '../AdvertiserContext';
import { OrgChips } from '../OrgChips';

const ManageOrganization = () => {
  const adContext = useContext(AdvertiserContext);
  const { flags } = useFlags();
  const { permissions } = useRBACContext();

  const canViewNBCUOrganization = useMemo(
    () => permissions.CAN_VIEW_NBCU_ORGANIZATION,
    [flags, permissions]
  );
  const [, orgId] = useMemo(() => adContext.primary_org.split('/').reverse(), [adContext]);

  const { data: organization = {} } = useGetOrganization(orgId);
  const { data: nbcuOrganization = {} } = useGetNBCUOrganization(canViewNBCUOrganization ? orgId : null);
  const { trigger: patchOrganization } = usePatchOrganization(orgId);
  const { trigger: putNBCUOrganization } = usePutNBCUOrganization(canViewNBCUOrganization ? orgId : null);

  const [tabValue, setTabValue] = useState(TabValues.GENERAL_INGO);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const generalInfoFormFields = useMemo(
    () => mapGeneralInfoFields(organization),
    [organization],
  );

  const adminFormFields = useMemo(
    () => mapAdminFields(nbcuOrganization),
    [nbcuOrganization],
  );

  const onGeneralInfoFormSubmit = useCallback(async (values, setError) => {
    try {
      const data = mapFieldsBack(values, GeneralInfoFieldMappings);

      await patchOrganization(data);
    } catch (error) {
      const { data: errors } = error.response;

      Object.keys(errors).forEach(key => {
        if (errors[key] instanceof Array) {
          setError(GeneralInfoReverseFieldMappings[key], {
            type: "server",
            message: errors[key][0],
          });
        }
      });
    }
  }, []);

  const onAdminFormSubmit = useCallback(async (values, setError) => {
    try {
      const data = mapFieldsBack(values, AdminFieldMappings);

      await putNBCUOrganization(data);
    } catch (error) {
      const { data: errors } = error.response;

      Object.keys(errors).forEach(key => {
        if (errors[key] instanceof Array) {
          setError(AdminReverseFieldMappings[key], {
            type: "server",
            message: errors[key][0],
          });
        }
      });
    }
  }, []);

  return (
    <Stack
      spacing={5}
      sx={{
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Organization Tabs"
          sx={{ width: '100%' }}
        >
          <Tab label={<Typography variant="h4">General Info</Typography>} value={TabValues.GENERAL_INGO} />

          {canViewNBCUOrganization && (
            <Tab label={<Typography variant="h4">Admin</Typography>} value={TabValues.ADMIN} />
          )}
        </Tabs>

        <OrgChips org={organization} />
      </Stack>

      {tabValue === TabValues.GENERAL_INGO && (
        <GeneralInfoForm
          data={generalInfoFormFields}
          onSubmit={onGeneralInfoFormSubmit}
        />
      )}

      {tabValue === TabValues.ADMIN && (
        <AdminForm
          data={adminFormFields}
          onSubmit={onAdminFormSubmit}
        />
      )}
    </Stack>
  );
};

export default ManageOrganization;
