import React from "react";
import ReportsLookerPage from "../components/ReportsLookerPage";
import { LOOKER_CHARTS } from "../constants";
import { PathToPurchase } from "../components/PathToPurchase";

const ReportsPathToPurchase = ({ inExplore, setInExplore, internal }: { inExplore: boolean, setInExplore: React.Dispatch<React.SetStateAction<boolean>>, internal?: boolean }) => {
  return (
    <ReportsLookerPage
      tabs={{
        id: LOOKER_CHARTS.PATH_TO_PURCHASE.id,
        title: LOOKER_CHARTS.PATH_TO_PURCHASE.title,
        content: (
          setInExplore?: (inExplore: boolean) => void) => (
          <PathToPurchase
            setInExplore={setInExplore}
            internal={internal}
          />
        )
      }}
      setInExplore={setInExplore}
      inExplore={inExplore}
    />);
}

export default ReportsPathToPurchase;
