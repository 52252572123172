import React from 'react';
import { EmbedLookerChart } from "@components/EmbedLookerChart";
import ReportsLookerPage from "../components/ReportsLookerPage";
import { Stack, Typography } from '@mui/material';
import { DASHBOARD_NAMES, LOOKER_CHARTS } from '../constants';

interface ReportsBetaProps {
  setInExplore: React.Dispatch<React.SetStateAction<boolean>>;
  inExplore: boolean;
}

const ReportsBeta = ({ setInExplore, inExplore }: ReportsBetaProps) =>
  <ReportsLookerPage
    tabs={{
      id: LOOKER_CHARTS.BETA.id,
      title: LOOKER_CHARTS.BETA.title,
      content: () => (
        <Stack direction="column" spacing={2}>
          <Typography variant="body2">
            This screen is displaying reports that are tested internally while being considered for wider release. Please share with customers, and collect their feedback.
          </Typography>
          <EmbedLookerChart
            id={LOOKER_CHARTS.BETA.id}
            dashboard={DASHBOARD_NAMES.BETA}
            setInExplore={setInExplore}
          />
        </Stack>
      )
    }}
    setInExplore={setInExplore}
    inExplore={inExplore}
  />;

export default ReportsBeta;
