import { useWizardContext } from './useWizardContext';
import { useUser } from '@hooks/user';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';

export const useWizardPreview = ({
  key,
  group,
}: {
  key: string;
  group?: { out_of_sync: { beeswax_targeting: boolean } };
}) => {
  const { user } = useUser();
  const {
    navigation: { activeSection },
    state,
  } = useWizardContext();
  const { releaseSyncCheckColumn } = useLDFlags();

  // An ad group is considered out of sync if we receive an `out_of_sync` parameter from the api with any keys/values in it
  // indicating that these fields are out of sync with Beezewax
  const showOutOfSync =
    user?.is_tvsci_employee &&
    releaseSyncCheckColumn && 
    Boolean(group?.out_of_sync && Object.keys(group?.out_of_sync).length);

  return {
    active: key === activeSection,
    showOutOfSync,
    state,
  };
};
