import { yupResolver } from '@hookform/resolvers/yup';
import { defaultTo } from 'lodash';
import { useForm, useFormContext } from 'react-hook-form';
import {
  creativesSchema,
  fields,
  weightingRotationOptions,
} from '../constants';
import { fields as groupFields } from '@components/WizardContainers/GroupSection/formConfig';
import { useWizardPane } from '@components/Wizard';
import { labels } from '@components/WizardContainers/GroupSection/constants';
import { useEffect } from 'react';
import moment from 'moment';

export const useCreativeWrapper = ({
  creatives,
  onSubmit,
  weightingRotation,
}) => {
  const { watch } = useFormContext();
  const [adGroupStartDate, adGroupStartTime, adGroupEndDate, adGroupEndTime] =
    watch([
      groupFields.startDate.path,
      groupFields.startTime.path,
      groupFields.endDate.path,
      groupFields.endTime.path,
    ]);
  const adGroupContext = {
    adGroupStartDate: adGroupStartDate
      ? moment(adGroupStartDate)
          .set({
            minutes: adGroupStartTime
              ? moment(adGroupStartTime).minutes()
              : null,
            hours: adGroupStartTime ? moment(adGroupStartTime).hours() : null,
          })
          .toISOString()
      : null,
    adGroupEndDate: adGroupEndDate
      ? moment(adGroupEndDate)
          .set({
            minutes: adGroupEndTime ? moment(adGroupEndTime).minutes() : null,
            hours: adGroupEndTime ? moment(adGroupEndTime).hours() : null,
          })
          .toISOString()
      : null,
  };
  const { updateEntityState } = useWizardPane({
    key: labels.creative.value,
    workflowName: labels.creative.label,
  });
  const form = useForm({
    mode: 'onTouched',
    resolver: yupResolver(creativesSchema),
    values: {
      ...Object.values(fields).reduce((acc, { path, defaultValue }) => {
        acc[path] = defaultValue;
        return acc;
      }),
      [fields.weightingRotation.path]:
        weightingRotation === weightingRotationOptions.weighted,
      [fields.creatives.path]: defaultTo(creatives, []),
    },
    context: adGroupContext,
  });

  const handleSubmit = () => {
    onSubmit({
      creatives: form.getValues(fields.creatives.path),
      weightingRotation: form.getValues(fields.weightingRotation.path),
    });
  };

  const {
    formState: { isDirty },
  } = form;

  useEffect(() => {
    updateEntityState({
      dirty: isDirty,
    });
  }, [isDirty, updateEntityState]);

  return {
    form,
    submit: handleSubmit,
  };
};
