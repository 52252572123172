import React from 'react';
import { TableLeftRowVirtualized } from '../TableLeftRowVirtualized';
import { TableLeftProps } from '../types';
import { GroupedData, TableData } from '../../types';
import { VirtualizedRowType, VirtualizedTypeProperty } from '../../constants';
import type { TableRowProps } from 'react-virtualized';

export const useVirtualizedRenderer = <Value, Data extends TableData<Value>>({
  onChange,
  renderCell,
  renderGroup,
  PlaceholderCell,
  PlaceholderGroup,
  isTargeted,
}: Pick<
  TableLeftProps<Value, Data>,
  | 'onChange'
  | 'renderCell'
  | 'renderGroup'
  | 'PlaceholderCell'
  | 'PlaceholderGroup'
> & {
  isTargeted: boolean;
}) => {
  const renderedElement = ({ key, index, style, rowData }: TableRowProps) => {
    return rowData[VirtualizedTypeProperty] === VirtualizedRowType.GROUP ? (
      renderGroup ? (
        renderGroup({
          key,
          data: rowData as GroupedData<Value>,
          style,
          type: 'left',
        })
      ) : (
        <PlaceholderGroup
          key={key}
          style={style}
          type={'left'}
          data={rowData as GroupedData<Value>}
        />
      )
    ) : (
      <TableLeftRowVirtualized
        key={key}
        index={index}
        data={rowData as Value}
        style={style}
        isTargeted={isTargeted}
        renderCell={renderCell}
        PlaceholderCell={PlaceholderCell}
        onChange={onChange}
      />
    );
  };

  return renderedElement;
};

useVirtualizedRenderer.displayName = 'useVirtualizedRenderer';
