import BulkEditModalContent from './BulkEditModalContent';
import { BulkEditModalProps } from './constants';
import { BulkEditProvider } from './context';

const BulkEditModal = ({ ...props }: BulkEditModalProps) => {
  return (
    <BulkEditProvider>
      <BulkEditModalContent {...props} />
    </BulkEditProvider>
  );
};

export default BulkEditModal;
