import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Checkbox,
  IconButton,
  Typography,
  Divider,
  Grid,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  InputAdornment,
  FormControl,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ModalWrapper from '../../ModalWrapper';
import InfoTooltip from '../../InfoTooltip';
import { RHFAdvertiserUserRoleSelect, RHFTextField } from '@components/form';

const PREFIX = 'AddUserToAdAccountModal';

const classes = {
  head: `${PREFIX}-head`,
  root: `${PREFIX}-root`,
  closeIcon: `${PREFIX}-closeIcon`,
  description: `${PREFIX}-description`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`
};

const StyledModalWrapper = styled(ModalWrapper)(({
  theme: { breakpoints }
}) => ({
  [`&.${classes.root}`]: {
    [breakpoints.up('lg')]: {
      '& .MuiContainer-maxWidthLg': {
        maxWidth: '940px',
      }
    },
  },

  [`& .${classes.closeIcon}`]: {
    position: 'absolute',
    top: '0',
    right: '0',
  },

  [`& .${classes.description}`]: {
    color: '#5C6B73',
  },

  [`& .${classes.search}`]: {
    width: '100%',

    '& .MuiOutlinedInput-root': {
      borderRadius: '18px',
    },
  },

  [`& .${classes.searchIcon}`]: {
    color: '#77858C',
  }
}));

const StyledTableCell = styled(TableCell)(() => ({
  '& .MuiTableCell-head': {
    color: '#031620',
  },
}));

export const AddUserToAdAccountModal = ({
  title,
  description,
  data,
  onSubmit,
  onClose,
}) => {
  const { control, watch, handleSubmit, formState } = useForm({
    defaultValues: {
      search: '',
      selectAllUsers: false,
      selectedUsers: Object.fromEntries(data.map(item => [item.id, {
        selected: false,
        id: item.id,
        advertiserUserId: item.advertiserUserId,
        advertiser: item.advertiser,
        user: item.user,
        role: item.role
      }])),
    },
  });

  const search = watch('search');

  const { isSubmitting } = formState;

  const filteredData = useMemo(() => {
    const regex = new RegExp(search, 'i');

    return data.filter(row => [row.name, row.email].some(col => regex.test(col)));
  }, [data, search]);

  return (
    <StyledModalWrapper
      isOpen
      hasSubmit
      className={classes.root}
      submit="Update Roles"
      isLoading={isSubmitting}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container direction="column" spacing={2}>
        <Grid component={Box} item position="relative">
          <Typography variant="h3">{title}</Typography>

          <p className={classes.description}>{description}</p>

          <IconButton className={classes.closeIcon} onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid item>
          <Divider component={Grid} item />
        </Grid>

        <Grid item>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Controller
                    name="selectAllUsers"
                    control={control}
                    render={({ field }) => <Checkbox {...field} />}
                  />
                </TableCell>
                <StyledTableCell
                  align="left"
                  classes={{
                    head: classes.head
                  }}>User <InfoTooltip title="The user you are adding. If the person you're looking for is not listed, please contact the admin for the organization and ask that they add the person in question." /></StyledTableCell>
                <StyledTableCell
                  align="left"
                  classes={{
                    head: classes.head
                  }}>
                  <FormControl variant="standard" className={classes.search}>
                    <RHFTextField
                      control={control}
                      name="search"
                      color="secondary"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                      placeholder="Filter users by name or email"
                      value=""
                      variant="outlined"
                    />
                  </FormControl>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  classes={{
                    head: classes.head
                  }}>Ad Account Role <InfoTooltip title="The Ad Account role for the user you are adding." /></StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredData.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Controller
                      name={`selectedUsers.${item.id}.selected`}
                      control={control}
                      defaultValue={false}
                      render={({ field }) =>
                        (<Checkbox {...field} />)
                      }
                    />
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>
                    <RHFAdvertiserUserRoleSelect
                      control={control}
                      name={`selectedUsers.${item.id}.role`}
                    />
                  </TableCell>
                </TableRow>
              ))}

              {filteredData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4}>
                    All organization users are already members of this ad account.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </StyledModalWrapper>
  );
};

AddUserToAdAccountModal.propTypes = {
  title: PropTypes.element.isRequired,
  description: PropTypes.element.isRequired,
  data: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
