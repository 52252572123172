import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faTimer, faInbox } from '@fortawesome/pro-regular-svg-icons';
import { RHFTextField } from '@components/form';
import { formatBytes, formatDuration } from '@v2/components/campaign/FileUploads';
import { FormFields } from '../constants';

const EditElement = ({
  name,
  size,
  duration,
  formName,
  control,
}) => (
  <Stack direction="column" spacing={2.5} sx={{ width: '100%' }}>
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
    >
      <Typography variant="body2" color="text.primary" fontWeight={700}>
        <Box component="span" sx={{ color: 'grey.5' }}>
          <FontAwesomeIcon icon={faPlayCircle} size="1x" />
        </Box>
        &nbsp;{name}
      </Typography>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Typography variant="caption" color="grey.3">
          {formatBytes(size)} &#8226; {formatDuration(duration)} &#8226; 1080P
        </Typography>

        <Box component="span" sx={{ color: 'grey.3' }}>
          <FontAwesomeIcon icon={faInbox} size="1x" />
        </Box>
      </Stack>
    </Stack>

    <RHFTextField
      control={control}
      name={`${formName}.${FormFields.items.fields.NAME}`}
      label="Creative Title"
      placeholder="Enter Creative Title"
    />

    <Stack
      direction="row"
      spacing={1}
      sx={{
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Box component="span" sx={{ color: 'blue.7' }}>
        <FontAwesomeIcon icon={faTimer} size="1x" />
      </Box>

      <Typography variant="caption" color="grey.3">
        Queued for review
      </Typography>
    </Stack>
  </Stack>
);

EditElement.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  formName: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
};

export default EditElement;
