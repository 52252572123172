import React, { useMemo } from 'react';

import { EmbedLookerChart } from '../../../EmbedLookerChart';
import ReportsLookerPage from '../components/ReportsLookerPage';
import { DASHBOARD_NAMES, LOOKER_CHARTS } from '../constants';

const ReportsAlwaysOn = ({
  inExplore,
  setInExplore,
  active,
  internal = false,
}: {
  inExplore: boolean,
  setInExplore: React.Dispatch<React.SetStateAction<boolean>>,
  active?: boolean,
  internal?: boolean,
}) => {

  const tabs = useMemo(() => [
    {
      id: LOOKER_CHARTS.INCREMENTALITY_SUM.id,
      title: LOOKER_CHARTS.INCREMENTALITY_SUM.title,
      content: () => (
        <EmbedLookerChart
          id={LOOKER_CHARTS.INCREMENTALITY_SUM.id}
          dashboard={DASHBOARD_NAMES.INCREMENTALITY_SUM}
          setInExplore={setInExplore}
          internal={internal}
        />
      )
    },
    {
      id: LOOKER_CHARTS.INCREMENTALITY_CAMPAIGN.id,
      title: LOOKER_CHARTS.INCREMENTALITY_CAMPAIGN.title,
      content: () => (
        <EmbedLookerChart
          id={LOOKER_CHARTS.INCREMENTALITY_CAMPAIGN.id}
          dashboard={DASHBOARD_NAMES.INCREMENTALITY_CAMPAIGN}
          setInExplore={setInExplore}
        />
      )
    },
    {
      id: LOOKER_CHARTS.INCREMENTALITY_ADGROUP.id,
      title: LOOKER_CHARTS.INCREMENTALITY_ADGROUP.title,
      content: () => (
        <EmbedLookerChart
          id={LOOKER_CHARTS.INCREMENTALITY_ADGROUP.id}
          dashboard={DASHBOARD_NAMES.INCREMENTALITY_ADGROUP}
          setInExplore={setInExplore}
        />
      )
    },
  ], [])

  return (
    <ReportsLookerPage
      tabs={tabs}
      setInExplore={setInExplore}
      inExplore={inExplore}
      active={active}
    />
  );
};
export default ReportsAlwaysOn;
