import { styled } from '@mui/material';
import { DataGrid } from '@v2/components/ui/DataGrid';

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell': {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    alignItems: 'flex-start',
  },

  // Hide separators between column headers
  '& .MuiDataGrid-columnSeparator--sideRight': {
    display: 'none',
  },

  // Hide focus outline on cells and headers
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },

  '& .row-theme--Error': {
    backgroundColor: `${theme.palette.red[5]} !important`,
  },

  // Override default row selection/hover color
  '& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row:hover, & .MuiDataGrid-row:nth-of-type(odd).Mui-selected:hover':
    {
      backgroundColor: 'inherit',
    },

  '& .MuiCheckbox-root': {
    padding: 0,
  },
}));
