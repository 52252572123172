/* eslint-disable @typescript-eslint/no-explicit-any */

import type { User } from '@local-types/user';
import type { AdGroup } from '@local-types/group';
import { isEqual } from 'lodash';
import moment, { type Moment } from 'moment';
import { Targeting } from '@local-types/targeting';

export const parseTargeting = (passedTargeting: string): Targeting => {
  const targeting = passedTargeting ?? '{}';

  try {
    return JSON.parse(targeting);
  } catch {
    return {
      bundles: [],
      segments: [],
      excluded_segments: [],
      dayparting: [],
      timezone: '',
      geo: {
        countries: [
          {
            id: '',
            name: 'Entire US',
            type: 'country',
            text: 'Entire US',
            blacklist: false,
            place_name: 'Entire US',
          },
        ],
        states: [],
        cities: [],
        zipcodes: [],
        dmas: [],
      },
      device: { ids: [], blacklist: false },
      os: { ids: [], blacklist: false },
      inventory: '',
    } satisfies Targeting;
  }
};

export const getObjectDiff = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
): string[] => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!Object.hasOwn(obj2, key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
};

export const showOutOfSyncSubmitPrompt = ({
  adGroup,
  user,
  isTargetingOverrideEnabled,
}: {
  adGroup: AdGroup;
  user: User | null;
  isTargetingOverrideEnabled: boolean;
}) => {
  const isInternal = user?.is_tvsci_employee;
  const isOutOfSync = Boolean(adGroup?.out_of_sync?.targeting);
  return isInternal && isOutOfSync && isTargetingOverrideEnabled;
};

export const getOutOfSyncItems = ({ adGroup }: { adGroup: AdGroup }) => {
  const beeswax_targeting = adGroup?.out_of_sync?.beeswax_targeting;
  if (!beeswax_targeting) return [];
  const diff = getObjectDiff(
    (beeswax_targeting as Record<string, any>).local,
    (beeswax_targeting as Record<string, any>).beeswax
  );
  return diff.map(
    key =>
      `${String(key).charAt(0).toUpperCase()}${String(key).slice(
        1
      )} Targeting`
  );
};

export const toET = (date: string | Date | Moment) =>
  moment(date).tz('America/New_York');
