import React, { useEffect, useMemo, useState, useContext } from 'react';
import ModalWrapper from '../../ModalWrapper';
import PropTypes from 'prop-types';
import { Box, DialogTitle, Grid, IconButton, Link, MenuItem, Typography, Autocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { RHFTextField } from '@components/form';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAPI } from '../../hooks/api';
import { useUserAccountsContext } from '../UserAccountsContext';
import LoadingSpinner from '../../ui/LoadingSpinner';
import { useRBACContext } from '../../../providers/RBACContext';
import AdvertiserContext from '../../AdvertiserContext';

const schema = Yup.object().shape({
  adAccount: Yup.string().required('Ad Account is required'),
  role: Yup.string().required('Role is required'),
});

export const GrantAccessDialog = ({
  onClose,
  onAdded,
  users
}) => {
  const { currentUser: user, toggleRoleAndPrivileges } = useUserAccountsContext()
  const { roles } = useRBACContext();
  const adContext = useContext(AdvertiserContext);

  const userName = `${user.user_first_name} ${user.user_last_name}`

  const [status, setStatus] = useState('');
  const [adAccounts, setAdAccounts] = useState(null);

  const isLoading = status === 'loading';

  const { usePost, usePatch, useGet } = useAPI();

  const fetchAdAccounts = () => {
    useGet('/advertisers/for_org/').then(res => {

      if(res?.response?.status >= 400) {
        console.log('error getting advertisers for org', res);
      } else {
        setAdAccounts(res || []);
      }
    })
  };

  const availableAdAccounts = useMemo(() => {
    if (!adAccounts || !users) return [];

    const userActive = Object.fromEntries(users.map(item => [item.advertiser, item.status]));

    return adAccounts.filter(item => !userActive[item.url] || userActive[item.url] === 'DEACTIVATED');
  }, [adAccounts, users]);

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      adAccount: '',
      role: 'CAMPAIGN_MANAGER',
    },
  })

  const onSubmit = form.handleSubmit(async ({ adAccount, role }) => {
    setStatus('loading');
    try {
      const alreadyExists = users.find(item => item.advertiser === adAccount);
      if (alreadyExists) {
        await usePatch(`/advertiser_users/${alreadyExists.id}/`, {
          status: 'ACTIVE',
        });
        onAdded();
        return;
      }

      await usePost('/advertiser_users/', {
        advertiser: adAccount,
        user: user.user,
        role,
      });

      onAdded();
    } catch (error) {
      console.log(error);
      setStatus('error');
    }
  });

  useEffect(() => {
    if (adContext.primary_org) {
      fetchAdAccounts();
    }
  }, [adContext?.primary_org]);

  return (
    <FormProvider {...form}>
      <ModalWrapper isOpen hasSubmit submit="Add a user"
        isLoading={isLoading}
        maxWidth="md"
        onSubmit={onSubmit}
        onClose={onClose}>

        <DialogTitle id="delete-draft-dialog">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              <Typography variant="h3">Grant Access to an Ad Account</Typography>
              <Typography variant='overline' color='textSecondary'>
                You are granting <b>{userName}</b> access to an ad account.
                Learn more about <Link color='secondary' onClick={toggleRoleAndPrivileges} underline="hover">User Roles & Privileges</Link>.
              </Typography>
            </div>

            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>


        <Grid container spacing={2}>
          <Grid
            container
            item
            direction="column"
            md={6}
            spacing={3}
            sx={{ margin: '0 auto' }}
          >
            <Grid item>
              <Autocomplete
                id="adAccount"
                options={availableAdAccounts || []}
                getOptionLabel={(option) => option.ad_account_name || 'No name set'}
                onChange={(event, value) => form.setValue('adAccount', value ? value.url : '')}
                renderInput={(params) => (
                  <RHFTextField
                    {...params}
                    name="adAccount"
                    label="Ad Account"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item>
              <RHFTextField
                name="role"
                select
                fullWidth
              >
                {(roles?.advertiser_roles || []).map(({ value, label }) => (
                  <MenuItem key={value} value={value} >
                    {label}
                  </MenuItem>
                ))}
              </RHFTextField>
            </Grid>
          </Grid>
        </Grid>
        {adAccounts === null && <LoadingSpinner />}
      </ModalWrapper>
    </FormProvider>
  );
}

GrantAccessDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAdded: PropTypes.func,
  users: PropTypes.array.isRequired,
};
