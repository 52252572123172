import { useController } from 'react-hook-form';
import type {
  Control,
  FieldValues,
  FieldPath,
} from 'react-hook-form/dist/types';
import { SelectDate } from '@v2/components/ui/SelectDate';
import { SelectProps } from '@mui/material';

type RHFDatePickerProps<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  label: string;
  clearable?: boolean;
  lockIcon?: boolean;
  testId: string;
} & Omit<SelectProps, 'name'>;

export const RHFDatePicker = <T extends FieldValues>({
  name,
  control,
  label,
  clearable,
  lockIcon,
  testId,
  ...rest
}: RHFDatePickerProps<T>) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <SelectDate
      {...field}
      // @ts-expect-error TS2322: SelectDate prop types
      fieldState={fieldState}
      label={label}
      value={field.value || null}
      clearable={clearable}
      lockIcon={lockIcon}
      testId={testId}
      {...rest}
    />
  );
};
