import React, { forwardRef, useState } from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { faCalendarDay } from '@fortawesome/pro-regular-svg-icons';
import { SelectDateTimeProps } from './types';
import { IconStyled } from './styles';

const SelectDateTime = forwardRef<HTMLDivElement, SelectDateTimeProps>(
  ({ label, uniqueKey, testId, minDateTime, maxDateTime, ...props }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const {
      field,
      fieldState,
      slotProps: passedSlotProps,
      clearable,
      onBlur,
      ...rest
    } = props;

    const slotProps =
      passedSlotProps ?? fieldState
        ? {
            textField: {
              error: fieldState?.invalid,
              helperText: fieldState?.error?.message,
              onBlur,
            },
          }
        : {};

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          minDateTime={minDateTime}
          minTime={minDateTime}
          maxDateTime={maxDateTime}
          maxTime={maxDateTime}
          {...rest}
          {...field}
          ref={ref}
          label={label}
          format="MMM D, YYYY h:mm A"
          closeOnSelect={false}
          key={uniqueKey}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          slotProps={{
            field: {
              clearable: !!clearable,
              inputProps: {
                'data-testid': `${testId}-input`,
              } as Record<string, string>,
            },
            textField: {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setIsOpen(true)}
                      data-testid={`${testId}-calendar-button`}
                    >
                      <IconStyled icon={faCalendarDay} />
                    </IconButton>
                  </InputAdornment>
                ),
              },
              inputProps: {
                'data-testid': `${testId}-textfield`,
              },
              onBlur,
              ...slotProps.textField,
            },
            desktopPaper: {
              'data-testid': `${testId}-dropdown`,
            } as Record<string, string>,
          }}
        />
      </LocalizationProvider>
    );
  }
);

SelectDateTime.displayName = 'SelectDateTime';

export default SelectDateTime;
