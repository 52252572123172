import { UseGetReportingHomeDataProps } from '@components/containers/ReportsPage/types';
import useReportingHomeData from './reportingHome';
import { useContext } from 'react';
import AdvertiserContext, { COST_MODEL } from '@components/AdvertiserContext';

const useSpend = ({ dateFilter }: UseGetReportingHomeDataProps) => {
  const adContext = useContext(AdvertiserContext);
  const disable = adContext.cost_model === COST_MODEL.CPM;
  return useReportingHomeData({ dateFilter, endpoint: 'spend', disable });
}

export default useSpend;
