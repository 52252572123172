import { useFieldArray, useFormContext } from 'react-hook-form';
import { fields } from '../../constants';
import { fields as manageFields } from '../constants';
import { useCreativeContext } from '../../hooks/useCreativeContext';
import { transformFromImpressionPixelsArray } from '../../utils';
import { useCreativeGapsCheck } from './useCreativeGapsCheck';

export const useManage = ({ onSubmit }) => {
  const { updateCreative } = useCreativeContext();
  const { control, watch, trigger, setValue, getFieldState } =
    useFormContext();

  const {
    fields: listFields,
    remove,
    update,
  } = useFieldArray({
    control,
    name: fields.draftCreatives.path,
  });

  const [draftCreatives, weightingRotation] = watch([
    fields.draftCreatives.path,
    fields.weightingRotation.path,
  ]);

  const datetimeFields = draftCreatives.flatMap((_, index) => [
    `${fields.draftCreatives.path}.${index}.${manageFields.start_date.path}`,
    `${fields.draftCreatives.path}.${index}.${manageFields.end_date.path}`,
  ]);

  const controlledFields = listFields.map((field, index) => ({
    ...field,
    ...draftCreatives[index],
  }));

  const { gaps: gapsInCreative, setGaps: setGapsInCreative } =
    useCreativeGapsCheck();

  const handleSubmit = async () => {
    // validate creative start end datetime before submission
    const isValid = await trigger(datetimeFields);
    if (!isValid) return;

    const data = await Promise.all(
      transformFromImpressionPixelsArray(controlledFields).map(updateCreative)
    );

    setValue(fields.creatives.path, data);

    onSubmit();
  };

  return {
    controlledFields,
    weightingRotation,
    control,
    submit: handleSubmit,
    disabled: getFieldState(fields.draftCreatives.path).invalid,
    remove: index => () => remove(index),
    update: index => data =>
      update(index, { ...controlledFields[index], ...data }),
    gapsInCreative,
    removeGapWarningBar: () => {
      setGapsInCreative([]);
    },
  };
};
