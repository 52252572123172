/* eslint-disable @typescript-eslint/no-explicit-any */

import type {
  AppBundleListBundle,
  AppBundlesBundle,
  AppNameBundle,
  Bundle,
  BundleStoreStructure,
  DomainListBundle,
  GeneralBundle,
} from '@local-types/bundle';
import { inventoryCategoryName } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedCustomInventoryTable/constants/category';
import { customInventoryTabValues } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedCustomInventoryTable/constants/general';
import { filter } from 'lodash';
import { BundleMappingElementAdditionalData } from './types';

export const mapBundlesToDataItem = (bundle: Bundle): GeneralBundle => ({
  ...bundle,
  key: bundle.bundle_name,
  value: bundle.bundle_name,
  type: bundle.type ?? '',
});

export const mapAppBundleListToDataItem = (
  item: AppBundleListBundle | DomainListBundle | undefined,
  type: string,
  additionalParams: BundleMappingElementAdditionalData<AppBundleListBundle> = {}
): GeneralBundle | null => {
  if (!item) return null;

  return {
    id: item.id,
    value: item.id,
    key: item.name,
    display_name: item.name,
    type,
    ...additionalParams,
  };
};

export const mapAppBundleTargetingToDataItem = (
  item: AppBundlesBundle,
  type: string,
  additionalParams: BundleMappingElementAdditionalData<AppBundlesBundle> = {}
): GeneralBundle => {
  return {
    id: item.app_bundle_id,
    value: item.app_bundle_id,
    key: item.name,
    display_name: item.name,
    type,
    ...additionalParams,
  };
};

export const mapBundleTypeToDataItem = (
  item: AppNameBundle,
  type: string,
  additionalParams: Record<string, any> = {}
): GeneralBundle => {
  return {
    id: item,
    value: item,
    key: item,
    display_name: item,
    type,
    ...additionalParams,
  };
};

export const mapInventoryItemToDataItem = (
  item: Bundle,
  recommendedInventory: number[] = []
) => ({
  ...item,
  recommended: recommendedInventory.includes(item.id),
});

export const getPreparedGeneralBundles = ({
  availableBundles,
  bundles,
}: {
  availableBundles: Bundle[];
  bundles: string[];
}) =>
  availableBundles
    .filter(({ bundle_name, ott }) => bundles.includes(bundle_name) && !ott)
    .map(mapBundlesToDataItem);

export const getPreparedAppNameBundles = ({
  app_name,
}: {
  app_name: BundleStoreStructure;
}) =>
  (app_name.values ?? []).map(name =>
    mapBundleTypeToDataItem(
      name,
      inventoryCategoryName[customInventoryTabValues.appName],
      {
        included: !app_name.blacklist,
      }
    )
  );

export const getPreparedDealIdBundles = ({
  deal_id,
}: {
  deal_id: BundleStoreStructure;
}) =>
  (deal_id.values ?? []).map(name =>
    mapBundleTypeToDataItem(
      name,
      inventoryCategoryName[customInventoryTabValues.dealId],
      {
        included: !deal_id.blacklist,
      }
    )
  );

export const getPreparedDomainListBundles = ({
  domain_list,
  domainList,
}: {
  domain_list: BundleStoreStructure<number>;
  domainList: DomainListBundle[];
}) =>
  filter(
    (domain_list.values ?? []).map(passedId =>
      mapAppBundleListToDataItem(
        domainList.find(({ id }) => id === passedId),
        inventoryCategoryName[customInventoryTabValues.appDomainList],
        {
          included: !domain_list.blacklist,
        }
      )
    ),
    v => !!v
  );

export const getPreparedAppBundleListBundles = ({
  app_bundle_list,
  appBundleList,
}: {
  app_bundle_list: BundleStoreStructure<number>;
  appBundleList: AppBundleListBundle[];
}) =>
  filter(
    (app_bundle_list.values ?? []).map(name => {
      const actualBundleItem = appBundleList.find(({ id }) => id === name);
      const value = actualBundleItem ?? name;
      const fnc = actualBundleItem
        ? mapAppBundleListToDataItem
        : mapBundleTypeToDataItem;

      return fnc(
        // @ts-expect-error - value is of type of normalization function accepts.
        value,
        inventoryCategoryName[customInventoryTabValues.appBundleList],
        {
          included: !app_bundle_list.blacklist,
        }
      );
    }),
    v => !!v
  );

export const prepareBundles = ({
  bundles,
  availableBundles,
  appBundleList,
  app_name,
  deal_id,
  app_bundle_list,
  domain_list,
  domainList,
}: {
  bundles: string[];
  availableBundles: Bundle[];
  appBundleList: AppBundleListBundle[];
  app_name: BundleStoreStructure;
  deal_id: BundleStoreStructure;
  app_bundle_list: BundleStoreStructure<number>;
  domain_list: BundleStoreStructure<number>;
  domainList: DomainListBundle[];
}) => {
  const generalBundles = getPreparedGeneralBundles({
    availableBundles,
    bundles,
  });
  const appNameBundles = getPreparedAppNameBundles({ app_name });
  const dealIdBundles = getPreparedDealIdBundles({ deal_id });
  const domainListBundles = getPreparedDomainListBundles({
    domain_list,
    domainList,
  });
  const appBundleListBundles = getPreparedAppBundleListBundles({
    app_bundle_list,
    appBundleList,
  });

  return [
    ...generalBundles,
    ...appNameBundles,
    ...dealIdBundles,
    ...domainListBundles,
    ...appBundleListBundles,
  ];
};
