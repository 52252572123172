import React from 'react';
import { CircularProgress, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

export const RHFTextField = ({
  name,
  control,
  loading = false,
  inputProps = {},
  InputProps = {},
  ...rest
}) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <TextField
      color="secondary"
      fullWidth
      margin="none"
      InputLabelProps={{ shrink: true, htmlFor: name }}
      variant="outlined"
      {...rest}
      {...field}
      error={!!fieldState.error}
      helperText={fieldState.error ? fieldState.error.message : null}
      FormHelperTextProps={{
        id: `${name}-helper-text`,
      }}
      inputProps={{
        ...inputProps,
        'aria-invalid': !!fieldState.invalid,
        'aria-errormessage': `${name}-helper-text`,
      }}
      InputProps={{
        id: name,
        name,
        ...InputProps,
        endAdornment: loading ? (
          <CircularProgress size="24px" />
        ) : (
          InputProps?.endAdornment || null
        ),
      }}
    />
  );
};

RHFTextField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
};
