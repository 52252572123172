import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, IconButton, styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarRange,
  faLockKeyhole,
} from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import { isUndefined } from 'lodash';
import { DatePicker } from '@mui/x-date-pickers-pro';

const IconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
}));

const SelectDate = forwardRef(
  ({ label, uniqueKey, testId, minDate, ...props }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const {
      field,
      fieldState,
      slotProps: passedSlotProps,
      clearable,
      lockIcon,
      ...rest
    } = props;
    const slotProps =
      passedSlotProps ?? fieldState
        ? {
            textField: {
              error: fieldState.invalid,
              helperText: fieldState.error?.message,
            },
          }
        : {};

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          minDate={isUndefined(minDate) ? moment() : minDate}
          {...rest}
          {...field}
          ref={ref}
          label={label}
          format="MMM D, YYYY"
          key={uniqueKey}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          slotProps={{
            yearButton: {
              sx: {
                fontSize: 'body2.fontSize',
              },
              'data-testid': `${testId}-year-button`,
            },
            field: {
              clearable: !!clearable,
              inputProps: {
                'data-testid': `${testId}-input`,
              },
              ...slotProps.field,
            },
            textField: {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      data-testid={`${testId}-calendar-button`}
                    >
                      <IconStyled
                        icon={lockIcon ? faLockKeyhole : faCalendarRange}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              },
              'data-testid': `${testId}-textfield`,
              ...slotProps.textField,
            },
          }}
          PopperProps={{
            'data-testid': `${testId}-dropdown`,
          }}
        />
      </LocalizationProvider>
    );
  }
);

SelectDate.displayName = 'SelectDate';

// Define the prop types for the SelectDate component
SelectDate.propTypes = {
  ...DatePicker.propTypes,
  uniqueKey: PropTypes.string,
  testId: PropTypes.string,
};

export default SelectDate;
