import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, CircularProgress, Drawer, IconButton, List, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';

import AppHeader from '../../AppHeader';
//@ts-expect-error Typing for SVGs is not available
import drawerArrow from './assets/drawer-arrow.svg';
import ReportsRoutes from './ReportsRoutes';
import AdvertiserContext, { COST_MODEL } from '@components/AdvertiserContext';
import { useDomain, useUser } from '@components/hooks';
import {
  INTERNAL_ROUTES,
  MEASUREMENT_ROUTES,
  PERFORMANCE_ROUTES,
  GENERAL_ROUTES,
} from './constants/routes';
import { LOOKER_CHARTS, PAGE_TITLES } from './constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useReportConfig from '@components/hooks/apis/reportConfig';

const drawerWidth = 250;

const Main = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: open ? '' : `-${drawerWidth - 16}px !important`,
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
    },
  ],
}));

const ReportsPage = () => {
  const [openDrawer, setOpenDrawer] = useState(true);
  const [active, setActive] = useState('reporting-home');
  const [inExplore, setInExplore] = useState(false);
  const adContext = useContext(AdvertiserContext);
  const { user } = useUser();
  const domain = useDomain();
  const { reportsAdmin, reportsAlwaysOn } = useFlags();
  const location = useLocation();
  const history = useHistory();

  const [adContextChanged, setAdContextChanged] = useState(false);

  const adContextIdRef = useRef<null | number>(null)

  useEffect(() => {
    const hasAdContextChanged = adContextIdRef.current && adContextIdRef.current !== adContext.id;

    if (hasAdContextChanged) {
      setAdContextChanged(true);
    }

    adContextIdRef.current = adContext.id;
  }, [adContext]);

  const { data, isLoading, isValidating, mutate, initialLoaded } = useReportConfig.get();

  useEffect(() => {
    mutate();
  }, [adContext]);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  }

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  }

  const getRouteFromReportConfig = (reportConfig: string) => {
    switch (reportConfig) {
      case 'campaignPerformance':
        return LOOKER_CHARTS.CAMPAIGN_PERFORMANCE.id;
      case 'pathToPurchase':
        return LOOKER_CHARTS.PATH_TO_PURCHASE.id;
      case 'postConversions':
        return LOOKER_CHARTS.POST_CONVERSIONS.id;
      case 'spikeLift':
        return LOOKER_CHARTS.SPIKE_LIFT.id;
      case 'alwaysOnIncrementality':
        return LOOKER_CHARTS.INCREMENTALITY.id;
      default:
        return '';
    }
  }

  const routesToNotRender = useMemo(() => {
    const routeNames: string[] = [];
    if (reportsAdmin && data) {
      Object.keys(data).forEach(key => {
        if (!data[key as keyof typeof data]) {
          routeNames.push(getRouteFromReportConfig(key));
        }
      })
      if (adContext?.cost_model === COST_MODEL.CPM) {
        routeNames.push(LOOKER_CHARTS.CPA_INTERNAL.id);
      }
      if (!reportsAlwaysOn) {
        routeNames.push('incrementality')
      }
      return routeNames;
    }
    if (!adContext.path_to_purchase_validated) {
      routeNames.push(LOOKER_CHARTS.PATH_TO_PURCHASE.id);
    }
    if (!user?.is_tvsci_employee || (user?.is_tvsci_employee && user?.is_sales_demo_user)) {
      routeNames.push(LOOKER_CHARTS.BETA.id);
      routeNames.push(LOOKER_CHARTS.CPA_INTERNAL.id);
    } else if (adContext.cost_model === COST_MODEL.CPM) {
      routeNames.push(LOOKER_CHARTS.CPA_INTERNAL.id);
    }
    if (domain.peacock || !adContext?.has_sequential_events) {
      routeNames.push(LOOKER_CHARTS.POST_CONVERSIONS.id);
    }
    if (!adContext?.has_spike_lift) {
      routeNames.push('spike-lift');
    }
    return routeNames;
  }, [
    adContext?.path_to_purchase_validated,
    user?.is_tvsci_employee,
    domain.peacock,
    adContext?.has_sequential_events,
    adContext?.cost_model,
    data,
    reportsAlwaysOn,
    reportsAdmin,
  ]);

  const sections = useMemo(() => {
    const sections = [
      GENERAL_ROUTES,
      PERFORMANCE_ROUTES,
      MEASUREMENT_ROUTES,
    ];
    if (user?.is_tvsci_employee && !user?.is_sales_demo_user) {
      sections.push(INTERNAL_ROUTES);
    }
    return sections;
  }, [user?.is_tvsci_employee, user?.is_sales_demo_user]);

  const allRoutes = useMemo(() => {
    const routes = [
      ...GENERAL_ROUTES.routes,
      ...PERFORMANCE_ROUTES.routes,
      ...MEASUREMENT_ROUTES.routes,
    ];
    if (user?.is_tvsci_employee && !user?.is_sales_demo_user) {
      routes.push(...INTERNAL_ROUTES.routes);
    }
    return routes;
  }, [user?.is_tvsci_employee, user?.is_sales_demo_user]);

  useEffect(() => {
    const path = location.pathname;
    const lastSegment = path.split('/').filter(Boolean).pop();
    const route = allRoutes.find(route => lastSegment === route.key);
    if (route && !routesToNotRender.includes(route.key)) {
      setActive(route.key);
    }
  }, [location.pathname, allRoutes, routesToNotRender]);

  const handleRouteChange = (key: string, path: string) => {
    if (inExplore) {
      history.goBack();
      setInExplore(false);
    }
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('active');
    history.push({ pathname: path, search: searchParams.toString() });
  }

  const isValidRoute = allRoutes.some(route => route.path === location.pathname);

  return (
    <AppHeader history={history}>
      <Box display="flex" minHeight="100vh" bgcolor='#f5f5f5'>
        <Box position="relative">
          {!openDrawer && (
            <Box position="absolute" width="fit-content">
              <IconButton onClick={handleDrawerOpen} sx={{
                margin: '8px',
              }}>
                <img
                  src={drawerArrow}
                  alt="drawer-arrow-right"
                  style={{
                    transform: 'rotate(180deg)',
                    height: '20px',
                    width: '20px',
                  }}
                />
              </IconButton>
            </Box>
          )}
          <Drawer
            sx={{
              height: '100%',
              width: drawerWidth,
              marginLeft: '16px',
              flexShrink: 0,
              overflow: 'visible',
              '& .MuiPaper-root': {
                // This is to overwrite NBCU theme
                backgroundColor: '#f5f5f5 !important',
              },
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                position: 'relative',
                overflow: 'visible',
              },
            }}
            variant="persistent"
            anchor="left"
            open={openDrawer}
          >
            <Box paddingRight="16px" paddingX="4px">
              <IconButton onClick={handleDrawerClose} sx={{
                float: 'right',
                marginTop: '8px',
              }}>
                <img
                  src={drawerArrow}
                  alt="drawer-arrow-left"
                  style={{
                    height: '20px',
                    width: '20px',
                  }}
                />
              </IconButton>
            </Box>
            <List sx={{ paddingTop: 0 }}>
              {(isLoading || isValidating || !initialLoaded) && reportsAdmin ? (
                <Box display="flex" justifyContent="center" alignItems="center" marginTop="20px">
                  <CircularProgress sx={{ margin: 'auto' }} color="secondary" />
                </Box>
              ) : (
                <ReportsRoutes
                  sections={sections}
                  active={active}
                  handleRouteChange={handleRouteChange}
                  routesToNotRender={routesToNotRender}
                />
              )}
            </List>
          </Drawer>
        </Box>
        <Main open={openDrawer} sx={{ padding: '0 72px' }}>
          <Box marginBottom="32px">
            <Box
              fontSize='28px'
              fontWeight={700}
              margin="24px 0 28px 8px"
              color='#13171A'
            >
              {PAGE_TITLES[active]}
            </Box >
            <Paper
              elevation={4}
              sx={{
                padding: '8px 24px',
                minHeight: '650px'
              }}
            >
              {allRoutes
                .filter(({ key }) => !routesToNotRender.includes(key))
                .map(({ key, path, component: Component, usesLooker }) => {
                  if (usesLooker) {
                    return (
                      <Box key={key} display={active === key ? 'block' : 'none'}>
                        <Component
                          setInExplore={setInExplore}
                          inExplore={inExplore}
                          key={key}
                          active={active === key}
                        />
                      </Box>
                    )
                  }
                  return (
                    <Route
                      key={key}
                      exact
                      path={path}
                      render={(props) =>
                        <Component
                          {...props}
                          adContextChanged={adContextChanged}
                          setAdContextChanged={setAdContextChanged}
                          setInExplore={setInExplore}
                          inExplore={inExplore}
                        />
                      }
                    />
                  )
                })}
              {!isValidRoute && <Redirect to="/reports/home" />}
            </Paper>
          </Box>
        </Main>
      </Box>
    </AppHeader>
  );
};

export default ReportsPage;
