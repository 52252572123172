import { AddCircleOutline } from '@mui/icons-material';
import { InputAdornment, Stack } from '@mui/material';
import React from 'react';
import { ActionButtonStyled, AdvancedHeaderBoxStyled } from '../styles';
import { useCopy } from '@hooks/copy';
import { Copies } from '../constants';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  SearchFieldStyled,
  StyledClearIcon,
  StyledSearchIcon,
} from './styles';
import { TableLeftHeaderProps } from './types';

export const TableLeftHeader = <Value,>({
  data = [],
  filter,
  onFilter,
  onAddAll,
  onAddAllHovered,
}: TableLeftHeaderProps<Value>) => {
  const Copy = useCopy(Copies);

  return (
    <AdvancedHeaderBoxStyled>
      <Stack flex={1} sx={{ paddingRight: theme => theme.spacing(4.25) }}>
        {onFilter && (
          <SearchFieldStyled
            size="small"
            value={filter}
            data-testid="advanced-filter-input"
            onChange={e => onFilter(e.target.value)}
            placeholder="Search by name or ID..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {filter ? (
                    <StyledClearIcon
                      icon={faTimes}
                      onClick={() => onFilter('')}
                    />
                  ) : (
                    <StyledSearchIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      </Stack>
      <ActionButtonStyled
        disabled={!data.length}
        actionType="add"
        onClick={() => {
          onAddAll(data);
        }}
        variant="text"
        color="primary"
        data-testid="advanced-add-all"
        onMouseEnter={() => onAddAllHovered?.(true)}
        onMouseLeave={() => onAddAllHovered?.(false)}
      >
        <AddCircleOutline />
        <span>{Copy.addAll}</span>
      </ActionButtonStyled>
    </AdvancedHeaderBoxStyled>
  );
};
