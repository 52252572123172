import { Stack } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import {
  FirstCellVirtualizedStyled,
  LastCellVirtualizedStyled,
  TableCellVirtualizedStyled,
  VirtualizedHeaderRowStyled,
} from '../styles';
import { useTableVirtualizedData } from '../hooks/useTableVirtualizedData';
import { useVirtualizedRenderer } from './hooks/useVirtualizedRenderer';
import { AutoSizer, Table } from 'react-virtualized';
import { useTableSide } from '../hooks/useTableSide';
import { TableRightProps } from './types';
import { TableData } from '../types';

export const TableRightVirtualized = <Value, Data extends TableData<Value>>({
  data = [],
  onChange,
  onUpdate,
  renderCell,
  renderGroup,
  isTargeted,
  PlaceholderCell,
  PlaceholderGroup,
  settings,
  actualAmount,
  isRemoveAllHovered,
}: TableRightProps<Value, Data>) => {
  const tableRef = useRef<Table>(null);

  const { evenData } = useTableSide<Value, Data>(data, {
    actualAmount,
  });
  const { list, getRowHeight } = useTableVirtualizedData<Value, Data>({
    data: evenData as Data[],
  });

  const rowRenderer = useVirtualizedRenderer({
    onChange,
    renderCell,
    onUpdate,
    renderGroup,
    PlaceholderCell,
    PlaceholderGroup,
    isRemoveAllHovered,
    isTargeted,
    settings,
  });

  useEffect(() => {
    tableRef.current?.recomputeRowHeights();
  }, [list]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          width={width}
          height={height}
          headerHeight={74}
          ref={tableRef}
          rowHeight={getRowHeight}
          headerRowRenderer={props => (
            <VirtualizedHeaderRowStyled
              {...props}
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={0}
            >
              <FirstCellVirtualizedStyled>
                Selected
              </FirstCellVirtualizedStyled>
              <Stack>
                {settings.includable && (
                  <TableCellVirtualizedStyled>
                    Incl/Excl
                  </TableCellVirtualizedStyled>
                )}
                {settings.includable && settings.showSeparateSwitch && (
                  <LastCellVirtualizedStyled />
                )}
              </Stack>
            </VirtualizedHeaderRowStyled>
          )}
          rowCount={list.length}
          rowGetter={({ index }) => list[index]}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );
};
