import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import * as yup from 'yup';
import clsx from 'clsx';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { RHFTextField } from '@components/form';
import { withRedirectIfAuthenticated } from '../withRedirectIfAuthenticated';
import { useAuth } from '../hooks';

const PREFIX = 'LoginPage';

const classes = {
  background: `${PREFIX}-background`,
  container: `${PREFIX}-container`,
  login: `${PREFIX}-login`,
  logoContainer: `${PREFIX}-logoContainer`,
  peacockLogo: `${PREFIX}-peacockLogo`,
  signIn: `${PREFIX}-signIn`,
  formButton: `${PREFIX}-formButton`,
  input: `${PREFIX}-input`
};

const StyledBox = styled(Box)(({
  theme: { spacing }
}) => ({
  [`&.${classes.background}`]: {
    backgroundImage:
      "url('https://tvs-public-prod-icons.s3.us-west-2.amazonaws.com/peacock-sign-in-bg.png')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    height: '100vh',
  },

  [`& .${classes.container}`]: {
    height: '100%',
  },

  [`& .${classes.login}`]: {
    width: 400,
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },

  [`& .${classes.logoContainer}`]: {
    width: '100%',
    borderBottom: '1px solid',
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
  },

  [`& .${classes.peacockLogo}`]: {
    maxHeight: '100px',
    maxWidth: '280px',
  },

  [`& .${classes.signIn}`]: {
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    fontSize: 15,
    fontWeight: 600,
    color: 'black',
    textAlign: 'center',
  },

  [`& .${classes.formButton}`]: {
    marginTop: 30,
    '& .MuiButton-root': {
      backgroundColor: '#ffcb00',
      color: 'black',
      width: '100%',
      marginBottom: 20,
      fontSize: 14,
      fontWeight: 400,
    },
  },

  [`& .${classes.input}`]: {
    marginTop: spacing(2),
  }
}));

const emailDomainIsNBCU = email => {
  const domain = email.split('@')[1];

  return domain === 'nbcuni.com';
};

const validationSchema = yup.object().shape({
  email: yup.string().email('Email is invalid').required('Email is required'),
});

const LoginPage = () => {
  const { auth } = useAuth();

  const { control, handleSubmit } = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async ({ email }) => {
    if (emailDomainIsNBCU(email)) {
      auth.nbcuSignIn();
    } else {
      const oktaOptions = {
        loginHint: email,
      };

      auth.oktaSignInWithRedirect({ oktaOptions });
    }
  });

  useEffect(() => {
    auth.attemptQuietLogin();
  }, []);

  return (
    <StyledBox className={classes.background}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.container}
      >
        <Grid item>
          <Paper className={classes.login}>
            <Grid
              item
              container
              className={classes.logoContainer}
              justifyContent="center"
            >
              <img
                src={require('../../images/nbcu/padman-logo-horizontal-all-black.png')}
                alt="Peacock ad manager logo"
                className={classes.peacockLogo}
              />
            </Grid>
            <Grid item container direction="column" justifyContent="center">
              <Grid item>
                <Typography className={clsx(classes.signIn, 'peacockText')}>
                  Sign In
                </Typography>
              </Grid>
              <form noValidate onSubmit={onSubmit}>
                <Grid item container justifyContent="center">
                  <Grid item md={10} xs={10}>
                    <Box
                      mt={2}
                      component={RHFTextField}
                      type="email"
                      label="Email"
                      placeholder="Email"
                      name="email"
                      control={control}
                      className={classes.input}
                      id="email"
                    />
                  </Grid>
                  <Grid item md={10} xs={10}>
                    <Box className={classes.formButton}>
                      <Button
                        size="large"
                        fullWidth
                        variant="outlined"
                        type="submit"
                      >
                        Sign in
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default withRedirectIfAuthenticated(LoginPage);
