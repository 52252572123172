import React from 'react';
import { TableCell } from '@mui/material';
import { AdvancedElementRowStyled, FirstCellStyled } from '../styles';
import { IncludableData, TableData, WithId } from '../types';
import { IncludeExcludeSwitch } from '@v2/components/ui/Switch';
import { StyledRemoveIcon } from './styles';
import clsx from 'clsx';
import { TableRightRowProps } from './types';

export const TableRightRow = <Value, Data extends TableData<Value>>({
  data,
  index,
  isTargeted,
  renderCell,
  PlaceholderCell,
  isRemoveAllHovered,
  onChange,
  settings,
  onUpdate,
  ...props
}: TableRightRowProps<Value, Data>) => {
  return (
    <AdvancedElementRowStyled
      {...props}
      data-testid={
        data
          ? `table-right-row-${(data as WithId<Value>).id}`
          : `table-right-row-placeholder-${index}`
      }
      rowType="remove"
      className={clsx(
        props.className,
        `${data ? 'hover-row' : ''} ${
          isRemoveAllHovered ? 'remove-all-hover' : ''
        }`
      )}
      targeted={!!data && isTargeted}
      onClick={() => {
        if (data) {
          onChange(data);
        }
      }}
      highlighted={!!(index % 2)}
    >
      <FirstCellStyled>
        {data ? (
          renderCell({ data })
        ) : (
          <PlaceholderCell type="right" data={data} />
        )}
      </FirstCellStyled>

      {settings.includable && settings.showSeparateSwitch && (
        <TableCell
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {!!data && (
            <IncludeExcludeSwitch
              defaultChecked
              disabled={!settings.separateInclExcl}
              onChange={e => {
                onUpdate({ ...data, included: e.target.checked });
              }}
              checked={(data as IncludableData<Data>).included}
            />
          )}
        </TableCell>
      )}
      <TableCell
        sx={{
          overflow: 'hidden',
        }}
      >
        <StyledRemoveIcon
          className="hover-icon"
          color="primary"
          sx={{
            maxWidth: '100%',
            display: !data || !isTargeted ? 'none' : 'block',
            opacity: '33%',
          }}
        />
      </TableCell>
    </AdvancedElementRowStyled>
  );
};
