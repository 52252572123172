import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { Campaign } from '@local-types/campaign';
import { AdGroup } from '@local-types/group';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { useSort } from '@hooks/sort';
import RealtimeDeliveryModal from '@components/containers/AdGroupsIndexPage/renderers/RealtimeDeliveryModal';
import ModalContext from '@providers/ModalContext';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { GROUP_TYPE, useGroups } from '@components/hooks/apis/groups';
import { EntityStatusType } from '@local-types';
import BulkEditModal from '@components/modals/BulkEditModal/BulkEditModal';
import {
  useBulkUpdate,
  BulkUpdateRequestBody,
} from '@components/hooks/apis/bulk';
export interface AdGroupsTableContextProviderProps {
  campaign: Campaign;
  campaignId: number;
  statuses: EntityStatusType[];
  handleManageDisplay: (display: AdGroup) => void;
  handleManageAdGroup: (adGroup: AdGroup) => void;
  handleDuplicateAdGroup: (adGroup: AdGroup) => Promise<unknown>;
  handleOpenWeighting: (adGroup: AdGroup) => void;
  handleIsDuplicatable: (adGroup: AdGroup) => boolean;
  handleArchiveUnarchiveAdGroup: (adGroup: AdGroup) => void;
  handleOpenBid: (adGroup: AdGroup) => void;
  children: React.ReactNode;
}

export interface AdGroupsTableContextType
  extends Omit<AdGroupsTableContextProviderProps, 'children'> {
  rowCount: number;
  refreshGroups: () => Promise<void>;
  isLoading: boolean;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  setPaginationModel: (paginationModel: {
    page: number;
    pageSize: number;
  }) => void;
  sortModel: GridSortModel;
  setSortModel: (sortModel: GridSortModel) => void;
  sortedAdGroups: AdGroup[];
  handlePauseActive: (adGroup: AdGroup, isDisplay?: boolean) => void;
  handleOpenRealTimeModal: (
    event: React.MouseEvent<HTMLElement>,
    adGroup: AdGroup
  ) => void;
  selectedAdGroupIds: number[];
  setSelectedAdGroupIds: (ids: number[]) => void;
  clearSelectedAdGroups: () => void;
  handleBulkEdit: () => void;
  handleBulkPause: () => void;
  handleUpdateAdServer: (adGroup: AdGroup, isDisplay?: boolean) => void;
}

export const AdGroupsTableContext = createContext<AdGroupsTableContextType>({
  campaign: {} as Campaign,
  campaignId: 0,
  rowCount: 0,
  refreshGroups: async () => {},
  statuses: [],
  sortedAdGroups: [],
  isLoading: false,
  paginationModel: {
    page: 0,
    pageSize: 25,
  },
  sortModel: [
    {
      field: 'name',
      sort: 'asc',
    },
  ],
  setSortModel: () => {},
  setPaginationModel: () => {},
  handleManageDisplay: () => {},
  handleManageAdGroup: () => {},
  handleDuplicateAdGroup: async () => Promise.resolve(),
  handleOpenWeighting: () => {},
  handleIsDuplicatable: () => false,
  handleOpenBid: () => {},
  handlePauseActive: () => {},
  handleOpenRealTimeModal: () => {},
  handleArchiveUnarchiveAdGroup: () => {},
  handleUpdateAdServer: () => {},
  selectedAdGroupIds: [],
  setSelectedAdGroupIds: () => {},
  clearSelectedAdGroups: () => {},
  handleBulkEdit: () => {},
  handleBulkPause: () => {},
});

export const AdGroupsTableContextProvider = ({
  children,
  campaignId,
  campaign,
  statuses,
  ...rest
}: AdGroupsTableContextProviderProps) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const { lineItemIdForAdGroupWins, releaseSyncCheckColumn } = useLDFlags();
  const { setModal } = useContext(ModalContext);

  const { order, orderBy, getComparator, stableSort } = useSort();

  const {
    items,
    update,
    sync: syncAdGroups,
    isLoading: isLoadingLineItems,
    invalidate: invalidateGroups,
  } = useGroups(GROUP_TYPE.AD_GROUP, campaignId, {
    params: {
      force: true,
      queryProps: {
        ordering: '-active,-pending_active,-draft,archived,-start_date',
        status: statuses.join(','),
      },
    },
  });

  const {
    items: staticItems,
    isLoading: isLoadingDisplays,
    update: staticUpdate,
    sync: syncStaticAdGroups,
    invalidate: invalidateStaticGroups,
  } = useGroups(GROUP_TYPE.STATIC_GROUP, campaignId, {
    params: {
      force: true,
    },
  });

  const sortedAdGroups = useMemo(() => {
    return stableSort(
      [...items, ...staticItems] as never[],
      getComparator(order, orderBy)
    )
      .map(lineItem => {
        return {
          ...lineItem,
          campaign_name: campaign.name,
        } as unknown as AdGroup;
      })
      .filter(lineItem => {
        return (
          lineItem.status &&
          statuses.includes(lineItem.status) &&
          !lineItem.temporary
        );
      })
      .reduce((unique, lineItem) => {
        if (!unique.some(c => c.id === lineItem.id)) {
          unique.push(lineItem);
        }
        return unique;
      }, [] as AdGroup[]);
  }, [items, staticItems, order, orderBy, statuses, campaign]);

  // When the displays and ad groups load, get their sync statuses from the api
  useEffect(() => {
    if (releaseSyncCheckColumn) {
      syncAdGroups(items);
      syncStaticAdGroups(staticItems);
    }
  }, [items, staticItems, releaseSyncCheckColumn]);

  const handlePauseActive = async (adGroup: AdGroup, isDisplay?: boolean) => {
    const updateFunction = isDisplay ? staticUpdate : update;
    await updateFunction({ id: adGroup.id, active: !adGroup.active });
  };

  const handleOpenRealTimeModal = (
    event: React.MouseEvent<HTMLElement>,
    adGroup: AdGroup
  ) => {
    event.preventDefault();

    setModal({
      isOpen: true,
      component: RealtimeDeliveryModal,
      data: {
        id: lineItemIdForAdGroupWins ? adGroup.id : adGroup.beeswax_lid,
        description: `${adGroup.name} (${adGroup.id})`,
      },
    });
  };

  const [selectedAdGroupIds, setSelectedAdGroupIds] = useState<number[]>([]);

  const clearSelectedAdGroups = useCallback(() => {
    setSelectedAdGroupIds([]);
  }, []);

  const refreshGroups = useCallback(async () => {
    await invalidateGroups();
    await invalidateStaticGroups();
  }, [invalidateGroups, invalidateStaticGroups]);

  const handleBulkEdit = useCallback(() => {
    if (selectedAdGroupIds.length > 0) {
      const selectedAdGroups = sortedAdGroups.filter(adGroup =>
        selectedAdGroupIds.includes(adGroup.id)
      );

      setModal({
        isOpen: true,
        component: BulkEditModal,
        data: {
          adGroups: selectedAdGroups,
          campaign,
        },
        onClose: (close: () => void) => () => {
          refreshGroups();
          close();
        },
      });
    }
  }, [selectedAdGroupIds, sortedAdGroups, setModal, refreshGroups]);

  const { trigger: bulkUpdateTrigger } = useBulkUpdate({
    onSuccess: () => {
      refreshGroups();
    },
  });

  const handleBulkPause = useCallback(() => {
    if (selectedAdGroupIds.length > 0) {
      const updatePayload: BulkUpdateRequestBody = {
        type: 'LINEITEM',
        select: {
          id__in: selectedAdGroupIds,
        },
        fields: {
          active: false,
        },
      };

      bulkUpdateTrigger(updatePayload);
    }
  }, [selectedAdGroupIds, bulkUpdateTrigger]);

  /**
   * Handles updating the ad server with local changes when an ad group is out of sync.
   *
   * This function identifies fields that are out of sync between the local application
   * and the Beeswax ad server, then triggers an update to synchronize them. It extracts
   * the relevant fields from the out_of_sync object and applies the local values to the
   * ad server.
   *
   * @param {AdGroup} adGroup - The ad group that needs to be synchronized
   * @param {boolean} [isDisplay] - Optional flag indicating if this is a display ad group
   * @returns {void}
   */
  const handleUpdateAdServer = (adGroup: AdGroup, isDisplay?: boolean) => {
    if (!adGroup.out_of_sync) {
      return;
    }
    const updateFunction = isDisplay ? staticUpdate : update;
    const fieldsToUpdate = Object.keys(adGroup.out_of_sync ?? {}).reduce(
      (acc, key) => {
        acc[key] = adGroup[key];
        return acc;
      },
      {} as Record<string, boolean>
    );
    updateFunction(
      { id: adGroup.id, ...fieldsToUpdate, out_of_sync: {} },
      { params: { targeting_override: true } }
    );
  };

  return (
    <AdGroupsTableContext.Provider
      value={{
        ...rest,
        campaignId,
        campaign,
        statuses,
        sortedAdGroups,
        refreshGroups,
        handlePauseActive,
        handleOpenRealTimeModal,
        handleUpdateAdServer,
        rowCount: sortedAdGroups?.length ?? 0,
        isLoading: isLoadingLineItems || isLoadingDisplays,
        paginationModel,
        setPaginationModel,
        sortModel,
        setSortModel,
        selectedAdGroupIds,
        setSelectedAdGroupIds,
        clearSelectedAdGroups,
        handleBulkEdit,
        handleBulkPause,
      }}
    >
      {children}
    </AdGroupsTableContext.Provider>
  );
};

export const useAdGroupsTable = () => {
  return useContext(AdGroupsTableContext);
};
