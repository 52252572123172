import React from 'react';
import { type Control, type FieldArrayWithId } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { Row } from '../Row';
import { IFormValues } from '../types/ListFilterFormProps.types';

interface VirtualizedRowProps {
  index: number;
  style: React.CSSProperties;
  data: {
    items: FieldArrayWithId<IFormValues, 'items', 'internalId'>[];
    control: Control<IFormValues>;
    fields: FieldArrayWithId<IFormValues, 'items', 'internalId'>[];
    handleCheckboxChange: (checked: boolean, itemId: number) => void;
    searchValue?: string;
    hasMore?: boolean;
    isValidating?: boolean;
  };
}

const VirtualizedRow = React.memo(({ index, style, data }: VirtualizedRowProps) => {
  if (index === data.items.length && data.hasMore && data.isValidating) {
    return (
      <div style={style}>
        <Box sx={{ textAlign: 'center', py: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Loading more items...
          </Typography>
        </Box>
      </div>
    );
  }

  const item = data.items[index];
  if (!item) return null;

  return (
    <Row
      item={item}
      control={data.control}
      fields={data.fields}
      handleCheckboxChange={data.handleCheckboxChange}
      style={style}
      searchValue={data.searchValue}
    />
  );
});

VirtualizedRow.displayName = 'VirtualizedRow';

export { VirtualizedRow };
