import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Chip, useTheme } from '@mui/material';
import { EntityStatusType, Campaign } from '@local-types';
import { Status } from '@constants';

export const CampaignStatusCellRenderer = (
  params: GridCellParams<Campaign, EntityStatusType>,
) => {
  const theme = useTheme();
  const { palette } = theme;
  if (params.value === null || params.value === undefined) {
    return null;
  }

  const status = params.value;

  switch (status) {
    case Status.DRAFT:
      return (
        <Chip
          label="Draft"
          data-testid="draft-campaign"
          sx={{
            width: '100%',
            color: palette.blue?.['6'],
            background: palette.blue?.['10'],
          }}
        />
      );
    case Status.ACTIVE:
      return (
        <Chip
          label="Active"
          data-testid="active-campaign"
          sx={{
            width: '100%',
            color: palette.green?.['6'],
            background: palette.green?.['10'],
          }}
        />
      );
    case Status.INACTIVE:
      return (
        <Chip
          label="Paused"
          data-testid="paused-campaign"
          sx={{
            width: '100%',
            color: palette.red?.['1'],
            background: palette.red?.['5'],
          }}
        />
      );
    case Status.ARCHIVED:
      return (
        <Chip
          label="Archived"
          data-testid="archived-campaign"
          sx={{
            width: '100%',
            color: '#77858c',
            background: '#EDF1F2'
          }}
        />
      );
    default:
      return null;
  }
};
