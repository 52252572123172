import React, { useState, useEffect } from 'react';
import { Box, styled, RadioGroup } from '@mui/material';
import { BidStrategyAccordion, BidStrategyAccordionOption } from './BidStrategyAccordion';

const FIGMA_BOX_SHADOW = '0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.07)';

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: FIGMA_BOX_SHADOW
}));

export interface BidStrategyOptionsAccordionProps {
  value?: string;
  options: BidStrategyAccordionOption[];
  onChange: (value: string, option: BidStrategyAccordionOption) => void;
}

export const BidStrategyOptionsAccordion: React.FC<BidStrategyOptionsAccordionProps> = ({
  value,
  options,
  onChange
}) => {
  const [expandedOption, setExpandedOption] = useState<string | null>(value || null);

  useEffect(() => {
    if (value) {
      setExpandedOption(value);
    }
  }, [value]);

  const handleChange = (newValue: string) => {
    setExpandedOption(newValue);
    
    const selectedOption = options.find(option => option.value === newValue);
    if (selectedOption) {
      onChange(newValue, selectedOption);
    }
  };

  return (
    <StyledBox>
      <RadioGroup value={value || ''}>
        {options.map((option) => (
          <BidStrategyAccordion
            key={option.value}
            option={option}
            expanded={expandedOption === option.value}
            selected={value === option.value}
            onChange={handleChange}
          />
        ))}
      </RadioGroup>
    </StyledBox>
  );
}; 
