import React from 'react';
import { EmbedLookerChart } from "@components/EmbedLookerChart";
import ReportsLookerPage from "../components/ReportsLookerPage";
import { DASHBOARD_NAMES, LOOKER_CHARTS } from '../constants';

interface SpikeLiftProps {
  setInExplore: React.Dispatch<React.SetStateAction<boolean>>;
  inExplore: boolean;
  internal?: boolean;
}

const ReportsSpikeLift = ({ setInExplore, inExplore, internal }: SpikeLiftProps) =>
  <ReportsLookerPage
    tabs={{
      id: LOOKER_CHARTS.SPIKE_LIFT.id,
      title: LOOKER_CHARTS.SPIKE_LIFT.title,
      content: () => {
        return (
          <EmbedLookerChart
            id={LOOKER_CHARTS.SPIKE_LIFT.id}
            dashboard={DASHBOARD_NAMES.SPIKE_LIFT}
            setInExplore={setInExplore}
            internal={internal}
          />)
      }
    }}
    setInExplore={setInExplore}
    inExplore={inExplore}
  />;

export default ReportsSpikeLift;
