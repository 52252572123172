import React from 'react';
import { Controller, type FieldValues } from 'react-hook-form';
import { Stack, FormControlLabel, Switch, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import NumberTextField from '@v2/components/ui/NumberTextField/NumberTextField';
import { MaxCpmProps } from './types';

export const MaxCpm = <T extends FieldValues>({
  control,
  required = false,
  label,
  handleCPMSwitchChange,
  isMaxCPMBidEnabled,
  rangeText,
  fieldNames,
}: MaxCpmProps<T>) => (
  <Stack>
    {!required && (
      <FormControlLabel
        control={
          <Controller
            control={control}
            name={fieldNames.maxCPMBidEnabled}
            render={({ field }) => (
              <Switch
                {...field}
                color="primary"
                checked={!!field.value}
                onChange={handleCPMSwitchChange}
                data-testid="max-cpm-switch"
              />
            )}
          />
        }
        label={label ?? 'Set Max CPM Bid (optional)'}
        labelPlacement="end"
        sx={{ marginRight: 'auto', marginBottom: 2.5 }}
      />
    )}
    {(isMaxCPMBidEnabled || required) && (
      <Stack
        direction="row"
        spacing={2}
        alignItems="flexStart"
        sx={{ marginBottom: 1.25 }}
      >
        <Controller
          name={fieldNames.maxCPMBid}
          control={control}
          render={({ field, fieldState }) => (
            <NumberTextField
              {...field}
              {...fieldState}
              id="max-cpm-bid"
              data-testid="max-cpm-bid"
              label={label ?? "Max CPM Bid"}
              variant="outlined"
              inputAdornment="$"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              disabled={!isMaxCPMBidEnabled && !required}
            />
          )}
        />
        <InfoIcon sx={{ color: 'action.active' }} />
        <Typography
          color="grey.3"
          sx={{ fontWeight: 400, marginLeft: 1.25 }}
          variant="caption"
        >
          {rangeText}
        </Typography>
      </Stack>
    )}
  </Stack>
);
