import React, { useMemo } from 'react';
import ListFilterForm from "@components/forms/ListFilterForm";
import { IFormValues, IItem } from '@components/forms/ListFilterForm/types/ListFilterFormProps.types';

const STATUS_MAP = {
  1: 'ACTIVE',
  2: 'DRAFT',
  3: 'INACTIVE',
  4: 'ARCHIVED'
} as const;

const REVERSE_STATUS_MAP: Record<string, number> = {
  ACTIVE: 1,
  DRAFT: 2,
  INACTIVE: 3,
  ARCHIVED: 4
};

interface StatusFilterProps {
  onSubmit: (params: { status: string }) => void;
  data: { status?: string };
}

const StatusFilter: React.FC<StatusFilterProps> = ({ onSubmit, data }) => {
  const selectedIds = useMemo(() => {
    if (!data.status) return [];
    return data.status.split(',').map(status => REVERSE_STATUS_MAP[status]);
  }, [data.status]);

  const items = useMemo<IItem[]>(() => [
    { id: 1, name: 'Active', checked: selectedIds.includes(1) },
    { id: 2, name: 'Draft', checked: selectedIds.includes(2) },
    { id: 3, name: 'Paused', checked: selectedIds.includes(3) },
    { id: 4, name: 'Archived', checked: selectedIds.includes(4) }
  ], [selectedIds]);

  const formData: IFormValues = {
    items,
    selectedIds,
  };

  const handleSubmit = (values: IFormValues) => {
    if (values.selectedIds.length === 0) {
      onSubmit({ status: '' });
      return;
    }

    const statuses = values.selectedIds
      .map(id => STATUS_MAP[id as keyof typeof STATUS_MAP])
      .join(',');

    onSubmit({ status: statuses });
  };

  return (
    <ListFilterForm
      fieldName="Status"
      data={formData}
      onSubmit={handleSubmit}
      showSearch={false}
      virtualized={false}
    />
  );
};

export default StatusFilter;
