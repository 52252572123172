import React from 'react';
import { Controller, type FieldValues } from 'react-hook-form';
import { Stack, MenuItem } from '@mui/material';
import { TextFieldStyled } from '@v2/components/ui/styles';
import { EventPickerProps } from './types';

export const EventPicker = <T extends FieldValues>({
  control,
  bidStrategyEventSet = [],
  fieldName,
}: EventPickerProps<T>) => (
  <Stack>
    <Controller
      control={control}
      name={fieldName}
      render={({ field, fieldState }) => {
        return (
          <TextFieldStyled
            {...field}
            {...fieldState}
            select
            fullWidth
            id="bid-strategy-event"
            data-testid="bid-strategy-event"
            label="Conversion Event"
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            sx={{ marginLeft: 0, marginBottom: 2.5 }}
          >
            {bidStrategyEventSet?.map(({ id, name }) => (
              <MenuItem
                key={id}
                value={id}
                data-testid={`bid-strategy-event-${id}`}
              >
                {name}
              </MenuItem>
            ))}
          </TextFieldStyled>
        );
      }}
    />
  </Stack>
); 
