import { type EntityStatusType } from '@local-types';

export const Status = {
    ACTIVE: 'ACTIVE',
    DRAFT: 'DRAFT',
    INACTIVE: 'INACTIVE',
    ARCHIVED: 'ARCHIVED',
    PENDING: 'PENDING',
} as const;

export const DefaultStatusKeys: EntityStatusType[] = [Status.ACTIVE, Status.DRAFT, Status.INACTIVE];

export const StatusDisplayNames: Record<EntityStatusType, string> = {
    [Status.ACTIVE]: 'Active',
    [Status.DRAFT]: 'Draft',
    [Status.INACTIVE]: 'Paused',
    [Status.ARCHIVED]: 'Archived',
    [Status.PENDING]: 'Pending'
} as const;

export type CreativeStatusType = Exclude<EntityStatusType, 'DRAFT'> | 'PENDING';

export const CreativeStatusDisplayNames: Record<CreativeStatusType, string> = {
    [Status.ACTIVE]: 'Active',
    [Status.INACTIVE]: 'Inactive',
    [Status.PENDING]: 'Pending',
    [Status.ARCHIVED]: 'Archived'
} as const;

export const DefaultCreativeStatusKeys: CreativeStatusType[] = [Status.ACTIVE, Status.PENDING, Status.INACTIVE];

export const LineItemStatusDisplayNames: Record<LineItemStatusType, string> = {
    [Status.ACTIVE]: 'Active',
    [Status.INACTIVE]: 'Paused',
    [Status.ARCHIVED]: 'Archived',
    [Status.PENDING]: 'Pending',
    [Status.DRAFT]: 'Draft'
} as const;

export type LineItemStatusType = EntityStatusType;
export const DefaultLineItemStatusKeys: LineItemStatusType[] = [Status.ACTIVE, Status.PENDING, Status.INACTIVE, Status.DRAFT];
