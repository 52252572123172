import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useDeferredValue,
  useState,
} from 'react';
import { useCreatives } from '@apis/creatives';
import { Creative, EntityStatusType } from '@local-types';
import { uniqBy } from 'lodash';
import { DefaultCreativeStatusKeys } from '@constants';

export interface CreativesTableContextProviderProps {
  children: React.ReactNode;
}

export interface CreativesTableContextType
  extends Omit<CreativesTableContextProviderProps, 'children'> {
  isLoading: boolean;
  isValidating: boolean;
  count: number;
  hasMore: boolean;
  creatives: Creative[];
  handleArchiveUnarchiveCreative: (creative: Creative) => void;
  setSize: Dispatch<SetStateAction<number>>;
  filter: string;
  setFilter: Dispatch<SetStateAction<string>>;
  statuses: EntityStatusType[];
  setStatuses: Dispatch<SetStateAction<EntityStatusType[]>>;
}

export const CreativesTableContext = createContext<CreativesTableContextType>(
  {
    isLoading: false,
    isValidating: false,
    count: 0,
    creatives: [],
    hasMore: false,
    setSize: () => {},
    handleArchiveUnarchiveCreative: () => {},
    filter: '',
    setFilter: () => {},
    statuses: [],
    setStatuses: () => {},
  }
);

export const CreativesTableContextProvider = ({
  children,
  ...rest
}: CreativesTableContextProviderProps) => {
  const [filter, setFilter] = useState<string>('');
  const [statuses, setStatuses] = useState<EntityStatusType[]>(
    DefaultCreativeStatusKeys
  );
  const deferredFilter = useDeferredValue(filter);
  const {
    creatives,
    count = 0,
    isLoading,
    isValidating,
    archiveTrigger,
    setSize,
    hasMore,
  } = useCreatives({
    params: {
      expand: 'lineitem_set,stats_caches_by_lineitem_id',
      search: deferredFilter || undefined,
      status: statuses.join(','),
    },
    options: {
      keepPreviousData: true,
    },
  });

  const handleArchiveUnarchiveCreative = async (creative: Creative) => {
    await archiveTrigger({
      id: creative.id as never,
      isArchiving: !(creative.status === 'ARCHIVED'),
    });
  };

  return (
    <CreativesTableContext.Provider
      value={{
        ...rest,
        setSize,
        filter,
        setFilter,
        count,
        creatives: uniqBy(creatives, 'id'),
        isLoading,
        hasMore,
        isValidating,
        handleArchiveUnarchiveCreative,
        statuses,
        setStatuses,
      }}
    >
      {children}
    </CreativesTableContext.Provider>
  );
};

export const useCreativesTable = () => {
  return useContext(CreativesTableContext);
};
