/* eslint-disable @typescript-eslint/no-explicit-any */

import { isObject, values } from 'lodash';
import { GroupedData, TableData, VirtualizedData, WithId } from '../types';
import { VirtualizedRowType, VirtualizedTypeProperty } from '../constants';
import { inventoryCategoryName } from '../../CampaignAdGroupSection/AdvancedSubflows/AdvancedCustomInventoryTable/constants/category';
import { Values } from '@local-types';

export const useTableVirtualizedData = <
  Value,
  Data extends TableData<Value>
>({
  data,
}: {
  data: Data[];
}): {
  list: Data extends GroupedData<infer D>
    ? VirtualizedData<GroupedData<D>>[]
    : VirtualizedData<Data>[];
  getRowHeight: ({ index }: { index: number }) => number;
} => {
  const list = [
    ...data
      .map((v, i) =>
        v && isObject(v) && 'groupName' in (v as object)
          ? [
              {
                id: i + 1,
                groupName: (v as any).groupName,
                included: (v as any).data[0].included,
                [VirtualizedTypeProperty]: VirtualizedRowType.GROUP,
              },
              ...(v as any).data.map((d: any) => ({
                ...d,
                [VirtualizedTypeProperty]: d?.id
                  ? VirtualizedRowType.ROW
                  : VirtualizedRowType.EMPTY_ROW,
              })),
            ]
          : {
              ...v,
              [VirtualizedTypeProperty]: (v as WithId<Value>)?.id
                ? VirtualizedRowType.ROW
                : VirtualizedRowType.EMPTY_ROW,
            }
      )
      .flat()
      .filter(Boolean),
    {
      id: 'empty-end-row',
      [VirtualizedTypeProperty]: VirtualizedRowType.EMPTY_ROW,
    },
  ] as Data extends GroupedData<infer D>
    ? VirtualizedData<GroupedData<D>>[]
    : VirtualizedData<Data>[];

  return {
    getRowHeight: ({ index }: { index: number }) => {
      return list[index]?.[VirtualizedTypeProperty] ===
        VirtualizedRowType.GROUP
        ? values(inventoryCategoryName).includes(
            (
              list[index] as GroupedData<
                Value,
                Values<typeof inventoryCategoryName>
              >
            ).groupName
          )
          ? 48
          : 31
        : 74;
    },
    list,
  };
};
