import {
  BulkEditField,
  bulkEditFields,
  BulkEditStep,
  bulkEditSteps,
} from './base';

export const implementedFields: BulkEditField[] = [
  bulkEditFields.AD_GROUP_DATES_TIMES,
  bulkEditFields.AD_GROUP_BID_STRATEGY,
];

export type FieldOption = {
  value: BulkEditField;
  tooltip: string;
  disabled?: boolean;
};

export const stepHeaders = (
  adGroupCount: number
): Record<BulkEditStep, string> => {
  const plural = adGroupCount > 1 ? 's' : '';

  return {
    [bulkEditSteps.SELECT_FIELD]: `Select the field you want to edit for ${adGroupCount} Ad Group${plural}`,
    [bulkEditSteps.EDIT_FIELD]: `Edit field for ${adGroupCount} Ad Group${plural}`,
    [bulkEditSteps.REVIEW_EDITS]: `Review edits for ${adGroupCount} Ad Group${plural}`,
    [bulkEditSteps.APPLY_EDITS]: '',
  };
};

export const fieldEditHeaders: Record<BulkEditField, string> = {
  [bulkEditFields.AD_GROUP_DATES_TIMES]: 'Edit Ad Group Dates & Times',
  [bulkEditFields.AD_GROUP_BID_STRATEGY]: 'Edit Bid Strategy',
  [bulkEditFields.AD_GROUP_CREATIVE]: 'Edit Creative',
  [bulkEditFields.AD_GROUP_DEVICE_OS_TARGETING]:
    'Edit Device Type & Operating System',
};

export const confirmButtonText: Record<BulkEditStep, string> = {
  [bulkEditSteps.SELECT_FIELD]: '',
  [bulkEditSteps.EDIT_FIELD]: 'Next: Review Edits',
  [bulkEditSteps.REVIEW_EDITS]: 'Save Edits',
  [bulkEditSteps.APPLY_EDITS]: 'Done',
};

export const fieldOptions: FieldOption[] = [
  {
    value: bulkEditFields.AD_GROUP_DATES_TIMES,
    tooltip: 'Tooltip text',
  },
  {
    value: bulkEditFields.AD_GROUP_BID_STRATEGY,
    tooltip: 'Change the bid strategy for selected ad groups',
  },
];

export const fieldNames = {
  start_date: 'Start Date',
  start_time: 'Start Time',
  end_date: 'End Date',
  end_time: 'End Time',
  bid_strategy: 'Bid Strategy',
  bid_strategy_event: 'Conversion Event',
  cpm: 'CPM',
};
