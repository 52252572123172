import {
  BulkEditFieldState,
  BulkEditStep,
  bulkEditSteps,
  bulkEditFields,
  BulkEditModalProps,
  stepHeaders,
  fieldEditHeaders,
  confirmButtonText,
  BulkEditFieldValueState,
  FormattedDates,
} from './constants';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
  ThemeProvider,
  IconButton,
  Stack,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useMemo, useRef, useState } from 'react';
import {
  EditAdGroupDatesTimes,
  SelectField,
  ReviewEdits,
  EditBidStrategy,
  ApplyEdits,
} from './components';
import { CancelButtonStyled } from '@v2/components/campaign/CampaignAdGroupSection/styles';
import { Info } from '@v2/components/campaign/Info';
import { Button } from '@v2/components/ui/Button';
import {
  getBidStrategyOptions,
  mockBidStrategyData,
} from './constants/bidStrategyOptions';
import { defaultTheme_v2 } from '@themes/default_v2';
import {
  compileTableRows,
  getInvalidCount,
} from './components/ReviewEditsPage/utils';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useBulkEdit } from './context';
import { BidStrategyFormValues } from '@components/modals/BulkEditModal/types';

const BulkEditModalContent = ({
  data,
  onClose,
  onSubmit,
  ...props
}: BulkEditModalProps) => {
  const { adGroups = [], campaign } = data;

  const [step, setStep] = useState<BulkEditStep>(bulkEditSteps.SELECT_FIELD);
  const [field, setField] = useState<BulkEditFieldState>('Unselected');
  const [fieldValues, setFieldValues] =
    useState<BulkEditFieldValueState>('None');
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [ids, setIds] = useState<number[]>([]);
  const [invalidCount, setInvalidCount] = useState(0);

  const { validationResult, isUpdating } = useBulkEdit();

  /**
   * Configures the next button's enabled state and stores form submission handler
   * @param {boolean} isEnabled - Whether the next button should be enabled
   * @param {{ submit: () => void }} [submitRef] - Optional form submission handler reference
   */
  const setNextButtonState = (
    isEnabled: boolean,
    submitRef?: { submit: () => void }
  ) => {
    setIsNextEnabled(isEnabled);
    if (submitRef) {
      formRef.current = submitRef;
    }
  };

  const handleBackButton = () => {
    if (step === bulkEditSteps.EDIT_FIELD) {
      setStep(bulkEditSteps.SELECT_FIELD);
      setField('Unselected');
    } else {
      setStep(bulkEditSteps.EDIT_FIELD);
    }
  };

  const handleCancelButton = () => {
    onClose?.({} as React.SyntheticEvent, 'backdropClick');
  };

  const formRef = useRef<{ submit: () => void } | null>(null);

  const handleNextButton = () => {
    if (step === bulkEditSteps.EDIT_FIELD) {
      if (formRef.current) {
        formRef.current.submit();
      }
    } else if (step === bulkEditSteps.REVIEW_EDITS) {
      setStep(bulkEditSteps.APPLY_EDITS);
    } else if (step === bulkEditSteps.APPLY_EDITS) {
      handleCancelButton();
    }
  };

  const handleDatesTimesValidationSuccess = (
    formattedDates: FormattedDates
  ) => {
    setFieldValues(formattedDates);
    setStep(bulkEditSteps.REVIEW_EDITS);
  };

  const handleBidStrategyValidationSuccess = (
    formattedData: BidStrategyFormValues
  ) => {
    setFieldValues({
      bid_strategy: formattedData.bidStrategy,
      bid_strategy_event: formattedData.bidStrategyEvent,
      bid_strategy_target: formattedData.bidStrategyTarget,
      cpm: formattedData.maxCPMBid
        ? formatter.format(formattedData.maxCPMBid).slice(1)
        : null,
    });
    setStep(bulkEditSteps.REVIEW_EDITS);
  };

  const getHeaderTitle = () => {
    return field === 'Unselected'
      ? stepHeaders(adGroups.length)[step]
      : fieldEditHeaders[field];
  };

  // Compile table rows for ReviewEdits
  const tableRows = useMemo(() => {
    if (!adGroups?.length || !validationResult) {
      return [];
    }
    return compileTableRows(
      adGroups,
      validationResult,
      fieldValues !== 'None' ? fieldValues : {}
    );
  }, [adGroups, validationResult, fieldValues]);

  const handleInvalidRows = (ids: GridRowSelectionModel) => {
    if (!tableRows.length) {
      setInvalidCount(0);
      setIsNextEnabled(false);
      return;
    }
    const invalidCount = getInvalidCount(tableRows, ids);
    setInvalidCount(invalidCount);
    setIsNextEnabled(invalidCount === 0 && ids.length > 0);
  };

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    setIds(newSelection as number[]);
    handleInvalidRows(newSelection);
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const alreadyStarted = adGroups.some(adGroup => adGroup.active === true);

  const renderContent = () => {
    if (step === bulkEditSteps.SELECT_FIELD) {
      return (
        <SelectField
          adGroupCount={adGroups.length}
          setStep={setStep}
          setField={setField}
        />
      );
    }

    if (step === bulkEditSteps.EDIT_FIELD) {
      return (
        <>
          {field === bulkEditFields.AD_GROUP_DATES_TIMES && (
            <EditAdGroupDatesTimes
              alreadyStarted={alreadyStarted}
              setNextButtonState={setNextButtonState}
              selectedAdGroupIds={adGroups.map(group => group.id)}
              fieldValues={fieldValues}
              campaign={campaign}
              onValidationSuccess={handleDatesTimesValidationSuccess}
            />
          )}
          {field === bulkEditFields.AD_GROUP_BID_STRATEGY && (
            <EditBidStrategy
              fieldValues={fieldValues}
              bidStrategyOptions={getBidStrategyOptions(
                mockBidStrategyData.bidStrategyEventSet
              )}
              tvsciq={mockBidStrategyData.tvsciq}
              recommendedBidRange={mockBidStrategyData.recommendedBidRange}
              setNextButtonState={setNextButtonState}
              onValidationSuccess={handleBidStrategyValidationSuccess}
            />
          )}
        </>
      );
    }

    if (step === bulkEditSteps.REVIEW_EDITS) {
      return (
        <ReviewEdits
          rows={tableRows || []}
          invalidCount={invalidCount}
          handleSelectionChange={handleSelectionChange}
        />
      );
    }

    if (step === bulkEditSteps.APPLY_EDITS) {
      return (
        <ApplyEdits
          ids={ids}
          adGroups={adGroups}
          fieldValues={fieldValues !== 'None' ? fieldValues : {}}
        />
      );
    }

    return null;
  };

  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <Dialog
        onClose={(event, reason) => {
          onClose?.(event, reason);
        }}
        {...props}
        data-testid="bulk-edit-modal"
        fullWidth
        maxWidth="lg"
        open
        sx={{ pt: 2, pb: 2 }}
      >
        {step !== bulkEditSteps.APPLY_EDITS && (
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              pt={1}
            >
              <Box display="flex" alignItems="center">
                {step !== bulkEditSteps.SELECT_FIELD && (
                  <IconButton
                    onClick={handleBackButton}
                    size="large"
                    sx={{ mr: 1 }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
                <Typography variant="h3">{getHeaderTitle()}</Typography>
              </Box>

              <IconButton
                onClick={() =>
                  onClose?.({} as React.SyntheticEvent, 'escapeKeyDown')
                }
                size="large"
                aria-label="close"
              >
                <CloseIcon data-testid="CloseIcon" />
              </IconButton>
            </Box>
          </DialogTitle>
        )}

        <Divider />

        <DialogContent sx={{ p: 3 }}>{renderContent()}</DialogContent>

        {step !== bulkEditSteps.SELECT_FIELD && (
          <DialogActions disableSpacing sx={{ px: 4, py: 3 }}>
            <Grid container direction="column" sx={{ width: '100%' }}>
              {step !== bulkEditSteps.APPLY_EDITS && (
                <Divider sx={{ width: '100%', mb: 3 }} />
              )}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{ minHeight: 'auto' }}
              >
                <Grid>
                  {step !== bulkEditSteps.APPLY_EDITS && (
                    <CancelButtonStyled
                      variant="text"
                      onClick={handleCancelButton}
                    >
                      Cancel
                    </CancelButtonStyled>
                  )}
                </Grid>
                <Grid>
                  <Stack direction="row">
                    {field === bulkEditFields.AD_GROUP_DATES_TIMES &&
                      step === bulkEditSteps.REVIEW_EDITS && (
                        <Info type="error" variant="text" width={450}>
                          Your previous Ad Group schedule will be replaced if
                          you save these edits. Click Cancel to undo.
                        </Info>
                      )}
                    <Button
                      variant="contained"
                      type="submit"
                      label={confirmButtonText[step]}
                      disabled={!isNextEnabled}
                      onClick={handleNextButton}
                      // Instead of conditional rendering, hide button when updating to keep spacing consistent
                      sx={{ visibility: isUpdating ? 'hidden' : 'visible' }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default BulkEditModalContent;
