import { FormControlLabel, styled } from '@mui/material';

export const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.offWhite,
    border: `1px solid ${theme.palette.grey[300]}`,
    margin: 0,

    '& .MuiFormControlLabel-label': {
      color: theme.palette.grey[1],
      borderRadius: 10,
    },
    '&:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },

    '&:last-of-type': {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
    '&.Mui-checked': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiRadio-root': {
      display: 'none',
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.disabled,
      },
      '&:hover': {
        backgroundColor: theme.palette.action.disabledBackground,
        cursor: 'not-allowed',
      },
    },
  })
);
