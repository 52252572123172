import { styled, Radio } from '@mui/material';

export const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.grey[8],
  '&.Mui-checked': {
    color: theme.palette.primary.main,
  },
  padding: 0,
  marginRight: theme.spacing(1.5)
})); 
