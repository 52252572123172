import React, { useMemo } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import {
  FileCopyOutlined,
  OndemandVideo,
  PeopleOutlineOutlined,
  EditOutlined,
} from '@mui/icons-material';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import { classes } from '@components/containers/AdGroupsIndexPage/styles';
import { useAdGroupsTable } from '@components/containers/AdGroupsIndexPage/AdGroupsTableContext';
import { renderStatus } from '@components/containers/AdGroupsIndexPage/AdGroupsTable';
import { renderArchiveUnarchiveStatus } from '@components/containers/AdGroupsIndexPage/utils';

import {
  ActionMenuItem,
  DataGridActionMenuRenderer,
} from '@v2/components/ui/DataGrid/renderers/DataGridActionMenuRenderer';
import { AdGroup } from '@local-types/group';
import { Status } from '@constants';

export const AdGroupActionMenuCellRenderer = (
  params: GridCellParams<AdGroup>
) => {
  const {
    url,
    is_control_group,
    active,
    status,
    temporary,
    isDisplay: rowIsDisplay,
  } = params.row;
  const {
    handleManageDisplay,
    handleManageAdGroup,
    handleIsDuplicatable,
    handleDuplicateAdGroup,
    handleOpenWeighting,
    handleOpenBid,
    handlePauseActive,
    handleArchiveUnarchiveAdGroup,
    refreshGroups,
  } = useAdGroupsTable();

  const canArchive = status === Status.INACTIVE || status === Status.ARCHIVED;

  const isDisplay = url?.includes('static') ?? rowIsDisplay;

  const items = useMemo(() => {
    return [
      isDisplay && {
        id: 'manage',
        label: 'Manage Ad Group',
        onClick: () => handleManageDisplay(params.row),
        icon: (
          <AspectRatioOutlinedIcon
            fontSize="small"
            className={classes.menuIcon}
          />
        ),
      },
      !isDisplay &&
        !is_control_group &&
        status !== Status.ARCHIVED && {
          id: 'manage',
          label: 'Manage Ad Group',
          onClick: () => handleManageAdGroup(params.row),
          icon: <PeopleOutlineOutlined fontSize="small" />,
        },
      !isDisplay &&
        !is_control_group &&
        status !== Status.DRAFT && {
          id: 'duplicate',
          label: 'Duplicate Ad Group',
          onClick: () => {
            handleDuplicateAdGroup(params.row).then(() => refreshGroups());
          },
          icon: <FileCopyOutlined fontSize="small" />,
          disabled: handleIsDuplicatable(params.row),
        },
      !isDisplay &&
        !temporary &&
        status !== Status.ARCHIVED && {
          id: 'weighting',
          label: 'Edit Weighting',
          onClick: () => handleOpenWeighting(params.row),
          icon: <OndemandVideo fontSize="small" />,
        },
      !isDisplay &&
        !temporary &&
        !is_control_group &&
        status !== Status.DRAFT &&
        status !== Status.ARCHIVED && {
          id: 'bid',
          label: 'Edit Bid',
          onClick: () => handleOpenBid(params.row),
          icon: <EditOutlined fontSize="small" />,
        },
      !is_control_group &&
        !temporary &&
        (status === Status.ACTIVE || status === Status.INACTIVE) && {
          id: 'pause',
          label: renderStatus(active),
          onClick: () => handlePauseActive(params.row, isDisplay),
        },
      !isDisplay &&
        !temporary &&
        canArchive && {
          id: 'pause',
          label: renderArchiveUnarchiveStatus(status!, 'Ad Group'),
          onClick: () => handleArchiveUnarchiveAdGroup(params.row),
        },
    ].filter(Boolean);
  }, [
    isDisplay,
    canArchive,
    is_control_group,
    status,
    params.row,
  ]) as ActionMenuItem[];

  return <DataGridActionMenuRenderer params={params} items={items} />;
};
