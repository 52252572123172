import { Stack, Typography } from '@mui/material';
import { AudienceElement } from './AudienceElement';

import React from 'react';
import {
  AdditionalOptionsTypographyStyled,
  AdvancedSectionIconStyled,
  DividerStyled,
  DividerTypographyStyled,
  SectionBoxStyled,
  SectionInnerBoxStyled,
} from '../styles';
import { Copies, fields } from '../constants';
import { useAudience } from './hooks/useAudience';
import { faSquareArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import { DataList } from '../DataList';
import { SectionInfo } from '@components/SectionInfo';
import { Controller } from 'react-hook-form';
import { useCopy } from '@hooks/copy';
import { AudienceProps } from './types';
import { type Audience as AudienceType } from '@local-types/audience';
import { IncludableData } from '../AdvancedSubflows/types';
import { DataListElement } from './DataListElement';

export const Audience = ({
  campaignName,
  onAdvanced,
  info,
  isDisplay,
  ...props
}: AudienceProps) => {
  const Copy = useCopy(Copies);

  const { audience, control, showAdvancedTargeting } = useAudience({
    campaignName,
    isDisplay,
  });

  return (
    <SectionBoxStyled {...props}>
      <DividerStyled textAlign="left">
        <DividerTypographyStyled>Audience</DividerTypographyStyled>
      </DividerStyled>
      <SectionInnerBoxStyled>
        {showAdvancedTargeting && (
          <>
            <SectionInfo infoBlocks={info} />
            <Controller
              control={control}
              name={fields.social.path}
              render={({ field }) => <AudienceElement {...field} />}
            />
            <Stack spacing={1}>
              <AdditionalOptionsTypographyStyled onClick={onAdvanced}>
                <AdvancedSectionIconStyled icon={faSquareArrowUpRight} />
                {Copy.advancedTargetingOptionsLabel}
              </AdditionalOptionsTypographyStyled>
              {!!audience.length && (
                <Stack>
                  <DataList
                    singleSubField
                    title="Include:"
                    data={audience.filter(
                      (a: IncludableData<AudienceType>) => a.included
                    )}
                    data-testid="advanced-audience-data-list-include"
                    render={(data: AudienceType[]) => (
                      <DataListElement data={data} onAdvanced={onAdvanced} />
                    )}
                  />
                  <DataList
                    singleSubField
                    title="Exclude:"
                    data={audience.filter(
                      (a: IncludableData<AudienceType>) => !a.included
                    )}
                    data-testid="advanced-audience-data-list-exclude"
                    render={(data: AudienceType[]) => (
                      <DataListElement data={data} onAdvanced={onAdvanced} />
                    )}
                  />
                </Stack>
              )}
            </Stack>
          </>
        )}
        {isDisplay && !showAdvancedTargeting && (
          <DataList<string>
            singleSubField
            title="Include:"
            data={[campaignName]}
            data-testid="advanced-audience-data-list-include"
            render={(data: string[]) => (
              <Typography variant="body2">{data.join('')}</Typography>
            )}
          />
        )}
      </SectionInnerBoxStyled>
    </SectionBoxStyled>
  );
};
