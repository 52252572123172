import { Stack, Typography, Grid } from '@mui/material';
import { Controller, FormProvider } from 'react-hook-form';
import { useMemo, useEffect } from 'react';
import numeral from 'numeral';

import { FormWrapperStyled } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedAdGroupDelivery/styles';
import { useBidStrategyForm } from '../hooks/useBidStrategyForm';
import { BidStrategyOptionsAccordion } from './BidStrategyOptionsAccordion';
import { BidStrategyAccordionOption } from './BidStrategyAccordion';
import { EditBidStrategyProps } from '../constants';
import { BidStrategyFormValues } from '../types';
import { useBulkValidate } from '@components/hooks/apis/bulk';
import { useBulkEdit } from '../context';

export const EditBidStrategy = ({
  fieldValues,
  bidStrategyOptions = [],
  tvsciq = '',
  recommendedBidRange = [10, 20],
  selectedAdGroupIds = [],
  setNextButtonState,
  onValidationSuccess,
}: EditBidStrategyProps) => {
  const { setValidationState, setValidationResult } = useBulkEdit();

  const { trigger: validateBulk } = useBulkValidate({
    onSuccess: data => {
      setValidationResult(data);
      setValidationState(false, null);

      const allSuccess = data.results.every(
        result => result.status === 'SUCCESS',
      );

      setNextButtonState(allSuccess, {
        submit: () => {
          methods.handleSubmit(onSubmit);
        },
      });
    },
    onError: error => {
      setValidationState(false, error);
      setNextButtonState(false);
    },
  });

  const onSubmit = (values: BidStrategyFormValues) => {
    // only send this if the bid_strategy is Max Outcomes or Target Cost per Outcome
    const shouldSendBidStrategyEvent = bidStrategyOptions
      .filter(
        option =>
          option.label === 'Max Outcomes' ||
          option.label === 'Target Cost per Outcome',
      )
      .map(option => option.value)
      .includes(values.bidStrategy);

    // only send this if the bid_strategy is Target Cost per Outcome
    const shouldSendBidStrategyTarget = bidStrategyOptions
      .filter(option => option.label === 'Target Cost per Outcome')
      .map(option => option.value)
      .includes(values.bidStrategy);

    // only send this if the bid_strategy is Manual Bid
    const shouldSendCPMBid = bidStrategyOptions
      .filter(option => option.label === 'Manual Bid')
      .map(option => option.value)
      .includes(values.bidStrategy);

    try {
      const validationPromise = validateBulk({
        type: 'LINEITEM',
        select: {
          id__in: selectedAdGroupIds.map(id => id.toString()),
        },
        fields: {
          bid_strategy: values.bidStrategy,
          cpm: shouldSendCPMBid ? `${values.maxCPMBid}` : null,
          bid_strategy_target: shouldSendBidStrategyTarget
            ? values.bidStrategyTarget
            : null,

          // only send this if the bid_strategy is 6 or 8
          bid_strategy_event: shouldSendBidStrategyEvent
            ? values.bidStrategyEvent
            : null,
        },
      });

      validationPromise.then(data => {
        const allSuccess = data.results.every(
          result => result.status === 'SUCCESS',
        );

        if (allSuccess && onValidationSuccess) {
          onValidationSuccess(values);
        }
      });
    } catch (error) {
      setValidationState(
        false,
        error instanceof Error ? error : new Error(String(error)),
      );
    }
  };

  const {
    methods,
    control,
    isMaxCPMBidEnabled,
    handleCPMSwitchChange,
    submitHandler,
    isValid,
  } = useBidStrategyForm({
    defaultValues:
      fieldValues && fieldValues !== 'None'
        ? {
            bidStrategy: fieldValues.bid_strategy,
            maxCPMBid: fieldValues.cpm ? parseFloat(fieldValues.cpm) : null,
            bidStrategyTarget: fieldValues.bid_strategy_target,
            bidStrategyEvent: fieldValues.bid_strategy_event,
          }
        : undefined,
    bidStrategyOptions: bidStrategyOptions.map(option => ({
      value: option.value,
      label: option.label,
      description: option.description,
      locked: option.locked,
      infoText: option.infoText,
    })),
    onSubmit,
  });

  const rangeText = useMemo(() => {
    if (recommendedBidRange.length === 0) return 'not available';

    const min = recommendedBidRange[0];
    const max = recommendedBidRange[recommendedBidRange.length - 1];

    const formattedRange =
      min === max
        ? `${numeral(Math.ceil(min)).format('$0,0')}`
        : `${numeral(min).format('$0,0')} - ${numeral(Math.ceil(max)).format(
            '0,0',
          )}`;

    return `The recommended bid range for your inventory selection is ${formattedRange}.`;
  }, [recommendedBidRange]);

  const formContext = useMemo(
    () => ({
      control,
      isMaxCPMBidEnabled,
      handleCPMSwitchChange,
      rangeText,
    }),
    [control, isMaxCPMBidEnabled, handleCPMSwitchChange, rangeText],
  );

  const finalOptions = useMemo<BidStrategyAccordionOption[]>(() => {
    return bidStrategyOptions.map(option => {
      const baseOption = {
        value: String(option.value),
        label: option.label,
        description: option.description,
        infoText: option.infoText,
      };

      if (option.locked) {
        return {
          ...baseOption,
          locked: true,
          lockedInfo: option.lockedInfo
            ? String(option.lockedInfo)
            : 'This option is currently unavailable',
        };
      }

      return {
        ...baseOption,
        locked: false,
        unlockedContent: option.renderContent(formContext),
      };
    });
  }, [bidStrategyOptions, formContext]);

  const shouldShowBidStrategy = useMemo(
    () => bidStrategyOptions.length > 0 && tvsciq && tvsciq !== 'DISABLED',
    [bidStrategyOptions, tvsciq],
  );

  useEffect(() => {
    setNextButtonState(isValid, {
      submit: methods.handleSubmit(onSubmit),
    });
  }, [isValid, methods, onSubmit, setNextButtonState]);

  return (
    <FormProvider {...methods}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Stack
            component="form"
            onSubmit={submitHandler}
            sx={{ height: '100%' }}
          >
            <FormWrapperStyled>
              <Stack spacing={3}>
                {shouldShowBidStrategy ? (
                  <Controller
                    name="bidStrategy"
                    control={control}
                    render={({ field }) => (
                      <BidStrategyOptionsAccordion
                        value={field.value}
                        options={finalOptions}
                        onChange={newValue => {
                          field.onChange(newValue);
                        }}
                      />
                    )}
                  />
                ) : (
                  <Typography variant="body1">
                    Bid strategy options are not available.
                  </Typography>
                )}
              </Stack>
            </FormWrapperStyled>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
