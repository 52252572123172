import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';

import { CreativeObjectValueGetter } from '@v2/components/ui/DataGrid/CreativeObjectValueGetter';
import { CreativeNameCellRenderer } from './renderers/CreativeNameCellRenderer';
import { CreativeStatusCellRenderer } from './renderers/CreativeStatusCellRenderer';
import { LineItem } from '@local-types';
import { DateCellRenderer } from '@v2/components/ui/DataGrid/renderers/DateCellRenderer';
import { DateTimeCellRenderer } from './renderers/DateTimeCellRenderer';
import { DotNotationValueGetter } from '@v2/components/ui/DataGrid/DotNotationValueGetter';
import { CurrencyCellRenderer } from '@v2/components/ui/DataGrid/renderers/CurrencyCellRenderer';

export const getBaseColumns = (adGroup: LineItem | null): GridColDef[] => [
  {
    field: 'id',
    headerName: 'ID',
    width: 60,
    resizable: false,
    hideable: false,
    valueGetter: CreativeObjectValueGetter,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: CreativeNameCellRenderer,
    minWidth: 220,
    hideable: false,
  },
  {
    field: 'active',
    headerName: 'Status',
    renderCell: CreativeStatusCellRenderer,
    minWidth: 180,
    hideable: false,
  },
  {
    field: 'adGroup',
    headerName: 'Ad Group',
    flex: 1,
    renderCell: () => adGroup?.name,
    resizable: false,
    hideable: false,
  },
  {
    field: 'media_upload_date',
    headerName: 'Uploaded',
    renderCell: DateCellRenderer,
    minWidth: 150,
    resizable: false,
    hideable: false,
    valueGetter: CreativeObjectValueGetter,
  },
  {
    field: 'stats.total_spend',
    headerName: 'Spend',
    valueGetter: DotNotationValueGetter,
    renderCell: CurrencyCellRenderer,
    minWidth: 120,
    align: 'right',
    resizable: false,
    hideable: false,
  },
  {
    field: 'stats.visit_count',
    headerName: 'Visits',
    valueGetter: DotNotationValueGetter,
    minWidth: 120,
    align: 'right',
    resizable: false,
    hideable: false,
  },
  {
    field: 'stats.cost_per_visit',
    headerName: 'Cost Per Visit',
    valueGetter: DotNotationValueGetter,
    renderCell: CurrencyCellRenderer,
    minWidth: 120,
    align: 'right',
    resizable: false,
    hideable: false,
  },
];

export const startEndDateColumns = (
  adGroup: LineItem | null
): GridColDef[] => [
  {
    field: 'start_date',
    headerName: 'Start Date',
    renderCell: (params: GridCellParams) =>
      // eslint-disable-next-line new-cap
      DateTimeCellRenderer(params, adGroup?.start_date, 'No start date'),
    minWidth: 180,
    resizable: false,
    hideable: false,
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    renderCell: (params: GridCellParams) =>
      // eslint-disable-next-line new-cap
      DateTimeCellRenderer(params, adGroup?.end_date, 'No end date'),
    minWidth: 180,
    resizable: false,
    hideable: false,
  },
];
