import React from 'react';
import { Stack } from '@mui/material';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledRadio,
  TitleTypography,
  DescriptionTypography,
  InfoIcon,
  OptionTitleWrapper
} from './styledComponents';

export interface BidStrategyAccordionOption {
  value: string;
  label: string;
  description: string;
  infoText?: string;
  locked?: boolean;
  lockedInfo?: string;
  unlockedContent?: React.ReactNode;
}

export interface BidStrategyAccordionProps {
  option: BidStrategyAccordionOption;
  expanded: boolean;
  onChange: (value: string) => void;
  selected: boolean;
}

export const BidStrategyAccordion: React.FC<BidStrategyAccordionProps> = ({
  option,
  expanded,
  onChange,
  selected
}) => {
  const handleChange = () => {
    if (!option.locked) {
      onChange(option.value);
    }
  };

  const handleAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (event.target instanceof HTMLElement && 
        (event.target.closest('input[type="radio"]') || 
         event.target.closest('label'))) {
      return;
    }
    
    if (!option.locked && isExpanded) {
      onChange(option.value);
    }
  };

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={handleAccordionChange}
      disabled={option.locked}
    >
      <StyledAccordionSummary>
        <Stack direction="row" spacing={1} alignItems="flex-start" width="100%">
          <StyledRadio
            checked={selected}
            onChange={handleChange}
            disabled={option.locked}
            size="medium"
          />
          <Stack flexGrow={1}>
            <OptionTitleWrapper>
              <TitleTypography>{option.label}</TitleTypography>
              {option.infoText && <InfoIcon icon={faCircleInfo} />}
            </OptionTitleWrapper>
            <DescriptionTypography>{option.description}</DescriptionTypography>
          </Stack>
        </Stack>
      </StyledAccordionSummary>
      {expanded && option.unlockedContent && (
        <StyledAccordionDetails>
          {option.unlockedContent}
        </StyledAccordionDetails>
      )}
    </StyledAccordion>
  );
};
