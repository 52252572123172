import { GridCellParams } from '@mui/x-data-grid-pro';
import { AdGroup } from '@local-types/group';
import BWSyncStatus from '@components/BWSync';
import { Status } from '@constants';
import { CircularProgress } from '@mui/material';
import { useAdGroupsTable } from '@components/containers/AdGroupsIndexPage/AdGroupsTableContext';

export const AdGroupSyncCheckRenderer = (params: GridCellParams<AdGroup>) => {
  const { handleUpdateAdServer } = useAdGroupsTable();
  const {
    in_sync,
    out_of_sync,
    beeswax_lid,
    is_syncing,
    isDisplay: rowIsDisplay,
    url,
  } = params.row;
  const isDisabled = params.row.status !== Status.ACTIVE;
  const isDisplay = url?.includes('static') ?? rowIsDisplay;

  if (is_syncing) {
    return <CircularProgress size={20} sx={{ color: '#1DAFFF' }} />;
  }

  return (
    <BWSyncStatus
      isAdGroup={!!beeswax_lid}
      bwStatus={in_sync ?? false}
      diff={out_of_sync ?? {}}
      isDisabled={isDisabled}
      onClickUpdateAdServer={() => handleUpdateAdServer(params.row, isDisplay)}
    />
  );
};
