import { TableData } from '../types';
import { makeEven } from '../utils';

export const useTableSide = <Value, Data extends TableData<Value>>(
  data: Data[],
  {
    actualAmount,
  }: {
    actualAmount: number;
  }
) => {
  return {
    evenData: makeEven<Data>(data, actualAmount),
  };
};
