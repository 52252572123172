import { useMemo, useState, useRef, useCallback } from "react";
import Draggable, { DraggableEventHandler } from 'react-draggable';
import { IconButton, Typography, Paper, Box } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { ThemeProvider, Theme } from "@mui/material/styles";
import { defaultTheme_v2 } from "@themes/default_v2";
import FilterList from "./FilterList";
import { FilterMenuProps, FilterItem } from "./types";

const FilterMenu = (props: FilterMenuProps) => {
  const {
    data,
    items,
    onClose,
    onSubmit,
  } = props;

  const defaultPosition = useMemo(() => ({
    x: window.innerWidth * 0.25,
    y: 80
  }), []);

  const [selectedItem, setSelectedItem] = useState<FilterItem | undefined>(items[0]);
  const [position, setPosition] = useState(defaultPosition);
  const [isDragging, setIsDragging] = useState(false);
  const nodeRef = useRef(null);

  const { component: Form, id } = useMemo(
    () => selectedItem || { component: undefined, id: undefined },
    [selectedItem]
  );

  const paperStyles = useMemo(() => ({
    position: 'fixed',
    width: '66.67vw',
    height: '570px',
    bgcolor: 'common.offWhite',
    zIndex: (theme: Theme) => theme.zIndex.modal + 1,
    userSelect: isDragging ? 'none' : 'text'
  }), [isDragging]);

  const draggableTitleStyles = useMemo(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '40px',
    cursor: 'move',
  }), []);

  const closeButtonStyles = useMemo(() => ({
    position: 'absolute',
    right: (theme: Theme) => theme.spacing(2),
    top: (theme: Theme) => theme.spacing(2),
    zIndex: (theme: Theme) => theme.zIndex.modal + 2
  }), []);

  const handleSelectItem = useCallback((item: FilterItem) => {
    setSelectedItem(item);
  }, []);

  const handleStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleStop = useCallback<DraggableEventHandler>((_e, data) => {
    setIsDragging(false);
    setPosition({ x: data.x, y: data.y });
  }, []);

  const handleMouseLeave = useCallback(() => {
    const event = new MouseEvent('mouseup');
    document.dispatchEvent(event);
  }, []);

  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <Draggable
        nodeRef={nodeRef}
        handle=".draggable-dialog-title"
        defaultPosition={position}
        onStart={handleStart}
        onStop={handleStop}
        bounds="parent"
      >
        <Paper
          component={Grid}
          container
          elevation={8}
          ref={nodeRef}
          onMouseLeave={handleMouseLeave}
          sx={paperStyles}
        >
          <Box 
            className="draggable-dialog-title" 
            sx={draggableTitleStyles} 
          />
          
          <IconButton
            onClick={onClose}
            size="small"
            sx={closeButtonStyles}
          >
            <FontAwesomeIcon icon={faXmark} style={{ fontSize: 16 }} />
          </IconButton>
          
          <Grid
            xs={3}
            sx={{
              bgcolor: 'grey.11',
              padding: theme => theme.spacing(3, 0),
              minHeight: '100%',
              minWidth: 200,
            }}
          >
            <Typography
              sx={theme => ({
                padding: theme.spacing(1.5, 4),
                fontSize: '1.125rem',
                fontWeight: 700,
                lineHeight: 1,
                color: 'text.primary'
              })}
            >
              Filter Options
            </Typography>
            
            <FilterList
              items={items}
              selectedItem={selectedItem}
              onSelectItem={handleSelectItem}
            />
          </Grid>

          <Grid xs sx={{ height: '100%' }}>
            {Form && (
              <Form
                key={id}
                data={data}
                onSubmit={onSubmit}
              />
            )}
          </Grid>
        </Paper>
      </Draggable>
    </ThemeProvider>
  );
};

export default FilterMenu;
