import React from 'react';
import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Controller, type Control, type FieldArrayWithId } from 'react-hook-form';
import { IFormValues } from './types/ListFilterFormProps.types';

export interface RowProps {
  item: FieldArrayWithId<IFormValues, 'items', 'internalId'>;
  control: Control<IFormValues>;
  fields: FieldArrayWithId<IFormValues, 'items', 'internalId'>[];
  handleCheckboxChange: (checked: boolean, itemId: number) => void;
  style?: React.CSSProperties;
  searchValue?: string;
}

export const Row = React.memo(function Row({ item, control, fields, handleCheckboxChange, style, searchValue }: RowProps) {
  const fieldIndex = fields.findIndex(field => Number(field.id) === item.id);

  return (
    <ListItem style={style} disablePadding data-testid={`list-item-${item.id}`}>
      <Controller
        name={`items.${fieldIndex}.checked`}
        control={control}
        render={({ field: checkboxField }) => {
          const handleChange = (checked: boolean) => {
            checkboxField.onChange(checked);
            handleCheckboxChange(checked, item.id);
          };

          return (
            <ListItemButton
              role={undefined}
              dense
              onClick={() => handleChange(!checkboxField.value)}
              data-testid={`list-item-button-${item.id}`}
            >
              <ListItemIcon sx={{ minWidth: theme => theme.spacing(3) }}>
                <Checkbox
                  edge="start"
                  checked={checkboxField.value}
                  onChange={(e) => handleChange(e.target.checked)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': `checkbox-list-label-${item.id}` }}
                  sx={{ p: 0 }}
                  data-testid={`checkbox-${item.id}`}
                />
              </ListItemIcon>
              <ListItemText
                id={`checkbox-list-label-${item.id}`}
                data-testid={`list-item-text-${item.id}`}
                primary={
                  searchValue?.trim() ? (
                    <>
                      {item.name.split(new RegExp(`(${searchValue})`, 'i')).map((part, i) => (
                        part.toLowerCase() === searchValue.toLowerCase() ? (
                          <span key={i} style={{ fontWeight: 400 }}>{part}</span>
                        ) : (
                          <span key={i} style={{ fontWeight: 700 }}>{part}</span>
                        )
                      ))}
                    </>
                  ) : (
                    <span style={{ fontWeight: 400 }}>{item.name}</span>
                  )
                }
              />
            </ListItemButton>
          );
        }}
      />
    </ListItem>
  );
});
