export const bulkEditSteps = {
  SELECT_FIELD: 'SelectField',
  EDIT_FIELD: 'EditField',
  REVIEW_EDITS: 'ReviewEdits',
  APPLY_EDITS: 'ApplyEdits',
} as const;

const AD_GROUP_DATES_TIMES = 'Ad Group Dates & Times';
const AD_GROUP_BID_STRATEGY = 'Bid Strategy';
const AD_GROUP_CREATIVE = 'Creative';
const AD_GROUP_DEVICE_OS_TARGETING = 'Device Type & Operating System';

export const bulkEditFields = {
  AD_GROUP_DATES_TIMES,
  AD_GROUP_BID_STRATEGY,
  AD_GROUP_CREATIVE,
  AD_GROUP_DEVICE_OS_TARGETING,
} as const;

export type BulkEditStep = (typeof bulkEditSteps)[keyof typeof bulkEditSteps];
export type BulkEditField =
  (typeof bulkEditFields)[keyof typeof bulkEditFields];
export type BulkEditFieldState = 'Unselected' | BulkEditField;
export type BulkEditFieldValue = {
  active?: boolean;
  start_date?: string | null;
  end_date?: string | null;
  bid_strategy?: string;
  bid_strategy_event?: string | number | null;
  bid_strategy_target?: number | null;
  cpm?: string | null;
};
export type BulkEditFieldValueState = 'None' | BulkEditFieldValue;
