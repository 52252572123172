import { Divider, Stack } from '@mui/material';
import {
  bulkEditSteps,
  fieldOptions,
  BulkEditField,
  SelectFieldProps,
} from '../constants';
import { OptionsList } from '@components/OptionsList';

export const SelectField = ({ setStep, setField }: SelectFieldProps) => {
  const handleSelectField = (value: BulkEditField) => {
    setField(value);
    setStep(bulkEditSteps.EDIT_FIELD);
  };

  return (
    <Stack spacing={3}>
      <OptionsList
        options={fieldOptions}
        onChange={e => handleSelectField(e.target.value as BulkEditField)}
      />
      <Divider />
    </Stack>
  );
};
