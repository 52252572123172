import { GridCellParams } from '@mui/x-data-grid-pro';
import { useAdGroupCreativeTable } from '../hooks/useAdGroupCreativeTable';
import { DataGridLink } from '@v2/components/ui/DataGrid/DataGridLink';
import { CreativeLineItemWithCreative } from '@local-types/creativeLineItem';

export const CreativeNameCellRenderer = (
  params: GridCellParams<CreativeLineItemWithCreative>
) => {
  const { adGroupId, campaignId } = useAdGroupCreativeTable();
  const {
    creative_object: { name, id },
  } = params.row;

  return (
    <DataGridLink
      to={`/campaigns/${campaignId}/adgroups/${adGroupId}/creative/${id}`}
    >
      {name}
    </DataGridLink>
  );
};
