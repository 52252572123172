import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { useDisplaysInfinite } from '@apis/displays';
import { Display } from '@local-types';

export interface DisplaysTableContextProviderProps {
  children: React.ReactNode;
}

export interface DisplaysTableContextType
  extends Omit<DisplaysTableContextProviderProps, 'children'> {
  isLoading: boolean;
  isValidating: boolean;
  count: number;
  hasMore: boolean;
  displays: Display[];
  setSize: Dispatch<SetStateAction<number>>;
}

export const DisplaysTableContext = createContext<DisplaysTableContextType>({
  isLoading: false,
  isValidating: false,
  count: 0,
  displays: [],
  hasMore: false,
  setSize: () => {},
});

export const DisplaysTableContextProvider = ({
  children,
  ...rest
}: DisplaysTableContextProviderProps) => {
  const {
    displays,
    count = 0,
    isLoading,
    isValidating,
    setSize,
    hasMore,
  } = useDisplaysInfinite({
    options: {
      keepPreviousData: true,
    },
  });

  return (
    <DisplaysTableContext.Provider
      value={{
        ...rest,
        setSize,
        count,
        displays,
        isLoading,
        hasMore,
        isValidating,
      }}
    >
      {children}
    </DisplaysTableContext.Provider>
  );
};

export const useDisplaysTable = () => {
  return useContext(DisplaysTableContext);
};
