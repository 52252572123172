import { useContext } from 'react';
import FlagsContext from '../../providers/FlagsContext';
import { Flags } from '../../constants/flags';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';

export interface FlagsType {
  [key: string]: boolean;
}

export interface FlagsContextType {
  flags: FlagsType;
  isFlagsLoading: boolean;
}

export const useFlags = () => {
  const flagsContext = useContext(
    FlagsContext
  ) as unknown as FlagsContextType | null;

  if (flagsContext === null) {
    throw new Error('useFlags was used outside a FlagsContextProvider');
  }
  const { flags, isFlagsLoading } = flagsContext;

  return { flags, Flags, isFlagsLoading };
};

export const useAllFlags = () => {
  const ldFlags = useLDFlags();
  const defaultFlags = useFlags();

  return {
    ...defaultFlags,
    flags: {
      ...defaultFlags.flags,
      ...ldFlags,
    },
  };
};
