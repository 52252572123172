import { ReportingHomeData, UseGetReportingHomeDataProps } from "@components/containers/ReportsPage/types";
import { useCurrentSession } from "../currentSession";
import useSWR from "swr";

const useReportingHomeData = ({ dateFilter, endpoint, disable = false }: UseGetReportingHomeDataProps & { endpoint: string, disable?: boolean }) => {
  const { get, apiIsReady } = useCurrentSession();

  const fetcher: ({ url }: { url?: string }) => Promise<ReportingHomeData> = ({ url }) =>
    get(`${process.env.REPORTING_API}/${url}`).then(res => res.data);

  const {
    data,
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(
    apiIsReady && !disable ? { url: `${endpoint}?dateFilter=${dateFilter}` } : null,
    ({ url }) => fetcher({ url }),
    {
      revalidateOnFocus: false,
    }
  );

  return {
    data,
    isLoading,
    error,
    isValidating,
    mutate,
  };
}

export default useReportingHomeData;
