import React, {
  Suspense,
  lazy,
  useMemo,
  useState,
  useEffect,
  useContext,
} from 'react';
import {
  FiltersProvider,
  useFilters,
} from '@components/providers/FiltersProvider';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Paper,
  IconButton,
  styled,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Status, DefaultStatusKeys, RoutePaths } from '@constants';
import AdvertiserContext from '../../AdvertiserContext';
import DeleteDraftCampaign from '../../DeleteDraftCampaign';
import LoadingSpinner from '../../ui/LoadingSpinner';
import ManageBudget from '../../ManageBudget';
import ModalOverlay from '../../ModalOverlay';
import moment from 'moment-timezone';
import { useAPI } from '../../hooks/api';
import { useLogRocket } from '../../hooks/logRocket';
import { useDuplicateCampaign } from '../../hooks/apis/campaigns';
import AlertBox from '../../AlertBox';
import { EmbedLookerChart } from '../../EmbedLookerChart';
import { useIntercom } from '../../../helpers/intercomWrapper';
import { getPrimaryOrgId } from '../../../helpers';
import CampaignsTable from './CampaignsTable';
import { classes, StyledAppHeader } from './styles';
import { generatePath } from 'react-router-dom';
import { ErrorBoundary } from '@v2/components/ui/ErrorBoundary';
import {
  useAdvertisers,
  useFlags,
  useLoader,
  useQuery,
  useWizardRedirect,
} from '@hooks';
import { useDomain } from '@hooks/domain';
import ArchiveUnarchive from '@components/ArchiveUnarchive';
import { FilterMenu } from '@components/FilterMenu';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import CampaignsTableV2 from './CampaignsTableV2';
import {
  CampaignsTableContextProvider,
  CampaignsTableContext,
} from './CampaignsTableContext';
import CampaignIdFilter from './CampaignIdFilter';
import CampaignNameFilter from './CampaignNameFilter';
import AdGroupIdFilter from './AdGroupIdFilter';
import AdGroupNameFilter from './AdGroupNameFilter';
import { StatusSelect } from '@components/StatusSelect';
import StatusFilter from './StatusFilter';

const ManageCampaign = lazy(() => import('../../ManageCampaign'));

const PaperStyled = styled(Paper)(() => ({
  overflow: 'hidden',
}));

const filterMenuItems = [
  {
    id: 'campaign_id',
    name: 'Campaign ID',
    component: CampaignIdFilter,
  },
  {
    id: 'campaign_name',
    name: 'Campaign',
    component: CampaignNameFilter,
  },
  {
    id: 'status',
    name: 'Status',
    component: StatusFilter,
  },
  {
    id: 'adgroup_id',
    name: 'Ad Group ID',
    component: AdGroupIdFilter,
  },
  {
    id: 'adgroup_name',
    name: 'Ad Group',
    component: AdGroupNameFilter,
  },
];

const CampaignsPageContent = props => {
  const adContext = useContext(AdvertiserContext);
  const domain = useDomain();
  const urlQuery = useQuery();
  const { currentAdvertiser } = useAdvertisers();
  const { flags, Flags } = useFlags();

  const isVerticalCampaignFlowEnabled =
    flags[Flags.USER_GETS_VERTICAL_CAMPAIGN];
  const { campaignsV2Table, fancyTableFiltering } = useLDFlags();

  const { useGet } = useAPI();
  const { trigger: duplicateCampaign, isMutating: isDuplicating } = useDuplicateCampaign();
  const { isLoading: isPageLoading } = useLoader();

  const [alert, setAlert] = useState(null);
  const [isAlertOpen, setAlertOpen] = useState(true);
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [isCampaign, setIsCampaign] = useState(false);
  const [isDupeCampaign, setIsDupeCampaign] = useState(false);
  const [isEditBudget, setIsEditBudget] = useState(false);
  const [isDeleteDraft, setIsDeleteDraft] = useState(false);
  const [archiveState, setArchiveState] = useState(null);
  const [statuses, setStatuses] = useState(DefaultStatusKeys);
  const [filterOpen, setFilterOpen] = useState(false);

  const { tagUser } = useLogRocket();
  const { showArticle } = useIntercom();
  const { editCampaign } = useWizardRedirect();

  const { filters, getQueryParams, updateFilter, setFilters } = useFilters();
  const params = useMemo(() => getQueryParams('campaigns'), [getQueryParams]);

  const handleManageBudget = campaign => {
    setCurrentCampaign(campaign);
    setIsEditBudget(true);
  };

  const handleDeleteDraft = campaign => {
    setCurrentCampaign(campaign);
    setIsDeleteDraft(true);
  };

  const handleDuplicateCampaign = async campaign => {
    if (isVerticalCampaignFlowEnabled) {
      try {
        const duplicateData = {
          name: `Copy of ${campaign.name}`,
          daily_budget: campaign.daily_budget,
          budget: campaign.daily_budget,
          start_date: moment().format(),
          end_date: campaign.end_date,
        };
        
        const result = await duplicateCampaign({
          campaignId: campaign.id,
          data: duplicateData
        });
        
        if (result && result.id) {
          // Redirect to the vertical workflow with the new campaign
          return editCampaign({ campaignId: result.id });
        }
      } catch (error) {
        console.error('Error duplicating campaign', error);
      }
    } else {
      // Fallback to old behavior for users without vertical flow
      setCurrentCampaign(campaign);
      setIsDupeCampaign(true);
      setIsCampaign(true);
    }
  };

  const handleDraftCampaign = campaign => {
    if (isVerticalCampaignFlowEnabled) {
      return editCampaign({ campaignId: campaign });
    }

    props.history.push(
      generatePath(RoutePaths.OLD_CAMPAIGN_EDIT, { id: campaign })
    );
  };

  const handleManageCampaign = campaign => {
    if (isVerticalCampaignFlowEnabled) {
      return editCampaign({ campaignId: campaign.id });
    }

    setCurrentCampaign(campaign);
    setIsCampaign(true);
  };

  const handleArchiveUnarchiveCampaign = campaign => {
    const isArchiving = campaign.status === Status.INACTIVE;
    setArchiveState(isArchiving ? 'archiving' : 'unarchiving');
    setCurrentCampaign(campaign);
  };

  const handleCloseDeleteDraft = () => {
    setIsDeleteDraft(false);
  };

  const handleCloseBudget = () => {
    setIsEditBudget(false);
    setCurrentCampaign(null);
  };

  const handleCloseArchiveUnarchive = refreshCampaigns => {
    setArchiveState(null);
    setCurrentCampaign(null);
    refreshCampaigns();
  };

  useEffect(() => {
    updateFilter('status', statuses.join(','));
  }, [statuses]);

  useEffect(() => {
    // Tag user sso id, name and email in Log Rocket
    tagUser();
    getAlerts();
  }, []);

  useEffect(() => {
    if (isVerticalCampaignFlowEnabled) {
      urlQuery.set('org', getPrimaryOrgId(currentAdvertiser.primary_org));
      urlQuery.set('adAccount', currentAdvertiser?.id);
    }
  }, [
    isVerticalCampaignFlowEnabled,
    urlQuery,
    currentAdvertiser?.id,
    currentAdvertiser?.primary_org,
  ]);

  async function getAlerts() {
    const alerts = await useGet('/alerts');
    setAlert(alerts.activeAlert !== null ? alerts : null);
  }

  const goLiveMessage = {
    title: 'NOTICE',
    message:
      'This Ad Account needs to be associated with a valid Billing Account before any Campaigns will go live! ',
    type: 'MAJOR',
    closeable: false,
    isAlertOpen: true,
  };

  const campaignsTableProps = {
    params,
    setIsDupeCampaign,
    handleManageBudget,
    handleDeleteDraft,
    handleDuplicateCampaign,
    handleDraftCampaign,
    handleManageCampaign,
    handleArchiveUnarchiveCampaign,
    urlQuery,
  };

  const handleFilterClick = () => {
    setFilterOpen(prev => !prev);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  const handleFilterSubmit = newFilters => {
    setFilters(prev => ({
      ...prev,
      ...newFilters,
    }));
  };

  return (
    <>
      {filterOpen && (
        <FilterMenu
          data={filters}
          items={filterMenuItems}
          onClose={handleFilterClose}
          onSubmit={handleFilterSubmit}
        />
      )}

      <StyledAppHeader history={props.history}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {alert !== null && (
              <Grid item xs={12}>
                <AlertBox
                  {...alert.activeAlert}
                  isAlertOpen={isAlertOpen}
                  closeAlert={setAlertOpen}
                />
              </Grid>
            )}
            {!adContext?.billing_account_is_valid && (
              <Grid item xs={12}>
                <AlertBox
                  {...goLiveMessage}
                  isAlertOpen={isAlertOpen}
                  closeAlert={setAlertOpen}
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => showArticle('8516335')}
                    >
                      Click Here for Help!
                    </Button>
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <PaperStyled>
                <EmbedLookerChart dashboard="CAMPAIGN" />
              </PaperStyled>
            </Grid>
          </Grid>
        </Container>

        <ErrorBoundary fallback={<h2>Could not fetch campaigns.</h2>}>
          <Suspense>
            <CampaignsTableContextProvider {...campaignsTableProps}>
              <CampaignsTableContext.Consumer>
                {({
                  handlePauseActive,
                  handleSaveCampaign,
                  handleDeleteCampaignComplete,
                  refreshCampaigns,
                }) => (
                  <Box
                    border={1}
                    borderColor="grey.300"
                    p={6}
                    pt={4}
                    m={4}
                    borderRadius="20px"
                    data-testid="campaigns-card"
                  >
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography
                          variant="h6"
                          component="div"
                          data-testid="active-campaigns-header"
                        >
                          Campaigns
                        </Typography>
                      </Grid>

                      <Grid item>
                        {fancyTableFiltering && (
                          <>
                            <IconButton
                              onClick={handleFilterClick}
                              size="small"
                              sx={{ ml: 1 }}
                              aria-label="open filter menu"
                            >
                              <FilterListIcon />
                            </IconButton>
                          </>
                        )}

                        {!fancyTableFiltering && (
                          <StatusSelect
                            regularStatuses={DefaultStatusKeys}
                            chosenStatuses={statuses}
                            onChange={setStatuses}
                          />
                        )}
                      </Grid>
                    </Grid>
                    {campaignsV2Table ? (
                      <CampaignsTableV2 />
                    ) : (
                      <CampaignsTable
                        {...campaignsTableProps}
                        handlePauseActive={handlePauseActive}
                      />
                    )}

                    {isEditBudget && (
                      <ManageBudget
                        isModal
                        isOpen={isEditBudget}
                        currentCampaign={currentCampaign}
                        onClose={handleCloseBudget}
                        setHasSaved={handleSaveCampaign}
                      />
                    )}

                    {isCampaign && (
                      <ModalOverlay
                        className={classes.modal}
                        isOpen={isCampaign}
                        onClose={() => {
                          setIsCampaign(false);
                          setIsDupeCampaign(false);
                        }}
                      >
                        <Suspense fallback={<LoadingSpinner />}>
                          <ManageCampaign
                            showTestIncrementality={
                              domain.default ||
                              (domain.peacock &&
                                !!currentCampaign.experiment_type)
                            }
                            showType={domain.default}
                            isDupeCampaign={isDupeCampaign}
                            setHasSaved={handleSaveCampaign}
                            setIsCampaign={setIsCampaign}
                            setIsDupeCampaign={setIsDupeCampaign}
                            currentCampaign={currentCampaign}
                          />
                        </Suspense>
                      </ModalOverlay>
                    )}

                    {isDeleteDraft && (
                      <DeleteDraftCampaign
                        isModal
                        isOpen={isDeleteDraft}
                        campaign={currentCampaign}
                        onClose={handleCloseDeleteDraft}
                        onDelete={handleDeleteCampaignComplete}
                      />
                    )}

                    {(isPageLoading || isDuplicating) && (
                      <Box
                        width="100%"
                        height="100%"
                        className={classes.pageLoader}
                      >
                        <CircularProgress color="secondary" />
                      </Box>
                    )}

                    {archiveState && (
                      <ArchiveUnarchive
                        isOpen={!!archiveState}
                        item={currentCampaign}
                        onClose={() =>
                          handleCloseArchiveUnarchive(refreshCampaigns)
                        }
                        isArchiving={archiveState === 'archiving'}
                        itemType="Campaign"
                      />
                    )}
                  </Box>
                )}
              </CampaignsTableContext.Consumer>
            </CampaignsTableContextProvider>
          </Suspense>
        </ErrorBoundary>
      </StyledAppHeader>
    </>
  );
};

CampaignsPageContent.propTypes = {
  history: PropTypes.object,
};

const CampaignsPage = props => {
  return (
    <FiltersProvider>
      <CampaignsPageContent {...props} />
    </FiltersProvider>
  );
};

CampaignsPage.propTypes = {
  history: PropTypes.object,
};

export default CampaignsPage;
