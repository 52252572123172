import React, { useState, useEffect, ReactNode } from 'react';
import { Box, Tabs, Tab, Typography, Button } from '@mui/material';
import { useSearchParams } from '@hooks/useSearchParams';
import { useHistory } from 'react-router-dom';

interface Report {
  id: string;
  title: string;
  content: () => ReactNode;
}

interface ReportsLookerPageProps {
  tabs: Report[] | Report;
  setInExplore: (inExplore: boolean) => void;
  inExplore: boolean;
  active?: boolean
}

const ReportsLookerPage = ({ tabs, setInExplore, inExplore, active }: ReportsLookerPageProps) => {
  const showTabs = Array.isArray(tabs) && tabs.length > 1;
  const history = useHistory();

  const tab = Array.isArray(tabs) ? tabs[0] : tabs;

  const [openTab, setOpenTab] = useState<string | null>(showTabs ? tabs[0].id : null);
  const { searchParams, setSearchParams } = useSearchParams();

  useEffect(() => {
    if (!showTabs || tabs.length === 0) return
    const activeParam = searchParams.get('active')
    const paramIsValid = tabs.some(tab => tab.id === activeParam)
    if (activeParam && paramIsValid && showTabs) {
      setOpenTab(activeParam)
    } else {
      const id = tabs.length > 0 ? tabs[0].id : null;
      if (id && active) {
        setOpenTab(id);
        setSearchParams({ key: 'active', value: id })
      }
    }
  }, [tabs, active])


  const handleTabChange = (e: React.SyntheticEvent, v: string) => {
    if (inExplore) {
      history.goBack();
      setInExplore(false);
    }
    setOpenTab(v)
    setSearchParams({ key: 'active', value: v })
  }

  const handleBack = () => {
    setInExplore(false);
    history.goBack();
  }

  const isPathToPurchase = !Array.isArray(tabs) && tabs.id === 'path';

  return (
    <>
      <Box>
        {showTabs && (
          <Box sx={{ marginX: '-24px', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={openTab}
              onChange={handleTabChange}
            >
              {tabs.map(tab => (
                <Tab
                  disableRipple={true}
                  sx={{
                    minWidth: '130px',
                    margin: '0 20px',

                    ['& .MuiTypography-root']: {
                      fontSize: '1rem',
                    },

                    ['&.Mui-selected .MuiTypography-root']: {
                      fontWeight: 700,
                    }
                  }}
                  key={tab.id}
                  value={tab.id}
                  data-testid={`reports-page-tab-${tab.id}`}
                  label={
                    <Typography>
                      {tab.title}
                    </Typography>
                  }
                />
              ))}
            </Tabs>
          </Box>
        )}
        <Box sx={{ position: 'relative' }}>
          {inExplore && (
            <Button
              sx={{
                position: 'absolute',
                padding: 0,
                top: isPathToPurchase ? '24px' : '4px',
                left: '10px',
                fontSize: '10px',
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                },
                color: '#1DAFFF'
              }}
              onClick={handleBack}
              disableRipple
              disableFocusRipple
              disableTouchRipple
            >
              &lt; Back
            </Button>
          )}
          <Box>
            {showTabs && tabs.map(tab => (
              <div
                key={tab.id}
                style={{ display: openTab === tab.id ? 'unset' : 'none' }}
                data-testid={`reports-iframe-container-${tab.id}`}
              >
                {tab.content()}
              </div>
            ))}
            {
              !showTabs && tab && (
                <div>
                  {tab.content()}
                </div>
              )
            }
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ReportsLookerPage;
