import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Box, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import AsyncButton from '@components/AsyncButton';
import SankeyChart from '@components/visualizations/SankeyChart';
import { EmbedLookerChart } from '@components/EmbedLookerChart';
import AdvertiserContext from '@components/AdvertiserContext';
import { formatDateData } from '@components/util';
import { useLoader } from '@hooks/loader';
import { useAPI } from '@hooks/api';
import { Themes } from '@constants/themes';
import { useLookerFiltersContext } from '@providers/LookerFiltersContext';

const PathToPurchaseDashboards = {
  [Themes.DEFAULT]: 'P2P',
  [Themes.NBCU]: 'NBCU_P2P',
}

export const PathToPurchase = ({ setInExplore, internal }) => {
  const adContext = useContext(AdvertiserContext);
  const { useGet } = useAPI();
  const { isLoading, setIsLoading } = useLoader(false);
  const location = useLocation();
  const { filters, setFilters } = useLookerFiltersContext()

  const [minStartDate] = useState(
    moment().subtract(90, 'days')
  );
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment().subtract(30, 'days')
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment()
  );
  const [dashboardData, setDashboardData] = useState(null);

  const pathToPurchaseDashboard = useMemo(
    () => PathToPurchaseDashboards[adContext.theme],
    [adContext.theme]
  );

  const handleUpdateStartDate = date => {
    if (!date) {
      date = '';
    }
    setSelectedStartDate(date);
  };

  const handleUpdateEndDate = date => {
    if (!date) {
      date = '';
    }
    setSelectedEndDate(date);
  };

  const parseP2PData = (data) => {
    const dataMap = {
      "links": [
        {
          "source": 0,
          "target": 1,
          "value": null
        },
        {
          "source": 0,
          "target": 2,
          "value": null
        },
        {
          "source": 0,
          "target": 3,
          "value": null
        },
        {
          "source": 0,
          "target": 4,
          "value": null
        },
        {
          "source": 1,
          "target": 5,
          "value": null
        },
        {
          "source": 2,
          "target": 6,
          "value": null
        },
        {
          "source": 3,
          "target": 7,
          "value": null
        },
        {
          "source": 4,
          "target": 8,
          "value": null
        },
        {
          "source": 5,
          "target": 9,
          "value": null
        },
        {
          "source": 6,
          "target": 9,
          "value": null
        },
        {
          "source": 7,
          "target": 9,
          "value": null
        },
        {
          "source": 8,
          "target": 9,
          "value": null
        }
      ],
      "nodes": [
        {
          "color": "#0fbf84",
          "name": "Impressions"
        },
        {
          "color": "#f27799",
          "name": "Direct/Organic"
        },
        {
          "color": "#5744b2",
          "name": "Social"
        },
        {
          "color": "#ffb837",
          "name": "Search"
        },
        {
          "color": "#727383",
          "name": "Other"
        },
        {
          "color": "#f27799",
          "name": "Direct/Organic Outcome"
        },
        {
          "color": "#5744b2",
          "name": "Social Outcome"
        },
        {
          "color": "#ffb837",
          "name": "Search Outcome"
        },
        {
          "color": "#727383",
          "name": "Other Outcome"
        },
        {
          "color": "#009061",
          "name": "Conversion"
        }
      ]
    }

    const values = []
    let results = {};

    data.forEach(arr => {
      arr.forEach(item => {
        if (typeof item === 'number') values.push(item)
      })

    })
    results = dataMap.links.map((link, i) => ({ ...link, value: values[i] }))

    dataMap.links = results;

    return dataMap;
  }

  const fetchP2PData = async () => {
    setIsLoading(true)
    const p2pUrl = `${process.env.REPORTING_API}/sankeylast30days`

    try {
      const response = await useGet(p2pUrl);

      if (response) {
        const parsed = await parseP2PData(response)
        setDashboardData(parsed)
        setIsLoading(false)
      }
      return response;
    } catch (err) {
      console.log('error loading path to purchase data', err);
      setIsLoading(false)
      return err;
    }
  };

  const filterP2PData = async (startDate, endDate) => {
    const start = formatDateData(startDate).split('T')[0];
    const end = formatDateData(endDate).split('T')[0];
    const p2pUrl = `${process.env.REPORTING_API}/sankey?startDate=${start}&endDate=${end}`
    try {
      const response = await useGet(p2pUrl);

      if (response) {
        const parsed = await parseP2PData(response)
        setDashboardData(parsed)
        setIsLoading(false)
      }
      return response;
    } catch (err) {
      console.log('error loading path to purchase data', err);
      setIsLoading(false)
      return err;
    }
  }

  useEffect(() => {
    if (adContext?.id && adContext.path_to_purchase_validated) {
      fetchP2PData();
    }
  }, [adContext.id, location]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box mb={1}>
          <EmbedLookerChart
            id='path-to-purchase'
            dashboard={pathToPurchaseDashboard}
            withAuth={false}
            sx={{ height: 1100 }}
            filters={filters}
            setFilters={setFilters}
            setInExplore={setInExplore}
            internal={internal}
          />
        </Box>

        <Box width="100%">
          <Grid container item xs={12} justifyContent="space-evenly" spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <DatePicker
                    color="secondary"
                    disableFuture
                    disableToolbar
                    fullWidth
                    inputFormat='MM/dd/yyyy'
                    inputVariant='outlined'
                    label="Start Date"
                    data-testid="start-date-picker"
                    id='date-picker-inline'
                    margin='normal'
                    minDate={minStartDate}
                    maxDate={selectedEndDate}
                    onChange={handleUpdateStartDate}
                    value={selectedStartDate}
                    variant='inline'
                  />
                </Grid>

                <Grid item xs={4}>
                  <DatePicker
                    sx={{ marginLeft: '10px' }}
                    color="secondary"
                    disableFuture
                    disableToolbar
                    fullWidth
                    inputFormat='MM/dd/yyyy'
                    inputVariant='outlined'
                    label="End Date"
                    data-testid="end-date-picker"
                    id='date-picker-inline'
                    margin='normal'
                    minDate={selectedStartDate}
                    onChange={handleUpdateEndDate}
                    value={selectedEndDate}
                    variant='inline'
                  />
                </Grid>

                <Grid item xs={4}>
                  <AsyncButton
                    id="date-filter"
                    isLoading={isLoading}
                    variant="contained"
                    color="secondary"
                    size="medium"
                    sx={{
                      marginTop: '15px',
                      fontSize: '21px',
                      minWidth: '185px',
                      minHeight: '59px',
                    }}
                    onClick={() => {
                      setIsLoading(true);
                      filterP2PData(selectedStartDate, selectedEndDate);
                    }}
                    loadingButton="Loading..."
                  >
                    Filter
                  </AsyncButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {!isLoading && dashboardData ? (
            <SankeyChart data={dashboardData} />
          ) : (
            <p>loading...</p>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

PathToPurchase.propTypes = {
  setInExplore: PropTypes.func,
  internal: PropTypes.bool,
};
