import React, { createContext, useContext, useState, ReactNode } from 'react';
import moment from 'moment';
import {
  BulkUpdateResponse,
  BulkValidateResponse,
} from '@components/hooks/apis/bulk';

export interface BulkEditDatesTimesFormData {
  startDate: moment.Moment | null;
  startTime: string | null;
  endDate: moment.Moment | null;
  endTime: string | null;
}

interface BulkEditContextState {
  datesTimesFormData: BulkEditDatesTimesFormData;

  validationResult: BulkValidateResponse | null;
  isValidating: boolean;
  validationError: Error | null;

  updateResult: BulkUpdateResponse | null;
  isUpdating: boolean;
  updateError: Error | null;

  setDatesTimesFormData: (data: BulkEditDatesTimesFormData) => void;

  setValidationResult: (result: BulkValidateResponse | null) => void;
  setValidationState: (isValidating: boolean, error: Error | null) => void;

  setUpdateResult: (result: BulkUpdateResponse | null) => void;
  setUpdateState: (isUpdating: boolean, error: Error | null) => void;

  resetContext: () => void;
}

const initialState: BulkEditContextState = {
  datesTimesFormData: {
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  },

  validationResult: null,
  isValidating: false,
  validationError: null,

  updateResult: null,
  isUpdating: false,
  updateError: null,

  setDatesTimesFormData: () => {},

  setValidationResult: () => {},
  setValidationState: () => {},

  setUpdateResult: () => {},
  setUpdateState: () => {},

  resetContext: () => {},
};

export const BulkEditContext =
  createContext<BulkEditContextState>(initialState);

interface BulkEditProviderProps {
  children: ReactNode;
}

export const BulkEditProvider = ({ children }: BulkEditProviderProps) => {
  const [datesTimesFormData, setDatesTimesFormData] =
    useState<BulkEditDatesTimesFormData>(initialState.datesTimesFormData);

  const [validationResult, setValidationResult] =
    useState<BulkValidateResponse | null>(null);
  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState<Error | null>(null);

  const [updateResult, setUpdateResult] = useState<BulkUpdateResponse | null>(
    null
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState<Error | null>(null);

  const setValidationState = (isValidating: boolean, error: Error | null) => {
    setIsValidating(isValidating);
    setValidationError(error);
  };

  const setUpdateState = (isUpdating: boolean, error: Error | null) => {
    setIsUpdating(isUpdating);
    setUpdateError(error);
  };

  const resetContext = () => {
    setDatesTimesFormData(initialState.datesTimesFormData);
    setValidationResult(null);
    setIsValidating(false);
    setValidationError(null);
    setUpdateResult(null);
    setIsUpdating(false);
    setUpdateError(null);
  };

  const value = {
    datesTimesFormData,

    validationResult,
    isValidating,
    validationError,

    updateResult,
    isUpdating,
    updateError,

    setDatesTimesFormData,

    setValidationResult,
    setValidationState,

    setUpdateResult,
    setUpdateState,

    resetContext,
  };

  return (
    <BulkEditContext.Provider value={value}>
      {children}
    </BulkEditContext.Provider>
  );
};

export const useBulkEdit = () => {
  const context = useContext(BulkEditContext);

  if (context === undefined) {
    throw new Error('useBulkEdit must be used within a BulkEditProvider');
  }

  return context;
};
