import { TableBody, TableHead, TableRow } from '@mui/material';
import React, { Fragment } from 'react';
import {
  AdvancedTableStyled,
  FirstCellStyled,
  LastCellStyled,
  TableContainerStyled,
} from '../styles';
import { TableLeftRow } from './TableLeftRow';
import { useTableSide } from '../hooks/useTableSide';
import { TableLeftProps } from './types';
import { isNull } from 'lodash';
import { GroupedData, TableData } from '../types';

export const TableLeft = <Value, Data extends TableData<Value>>({
  data = [],
  dataName,
  onChange,
  renderCell,
  renderGroup,
  actualAmount,
  PlaceholderGroup,
  isTargeted,
  PlaceholderCell,
}: TableLeftProps<Value, Data>) => {
  const { evenData } = useTableSide<Value, Data>(data, {
    actualAmount,
  });
  return (
    <TableContainerStyled>
      <AdvancedTableStyled>
        <TableHead>
          <TableRow>
            <FirstCellStyled>{dataName}</FirstCellStyled>
            <LastCellStyled />
          </TableRow>
        </TableHead>
        <TableBody data-testid="advanced-left-table-body">
          {!isNull(evenData[0]) && 'groupName' in (evenData[0] as Data[])
            ? (evenData as GroupedData<Value, string>[]).map((v, i) => (
                <Fragment key={`${v.groupName}-${i}`}>
                  <TableRow data-testid={`table-left-group-${v.groupName}`}>
                    {renderGroup ? (
                      renderGroup({
                        data: v,
                        type: 'left',
                      })
                    ) : (
                      <PlaceholderGroup type={'left'} data={v} />
                    )}
                  </TableRow>
                  {v.data.map((d, j) => (
                    <TableLeftRow
                      key={
                        'id' in data
                          ? (data.id as string)
                          : `placeholder-${v.groupName}-${i}-${j}`
                      }
                      data={d}
                      index={j}
                      isTargeted={v && !!(isTargeted || v.effects?.hover)}
                      onChange={onChange}
                      PlaceholderCell={PlaceholderCell}
                      renderCell={renderCell}
                    />
                  ))}
                </Fragment>
              ))
            : (evenData as Value[]).map((v, i) => (
                <TableLeftRow
                  key={
                    'id' in data ? (data.id as string) : `placeholder-${i}`
                  }
                  data={v}
                  index={i}
                  isTargeted={v && isTargeted}
                  onChange={onChange}
                  PlaceholderCell={PlaceholderCell}
                  renderCell={renderCell}
                />
              ))}
        </TableBody>
      </AdvancedTableStyled>
    </TableContainerStyled>
  );
};
