export const customInventoryTabValues = {
  channels: 'channels',
  inventory: 'inventory',
  appName: 'app_name',
  appBundleList: 'app_bundle_list',
  dealId: 'deal_id',
  appBundleTargeting: 'app_bundle',
  appDomainList: 'app_domain_list',
};

export enum AdditionalFlag {
  SALES_DEMO = 'SALES_DEMO',
}

export const tabsBlockedFlag = [AdditionalFlag.SALES_DEMO];

export const customInventoryTypes = {
  [customInventoryTabValues.appName]: 'app_name',
  [customInventoryTabValues.appBundleList]: 'app_bundle_list',
  [customInventoryTabValues.dealId]: 'deal_id',
  [customInventoryTabValues.appBundleTargeting]: 'app_bundle',
  [customInventoryTabValues.appDomainList]: 'app_domain_list',
};

export const customInventoryTabsOrder = {
  [customInventoryTabValues.inventory]: [
    'Peacock',
    'Network',
    'Category',
    'Genre',
    'Speciality',
    'Private',
  ],
};
