import { Box, Stack, Typography, keyframes } from '@mui/material';
import Lottie from 'lottie-react';
import updatingAnimation from '../../../../../images/animation/updating.json';

const ellipsisAnimation = keyframes`
  0% { content: ""; }
  25% { content: "."; }
  50% { content: ".."; }
  75% { content: "..."; }
  100% { content: ""; }
`;

export const Updating = ({ adGroupCount = 1 }: { adGroupCount: number }) => {
  return (
    <Stack sx={{ alignItems: 'center', overflow: 'clip' }}>
      <Lottie
        style={{
          margin: '0 auto',
          marginTop: '-70px',
        }}
        animationData={updatingAnimation}
        loop
      />
      <Typography variant="h4" sx={{ mt: -7, lineHeight: 'inherit' }}>
        Updating {adGroupCount} Ad Group{adGroupCount > 1 && 's'}
        <Box
          component="span"
          sx={{
            display: 'inline-block',
            width: '1.2em',
            '&::after': {
              content: '""',
              animation: `${ellipsisAnimation} 1.3s infinite`,
            },
          }}
        />
      </Typography>
    </Stack>
  );
};
