import type {
  AppBundleListBundle,
  Bundle,
  DomainListBundle,
  GeneralBundle,
} from '@local-types/bundle';
import type { Audience } from '@local-types/audience';
import { parseTargeting } from './general';
import { prepareBundles } from './bundles';
import { transformGeography } from './geography';
import {
  denormalizeGeoItems,
  distinctNames,
  GeoItem,
  NormalizedGeoItems,
  normalizeGeoItems,
} from '@v2/components/campaign/GeoTargeter';
import { mapAudiences } from './audience';
import { prepareOsDeviceForSave, prepareOsDeviceSelected } from './inventory';
import { fields } from '../formConfig';
import { defaultGeoValue } from '@v2/components/campaign/CampaignAdGroupSection/constants';
import type {
  InventoryTargeting,
  InventoryTargetingStoreStructure,
} from '@local-types/inventory-targeting';
import { groupBy, isEmpty, map } from 'lodash';
import type { AdGroup } from '@local-types/group';
import { targetingFields as targetingFieldsToTransform } from '../constants';
import { Fields } from '@local-types';
import { removeNullValues } from '@components/WizardContainers/utils';
import { customInventoryTabValues } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedCustomInventoryTable/constants/general';
import { inventoryCategoryName } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedCustomInventoryTable/constants/category';
import { AudienceElementValue } from '@v2/components/campaign/CampaignAdGroupSection/Audience/types';
import { IncludableData } from '@v2/components/campaign/Advanced/types';

export const transformTargetingFields = (
  targetingFields: string,
  {
    appBundleList = [],
    allowedTargeting = [],
    availableBundles = [],
    allowedAudiences = [],
    domainList = [],
  }: {
    appBundleList: AppBundleListBundle[];
    allowedTargeting: InventoryTargeting[];
    availableBundles: Bundle[];
    allowedAudiences: Audience[];
    domainList: DomainListBundle[];
  }
) => {
  const {
    app_name = {
      values: [],
      blacklist: false,
    },
    deal_id = {
      values: [],
      blacklist: false,
    },
    app_bundle_list = {
      values: [],
      blacklist: false,
    },
    domain_list = {
      values: [],
      blacklist: false,
    },
    bundles = [],
    geo = defaultGeoValue,
    dayparting = [],
    segments = [],
    excluded_segments = [],
    os = {
      ids: [],
      blacklist: false,
    },
    device = {
      ids: [],
      blacklist: false,
    },
    gender = fields.social.defaultValue.gender,
    age = fields.social.defaultValue.age,
    income = fields.social.defaultValue.income,
    inventory,
  } = parseTargeting(targetingFields ?? '{}');

  return {
    [fields.advancedCustomInventory.path]: prepareBundles({
      appBundleList,
      app_name,
      deal_id,
      app_bundle_list,
      bundles,
      availableBundles,
      domain_list,
      domainList,
    }),
    [fields.geography.path]: transformGeography(normalizeGeoItems(geo)),
    [fields.advancedAudience.path]: mapAudiences({
      segments,
      excluded_segments,
      allowedAudiences,
    }),
    [fields.social.path]: {
      gender,
      age,
      income,
    },
    [fields.inventoryOption.path]: inventory,
    [fields.advancedInventory.path]: prepareOsDeviceSelected({
      os,
      device,
      allowedTargeting,
    }),
    [fields.configureAdGroupDaypartingEnabled.path]: dayparting?.length > 0,
    [fields.configureAdGroupDayparting.path]: dayparting,
  };
};

export const transformToTargeting = (
  formData: Partial<Fields<typeof fields>>,
  {
    allowedTargeting,
    adGroup,
  }: { allowedTargeting: InventoryTargeting[]; adGroup: AdGroup }
) => {
  const [
    { os, device },
    social,
    audiences,
    bundles,
    inventory,
    dayparting = [],
    timezone,
    geoFormData,
  ] = map(targetingFieldsToTransform, field => formData[field]) as [
    {
      os: InventoryTargetingStoreStructure;
      device: InventoryTargetingStoreStructure;
    },
    AudienceElementValue,
    Audience[],
    IncludableData<GeneralBundle>[],
    string,
    string[],
    string,
    GeoItem[]
  ];

  const initialTargeting = parseTargeting(adGroup?.targeting);

  const { age, gender, income } = social;

  const groupedGeo = groupBy(
    geoFormData.filter(v => v.type !== distinctNames.empty),
    'type'
  ) as NormalizedGeoItems;

  const geo = {
    /** @ts-expect-error better keep it. */
    [distinctNames.countries]: [],
    /** @ts-expect-error better keep it. */
    [distinctNames.region]: [],
    /** @ts-expect-error better keep it. */
    [distinctNames.place]: [],
    /** @ts-expect-error better keep it. */
    [distinctNames.zip]: [],
    /** @ts-expect-error better keep it. */
    [distinctNames.dma]: [],
    ...denormalizeGeoItems(groupedGeo),
  };

  // We need to move geo.country into geo.countries
  /** @ts-expect-error it is a helper to prevent a bug with missnaming. */
  if (geo[distinctNames.country]) {
    geo[distinctNames.countries] = [
      ...geo[distinctNames.countries],
      /** @ts-expect-error it is a helper to prevent a bug with missnaming. */
      ...geo[distinctNames.country],
    ];
    /** @ts-expect-error it is a helper to prevent a bug with missnaming. */
    delete geo[distinctNames.country];
  }

  const app_names = bundles.filter(
    bundle =>
      bundle.type === inventoryCategoryName[customInventoryTabValues.appName]
  );

  const app_bundle_list = bundles.filter(
    bundle =>
      bundle.type ===
      inventoryCategoryName[customInventoryTabValues.appBundleList]
  );

  const deal_id = bundles.filter(
    bundle =>
      bundle.type === inventoryCategoryName[customInventoryTabValues.dealId]
  );

  const domain_list = bundles.filter(
    bundle =>
      bundle.type ===
      inventoryCategoryName[customInventoryTabValues.appDomainList]
  );

  const preparedOsDevice = prepareOsDeviceForSave({
    os,
    device,
    allowedTargeting,
  });

  const data = removeNullValues({
    geo,
    dayparting,
    ...preparedOsDevice,
    gender,
    ...(isEmpty(age) ? {} : { age }),
    ...(isEmpty(income) ? {} : { income }),
    bundles: bundles.length
      ? map(bundles, 'bundle_name').filter(Boolean)
      : null,
    app_name: app_names.length
      ? {
          values: app_names.map(bundle => bundle.value),
          blacklist: !app_names[0].included,
        }
      : initialTargeting.app_name
      ? { values: [] }
      : null,
    app_bundle_list: app_bundle_list.length
      ? {
          values: app_bundle_list.map(bundle => bundle.value),
          blacklist: !app_bundle_list[0].included,
        }
      : initialTargeting.app_bundle_list
      ? { values: [] }
      : null,
    deal_id: deal_id.length
      ? {
          values: deal_id.map(bundle => bundle.value),
          blacklist: true,
        }
      : initialTargeting.deal_id
      ? { values: [] }
      : null,
    domain_list: domain_list.length
      ? {
          values: domain_list.map(bundle => bundle.value),
          blacklist: !domain_list[0].included,
        }
      : initialTargeting.domain_list
      ? { values: [] }
      : null,
    segments: map(
      audiences.filter(a => a.included),
      'audience_name'
    ),
    excluded_segments: map(
      audiences.filter(a => !a.included),
      'audience_name'
    ),
    inventory,
    timezone,
  });

  return JSON.stringify(data);
};
