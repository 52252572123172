/* eslint-disable @typescript-eslint/no-explicit-any */

import { Status } from '@constants';
import type {
  AppBundleListBundle,
  Bundle,
  DomainListBundle,
} from '@local-types/bundle';
import type { BidStrategy, BidStrategyEvent } from '@local-types/bidStrategy';
import type { Campaign } from '@local-types/campaign';
import type { Creative } from '@local-types/creative';
import type { Fields } from '@local-types/general';
import type { Audience } from '@local-types/audience';
import type { AdGroup } from '@local-types/group';
import type { InventoryTargeting } from '@local-types/inventory-targeting';
import { isNil, isNull, isUndefined, omit } from 'lodash';
import moment, { type Moment } from 'moment';
import { convertToStatus, removeNullValues } from '../utils';
import { fields } from './formConfig';
import { getFirstBidStrategy } from './utils/bid-strategy';
import { mapCreativesToAdditionalData } from './utils/creatives';
import {
  getPacingFromFormData,
  transformFreqCapFields,
} from './utils/delivery';
import { toET } from './utils/general';
import {
  transformTargetingFields,
  transformToTargeting,
} from './utils/targeting';
import type { BudgetPacingOptions } from '@v2/components/campaign/PacingFieldsGroup';

export const transformAdGroupToFields = (
  adGroup: AdGroup,
  {
    bidStrategies = [],
    bidStrategyEventSet = [],
    appBundleList = [],
    audiences = [],
    customInventory = [],
    creatives: mappedCreatives = [],
    allowedTargeting = [],
    domainList = [],
    campaign = {} as Campaign,
  }: {
    bidStrategies: BidStrategy[];
    bidStrategyEventSet: BidStrategyEvent[];
    appBundleList: AppBundleListBundle[];
    audiences: Audience[];
    customInventory: Bundle[];
    creatives: Creative[];
    allowedTargeting: InventoryTargeting[];
    domainList: DomainListBundle[];
    campaign: Campaign;
  }
) => {
  const {
    audience_extension,
    budget_pacing_options,
    targeting,
    creatives = [],
    start_date,
    end_date,
    cpm,
    bid_strategy,
    daily_budget,
    draft,
    ...rest
  } = adGroup ?? {};

  const transformedFields = {
    ...transformTargetingFields(targeting, {
      allowedTargeting,
      appBundleList,
      availableBundles: customInventory,
      allowedAudiences: audiences,
      domainList,
    }),
    ...transformFreqCapFields(adGroup),
  };

  const nowISO = moment(toET(moment()).toISOString());

  const pacingEnabled = !isNil(budget_pacing_options);

  const pacingOptions = pacingEnabled
    ? {
        [fields.adGroupPacingEnabled.path]: true,
        [fields.adGroupPacing.path]: budget_pacing_options.pacing,
        [fields.adGroupPacingBehavior.path]: budget_pacing_options.behavior,
        [fields.adGroupPacingCatchupBehavior.path]:
          budget_pacing_options.catchup,
      }
    : {
        [fields.adGroupPacingEnabled.path]: false,
      };

  const actualBidStrategy =
    !isUndefined(bid_strategy) && !isNull(bid_strategy)
      ? bid_strategy
      : getFirstBidStrategy({ bidStrategies, bidStrategyEventSet })?.id ??
        null;

  return {
    ...rest,
    ...transformedFields,
    [fields.audienceExtension.path]: audience_extension,
    [fields.draft.path]:
      'draft' in adGroup ? draft : campaign?.status === Status.DRAFT,
    [fields.campaign.path]: campaign?.id,
    [fields.status.path]: convertToStatus(adGroup),
    [fields.adGroupBudget.path]: daily_budget ?? 0,
    [fields.adGroupBidStrategy.path]: actualBidStrategy,
    ...pacingOptions,
    [fields.maxCPMBidEnabled.path]: parseFloat(cpm) > 0,
    [fields.maxCPMBid.path]: cpm,
    [fields.startDate.path]: start_date
      ? moment(start_date)
      : moment(campaign.start_date ?? nowISO),
    [fields.endDate.path]:
      end_date || (campaign?.end_date && adGroup.temporary)
        ? moment(end_date ?? campaign?.end_date)
        : null,
    [fields.startTime.path]: start_date
      ? moment(start_date)
      : campaign.start_date
      ? moment(campaign.start_date)
      : null,
    [fields.endTime.path]:
      end_date || (campaign?.end_date && adGroup.temporary)
        ? moment(end_date ?? campaign?.end_date)
        : null,
    [fields.creatives.path]: creatives.length ? mappedCreatives : [],
  };
};

export const mustIncludePostFields = [
  fields.adGroupBidStrategyEvent.path,
] as string[];

export const mustIncludePatchFields = [
  fields.adGroupBidStrategyEvent.path,
  fields.endDate.path,
] as string[];

export const filter =
  (mandatoryFields = mustIncludePostFields) =>
  (key: string, value: any) => {
    if (mandatoryFields.includes(key)) {
      return true;
    }
    return value;
  };

export const transformToAdGroup = (
  formData: Fields<typeof fields>,
  {
    bidStrategies,
    budgetPacingOptions,
    campaign,
    allowedTargeting,
    adGroup,
    isDisplay,
    filter,
  }: {
    bidStrategies: BidStrategy[];
    budgetPacingOptions?: BudgetPacingOptions;
    campaign: Campaign;
    allowedTargeting: InventoryTargeting[];
    adGroup: AdGroup;
    isDisplay: boolean;
    filter: (key: string, value: any) => boolean;
  }
) => {
  const actualData = omit(formData, [
    fields.adGroupPacing.path,
    fields.adGroupPacingBehavior.path,
    fields.adGroupPacingCatchupBehavior.path,
    fields.adGroupPacingEnabled.path,
    fields.advancedCustomInventory.path,
    fields.advancedAudience.path,
    fields.audienceExtension.path,
    fields.advancedInventory.path,
    fields.maxCPMBidEnabled.path,
    fields.configureAdGroupDaypartingEnabled.path,
    fields.configureAdGroupFrequencyCapEnabled.path,
    fields.configureAdGroupFrequencyCap.path,
    fields.configureAdGroupDayparting.path,
    fields.startTime.path,
    fields.endTime.path,
    fields.geo.path,
    fields.social.path,
    fields.configureAdGroupFrequencyCap.path,
    fields.inventoryOption.path,
    fields.description.path,
    fields.adGroupBudget.path,
    fields.status.path,
    fields.targetCPA.path,
    'budget',
    'adjusted_cpm',
    'bid_strategy_event_url',
    'out_of_sync',
  ]);

  const pacing = getPacingFromFormData({ budgetPacingOptions, formData });

  const formBidStrategy = formData[fields.adGroupBidStrategy.path];

  const actualStartTime = formData[fields.startTime.path] ?? moment();

  const data = removeNullValues(
    {
      ...actualData,
      audience_extension: formData[fields.audienceExtension.path],
      draft: actualData[fields.draft.path] ?? true,
      description: actualData[fields.description.path],
      freq_caps: formData[fields.configureAdGroupFrequencyCap.path]?.map(v =>
        removeNullValues(v)
      ),
      campaign: campaign?.id,
      daily_budget: Number(formData[fields.adGroupBudget.path]).toFixed(2),
      bid_strategy: formBidStrategy
        ? bidStrategies.find(
            strategy =>
              strategy.id === formData[fields.adGroupBidStrategy.path]
          )?.id
        : formBidStrategy,
      bid_strategy_event: bidStrategies.find(
        (strategy: BidStrategy) =>
          strategy.id === formData[fields.adGroupBidStrategy.path]
      )?.has_event
        ? actualData[fields.adGroupBidStrategyEvent.path]
        : null,
      start_date: moment(formData[fields.startDate.path])
        .set({
          minutes: actualStartTime.minutes(),
          hours: actualStartTime.hours(),
        })
        .toISOString(),
      end_date: formData[fields.endDate.path]
        ? moment(formData[fields.endDate.path])
            .set({
              minutes: (
                formData[fields.endTime.path] as unknown as Moment
              ).minutes(),
              hours: (
                formData[fields.endTime.path] as unknown as Moment
              ).hours(),
            })
            .toISOString()
        : null,
      creatives: (formData.creatives as unknown as Creative[]).map(
        creative => creative.id
      ),
      creative_weighting_method: formData[fields.weightingRotation.path],
      targeting: transformToTargeting(formData, {
        allowedTargeting,
        adGroup,
      }),
      creativesAdditionalData: mapCreativesToAdditionalData(
        formData.creatives as unknown as Creative[]
      ),
      ...pacing,
    },
    filter
  );

  // setting nullable, empty string fields here
  if (!isDisplay) {
    data.cpm =
      formData[fields.maxCPMBid.path] === ''
        ? null
        : formData[fields.maxCPMBid.path];
  }

  return data;
};
