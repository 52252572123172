import React from 'react';
import { EmbedLookerChart } from "@components/EmbedLookerChart";
import ReportsLookerPage from "../components/ReportsLookerPage";
import { DASHBOARD_NAMES, LOOKER_CHARTS } from '../constants';

interface ReportsCPAInternalProps {
  setInExplore: React.Dispatch<React.SetStateAction<boolean>>;
  inExplore: boolean;
}

const ReportsCPAInternal = ({ setInExplore, inExplore }: ReportsCPAInternalProps) =>
  <ReportsLookerPage
    tabs={{
      id: LOOKER_CHARTS.CPA_INTERNAL.id,
      title: LOOKER_CHARTS.CPA_INTERNAL.title,
      content: () => (
        <EmbedLookerChart
          id={LOOKER_CHARTS.CPA_INTERNAL.id}
          dashboard={DASHBOARD_NAMES.CPA_INTERNAL}
          setInExplore={setInExplore}
        />
      )
    }}
    setInExplore={setInExplore}
    inExplore={inExplore}
  />;

export default ReportsCPAInternal;
