import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateLeft,
  faBoxArchive,
} from '@fortawesome/pro-regular-svg-icons';
import { classes } from './styles';
import moment from 'moment';
import React from 'react';
import { Status } from '@constants';
import type { EntityStatusType } from '@local-types';

export const renderPauseActivateStatus = (status: EntityStatusType) => {
  if (status === Status.ACTIVE) {
    return (
      <>
        <PauseCircleOutlineOutlinedIcon
          fontSize="small"
          className={classes.menuIcon}
        />
        Pause Ad Group
      </>
    );
  }

  if (status === Status.INACTIVE) {
    return (
      <>
        <PlayCircleOutlineIcon fontSize="small" className={classes.menuIcon} />
        Activate Ad Group
      </>
    );
  }
};

export const renderArchiveUnarchiveStatus = (status: EntityStatusType, type: string) => {
  if (status === Status.INACTIVE) {
    return (
      <>
        <FontAwesomeIcon icon={faBoxArchive} className={classes.menuIcon} fontSize="20px" />
        Archive {type}
      </>
    );
  }

  if (status === Status.ARCHIVED) {
    return (
      <>
        <FontAwesomeIcon icon={faArrowRotateLeft} fontSize="20px" className={classes.menuIcon} />
        Unarchive {type}
      </>
    );
  }
};

export const formatEndDate = (endDate: string | null) => {
  if (endDate && Date.parse(endDate)) {
    return moment(endDate).format('l');
  }
  return 'No End Date';
};
