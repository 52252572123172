import { isValidUrl } from '@helpers/url';
import * as yup from 'yup';
import moment from 'moment';
import { generateSchemaFromFields } from '@helpers/form';

export const fields = {
  name: {
    path: 'name',
    rule: yup.string(),
  },
  language: {
    path: 'language',
    rule: yup.string(),
  },
  weighting: {
    path: 'weighting',
  },
  click_url: {
    path: 'click_url',
    rule: yup
      .string()
      .test('is-url-valid', 'Please provide a valid URL', isValidUrl),
  },
  impression_tracking_pixel: {
    path: 'pixels',
    rule: yup.array().of(
      yup.object().shape({
        value: yup
          .string()
          .test('is-url-valid', 'Please provide a valid URL', isValidUrl),
      })
    ),
  },
  start_date: {
    path: 'start_date',
    defaultValue: '',
    rule: yup
      .mixed()
      .test(
        'both-required',
        'Start date and time are required if end date and time are set',
        function (value) {
          const end_date = this.parent.end_date;
          return !end_date || !!value;
        }
      )
      .test(
        'start-before-end',
        'Start datetime must be before end datetime',
        function (value) {
          const { end_date } = this.parent;
          if (!value || !end_date) return true;
          return moment(value).isBefore(moment(end_date));
        }
      )
      .test({
        name: 'cannot-be-earlier-than-ad-group-start-date',
        test(value) {
          if (!value || !moment(value).isValid()) return true;
          const context = this.options.context as {
            adGroupStartDate: moment.Moment | null;
          };
          if (!context?.adGroupStartDate) return true;
          const startDateValid = moment(value).isSameOrAfter(
            moment(context.adGroupStartDate)
          );
          if (!startDateValid) {
            const formattedStartDate = moment(
              context.adGroupStartDate
            ).format('MMM D, YYYY');
            return this.createError({
              message: `The custom creative start date can not be set to earlier than the Ad Group start date (${formattedStartDate})`,
              path: this.path,
            });
          }
          return true;
        },
      })
      .test({
        name: 'cannot-be-later-than-ad-group-end-date',
        test(value) {
          if (!value || !moment(value).isValid()) return true;
          const context = this.options.context as {
            adGroupEndDate: moment.Moment | null;
          };
          if (!context?.adGroupEndDate) return true;

          const endDateValid = moment(value).isSameOrBefore(
            moment(context.adGroupEndDate)
          );
          if (!endDateValid) {
            const formattedEndDate = moment(context.adGroupEndDate).format(
              'MMM D, YYYY'
            );
            return this.createError({
              message: `The custom creative start date can not be set to later than the Ad Group end date (${formattedEndDate})`,
              path: this.path,
            });
          }
          return true;
        },
      }),
  },
  end_date: {
    path: 'end_date',
    defaultValue: '',
    rule: yup
      .mixed()
      .test(
        'both-required',
        'End date and time are required if start date and time are set',
        function (value) {
          const start_date = this.parent.start_date;
          return !start_date || !!value;
        }
      )
      .test(
        'end-after-start',
        'End datetime must be after start datetime',
        function (value) {
          const { start_date } = this.parent;
          if (!value || !start_date) return true;
          return moment(value).isAfter(moment(start_date));
        }
      )
      .test({
        name: 'cannot-be-earlier-than-ad-group-start-date',
        test(value) {
          if (!value || !moment(value).isValid()) return true;
          const context = this.options.context as {
            adGroupStartDate: moment.Moment | null;
          };
          if (!context?.adGroupStartDate) return true;
          const startDateValid = moment(value).isSameOrAfter(
            moment(context.adGroupStartDate)
          );
          if (!startDateValid) {
            const formattedStartDate = moment(
              context.adGroupStartDate
            ).format('MMM D, YYYY');
            return this.createError({
              message: `The custom creative end date can not be set to earlier than the Ad Group start date (${formattedStartDate})`,
              path: this.path,
            });
          }
          return true;
        },
      })
      .test({
        name: 'cannot-be-later-than-ad-group-end-date',
        test(value) {
          if (!value || !moment(value).isValid()) return true;
          const context = this.options.context as {
            adGroupEndDate: moment.Moment | null;
          };
          if (!context?.adGroupEndDate) return true;

          const endDateValid = moment(value).isSameOrBefore(
            moment(context.adGroupEndDate)
          );
          if (!endDateValid) {
            const formattedEndDate = moment(context.adGroupEndDate).format(
              'MMM D, YYYY'
            );
            return this.createError({
              message: `The custom creative end date can not be set to later than the Ad Group end date (${formattedEndDate})`,
              path: this.path,
            });
          }
          return true;
        },
      }),
  },
  showClickUrl: {
    path: 'showClickUrl',
  },
  showImpressionTrackingPixel: {
    path: 'showImpressionTrackingPixel',
  },
  showDateTime: {
    path: 'showDateTime',
  },
} as const;

export const validationSchema = generateSchemaFromFields(fields);
