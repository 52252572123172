export const formatNumber = (num: number | string | undefined | null) => {
  if (num === 0) return '0';
  if (!num) return '';
  if (Number(num) >= 1000000) {
    const formattedNumber = (Number(num) / 1000000).toFixed(1);
    return `${parseFloat(formattedNumber)}m`;
  }
  else if (Number(num) >= 1000) {
    const formattedNumber = (Number(num) / 1000).toFixed(1);
    return `${parseFloat(formattedNumber)}k`;
  }
  return parseFloat(Number(num).toFixed(1)).toString();
};

export const getLast30Days = () => {
  const dates = [];
  const today = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }))

  for (let i = 1; i <= 30; i++) {
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - i);
    const formattedDate = pastDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      timeZone: 'America/New_York',
    });
    dates.push(formattedDate);
  }

  return dates.reverse();
};

export const getDaysOfCurrentMonth = () => {
  const dates = [];
  const today = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }))
  const year = today.getFullYear();
  const month = today.getMonth();

  for (let day = 1; day < today.getDate(); day++) {
    const date = new Date(year, month, day);
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      timeZone: 'America/New_York',
    });
    dates.push(formattedDate);
  }

  return dates;
};

export const formatCurrency = (value: number | string | undefined | null) => {
  if (!value) return '';
  return `$${Number(value).toFixed(2)}`;
};

