import type { Audience } from '@local-types/audience';
import type { IncludableData } from '@v2/components/campaign/Advanced/types';

export const mapAudiences = ({
  segments = [],
  excluded_segments = [],
  allowedAudiences = [],
}: {
  segments: string[];
  excluded_segments: string[];
  allowedAudiences: Audience[];
}) =>
  [...segments, ...excluded_segments].reduce((acc, curr) => {
    const foundAudience = allowedAudiences.find(
      audience => audience.audience_name === curr
    );

    if (foundAudience) {
      return [
        ...acc,
        {
          ...foundAudience,
          included: !excluded_segments.find(
            v => v === foundAudience.audience_name
          ),
        },
      ];
    }

    return acc;
  }, [] as IncludableData<Audience>[]);
