import { useCurrentSession } from '@hooks/currentSession';
import { Key } from 'swr';
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation';
import moment from 'moment';
import {
  BulkValidateResponse,
  BulkUpdateResponse,
  BulkValidateRequestBody,
  BulkUpdateRequestBody,
} from './types';

/**
 * Hook for validating bulk updates before applying them
 */
export const useBulkValidate = (
  options?: SWRMutationConfiguration<
    BulkValidateResponse,
    Error,
    Key,
    BulkValidateRequestBody
  >
) => {
  const { post, apiIsReady, currentAdvertiser } = useCurrentSession();

  const validateBulk = async (
    url: string,
    { arg }: { arg: BulkValidateRequestBody }
  ) => {
    return post('/bulk/validate/update_fields/', arg).then((res) => res.data);
  };

  return useSWRMutation<
    BulkValidateResponse,
    Error,
    Key,
    BulkValidateRequestBody
  >(
    apiIsReady && currentAdvertiser.id ? 'bulk-validate-only' : null,
    validateBulk,
    {
      revalidate: false,
      populateCache: false,
      ...options,
    }
  );
};

/**
 * Hook for applying bulk updates to entities
 */
export const useBulkUpdate = (
  options?: SWRMutationConfiguration<
    BulkUpdateResponse,
    Error,
    Key,
    BulkUpdateRequestBody
  >
) => {
  const { post, apiIsReady, currentAdvertiser } = useCurrentSession();

  const updateBulk = async (
    url: string,
    { arg }: { arg: BulkUpdateRequestBody }
  ) => {
    return post('/bulk/update_fields/', arg).then((res) => res.data);
  };

  return useSWRMutation<BulkUpdateResponse, Error, Key, BulkUpdateRequestBody>(
    apiIsReady && currentAdvertiser.id ? 'bulk-update-fields' : null,
    updateBulk,
    {
      revalidate: false,
      populateCache: false,
      ...options,
    }
  );
};

/**
 * Helper function to format date and time for API requests
 * @param date Date object or null
 * @param time Time string, Moment or null
 * @returns Formatted ISO date string or null
 */
export const formatDateTimeForApi = (
  date: moment.Moment | null,
  time: string | moment.Moment | null
): string | null => {
  if (!date) return null;
  
  let timeMoment;
  if (!time) {
    timeMoment = moment('00:00:00', 'HH:mm:ss');
  } else if (typeof time === 'string') {
    timeMoment = moment(time, 'HH:mm:ss');
  } else {
    timeMoment = time;
  }
  
  const dateTime = moment(date)
    .set({
      hours: timeMoment.hours(),
      minutes: timeMoment.minutes(),
      seconds: timeMoment.seconds()
    });
    
  return dateTime.toISOString();
};
