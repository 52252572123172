import { useMemo } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import useSWRInfinite from 'swr/infinite';
import useSWRMutation from 'swr/mutation';
import { useCurrentSession } from '../currentSession';
import { useLoadInfinite } from './general';

const buildStaticCreativeCacheKey = (
  creativeId,
  currentAdvertiser,
  params = {}
) => {
  const url = `/staticdisplaycreatives/${creativeId}`;

  const cacheKey = {
    url,
    advertiser: currentAdvertiser.id ?? '-',
    ...params,
  };

  return cacheKey;
};

export const useDisplaysInfinite = ({ params = {}, options = {} }) => {
  const { getV1, apiIsReady, currentAdvertiser } = useCurrentSession();
  const fetcher = ({ url, params }) =>
    getV1(url, params).then(res => res.data);

  const { data, mutate, ...rest } = useSWRInfinite(
    (index, previousPageData) => {
      if (!apiIsReady || (previousPageData && !previousPageData.next))
        return null;

      return {
        url: '/static_display_creatives/',
        advertiser: currentAdvertiser.id ?? '-',
        params: {
          expand: 'lineitem_set,stats_caches_by_lineitem_id',
          page_size: 25,
          ...(index && { page: index + 1 }),
          ...params,
        },
      };
    },
    fetcher,
    {
      revalidateFirstPage: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      ...options,
    }
  );

  const items = useMemo(
    () => (data ? [].concat(...data.map(page => page?.results ?? [])) : []),
    [data]
  );
  const totalItems = data?.[0]?.count;

  return {
    displays: items,
    nextPage: data?.next,
    previousPage: data?.previous,
    count: totalItems,
    hasMore:
      totalItems === undefined ||
      (totalItems > 0 && items?.length < totalItems),
    ...rest,
  };
};

export const useDisplays = ({ page, params = {} }) => {
  const { getV1, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { disabled = false, ...otherParams } = params;

  const fetcher = ({ url, params }) =>
    getV1(url, params).then(res => res.data);

  if (page >= 1) {
    otherParams['page'] = page;
  }

  const swr = useSWR(
    apiIsReady && !disabled
      ? {
          url: '/static_display_creatives/',
          advertiser: currentAdvertiser?.id ?? '-',
          params: otherParams,
        }
      : null,
    fetcher
  );

  const { data, error, isLoading } = swr;

  return {
    displays: useMemo(
      () =>
        data?.results.map(result => ({ ...result, type: 'display' })) ?? [],
      [data]
    ),
    nextPage: data?.next,
    previousPage: data?.previous,
    count: data?.count,
    error,
    isLoading,
  };
};

export const useAllDisplays = (options = {}) => {
  const { mutate, items } = useLoadInfinite('static_display_creatives/', {
    params: {
      v1: true,
      ...options,
    },
  });

  return {
    items: items.map(item => ({ ...item, type: 'display' })),
    invalidate: mutate,
  };
};

export const usePatchStaticDisplayCreative = () => {
  const { patchV1, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { mutate } = useSWRConfig();

  const updateDisplayCreative = (url, { arg: { id, ...data } }) => {
    return patchV1(`${url}${id}/`, data).then(res => res.data);
  };

  const options = {
    onSuccess: updatedDisplayCreative => {
      const key = buildStaticCreativeCacheKey(
        updateDisplayCreative.id,
        currentAdvertiser
      );
      mutate(key, updatedDisplayCreative, { populateCache: true });
    },
    revalidate: false,
  };

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? '/static_display_creatives/' : null,
    updateDisplayCreative,
    options
  );

  return { trigger, isMutating };
};

export const useGetStaticCreativeLineItemTrigger = () => {
  const { get, apiIsReady } = useCurrentSession();

  const { trigger } = useSWRMutation(
    apiIsReady ? `/staticcreativelineitems/` : null,
    (url, { arg: id }) => {
      return get(`${url}?static_display_line_item_id=${id}`).then(
        res => res.data.results ?? []
      );
    }
  );

  return { trigger };
};

export const useGetStaticCreativeLineItems = adGroupId => {
  const { get, apiIsReady } = useCurrentSession();

  const {
    data = [],
    isLoading,
    isValidating,
  } = useSWR(
    apiIsReady
      ? `/staticcreativelineitems/?static_display_line_item_id=${adGroupId}`
      : null,
    url => get(url).then(res => res.data.results ?? [])
  );

  return { data, isLoading: isLoading || isValidating };
};

export const usePatchStaticCreativeLineItem = () => {
  const { patch } = useCurrentSession();

  const updateStaticCreative = (url, { arg: { id, ...data } }) =>
    patch(`${url}${id}/`, data).then(res => res.data);

  const options = {};

  const { trigger, isMutating } = useSWRMutation(
    '/staticcreativelineitems/',
    updateStaticCreative,
    options
  );

  return { trigger, isMutating };
};

export const useStaticDisplayAdGroupCreatives = (adGroup, options = {}) => {
  const { getV1, apiIsReady } = useCurrentSession();
  const fetcher = urls => {
    return Promise.all(urls.map(url => getV1(url).then(res => res.data)));
  };
  const urls =
    adGroup.creatives?.map(creativeId => ({
      url: `/static_display_creatives/${creativeId}/`,
      adGroupId: adGroup.id,
    })) ?? [];

  const swr = useSWR(
    !options.disabled && apiIsReady && urls.length > 0
      ? urls.map(item => item.url)
      : null,
    fetcher
  );
  const { data, error, isLoading } = swr;

  return {
    adGroupsCreatives: data,
    items: data,
    error,
    isLoading,
  };
};
