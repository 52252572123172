import { StyledFormControlLabel } from '@components/styledComponents/StyledFormControlLabel';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Hint } from '@v2/components/ui/Hint';

type BaseOption = {
  value: string;
  tooltip: string;
  disabled?: boolean;
};

type OptionsListProps<T extends BaseOption> = {
  options: T[];
} & Omit<RadioGroupProps, 'children'>;

export const OptionsList = <T extends BaseOption>({
  options,
  ...rest
}: OptionsListProps<T>) => {
  const theme = useTheme();

  return (
    <FormControl sx={{ alignItems: 'center' }}>
      <RadioGroup
        {...rest}
        sx={{
          borderRadius: `${theme.shape.borderRadius}px`,
          boxShadow: '-2px 4px 8px -4px rgba(0, 0, 0, 0.4)',
          ...rest.sx,
        }}
      >
        {options.map(option => (
          <StyledFormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                justifyContent="space-between"
                width={theme.spacing(60)}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="h5">{option.value}</Typography>
                  <Hint color={theme.palette.grey[8]}>{option.tooltip}</Hint>
                </Stack>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  data-testid={'chevron-icon'}
                />
              </Stack>
            }
            disabled={option.disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
