import React, { ReactNode, useContext, useMemo } from 'react';

import { EmbedLookerChart } from '../../../EmbedLookerChart';
import AdvertiserContext, { COST_MODEL, LOOKER_EXPERIENCE } from '../../../AdvertiserContext';
import { useRoles, useUser } from "@hooks/index";
import { useDomain } from '@hooks/domain';
import useReportConfig from '@components/hooks/apis/reportConfig';
import ReportsLookerPage from '../components/ReportsLookerPage';
import { DASHBOARD_NAMES, LOOKER_CHARTS } from '../constants';

interface ReportsPerformance {
  inExplore: boolean;
  setInExplore: React.Dispatch<React.SetStateAction<boolean>>;
  active?: boolean;
  internal?: boolean;
}

const ReportsPerformance = ({ inExplore, setInExplore, active, internal }: ReportsPerformance) => {
  const roles = useRoles();
  const { user } = useUser();
  const adContext = useContext(AdvertiserContext);
  const domain = useDomain();

  const { data } = useReportConfig.get();

  const campaignPerformanceDashboard = useMemo(() => {
    if (domain.peacock) {
      if (adContext?.cost_model === COST_MODEL.CPM) {
        return DASHBOARD_NAMES.NBCU_CAMPAIGN_PERFORMANCE;
      } else if (adContext?.cost_model === COST_MODEL.CPA) {
        return roles.TENANT_ADMIN ? DASHBOARD_NAMES.CPA_INTERNAL_NBCU : DASHBOARD_NAMES.CPA_EXTERNAL_NBCU;
      }
      if (adContext?.cost_model === COST_MODEL.CPA) {
        return DASHBOARD_NAMES.CPA_EXTERNAL_NBCU;
      }
    }

    if (domain.default) {
      if (adContext?.cost_model === COST_MODEL.CPM) {
        return DASHBOARD_NAMES.CAMPAIGN_PERFORMANCE;
      }
      if (adContext?.cost_model === COST_MODEL.CPA) {
        return DASHBOARD_NAMES.CPA_EXTERNAL;
      }
    }

    return '';
  }, [
    domain.peacock,
    domain.default,
    adContext?.cost_model,
    roles.TENANT_ADMIN,
    user?.is_tvsci_employee
  ]);

  const tabs = useMemo(() => {
    const tabsList: { id: string, title: string, content: () => ReactNode }[] = [];
    if (!adContext.name) return tabsList;

    if (adContext?.cost_model === COST_MODEL.CPM) {
      tabsList.push({
        id: LOOKER_CHARTS.CAMPAIGN_DELIVERY.id,
        title: LOOKER_CHARTS.CAMPAIGN_DELIVERY.title,
        content: () => (
          <EmbedLookerChart
            id={LOOKER_CHARTS.CAMPAIGN_DELIVERY.id}
            dashboard={
              domain.peacock ?
                DASHBOARD_NAMES.NBCU_CAMPAIGN_DELIVERY :
                DASHBOARD_NAMES.CAMPAIGN_DELIVERY}
            setInExplore={setInExplore}
            internal={internal}
          />
        )
      });
    }

    if (data?.measurementLevel !== 'Level_1' || adContext?.cost_model === COST_MODEL.CPA) {
      tabsList.push({
        id: LOOKER_CHARTS.CAMPAIGN_PERFORMANCE.id,
        title: LOOKER_CHARTS.CAMPAIGN_PERFORMANCE.title,
        content: () => (
          <EmbedLookerChart
            id={LOOKER_CHARTS.CAMPAIGN_PERFORMANCE.id}
            dashboard={campaignPerformanceDashboard}
            setInExplore={setInExplore}
            internal={internal}
          />
        )
      });
    }

    if (
      domain.default &&
      adContext?.cost_model === COST_MODEL.CPM &&
      data?.measurementLevel !== 'Level_1'
    ) {
      tabsList.push({
        id: LOOKER_CHARTS.INVENTORY_GEO.id,
        title: LOOKER_CHARTS.INVENTORY_GEO.title,
        content: () => (
          <EmbedLookerChart
            id={LOOKER_CHARTS.INVENTORY_GEO.id}
            dashboard={
              adContext.looker_experience === LOOKER_EXPERIENCE.GAMING ?
                DASHBOARD_NAMES.INVENTORY_GEO_GAMING :
                DASHBOARD_NAMES.INVENTORY_GEO
            }
            setInExplore={setInExplore}
            internal={internal}
          />
        )
      });
    }

    return tabsList;
  }, [
    adContext?.cost_model,
    adContext.name,
    campaignPerformanceDashboard,
    domain.peacock,
    domain.default,
    setInExplore,
    adContext.looker_experience,
    data?.measurementLevel,
  ]);

  return (
    <ReportsLookerPage
      tabs={tabs}
      setInExplore={setInExplore}
      inExplore={inExplore}
      active={active}
    />
  );
};

export default ReportsPerformance;
