import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import {
  Box,
  Typography,
  Toolbar,
  Stack,
  Tab,
  Tabs,
  Alert,
  IconButton,
  Link,
  Button,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Themes } from '@constants/themes';
import { padmanTheme_v2 } from '@themes/padman_v2';
import { defaultTheme_v2 } from '@themes/default_v2';
import { useAppDeployment } from '@hooks/appDeployment';
import { useOrg } from '@hooks/org';
import { Scopes } from '@constants/scopes';
import { CreativeQuantityControl } from '@components/modals';
import { getVideoDuration } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedCreative';
import { usePermissions } from '@components/hooks';
import AdvertiserContext from '@components/AdvertiserContext';
import { useFlags } from '@components/hooks/flags';
import ModalContext from '@providers/ModalContext';
import {
  useCreativeVideoUpload,
  useCreativeVideoUploadV1,
  usePostCreative,
} from '@apis/creatives';
import { FileDropzone, List } from '../components';
import { FormFields, View } from '../constants';

const TabValue = {
  UPLOAD_VIDEO: 0,
};

const V2Theme = {
  [Themes.DEFAULT]: defaultTheme_v2,
  [Themes.NBCU]: padmanTheme_v2,
};

const AddCreativeView = ({ onActionChange }) => {
  const { org } = useOrg();
  const adContext = useContext(AdvertiserContext);
  const { setModal } = useContext(ModalContext);
  const { theme } = useAppDeployment({
    currentAdvertiserId: adContext?.id,
    orgTenant: org?.primary_tenant,
  });
  const { hasPermission } = usePermissions();
  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);
  const { trigger: uploadFileV1 } = useCreativeVideoUploadV1();
  const { trigger: uploadFileV2 } = useCreativeVideoUpload();
  const { trigger: createCreative } = usePostCreative();
  const { flags } = useFlags();

  const useUploadV2 = flags.LARGE_ASSET_UPLOAD_FEATURE;

  const uploadFile = useMemo(
    () => (useUploadV2 ? uploadFileV2 : uploadFileV1),
    [useUploadV2]
  );

  const [tab, setTab] = useState(0);
  const [files, setFiles] = useState([]);
  const [showVideoCheckAlert, setShowVideoCheckAlert] = useState(false);

  const themeObject = useMemo(() => V2Theme[theme], [theme]);

  const handleCloseVideoCheckAlert = () => {
    setShowVideoCheckAlert(false);
  };

  const handleTab = (event, newTab) => {
    setTab(newTab);
  };

  const saveFile = useCallback(async file => {
    const fileObject = {
      id: uniqueId(),
      progress: 0,
      name: file.name,
      size: file.size,
      duration: await getVideoDuration(file),
    };

    setFiles(prevState => [
      ...prevState,
      fileObject
    ]);

    try {
      const { result, cancel } = uploadFile({
        file,
        advertiser: useUploadV2 ? adContext.id : adContext.url,
        listener: ({ loaded, total }) => {
          setFiles(prevState => prevState.map((item) => {
            if (item.id === fileObject.id) {
              return {
                ...item,
                progress: Math.round((loaded * 100) / total),
              };
            }

            return item;
          }));
        },
      });

      setFiles(prevState => prevState.map((item) => {
        if (item.id === fileObject.id) {
          return {
            ...item,
            cancel,
          };
        }

        return item;
      }));

      const uploadedFile = await result;

      setFiles(prevState => prevState.map((item) => {
        if (item.id === fileObject.id) {
          return {
            ...item,
            ...uploadedFile,
            progress: 100,
          };
        }

        return item;
      }));
    } catch (error) {
      setFiles(prevState => prevState.filter(item => item.id !== fileObject.id));

      throw error;
    }
  }, [adContext.url]);

  const saveCreative = useCallback(data => createCreative({
    name: data.name,
    video_asset: data.id,
    advertiser: adContext.id,
    active: true,
  }), [adContext.id]);

  const handleCreativeQualityControlShow = useCallback(() => {
    setModal({
      isOpen: true,
      component: CreativeQuantityControl,
    });
  }, []);

  const handleDrop = useCallback(async files => {
    try {
      await Promise.all(files.map(saveFile));
    } catch (error) {
      console.error(error);
    }
  }, [saveFile]);

  const handleCancel = useCallback(() => {
    files.forEach(({ progress, cancel }) => {
      if (progress < 100) {
        cancel();
      }
    });

    onActionChange(View.VIEW_CREATIVES);
  }, [files, onActionChange]);

  const handleSubmit = useCallback(async ({ [FormFields.items.NAME]: creatives }) => {
    try {
      await Promise.all(creatives.map(saveCreative));

      onActionChange(View.VIEW_CREATIVES);
    } catch (error) {
      console.error(error);
    }
  }, [saveCreative]);

  useEffect(() => {
    setShowVideoCheckAlert(showQCFunctionality);
  }, [showQCFunctionality]);

  return (
    /* TODO: Remove ThemeProvider when entire app switches to blue app  */
    <ThemeProvider theme={themeObject}>
      <Box
        border={1}
        borderColor="grey.300"
        borderRadius="20px"
        m={4}
      >
        {showVideoCheckAlert && (
          <Alert
            icon={<InfoOutlinedIcon fontSize="inherit" />}
            severity="info"
            sx={{ borderRadius: '20px 20px 0 0' }}
            action={
              <IconButton
                aria-label="close"
                size="inherit"
                onClick={handleCloseVideoCheckAlert}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <Typography variant="body2">
                We will check your video specs to ensure it meets minimum quality requirements. Issues will be automatically fixed in most cases.
              </Typography>

              <Link
                size="small"
                color="inherit"
                component={Button}
                onClick={handleCreativeQualityControlShow}
                underline="hover"
                sx={{ p: 0 }}
              >
                Learn more
              </Link>
            </Stack>
          </Alert>
        )}

        <Box p={6} pt={4}>
          <Toolbar disableGutters>
            <Typography variant="h6" component="div">Add Creative</Typography>
          </Toolbar>

          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            mb={4}
            borderBottom="1px solid #e0e0e0"
          >
            <Tabs onChange={handleTab} value={tab}>
              <Tab value={TabValue.UPLOAD_VIDEO} label="Upload Video" />
            </Tabs>
          </Stack>

          {tab === TabValue.UPLOAD_VIDEO && !files.length && (
            <FileDropzone onDrop={handleDrop} />
          )}

          {tab === TabValue.UPLOAD_VIDEO && files.length > 0 && (
            <List
              data={files}
              onLearnMoreClick={handleCreativeQualityControlShow}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
            />
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

AddCreativeView.propTypes = {
  onActionChange: PropTypes.func.isRequired,
};

export default AddCreativeView;
