import { useGetAllowedTargeting } from '@apis/allowedTargeting';
import { useGetAllAudiences } from '@apis/audiences';
import { useGetBundles } from '@apis/bundles';
import { useGetCampaign } from '@apis/campaigns';
import { useAdGroupCreatives } from '@apis/creatives';
import { useStaticDisplayAdGroupCreatives } from '@apis/displays';
import { useTargetingKeys } from '@components/hooks/apis/targetingKeys';
import { weightingRotationOptions } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedCreative';
import { fields as manageFields } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedCreative/Manage';
import {
  customInventoryTypes,
  customInventoryTabValues,
} from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedCustomInventoryTable/constants/general';
import { zipCodesEmptyValue } from '@v2/components/campaign/CampaignAdGroupSection/constants';
import { useEffect, useMemo } from 'react';
import { useWizardSection } from '../../../Wizard/hooks/useWizardSection';
import { SCROLL_DELAY } from '../../constants';
import { useScrollTo } from '../../hooks/useScrollTo';
import { dateFields, fields } from '../formConfig';
import { useGroupSectionForm } from './useGroupSectionForm';
import { useGroupSectionSubmit } from './useGroupSectionSubmit';
import { useGroupSectionsInfo } from './useGroupSectionsInfo';
import { useInventoryAvailability } from './useInventoryAvailabilty';
import { map, uniq } from 'lodash';
import { Bundle } from '@local-types/bundle';
import { Creative } from '@local-types/creative';
import { Audience } from '@local-types/audience';
import { AdvancedInventoryData } from '@local-types/inventory-targeting';
import { AdGroup } from '@local-types/group';
import { GeoItem } from '@v2/components/campaign/GeoTargeter';
import type { AdvancedDeliverySubmitFormData } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedAdGroupDelivery/types';

export const useGroupSection = ({
  campaignId,
  adGroup,
  sectionKey,
  isDisplay,
}: {
  campaignId: number;
  adGroup: AdGroup;
  sectionKey: string;
  isDisplay: boolean;
}) => {
  const { ref } = useScrollTo(SCROLL_DELAY);
  const { updateEntityState, activePane } = useWizardSection({
    key: sectionKey,
  });

  const { items: audiences } = useGetAllAudiences();
  const { campaign } = useGetCampaign(campaignId);

  const { data: appBundleList } = useTargetingKeys({
    type: customInventoryTypes[customInventoryTabValues.appBundleList],
  });

  const { data: domainList = [] } = useTargetingKeys({
    type: customInventoryTypes[customInventoryTabValues.appDomainList],
  });

  const { data: targeting } = useGetAllowedTargeting();

  const { items: bundles } = useGetBundles({ campaignId });

  const { items: lib } = useAdGroupCreatives(adGroup, {
    disabled: isDisplay,
  });

  const { items: displayLib } = useStaticDisplayAdGroupCreatives(adGroup, {
    disabled: !isDisplay,
  });

  const creatives = useMemo(
    () => (isDisplay ? displayLib : lib) ?? [],
    [isDisplay, displayLib, lib]
  );

  const { form } = useGroupSectionForm({
    adGroup,
    bundles,
    creatives,
    appBundleList,
    domainList,
    audiences,
    targeting,
    campaign,
  });

  const {
    formState: { isDirty },
    trigger,
  } = form;

  const { availability: inventoryAvailability } = useInventoryAvailability({
    form,
    adGroup,
  });

  const { info } = useGroupSectionsInfo({ form });

  const { submit, mutating } = useGroupSectionSubmit({
    campaign,
    adGroup,
    form,
    sectionKey,
    isDisplay,
  });

  const handleSubmitCreatives = ({
    creatives,
    weightingRotation,
  }: {
    creatives: Creative[];
    weightingRotation: boolean;
  }) => {
    form.setValue(
      fields.creatives.path,
      creatives.map(creative => ({
        ...creative,
        [manageFields.click_url.path]:
          creative[manageFields.click_url.path]?.trim() ?? null,
      })),
      { shouldDirty: true }
    );

    form.setValue(
      fields.weightingRotation.path,
      weightingRotation
        ? weightingRotationOptions.weighted
        : weightingRotationOptions.random,
      { shouldDirty: true }
    );
  };
  const handleSubmitCustomInventory = ({ items }: { items: Bundle[] }) => {
    form.setValue(fields.inventoryOption.path, 'bundles', {
      shouldDirty: true,
    });
    form.setValue(fields.advancedCustomInventory.path, items, {
      shouldDirty: true,
    });
  };

  const handleSubmitZipCodes = ({
    prevData,
    data,
  }: {
    prevData: {
      include: boolean;
      data: GeoItem[];
    };
    data: GeoItem[];
  }) => {
    const geo = form.getValues(fields.geography.path);

    const providedIds = uniq([
      ...map(data, 'id'),
      ...map(prevData.data, 'id'),
    ]).filter(v => v);

    const newGeo = geo.filter(({ id }) => !providedIds.includes(id));
    form.setValue(fields.geography.path, [...newGeo, ...data], {
      shouldDirty: true,
    });
  };

  const handleSubmitAudiences = ({
    items,
    audienceExtension,
  }: {
    items: Audience[];
    audienceExtension: boolean;
  }) => {
    form.setValue(fields.advancedAudience.path, items, { shouldDirty: true });
    form.setValue(fields.audienceExtension.path, audienceExtension, {
      shouldDirty: true,
    });
  };

  const handleSubmitAdvancedInventory = (items: AdvancedInventoryData) => {
    form.setValue(fields.advancedInventory.path, items, {
      shouldDirty: true,
    });
  };

  const handleSubmitAdvancedDelivery = (
    data: AdvancedDeliverySubmitFormData
  ) => {
    form.setValue(
      fields.configureAdGroupFrequencyCap.path,
      data[fields.configureAdGroupFrequencyCap.path],
      { shouldDirty: true }
    );
    form.setValue(
      fields.configureAdGroupDayparting.path,
      data[fields.configureAdGroupDayparting.path],
      { shouldDirty: true }
    );
    form.setValue(
      fields.adGroupPacingEnabled.path,
      data[fields.adGroupPacingEnabled.path],
      { shouldDirty: true }
    );
    form.setValue(
      fields.adGroupPacing.path,
      data[fields.adGroupPacing.path],
      { shouldDirty: true }
    );
    form.setValue(
      fields.adGroupPacingBehavior.path,
      data[fields.adGroupPacingBehavior.path],
      { shouldDirty: true }
    );
    form.setValue(
      fields.adGroupPacingCatchupBehavior.path,
      data[fields.adGroupPacingCatchupBehavior.path],
      { shouldDirty: true }
    );

    trigger(dateFields);
  };

  const handleResetZipCodes = () => {
    form.setValue(fields.zipcodes.path, zipCodesEmptyValue);
  };

  useEffect(() => {
    return () => {
      creatives.forEach(({ preview_url }) => {
        URL.revokeObjectURL(preview_url);
      });
    };
  }, [creatives]);

  useEffect(() => {
    updateEntityState({
      dirty: isDirty,
    });
  }, [isDirty, updateEntityState]);

  return {
    bundles,
    ref,
    form,
    activePane,
    campaign,
    sectionsInfo: info,
    mutating,
    submit,
    submitCreatives: handleSubmitCreatives,
    submitZipCodes: handleSubmitZipCodes,
    submitCustomInventory: handleSubmitCustomInventory,
    submitAdvancedInventory: handleSubmitAdvancedInventory,
    submitAudiences: handleSubmitAudiences,
    submitAdvancedDelivery: handleSubmitAdvancedDelivery,
    inventoryAvailability,
    resetZipCodes: handleResetZipCodes,
    isActivePane: (paneKey: string) => activePane === paneKey,
  };
};
