import React, { useMemo, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import ListFilterForm from "@components/forms/ListFilterForm";
import { IFormValues, IItem } from '@components/forms/ListFilterForm/types';
import { useGetCampaigns } from '@apis/campaigns';

interface ICampaignIdFilterProps {
  onSubmit: (params: Record<string, string>) => void;
  data: Record<string, string>;
}

const CampaignIdFilter: React.FC<ICampaignIdFilterProps> = ({
  onSubmit,
  data
}) => {
  const [searchValue, setSearchValue] = useState('');

  const queryParams = useMemo(() => {
    const params: Record<string, string> = {};

    if (searchValue) {
      params.id = searchValue;
    }

    if (data.adGroupIds) {
      params.lineitem_ids = data.adGroupIds;
    }

    if (data.status) {
      params.status = data.status;
    }

    return params;
  }, [searchValue, data.adGroupIds, data.status]);

  const { items: campaigns, isLoading, isValidating, setSize, hasMore } = useGetCampaigns(queryParams, {
    revalidateOnMount: true
  });

  const handleLoadMore = useCallback(() => {
    setSize(prev => prev + 1);
  }, [setSize]);

  const formData = useMemo(() => {
    const selectedIds = data.campaignIds ? data.campaignIds?.split(',').map(Number) : [];

    return {
      items: campaigns.map((campaign: IItem) => ({
        id: campaign.id,
        name: campaign.id.toString(),
        checked: selectedIds.includes(campaign.id),
      })),
      selectedIds,
      isLoading,
      isValidating,
      hasMore,
    };
  }, [campaigns, data.campaignIds, isLoading, isValidating, hasMore]);

  const handleSearch = useCallback(
    debounce((value: string | undefined) => {
      setSearchValue(value || '');
    }, 300),
    []
  );

  const handleSubmit = (formData: IFormValues) => {
    onSubmit({
      campaignIds: formData.selectedIds.join(',')
    });
  };

  return (
    <ListFilterForm
      fieldName="Campaign ID"
      data={formData}
      onSearch={handleSearch}
      onSubmit={handleSubmit}
      onLoadMore={handleLoadMore}
    />
  );
};

export default CampaignIdFilter;
