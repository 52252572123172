import React, { useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@v2/components/ui/DataGrid/DataGrid';
import { defaultTheme_v2 } from '@themes/default_v2';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';
import {
  DisplaysTableContext,
  DisplaysTableContextProvider,
} from './DisplaysTableContext';
import { DateCellRenderer } from '@v2/components/ui/DataGrid/renderers/DateCellRenderer';
import { FileSizeCellRenderer } from '@v2/components/ui/DataGrid/renderers/FileSizeCellRenderer';
import { DisplayAdGroupCellRenderer } from './renderers/DisplayAdGroupCellRenderer';
import { DisplayNameCellRenderer } from './renderers/DisplayNameCellRenderer';
import { DisplayStatusCellRenderer } from './renderers/DisplayStatusCellRenderer';

export const DisplaysTableV2 = () => {
  const columns = useMemo(
    () =>
      [
        {
          field: 'id',
          headerName: 'ID',
          width: 60,
          resizable: false,
          hideable: false,
        },
        {
          field: 'name',
          headerName: 'Name',
          renderCell: DisplayNameCellRenderer,
          minWidth: 300,
          hideable: false,
          sortable: true,
        },
        {
          field: 'status',
          headerName: 'Status',
          renderCell: DisplayStatusCellRenderer,
          minWidth: 180,
          hideable: false,
          sortable: false,
        },
        {
          field: 'adGroup',
          headerName: 'Ad Group',
          flex: 1,
          renderCell: DisplayAdGroupCellRenderer,
          resizable: false,
          hideable: false,
          sortable: false,
        },
        {
          field: 'resolution',
          headerName: 'Resolution',
          minWidth: 90,
        },
        {
          field: 'file_size_bytes',
          headerName: 'File Size',
          minWidth: 90,
          align: 'right',
          renderCell: FileSizeCellRenderer,
        },
        {
          field: 'media_upload_date',
          headerName: 'Uploaded',
          renderCell: DateCellRenderer,
          minWidth: 150,
          resizable: false,
          hideable: false,
          sortable: false,
        },
      ].filter(Boolean) as GridColDef[],
    []
  );

  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <DisplaysTableContextProvider>
        <DisplaysTableContext.Consumer>
          {({
            count,
            displays,
            isLoading,
            setSize,
            hasMore,
          }) => {
            return (
              <>
                <Stack sx={{ height: '70vh' }}>
                  <DataGrid
                    onRowsScrollEnd={() => {
                      if (hasMore && !isLoading) {
                        setSize(size => size + 1);
                      }
                    }}
                    initialState={{
                      pinnedColumns: {
                        left: ['id', 'name', 'status'],
                      },
                    }}
                    scrollEndThreshold={200}
                    loading={isLoading}
                    rows={displays}
                    rowCount={count}
                    columns={columns}
                    disableRowSelectionOnClick
                  />
                </Stack>
              </>
            );
          }}
        </DisplaysTableContext.Consumer>
      </DisplaysTableContextProvider>
    </ThemeProvider>
  );
};
