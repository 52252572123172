import React from 'react';
import { HighlightedContentProps } from './types';
import { Typography } from '@mui/material';
import { HighlightStyled } from './styles';
import { isArray, map } from 'lodash';

export const HighlightedContent = ({
  data = '',
  ...props
}: HighlightedContentProps) => (
  <Typography
    title={!isArray(data) ? data : map(data, 'value').join('')}
    {...props}
  >
    {!isArray(data)
      ? data
      : data.map((v, i) => (
          <HighlightStyled
            data-testid={v.stressed ? `stressed-${i}` : `non-stressed-${i}`}
            stressed={v.stressed}
            key={i}
          >
            {v.value}
          </HighlightStyled>
        ))}
  </Typography>
);
