import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Typography, useTheme } from '@mui/material';

export const UpdateSuccess = ({
  adGroupCount = 1,
}: {
  adGroupCount: number;
}) => {
  const theme = useTheme();

  return (
    <Stack sx={{ alignItems: 'center' }} spacing={theme.spacing(4)}>
      <FontAwesomeIcon
        icon={faCircleCheck}
        fontSize="80px"
        color={theme.palette.green[6]}
      />
      <Typography variant="h4">
        {adGroupCount} Ad Group{adGroupCount > 1 && 's'} updated!
      </Typography>
    </Stack>
  );
};
