import React from 'react';
import { Stack, Typography, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { type Moment } from 'moment';
import { convertGapsToReadableString } from './utils';

export const GapInCreativeWarningBar = ({
  onClose,
  gap,
}: {
  onClose: () => void;
  gap: [Moment, Moment | null][];
}) => {
  const { message, warning } = convertGapsToReadableString(gap);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: '100%' }}
    >
      <Stack direction="row" alignItems={'center'}>
        <Typography
          variant="h5"
          sx={{ lineHeight: 1.5, verticalAlign: 'baseline' }}
        >
          {warning}
        </Typography>
        <Typography
          variant="body2"
          sx={{ lineHeight: 1.5, verticalAlign: 'baseline' }}
        >
          &nbsp;–&nbsp;{message}
        </Typography>
      </Stack>
      <IconButton size="small" sx={{ padding: 0 }} onClick={onClose}>
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
    </Stack>
  );
};
