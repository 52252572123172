import React from 'react';
import InSync from './InSync';
import OutOfSync from './OutOfSync';

/**
 * Component to display the synchronization status between the application and Beeswax.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.isAdGroup - Indicates if the item is an ad group
 * @param {boolean} props.bwStatus - Current synchronization status with Beeswax
 * @param {Object|null} props.diff - Object containing differences between local and Beeswax data
 * @param {boolean} props.isDisabled - Whether the sync functionality is disabled
 * @param {boolean} [props.isBWOverride] - Optional flag indicating if Beeswax settings override local settings
 * @param {Function} [props.onClickUpdateAdServer] - Optional callback function when update to ad server is requested
 * @returns {JSX.Element} Rendered component showing sync status
 */

const BWSyncStatus = ({
  isAdGroup,
  bwStatus,
  diff,
  isDisabled,
  isBWOverride,
  onClickUpdateAdServer,
}: {
  isAdGroup: boolean;
  bwStatus: boolean;
  diff: Record<string, string | boolean> | null;
  isDisabled: boolean;
  isBWOverride?: boolean;
  onClickUpdateAdServer?: () => void;
}) => {
  if (bwStatus && !isDisabled) {
    return <InSync />;
  }

  return isAdGroup ? (
    <OutOfSync
      diff={diff}
      isDisabled={isDisabled}
      isBWOverride={isBWOverride ?? false}
      onClickUpdateAdServer={onClickUpdateAdServer}
    />
  ) : (
    <OutOfSync diff={diff} isDisabled={isDisabled} />
  );
};
export default BWSyncStatus;
