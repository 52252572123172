export const PAGE_TITLES: { [key: string]: string } = {
  'home': 'Reporting Home',
  'performance': 'Campaign Performance',
  'conversions': 'Post Conversions',
  'path-to-purchase': 'Path To Purchase',
  'spike-lift': 'Spike Lift',
  'beta': 'Beta',
  'cpa-internal': 'CPA Performance',
  'internal-performance': 'Performance Internal',
  'internal-path-to-purchase': 'Path To Purchase Internal',
  'internal-spike-lift': 'Spike Lift Internal',
  'internal-conversions': 'Post Conversions Internal',
  'incrementality': 'Always-on Incrementality',
  'internal-incrementality': 'Always-on Incrementality Internal',
};

export const DASHBOARD_NAMES: { [key: string]: string } = {
  CAMPAIGN_PERFORMANCE: 'CAMPAIGN_PERFORMANCE',
  POST_CONVERSIONS: 'SEQUENTIAL_EVENTS',
  PATH_TO_PURCHASE: 'PATH_TO_PURCHASE',
  SPIKE_LIFT: 'SPIKE_LIFT',
  CAMPAIGN_DELIVERY: 'CAMPAIGN_DELIVERY',
  INVENTORY_GEO: 'INVENTORY_GEO',
  INVENTORY_GEO_GAMING: 'INVENTORY_GEO_GAMING',
  NBCU_CAMPAIGN_DELIVERY: 'NBCU_CAMPAIGN_DELIVERY',
  NBCU_CAMPAIGN_PERFORMANCE: 'NBCU_CAMPAIGN_PERFORMANCE',
  CPA_INTERNAL: 'CPA_INTERNAL',
  CPA_EXTERNAL: 'CPA_EXTERNAL',
  CPA_INTERNAL_NBCU: 'CPA_INTERNAL_NBCU',
  CPA_EXTERNAL_NBCU: 'CPA_EXTERNAL_NBCU',
  NBCU_INCREMENTALITY: 'NBCU_INCREMENTALITY',
  INCREMENTALITY: 'INCREMENTALITY',
  SEQUENTIAL_EVENTS: 'SEQUENTIAL_EVENTS',
  BETA: 'INTERNAL_EXPERIMENTAL',
  INCREMENTALITY_ADGROUP: 'INCREMENTALITY_ADGROUP',
  INCREMENTALITY_CAMPAIGN: 'INCREMENTALITY_CAMPAIGN',
  INCREMENTALITY_SUM: 'INCREMENTALITY_SUM',
};

export const LOOKER_CHARTS: {
  [key: string]: {
    id: string,
    title: string,
  }
} = {
  CAMPAIGN_PERFORMANCE: {
    id: 'performance',
    title: 'Performance',
  },
  POST_CONVERSIONS: {
    id: 'conversions',
    title: 'Post Conversions',
  },
  PATH_TO_PURCHASE: {
    id: 'path-to-purchase',
    title: 'Path To Purchase',
  },
  SPIKE_LIFT: {
    id: 'spike-lift',
    title: 'Spike Lift',
  },
  NBCU_CAMPAIGN_DELIVERY: {
    id: 'delivery',
    title: 'NBCU Campaign Delivery',
  },
  CAMPAIGN_DELIVERY: {
    id: 'delivery',
    title: 'Delivery',
  },
  INVENTORY_GEO: {
    id: 'inventory',
    title: 'Inventory & Geo',
  },
  INCREMENTALITY: {
    id: 'incrementality',
    title: 'Incrementality',
  },
  SEQUENTIAL_EVENTS: {
    id: 'sequential-events',
    title: 'Sequential Events',
  },
  BETA: {
    id: 'beta',
    title: 'Beta',
  },
  CPA_INTERNAL: {
    id: 'cpa-internal',
    title: 'CPA Report',
  },
  INCREMENTALITY_ADGROUP: {
    id: 'incrementality-adgroup',
    title: 'Ad Group Details',
  },
  INCREMENTALITY_CAMPAIGN: {
    id: 'incrementality-campaign',
    title: 'Campaign Details',
  },
  INCREMENTALITY_SUM: {
    id: 'incrementality-sum',
    title: 'Account Snapshot',
  }
}
