import { useState } from 'react';
import { GroupedData } from '../types';

export const useTable = <Value extends object, Selected = Value>({
  minRowsAmount = 0,
  data,
  selected,
  selectedFilter,
}: {
  minRowsAmount?: number;
  data: Value[];
  selected: Selected[];
  selectedFilter?: (selected: Selected[]) => Selected[];
}) => {
  const [isAddAllHovered, setIsAddAllHovered] = useState(false);
  const [isRemoveAllHovered, setIsRemoveAllHovered] = useState(false);

  const dataLength = !data.length
    ? 0
    : 'groupName' in data[0]
    ? (data as GroupedData<Value>[]).reduce(
        (acc, curr) => acc + curr.data.length,
        0
      )
    : data.length;

  const filteredSelected = selectedFilter
    ? selectedFilter(selected)
    : selected;

  const actualAmount = Math.max(
    dataLength,
    filteredSelected.length,
    minRowsAmount
  );

  return {
    filteredSelected,
    actualAmount,
    isAddAllHovered,
    setIsAddAllHovered,
    isRemoveAllHovered,
    setIsRemoveAllHovered,
  };
};
