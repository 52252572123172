import { useSearchParams } from '@components/hooks/useSearchParams';
import { LookerEmbedDashboard } from '@looker/embed-sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { createContext, useState, useContext, useEffect, useRef } from 'react';

export interface Filters {
  [key: string]: string;
}

interface LookerFilterContextType {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  updateDashboardRefs: (dashboardId: string, dashboard: LookerEmbedDashboard) => void;
  dashboardRefs: { [key: string]: LookerEmbedDashboard };
}

export const LookerFiltersContext = createContext<LookerFilterContextType>({
  filters: {},
  setFilters: () => { },
  updateDashboardRefs: () => { },
  dashboardRefs: {},
});

export const LookerFiltersContextProvider = ({ children }: { children: React.ReactElement }) => {
  const [filters, setFilters] = useState<Filters>({});
  const { reportsStickyFilters } = useFlags();
  const [dashboardRefs, setDashboardRefs] = useState<{ [key: string]: LookerEmbedDashboard }>({});
  const { setSearchParams, searchParams } = useSearchParams();
  const isUpdatingFilters = useRef(false);
  const isUpdatingSearchParams = useRef(false);

  useEffect(() => {
    if (!isUpdatingSearchParams.current) {
      isUpdatingFilters.current = true;
      const newFilters: Filters = {};
      searchParams.forEach((value, key) => {
        if (key === 'active') return;
        if (key === 'Attribution Model') {
          newFilters['Attribution Model Selector'] = value;
        }
        if (key === 'Attribution Window') {
          newFilters['Attribution Window Selector'] = value;
        }
        if (key === 'Campaign') {
          newFilters['Campaign Name'] = value;
        }
        newFilters[key] = value;
      });
      setFilters(newFilters);
      isUpdatingFilters.current = false;
    }
  }, [searchParams]);

  useEffect(() => {
    if (!isUpdatingFilters.current) {
      isUpdatingSearchParams.current = true;
      Object.keys(filters).forEach((key) => {
        if (
          key === 'Attribution Model Selector' ||
          key === 'Attribution Window Selector' ||
          key === 'Campaign Name'
        ) return;
        if (filters[key]) {
          setSearchParams({ key, value: filters[key] });
        }
      });
      isUpdatingSearchParams.current = false;
    }

    if (
      reportsStickyFilters &&
      Object.keys(filters).length > 0 &&
      dashboardRefs &&
      Object.keys(dashboardRefs).length > 0
    ) {
      Object.keys(dashboardRefs).forEach((dashboardKey) => {
        dashboardRefs[dashboardKey].updateFilters(filters);
      });
    }
  }, [filters, reportsStickyFilters, dashboardRefs, setSearchParams]);

  const updateDashboardRefs = (dashboardId: string, dashboard: LookerEmbedDashboard) => {
    setDashboardRefs((prev) => ({
      ...prev,
      [dashboardId]: dashboard,
    }));
  };

  return (
    <LookerFiltersContext.Provider value={{ filters, setFilters, updateDashboardRefs, dashboardRefs }}>
      {children}
    </LookerFiltersContext.Provider>
  );
};

export const useLookerFiltersContext = () => {
  return useContext(LookerFiltersContext);
};
