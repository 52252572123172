import {
  Button,
  Stack,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Theme,
  Typography,
  styled,
} from '@mui/material';

const generalRowRenderProps = {
  shouldForwardProp: (prop: string) =>
    prop !== 'highlighted' && prop !== 'targeted' && prop !== 'rowType',
};

export const AdvancedVirtualizedElementRowStyled = styled(
  Stack,
  generalRowRenderProps
)<{
  highlighted: boolean;
  targeted: boolean;
  rowType?: string;
}>(({ theme, highlighted, targeted, rowType }) => {
  const rowColor =
    rowType === 'remove' ? theme.palette.red[5] : theme.palette.blue[11];
  return {
    position: 'relative',
    height: theme.spacing(9.25),
    borderBottom: `1px solid ${theme.palette.grey[10]}`,
    background: targeted
      ? rowColor
      : highlighted
      ? theme.palette.common.offWhite
      : 'white',
    '&.hover-row': {
      cursor: 'pointer',
      '&:hover': {
        background: rowColor,

        '& .hover-icon': {
          display: 'block',
        },
      },
    },
  };
});

export const AdvancedElementRowStyled = styled(
  TableRow,
  generalRowRenderProps
)<{
  highlighted: boolean;
  targeted: boolean;
  rowType?: string;
}>(({ theme, highlighted, targeted, rowType }) => {
  const rowColor =
    rowType === 'remove' ? theme.palette.red[5] : theme.palette.blue[11];
  return {
    position: 'relative',
    height: theme.spacing(9.25),
    borderBottom: `1px solid ${theme.palette.grey[10]}`,
    background: targeted
      ? rowColor
      : highlighted
      ? theme.palette.common.offWhite
      : 'white',
    '&.hover-row': {
      cursor: 'pointer',
      '&:hover': {
        background: rowColor,

        '& .hover-icon': {
          display: 'block',
        },
      },
    },
  };
});

export const AdvancedTableStyled = styled(Table)({
  height: '100%',
});

const generalTableGroupCellRenderFunction = ({
  theme,
}: {
  theme: Theme;
}) => ({
  backgroundColor: theme.palette.grey[11],
  padding: theme.spacing(0.25, 5.5),
  justifyContent: 'center',
  borderBottom: `1px solid ${theme.palette.grey[10]}`,
});

export const TableGroupCellStyled = styled(TableCell)(
  generalTableGroupCellRenderFunction
);

export const VirtualizedTableGroupCellStyled = styled(Stack)(
  generalTableGroupCellRenderFunction
);

export const TableGroupCellStyledVirtualized = styled(Stack)(({ theme }) => ({
  ...generalTableGroupCellRenderFunction({ theme }),
  height: 31,
}));

export const SelectionElementRowStyled = styled(AdvancedElementRowStyled, {
  shouldForwardProp: prop => prop !== 'featured',
})({});

export const VirtualizedSelectionElementRowStyled = styled(
  AdvancedVirtualizedElementRowStyled,
  {
    shouldForwardProp: prop => prop !== 'featured',
  }
)({
  height: 74,
});

export const TableCellStyled = styled(TableCell)(({ theme }) => ({
  height: theme.spacing(9.25),
}));

export const TableCellVirtualizedStyled = styled(Stack)(({ theme }) => ({
  height: theme.spacing(9.25),
  fontSize: theme.spacing(1.75),
  fontWeight: theme.typography.fontWeightMedium,
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const FirstCellStyled = styled(TableCellStyled)(({ theme }) => ({
  paddingLeft: theme.spacing(5.55),
  position: 'relative',
}));

export const FirstCellVirtualizedStyled = styled(TableCellVirtualizedStyled)(
  ({ theme }) => ({
    width: '100%',
    paddingLeft: theme.spacing(5.55),
    position: 'relative',
  })
);

export const VirtualizedHeaderRowStyled = styled(Stack)(({ theme }) => ({
  textTransform: 'none',
  borderBottom: `1px solid ${theme.palette.grey[10]}`,
}));

export const FirstInnCellVirtualizedStyled = styled(
  TableCellVirtualizedStyled
)(({ theme }) => ({
  paddingLeft: theme.spacing(0.5),
  position: 'relative',
  width: '100%',
}));

export const LastCellStyled = styled(TableCellStyled)(({ theme }) => ({
  paddingRight: theme.spacing(5.55),
}));

export const LastCellVirtualizedStyled = styled(TableCellVirtualizedStyled)(
  ({ theme }) => ({
    paddingRight: theme.spacing(5.55),
  })
);

export const AdvancedWrapperStyled = styled(Stack)(({ theme }) => ({
  border: `${theme.spacing(0.125)} solid ${theme.palette.grey[8]}`,
  borderRadius: theme.spacing(0.55),
  flex: 1,
}));

export const AdvancedListBoxStyled = styled(Stack)(({ theme }) => ({
  borderRight: `${theme.spacing(0.125)} solid ${theme.palette.grey[8]}`,
  flex: 1,
}));

export const AdvancedSelectedBoxStyled = styled(Stack)({});

export const AdvancedHeaderBoxStyled = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey[11],
  flexDirection: 'row',
  alignItems: 'center',
  height: theme.spacing(7.75),
  padding: theme.spacing(1.25, 3),
}));

export const TextFieldStyled = styled(TextField)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  backgroundColor: theme.palette.common.white,
  width: theme.spacing(37.5),
  borderRadius: theme.spacing(1),
}));

export const ActionButtonStyled = styled(Button, {
  shouldForwardProp: prop => prop !== 'actionType',
})<{
  actionType?: string;
  onClick: (data: unknown[]) => void;
}>(({ actionType, theme }) => {
  const actionTypeColor =
    actionType === 'add'
      ? theme.palette.primary.main
      : actionType === 'success'
      ? theme.palette.green[6]
      : theme.palette.error.main;
  return {
    color: actionTypeColor,
    fontSize: theme.spacing(1.5),
    fontWeight: 'bold',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    gap: theme.spacing(0.55),
    justifyContent: 'center',
    padding: 0,
    margin: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:disabled': {
      color: actionTypeColor,
      opacity: 0.33,
    },
  };
});

export const TabTypographyStyled = styled(Typography, {
  shouldForwardProp: prop => prop !== 'warning' && prop !== 'selected',
})<{
  warning?: boolean;
  selected: boolean;
}>(({ warning, theme, selected }) => ({
  color: warning ? theme.palette.warning.main : theme.palette.common.black,
  fontWeight: selected ? 700 : 400,
  fontSize: theme.spacing(2.5),
}));

export const TabsStyled = styled(Tabs)(({ theme }) => ({
  marginBottom: -2,
  '& .MuiTabs-indicator': {
    height: 4,
    backgroundColor: theme.palette.blue[12],
    borderRadius: 4,
  },
}));

export const HighlightStyled = styled('span')(({ stressed }) => ({
  fontWeight: stressed ? 700 : 400,
}));

export const LegendTypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[3],
  fontSize: theme.spacing(1.5),
}));

export const TableContainerStyled = styled(TableContainer)({});

export const SwitchStyled = styled(Switch)(({ theme }) => ({
  width: theme.spacing(4.5),
  height: theme.spacing(2.25),
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: theme.spacing(1.95),
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2.5,
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.green[6],
      },
      '& .MuiSwitch-thumb:before': {
        content: '""',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="${encodeURIComponent(
          theme.palette.green[6]
        )}" d="M3.18182 5.93284L0.795455 3.58209L0 4.36567L3.18182 7.5L10 0.783582L9.20455 0L3.18182 5.93284Z"/></svg>')`,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: theme.spacing(1.55),
    height: theme.spacing(1.55),
    borderRadius: theme.spacing(1.75),
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    margin: theme.spacing(0.125),

    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: theme.spacing(1),
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
