import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { OutOfSyncErrorIcon, OutOfSyncDisabledIcon } from '../icons';
import { OutOfSyncTooltip } from '../styledComponents';
import { renderBWOverrideTitle } from './BWOverrideTitle';
import { BWOverrideTooltip } from './styles';
import { LineItem } from '@local-types/lineitem';
import { useFlags } from '@hooks/flags';

const FIELD_LABELS: Record<string, string> = {
  active: 'Active',
  beeswax_aid: 'BW advertiser ID',
  beeswax_campaign: 'BW campaign',
  beeswax_cid: 'BW campaign ID',
  beeswax_creative: 'BW creative',
  beeswax_crid: 'BW creative ID',
  beeswax_lid: 'BW line item ID',
  beeswax_targeting: 'Targeting',
  beeswax_teid: 'BW targeting expression ID',
  beeswax_vfids: 'BW campaign vendor fees ID',
  budget: 'Budget',
  campaign_budget: 'Campaign budget',
  creative_name: 'Creative name',
  daily_budget: 'Daily budget',
  duration: 'Duration',
  end_date: 'End date',
  preview_url: 'Preview URL',
  video_asset: 'Video asset',
} as const;

const getLabel = (key: string) => {
  return FIELD_LABELS[key as keyof typeof FIELD_LABELS] || key;
};

/**
 * Component to display out-of-sync status between the application and Beeswax ad server.
 *
 * This component shows when there are discrepancies between local data and Beeswax data,
 * providing visual indicators and tooltips to explain the differences. It also offers
 * functionality to update the ad server when appropriate.
 *
 * @component
 * @param {Object} props - Component props
 * @param {LineItem} [props.adGroup] - Optional ad group data
 * @param {Record<string, string|boolean>|null} props.diff - Object containing differences between local and Beeswax data
 * @param {boolean} [props.isDisabled] - Whether the sync functionality is disabled (e.g. ad group is paused)
 * @param {boolean} [props.isBWOverride] - Flag indicating if Beeswax settings override local settings
 * @param {Function} [props.onClickUpdateAdServer] - Callback function when update to ad server is requested
 * @returns {JSX.Element} Rendered component showing out-of-sync status
 */

const OutOfSync = ({
  adGroup,
  diff,
  isDisabled,
  isBWOverride,
  onClickUpdateAdServer,
}: {
  adGroup?: LineItem;
  diff: Record<string, string | boolean> | null;
  isDisabled?: boolean;
  isBWOverride?: boolean;
  onClickUpdateAdServer?: () => void;
}) => {
  const safeDiff = diff ?? {};
  const diffList = Object.entries(safeDiff);
  const fieldsText = diffList.reduce(
    (acc, item) =>
      acc ? `${acc}, ${getLabel(item[0])}` : `${getLabel(item[0])}`,
    ''
  );

  const { flags, Flags } = useFlags();

  const isTargetingOverrideEnabled =
    flags[Flags.TARGETING_OVERRIDE_THRU_QUERY_PARAM_FEATURE];

  const errorTitle = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 1,
        padding: 1,
      }}
    >
      <Typography
        sx={{ fontSize: '12px', fontWeight: '700', color: '#FF4B76' }}
      >
        Ad server out of sync
      </Typography>
      <Typography
        sx={{ fontSize: '12px', fontWeight: '500', color: '#ffffff' }}
      >
        Field(s): {fieldsText}
      </Typography>

      {isTargetingOverrideEnabled && (
        <>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '500',
              color: '#ffffff',
              marginBottom: 1,
            }}
          >
            To fix: Click the button below to update Beeswax
          </Typography>

          {onClickUpdateAdServer && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onClickUpdateAdServer()}
              size="small"
            >
              Update Ad Server
            </Button>
          )}
        </>
      )}
    </Box>
  );

  const bwOverrideTitle =
    adGroup && isBWOverride ? renderBWOverrideTitle({ adGroup }) : null;

  const disabledTitle = (
    <div>
      <Typography
        sx={{ fontSize: '12px', fontWeight: '500', color: '#ffffff' }}
      >
        Ad Server Sync status will display once Activated
      </Typography>
    </div>
  );

  return isDisabled ? (
    <OutOfSyncTooltip title={disabledTitle} placement="bottom" arrow>
      <OutOfSyncDisabledIcon data-testid="out-of-sync-disabled-icon" />
    </OutOfSyncTooltip>
  ) : isBWOverride ? (
    <BWOverrideTooltip
      title={bwOverrideTitle}
      placement="top"
      leaveDelay={250}
      arrow
    >
      <OutOfSyncErrorIcon data-testid="out-of-sync-error-icon" />
    </BWOverrideTooltip>
  ) : (
    <OutOfSyncTooltip title={errorTitle} placement="bottom" arrow>
      <OutOfSyncErrorIcon data-testid="out-of-sync-error-icon" />
    </OutOfSyncTooltip>
  );
};

export default OutOfSync;
