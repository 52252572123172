import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Tab, Tabs, CircularProgress, Skeleton } from '@mui/material';
import { BarChart, BarChartProps } from '@mui/x-charts/BarChart';
import { LineChart, LineChartProps, ScaleName } from '@mui/x-charts';
import DateFilter from '../components/DateFilter';
import useGetReportingHomeData from '../hooks/useGetReportingHomeData';
import { formatCurrency, formatNumber, getDaysOfCurrentMonth, getLast30Days } from '../utils';
//@ts-expect-error Typing for SVGs is not available
import infoIcon from '../assets/info-icon.svg';
import AdvertiserContext from '@components/AdvertiserContext';

interface LabelProps {
  name: string;
  value?: number;
  change?: number;
  id: string;
  showLoading: boolean;
}

const Label = ({ name, value, change, id, showLoading = true }: LabelProps) => {
  const isPositive = change && change > 0;
  return (
    <Box width='180px' textAlign='left' color="#13171A">
      <Box marginBottom='8px' fontSize="16px" fontWeight={700}>{name}</Box>
      <Box display='flex' alignItems='end'>
        <>
          {showLoading && (
            <Box width='100%'>
              <Box width='fit-content'>
                <Skeleton variant="text" width='140px' height='40px' />
              </Box >
            </Box >
          )}
          {!!value && !showLoading && (
            <>
              <Box marginRight='8px' fontSize='32px' fontWeight={700}>
                {id === 'revenue' || id === 'spend' ? '$' : ''}
                {formatNumber(value)}
                {id === 'roas' ? 'x' : ''}
              </Box>
              {change && (
                <Box
                  paddingBottom='2px'
                  fontWeight={400}
                  fontSize='16px'
                  color={isPositive ? '#0CA672' : '#DB4C4C'}
                >
                  {formatNumber(change)}%
                </Box>
              )}
            </>
          )}
          {!value && !showLoading && (
            <Box
              fontSize="32px"
              fontWeight={700}
              height="40px"
              marginLeft="8px"
            >
              -
            </Box>
          )}
        </>
      </Box>
    </Box>
  );
};

const getToolTipLabel = (activeTab: string) => {
  switch (activeTab) {
    case 'outcomes':
      return 'Outcomes';
    case 'reach':
      return 'Reach';
    case 'roas':
      return 'ROAS';
    case 'revenue':
      return 'Revenue';
    case 'spend':
      return 'Spend';
    default:
      return '';
  }
};

const getYAxisValueFormatter = (activeTab: string) => {
  switch (activeTab) {
    case 'revenue':
      return (value: number | null) => `$${value}`;
    case 'spend':
      return (value: number | null) => `$${value}`;
    case 'roas':
      return (value: number | null) => `${value}x`;
    default:
      return undefined;
  }
}
const getTooltipValueFormatter = (activeTab: string) => {
  switch (activeTab) {
    case 'revenue':
      return (value: number | null) => value ? formatCurrency(value) : '';
    case 'spend':
      return (value: number | null) => value ? formatCurrency(value) : '';
    case 'roas':
      return (value: number | null) => value ? `${value?.toFixed(3)}x` : '';
    default:
      return undefined;
  }
}

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);

const ReportingHome = ({
  adContextChanged,
  setAdContextChanged,
}:
  {
    adContextChanged?: boolean,
    setAdContextChanged?: React.Dispatch<React.SetStateAction<boolean>>,
  }) => {
  const [activeTab, setActiveTab] = useState('outcomes');
  const [dateFilter, setDateFilter] = useState<'30' | 'MONTH'>('30');
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const adContext = useContext(AdvertiserContext);

  const { data: data30, isLoading: isLoading30, isValidating: isValdating30 } = useGetReportingHomeData({
    dateFilter: '30',
  });
  const { data: dataMonth, isLoading: isLoadingMonth, isValidating: isValidatingMonth } = useGetReportingHomeData({
    dateFilter: 'MONTH',
  });

  const ReportingHomeTabs = useMemo(() => {
    const tabs = [
      { id: 'outcomes', name: 'Primary Outcomes' },
      { id: 'revenue', name: 'Revenue' },
      { id: 'roas', name: 'ROAS' },
      { id: 'reach', name: 'Reach' },
      { id: 'spend', name: 'Spend' },
    ]

    if (adContext.cost_model === 'CPM') {
      tabs.splice(4, 1);
    }
    if (adContext.cost_model === 'CPA') {
      tabs.splice(2, 1);
    }
    return tabs;
  }, [adContext.cost_model]);

  const data = dateFilter === '30' ? data30 : dataMonth;
  const isValidating = dateFilter === '30' ? isValdating30 : isValidatingMonth;
  const isLoading = dateFilter === '30' ? isLoading30 : isLoadingMonth;

  useEffect(() => {
    if (initialLoadComplete) return

    const allLoadingComplete =
      Object.values(isLoading).every((loading) => !loading);

    if (allLoadingComplete) {
      setInitialLoadComplete(true);
    }

  }, [isLoading])

  useEffect(() => {
    if (!adContextChanged || !setAdContextChanged) return;

    const allValidationsComplete =
      Object.values(isValidating).every((validating) => !validating);

    if (allValidationsComplete) {
      setAdContextChanged(false);
    }
  }, [adContextChanged, isValidating, setAdContextChanged]);

  const last30Days = getLast30Days();
  const daysOfCurrentMonth = getDaysOfCurrentMonth();

  const dates = dateFilter === '30' ? last30Days : daysOfCurrentMonth;

  const handleTabChange = (e: React.SyntheticEvent, v: string) => {
    setActiveTab(v);
  };

  const chartProps: LineChartProps | BarChartProps = {
    grid: { horizontal: true },
    colors: ['#57D9AD'],
    xAxis: [{
      scaleType: 'band' as ScaleName,
      data: dates,
      tickSize: 0,
    }],
    yAxis: [{
      tickSize: 0,
      valueFormatter: getYAxisValueFormatter(activeTab),
    }],
    series: [{
      data: data[activeTab]?.graph_data.slice(0, dates.length),
      label: getToolTipLabel(activeTab),
      valueFormatter:
        getTooltipValueFormatter(activeTab),
    }],
    height: 400,
    slotProps: {
      legend: { hidden: true },
    },
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginY="8px"
      >
        <Box
          fontSize="20px"
          fontWeight={700}
          color="#13171A"
          margin="16px 12px"
        >
          Ad Account Overview
        </Box>
        <DateFilter value={dateFilter} setValue={setDateFilter} />
      </Box>
      <Box sx={{
        borderBottom: 1,
        borderColor: "divider",
        marginX: "-24px",
      }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              width: '157px',
              height: '4px',
              backgroundColor: '#1DAFFF',
              marginLeft: '12px',
            }
          }}
        >
          <Box marginLeft="24px" />
          {ReportingHomeTabs.map(tab => {
            const tabData = data[tab.id];
            const tabIsLoading = isLoading[tab.id];
            const tabIsValidating = isValidating[tab.id] && !!adContextChanged;
            return (
              <Tab
                disableRipple={true}
                key={tab.id}
                id={tab.id}
                value={tab.id}
                label={
                  <Label
                    id={tab.id}
                    name={tab.name}
                    value={tabData?.current_period_total}
                    change={tabData?.percentage}
                    showLoading={tabIsValidating || tabIsLoading || (initialLoadComplete && !tabData)}
                  />}
              />
            );
          })}
        </Tabs >
      </Box>

      {(!data[activeTab] || (isValidating[activeTab] && adContextChanged) || isLoading[activeTab]) ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="400px"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <>
          {activeTab === 'roas' ? (
            <LineChart
              {...chartProps as LineChartProps}
            />
          ) : (
            <BarChart
              {...chartProps as BarChartProps}
            />
          )}
          <Box display="flex" fontSize={12} color="#77858C" fontWeight={400}>
            <img src={infoIcon} alt='info-icon' style={{ marginRight: '8px' }} />
            Performance data through {yesterday.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })} 11:59:59 EST. Percentages are compared to prior period.
          </Box>
        </>
      )
      }
    </>
  );
};

export default ReportingHome;
