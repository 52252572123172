import React, { useEffect, useState } from 'react';
import RoutesSection, { LIST_ITEM_HEIGHT } from './RoutesSection';
import { Box } from '@mui/material';
import { ReportsSection } from './constants/routes';

const TOP_OFFSET = 108;

interface ReportsRoutesProps {
  sections: ReportsSection[];
  active: string;
  handleRouteChange: (key: string, path: string) => void;
  routesToNotRender: string[];
}

const ReportsRoutes = ({
  sections,
  active,
  handleRouteChange,
  routesToNotRender,
}: ReportsRoutesProps) => {
  const [activeTabPosition, setActiveTabPosition] = useState(0);
  const [collapsedSections, setCollapsedSections] = useState<{ [key: string]: boolean }>({});
  const [hideActiveTabIndicator, setHideActiveTabIndicator] = useState(false);

  useEffect(() => {
    const sectionsToCollapse = sections.filter(section => section.defaultCollapsed);
    const collapsedSections = sectionsToCollapse.reduce<{ [key: string]: boolean }>((acc, section) => {
      acc[section.label] = true;
      return acc;
    }, {});
    setCollapsedSections(collapsedSections);
  }, [sections])

  useEffect(() => {
    setHideActiveTabIndicator(false);
    const isActiveInCollapsedSection = Object.keys(collapsedSections).some(section => {
      if (collapsedSections[section]) {
        const sectionData = sections.find(sec => sec.label === section);
        return sectionData?.routes.some(route => route.key === active);
      }
      return false;
    });

    setHideActiveTabIndicator(isActiveInCollapsedSection);
    const activeElement = document.querySelector(`[id="${active}"]`);
    const mainElement = document.querySelector('.AppHeader-content');
    if (activeElement && mainElement) {
      const rect = activeElement.getBoundingClientRect();
      const scrollTop = mainElement.scrollTop
      setActiveTabPosition(rect.top + scrollTop);
    }
  }, [active, collapsedSections]);

  const toggleCollapse = (label: string) => {
    setCollapsedSections(prevState => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        id="active-tab-indicator"
        sx={{
          position: 'absolute',
          bottom: 0,
          top: `calc(${activeTabPosition}px - ${TOP_OFFSET}px)`,
          right: '-3px',
          width: '3px',
          height: `${LIST_ITEM_HEIGHT}px`,
          backgroundColor: '#1dafff',
          transition: 'top 0.2s ease',
          borderRadius: '0 2px 2px 0',
          display: hideActiveTabIndicator ? 'none' : 'block',
        }}
      />
      {sections.map(section => {
        const routesToRender = section.routes.filter(route => !routesToNotRender.includes(route.key));
        if (routesToRender.length === 0) return null;

        const isCollapsed = collapsedSections[section.label];

        return (
          <Box key={section.label} marginBottom="16px" sx={section.sx}>
            <RoutesSection
              label={section.label}
              routes={routesToRender}
              active={active}
              handleRouteChange={handleRouteChange}
              toggleCollapse={() => toggleCollapse(section.label)}
              isCollapsed={isCollapsed}
              collapsible={section.collapsible}
              {
              ...(typeof section.routeSectionProps === 'object'
                ? section.routeSectionProps
                : {})
              }
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default ReportsRoutes;
