import React from 'react';
import { Typography } from '@mui/material';
import { TableGroupCellStyled } from './styles';
import { PlaceholderGroupProps } from './types';

export const PlaceholderGroup = <Data,>({
  data,
  type,
}: PlaceholderGroupProps<Data>) => {
  return (
    <TableGroupCellStyled colSpan={type === 'left' ? 2 : 3}>
      <Typography variant="caption" color="grey.1">
        {data.groupName}
      </Typography>
    </TableGroupCellStyled>
  );
};
