import React from 'react';
import ReportsPerformance from '../pages/ReportsPerformance';
import ReportsPathToPurchase from '../pages/ReportsPathToPurchase';
import PostConversions from '../pages/ReportsPostConversions';
import ReportsBeta from '../pages/ReportsBeta';
import SpikeLift from '../pages/ReportsSpikeLift';
import ReportsCPAInternal from '../pages/ReportsCPAInternal';
import ReportingHome from '../pages/ReportingHome';
import { SxProps } from '@mui/material';
import { COST_MODEL } from '@components/AdvertiserContext';
import ReportsAlwaysOn from '../pages/ReportsAlwaysOn';

interface ReportsRouteComponentProps {
  setInExplore: React.Dispatch<React.SetStateAction<boolean>>;
  inExplore: boolean;
  active?: boolean;
  internal?: boolean;
  adContextChanged?: boolean;
  setAdContextChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ReportsSection {
  label: string;
  routes: {
    label: string | React.ReactNode;
    key: string;
    path: string;
    component: (props: ReportsRouteComponentProps) => React.ReactElement;
    usesLooker: boolean;
  }[];
  sx?: SxProps;
  collapsible?: boolean;
  defaultCollapsed?: boolean;
  routeSectionProps?: unknown;
}

export const GENERAL_ROUTES: ReportsSection = {
  label: 'General',
  routes: [
    {
      label: 'Reporting Home',
      key: 'home',
      path: '/reports/home',
      component: ReportingHome,
      usesLooker: false

    },
  ]
};

export const PERFORMANCE_ROUTES: ReportsSection = {
  label: 'Performance',
  routes: [
    {
      label: 'Campaign Performance',
      key: 'performance',
      path: '/reports/performance',
      component: ReportsPerformance,
      usesLooker: true
    },
    {
      label: 'Post Conversions',
      key: 'conversions',
      path: '/reports/conversions',
      component: PostConversions,
      usesLooker: true
    },
    {
      label: 'Path To Purchase',
      key: 'path-to-purchase',
      path: '/reports/path-to-purchase',
      component: ReportsPathToPurchase,
      usesLooker: true
    },
  ]
};

export const MEASUREMENT_ROUTES: ReportsSection = {
  label: 'Measurement',
  routes: [
    {
      label: 'Spike Lift',
      key: 'spike-lift',
      path: '/reports/spike-lift',
      component: SpikeLift,
      usesLooker: true
    },
    {
      label: 'Always-on Incrementality',
      key: 'incrementality',
      path: '/reports/incrementality',
      component: ReportsAlwaysOn,
      usesLooker: true
    }
  ]
};

export const INTERNAL_ROUTES: ReportsSection = {
  label: 'Internal',
  routes: [
    {
      label: 'Campaign Performance',
      key: 'internal-performance',
      path: '/reports/internal-performance',
      component: (props) => <ReportsPerformance {...props} internal={true} />,
      usesLooker: true
    },
    {
      label: <span>CPA Performance<sup>*</sup></span>,
      key: 'cpa-internal',
      path: '/reports/cpa-internal',
      component: ReportsCPAInternal,
      usesLooker: true
    },
    {
      label: 'Path To Purchase',
      key: 'internal-path-to-purchase',
      path: '/reports/internal-path-to-purchase',
      component: (props) => <ReportsPathToPurchase {...props} internal={true} />,
      usesLooker: true
    },
    {
      label: 'Post Conversions',
      key: 'internal-conversions',
      path: '/reports/internal-conversions',
      component: (props) => <PostConversions {...props} internal={true} />,
      usesLooker: true
    },
    {
      label: 'Spike Lift',
      key: 'internal-spike-lift',
      path: '/reports/internal-spike-lift',
      component: (props) => <SpikeLift {...props} internal={true} />,
      usesLooker: true
    },
    {
      label: 'Always-on Incrementality',
      key: 'internal-incrementality',
      path: '/reports/internal-incrementality',
      component: (props) => <ReportsAlwaysOn {...props} internal={true} />,
      usesLooker: true
    },
    {
      label: 'Beta',
      key: 'beta',
      path: '/reports/beta',
      component: ReportsBeta,
      usesLooker: true
    },
  ],
  sx: {
    marginTop: '140px',
  },
  collapsible: true,
  defaultCollapsed: true,
  routeSectionProps: {
    showSubtext: (costModel: keyof typeof COST_MODEL) => costModel === COST_MODEL.CPA,
    subtext: 'differs from external version'
  }
};
