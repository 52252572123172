import { BidStrategy, BidStrategyEvent } from '@local-types';
import { getLockedStatus } from '@v2/components/campaign/CampaignAdGroupSection/BidStrategy/utils';

export const getFirstBidStrategy = ({
  bidStrategies,
  bidStrategyEventSet,
}: {
  bidStrategies: BidStrategy[];
  bidStrategyEventSet: BidStrategyEvent[];
}) => {
  const strategy = bidStrategies.find(
    option => !getLockedStatus({ option, bidStrategyEventSet })
  );
  return strategy ? strategy : null;
};
