import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  SelectChangeEvent
} from '@mui/material';
import { CPA_DEFAULTS, CPM_DEFAULTS, measurementCheckboxItems, menuItems, performanceCheckboxItems } from './constants/ReportsAdmin';
import useReportConfig from '@components/hooks/apis/reportConfig';
import { COST_MODEL } from '@components/AdvertiserContext';
import { useGetAdvertiser } from '@components/hooks/apis/advertisers';
import LoadingSpinner from '@components/ui/LoadingSpinner';

interface CheckBoxSectionProps {
  title: string;
  checkboxes: { name: string; label: string; checked: boolean }[];
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckBoxSection = ({
  title,
  checkboxes,
  handleCheckboxChange,
}: CheckBoxSectionProps) => (
  <Box display="flex" flexDirection="column">
    <Typography
      sx={{
        fontSize: '16px',
        marginBottom: '8px',
        marginTop: '16px',
        fontWeight: 700,
        color: '#13171A'
      }}
    >
      {title}
    </Typography>
    {checkboxes.map((checkbox) => (
      <FormControlLabel
        key={checkbox.name}
        control={
          <Checkbox
            sx={{ padding: '4px', marginLeft: '6px' }}
            checked={checkbox.checked}
            onChange={handleCheckboxChange}
            name={checkbox.name}
          />
        }
        label={checkbox.label}
      />
    ))}
  </Box>
);

interface FormValues {
  measurementLevel: string;
  campaignPerformance: boolean;
  pathToPurchase: boolean;
  postConversions: boolean;
  spikeLift: boolean;
  alwaysOnIncrementality: boolean;
}

const ReportsAdmin = ({ currentAccount }: { currentAccount: { ad_account_id: number } }) => {
  const [reportConfigFetched, setReportConfigFetched] = useState(false);
  const [reportConfigCreated, setReportConfigCreated] = useState(false);
  const [settingsChanged, setSettingsChanged] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    measurementLevel: 'Level_1',
    campaignPerformance: false,
    pathToPurchase: false,
    postConversions: false,
    spikeLift: false,
    alwaysOnIncrementality: false,
  });

  const { data: advertiser, isLoading } = useGetAdvertiser(currentAccount.ad_account_id);

  const { data, error, mutate, isValidating } = useReportConfig.get(currentAccount.ad_account_id);
  const { postReportConfig } = useReportConfig.post({
    onSuccess: () => mutate(),
    accountId: currentAccount.ad_account_id
  });
  const { patchReportConfig } = useReportConfig.patch({
    accountId: currentAccount.ad_account_id,
    onSuccess: () => {
      mutate();
    }
  });

  useEffect(() => {
    // if a report config is not found, trigger a post request to create a new one
    if (error?.response?.status === 404 && !reportConfigCreated) {
      postReportConfig({});
      setReportConfigCreated(true);
    }
  }, [error, postReportConfig]);

  useEffect(() => {
    if (data && !reportConfigFetched && currentAccount.ad_account_id === data.adAccount) {
      setFormValues({
        measurementLevel: data.measurementLevel,
        campaignPerformance: data.campaignPerformance,
        pathToPurchase: data.pathToPurchase,
        postConversions: data.postConversions,
        spikeLift: data.spikeLift,
        alwaysOnIncrementality: data.alwaysOnIncrementality,
      });
      setReportConfigFetched(true);
    }
  }, [data, currentAccount.ad_account_id]);

  const handleDropdownChange = (event: SelectChangeEvent) => {
    setSettingsChanged(true);
    const DEFAULT_VALUES = advertiser.cost_model === COST_MODEL.CPA ? CPA_DEFAULTS : CPM_DEFAULTS;
    switch (event.target.value) {
      case 'Level_1':
        setFormValues(DEFAULT_VALUES.LEVEL_1);
        break;
      case 'Level_2':
        setFormValues(DEFAULT_VALUES.LEVEL_2);
        break;
      case 'Level_3':
        setFormValues(DEFAULT_VALUES.LEVEL_3);
        break;
      case 'Level_4':
        setFormValues(DEFAULT_VALUES.LEVEL_4);
        break;
      default:
        break;
    };
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [event.target.name]: event.target.checked,
    }));
    setSettingsChanged(true);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (settingsChanged) {
      patchReportConfig(formValues);
      setSettingsChanged(false);
    }
  };

  const generateCheckboxes = (checkboxes: { name: string; label: string }[]) => {
    return checkboxes.map((checkbox) => ({
      ...checkbox,
      checked: !!formValues[checkbox.name as keyof typeof formValues],
    }));
  };

  const performanceCheckboxes = useMemo(() => (
    generateCheckboxes(performanceCheckboxItems)
  ), [formValues]);

  const measurementCheckboxes = useMemo(() => (
    generateCheckboxes(measurementCheckboxItems)
  ), [formValues]);

  if (isValidating || isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box marginLeft="16px">
      <Typography
        sx={{
          fontSize: '24px',
          marginBottom: '24px',
          fontWeight: 700,
          color: '#13171A'
        }}
      >
        Report Availability
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth sx={{ marginBottom: '16px', width: '420px' }}>
          <InputLabel id="measurement-level-label" color="secondary">Measurement Level</InputLabel>
          <Select
            labelId="measurement-level-label"
            id="measurement-level"
            value={formValues.measurementLevel}
            label="Measurement Level"
            onChange={handleDropdownChange}
            color='secondary'
          >
            {menuItems.map((item) => (
              <MenuItem key={item.value} value={item.value} data-testid={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <CheckBoxSection
          title="Performance Reports"
          checkboxes={performanceCheckboxes}
          handleCheckboxChange={handleCheckboxChange}
        />
        <CheckBoxSection
          title="Measurement Reports"
          checkboxes={measurementCheckboxes}
          handleCheckboxChange={handleCheckboxChange}
        />
        <Box display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!settingsChanged}
            sx={{
              width: '203px',
              height: '60px',
              borderRadius: '8px',
              fontSize: '20px',
              fontWeight: 700,
              marginTop: '24px',
              backgroundColor: settingsChanged ? '#1DAFFF' : '#B0BEC5',
            }}
          >
            Save Settings
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ReportsAdmin;
