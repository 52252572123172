import React from 'react';
import moment from 'moment-timezone';
import {
  Box,
  Stack,
  Modal,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  useTheme,
  CircularProgress,
} from '@mui/material';
import { ModalProps } from '@mui/material/Modal/Modal.d';
import Grid from '@mui/material/Unstable_Grid2';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareInfo } from '@fortawesome/pro-solid-svg-icons';
import { ModalWrapper, LineChart } from './components';
import { Tab } from './components/styled';
import { RealTimeModalProps as CustomRealTimeModalProps } from './constants';

type RealTimeModalProps = CustomRealTimeModalProps & Omit<ModalProps, 'children'> & { isLoading: boolean };

const RealTimeModal = (props: RealTimeModalProps) => {
  const {
    wins,
    data,
    timeRange = "1h",
    description,
    open,
    isLoading,
    lastUpdatedTimestamp,
    onTimeRangeChange,
    onClose,
    ...rest
  } = props;

  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="real-time-modal"
      {...rest}
    >
      <ModalWrapper sx={theme => ({ width: '100%', p: theme.spacing(5, 5, 4) })}>
        <Stack spacing={2}>
          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Grid xs>
              <Typography id="modal-modal-title" variant="subtitle1" sx={{ fontWeight: 700 }}>
                Real-time Data
              </Typography>
              <Typography id="modal-modal-description" variant="body1">
                {description}
              </Typography>
            </Grid>

            <Grid xs={1} sx={{ textAlign: 'right' }}>
              <IconButton
                size="large"
                aria-label="close button"
                sx={{ p: 0 }}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '450px'
              }}
            >
              <CircularProgress color="secondary" />
            </Box>
          )}

          {!isLoading && (
            <TabContext value="wins">
              <TabList
                aria-label='real time data tabs'
                sx={{
                '& .MuiTabs-fixed': {
                  borderBottom: `1px solid ${theme.palette.grey[8]}`,
                },

                  '& .MuiTabs-indicator': {
                    height: 4,
                  },
              }}
              >
                <Tab
                  value="wins"
                  label={
                    <>
                      <Typography 
                        variant="body1"
                        component="h2"
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                       >
                        <Box
                          sx={{
                            width: 16,
                            height: 16,
                            backgroundColor: 'chart.7',
                          }}
                        />
                        Real-time Wins
                      </Typography>

                    <Typography variant="h1" component="h2">
                      {wins.toLocaleString('en-US')}
                    </Typography>
                  </>
                }
              />

                <Stack
                  sx={{
                    width: '100%',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                  }}
                >
                  <ToggleButtonGroup
                    exclusive
                    value={timeRange}
                    onChange={onTimeRangeChange}
                    aria-label="chart frequency"
                    sx={{ p: 1 }}
                  >
                    <ToggleButton value="1d" aria-label="1 day">
                      1 day
                    </ToggleButton>

                    <ToggleButton value="1h" aria-label="1 hour">
                      1 hour
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </TabList>

            <TabPanel value="wins" sx={{ padding: 0 }}>
              <LineChart
                dataset={data}
                series={[
                  {
                    id: 'Wins',
                    dataKey: 'wins',
                    color: theme.palette.chart[7],
                    valueFormatter: (value: number | null, { dataIndex }: { dataIndex: number }) => {
                      if (value === null || !data[dataIndex]) {
                        return 'N/A';
                      }

                      const { date } = data[dataIndex];
                      return `${value} - ${moment(date).format('h:mm A')}`;
                    },
                  }
                ]}
                xAxis={[{
                  dataKey: 'date',
                  valueFormatter: (date) => moment(date).format('h:mm A'),
                  min: Math.min(...data.map(d => new Date(d.date).getTime())),
                  max: Math.max(...data.map(d => new Date(d.date).getTime()))
                }]}
                yAxis={[{ dataKey: 'wins' }]}
              />
            </TabPanel>

              <div>
                <Typography variant="caption" color="#77858C" sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                  <FontAwesomeIcon icon={faSquareInfo} style={{ marginRight: '4px' }} />
                  Real-time data through{' '}
                  {lastUpdatedTimestamp
                    ? moment(lastUpdatedTimestamp).format('h:mm A [(ET)], MMM D, YYYY')
                    : 'N/A'
                  }
                  . May not match Performance data.
                </Typography>
              </div>
            </TabContext>
          )}
        </Stack>
      </ModalWrapper>
    </Modal>
  );
};

export default RealTimeModal;
