import React from 'react';
import { dialogStylesOverride } from './styles';
import { AdServerOutOfSyncPromptProps } from './types';
import { useAdServerOutOfSyncPrompt } from './hooks/useAdServerOutOfSyncPrompt';
import { Title } from './Title';
import { Content } from './Content';
import { Actions } from './Actions';

// Comply with src/components/Dialog.js
export const adServerOutOfSyncPrompt = ({
  open,
  handleClose,
  handleMerge,
  handleOverwrite,
}: AdServerOutOfSyncPromptProps) => {
  const { merge, overwrite } = useAdServerOutOfSyncPrompt({
    handleClose,
    handleMerge,
    handleOverwrite,
  });

  return {
    isOpen: open,
    title: <Title handleClose={handleClose} />,
    content: <Content />,
    actions: <Actions merge={merge} overwrite={overwrite} />,
    onClose: handleClose,
    showDefaultCloseButton: false,
    dialogStylesOverride,
  } as const;
};
