import { Stack, useTheme } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import {
  convertISOToDateTime,
  getBidStrategyLabels,
  getCellVisibility,
} from '../utils';
import { DateTimeWithArrow } from '../components';

export const fromCellRenderer = (params: GridCellParams) => {
  const {
    startDateVisible,
    endDateVisible,
    bidStrategyVisible,
    bidStrategyEventVisible,
    cpmVisible,
  } = getCellVisibility(params);

  const isoStartDate = params.row.start_date;
  const isoEndDate = params.row.end_date;
  const cpm = params.row.cpm;

  const [start_date, start_time] = convertISOToDateTime(isoStartDate || '');
  const [end_date, end_time_base] = isoEndDate
    ? convertISOToDateTime(isoEndDate)
    : ['No end date', 'No end time'];
  const end_time = isoEndDate ? `${end_time_base} (ET)` : end_time_base;

  const theme = useTheme();

  const [bidStrategyLabel, bidStrategyEventLabel] = getBidStrategyLabels(
    params,
    'from'
  );

  return (
    <Stack spacing={theme.spacing(3)}>
      {startDateVisible && (
        <>
          <DateTimeWithArrow text={start_date} theme={theme} />
          <DateTimeWithArrow text={`${start_time} (ET)`} theme={theme} />
        </>
      )}
      {endDateVisible && (
        <>
          <DateTimeWithArrow text={end_date} theme={theme} />
          <DateTimeWithArrow text={end_time} theme={theme} />
        </>
      )}
      {bidStrategyVisible && (
        <DateTimeWithArrow text={bidStrategyLabel || ''} theme={theme} />
      )}
      {bidStrategyEventVisible && (
        <DateTimeWithArrow
          text={bidStrategyEventLabel || 'None'}
          theme={theme}
        />
      )}
      {cpmVisible && <DateTimeWithArrow text={cpm || 'None'} theme={theme} />}
    </Stack>
  );
};
