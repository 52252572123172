import React from 'react';
import { BidStrategyFormValues, BidStrategyOption } from '../types';
import { MaxCpm } from '@components/BidStrategy/MaxCpm';
import { EventPicker } from '@components/BidStrategy/EventPicker';
import { type Control } from 'react-hook-form';
import { Stack, InputAdornment } from '@mui/material';
import { RHFTextField } from '@components/form';
import { BidStrategyEvent } from '@local-types/bidStrategy';

function withDisplayName<P>(
  Component: React.ComponentType<P>,
  displayName: string
): React.ComponentType<P> {
  Component.displayName = displayName;
  return Component;
}

export interface FormContext {
  control: Control<BidStrategyFormValues>;
  isMaxCPMBidEnabled: boolean;
  handleCPMSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rangeText: string;
}

export const EmptyContent = withDisplayName(() => <></>, 'EmptyContent');

export const EventPickerComponent = withDisplayName<{ formContext: FormContext, bidStrategyEventSet: BidStrategyEvent[] }>(
  ({ formContext, bidStrategyEventSet }) => (
    <EventPicker
      control={formContext.control}
      bidStrategyEventSet={bidStrategyEventSet}
      fieldName="bidStrategyEvent"
    />
  ), 
  'EventPickerComponent'
);

export const TargetCpaComponent = withDisplayName<{ formContext: FormContext, bidStrategyEventSet: BidStrategyEvent[] }>(
  ({ formContext, bidStrategyEventSet }) => (
    <Stack spacing={2}>
      <EventPicker
        control={formContext.control}
        bidStrategyEventSet={bidStrategyEventSet}
        fieldName="bidStrategyEvent"
      />
      <RHFTextField
        name="bidStrategyTarget"
        label="Target Cost per Outcome"
        type="number"
        fullWidth
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        control={formContext.control}
      />
    </Stack>
  ), 
  'TargetCpaComponent'
);

export const MaxCpmComponent = withDisplayName<{ formContext: FormContext }>(
  ({ formContext }) => (
    <MaxCpm
      control={formContext.control}
      required={true}
      handleCPMSwitchChange={formContext.handleCPMSwitchChange}
      isMaxCPMBidEnabled={formContext.isMaxCPMBidEnabled}
      rangeText={formContext.rangeText}
      fieldNames={{
        maxCPMBidEnabled: "maxCPMBidEnabled",
        maxCPMBid: "maxCPMBid"
      }}
    />
  ), 
  'MaxCpmComponent'
);

export const getBidStrategyOptions = (bidStrategyEventSet: BidStrategyEvent[]): BidStrategyOption[] => {
  return [
    {
      value: '5',
      label: 'Max Impressions',
      description: 'Finding the lowest inventory cost to deliver as many impressions as possible while respecting daily spend goals.',
      locked: false,
      infoText: 'Finding the lowest inventory cost to deliver as many impressions as possible, while respecting daily spend goals. Daily budget is always spent.',
      renderContent: () => <EmptyContent />
    },
    {
      value: '6',
      label: 'Max Outcomes',
      description: 'Dynamic bidding to achieve the maximum number of outcomes possible.',
      locked: false,
      infoText: 'Dynamic bidding to achieve the maximum number of outcomes possible. Daily budget is always spent.',
      renderContent: (formContext: FormContext) => <EventPickerComponent formContext={formContext} bidStrategyEventSet={bidStrategyEventSet} />
    },
    {
      value: '8',
      label: 'Target Cost per Outcome',
      description: 'Bid optimization that seeks to deliver the specified outcome at or below the specified Target Cost per Action on average.',
      locked: false,
      infoText: 'Bid optimization that seeks to deliver the specified outcome at or below the specified Cost per Action on average. Bidding stops if the Target CPA can not be maintained.',
      renderContent: (formContext: FormContext) => <TargetCpaComponent formContext={formContext} bidStrategyEventSet={bidStrategyEventSet} />
    },
    {
      value: '10',
      label: 'Auto Bid',
      description: 'Maximizes exposure to different inventory sources by bidding on all impression opportunities at the best available price.',
      locked: false,
      infoText: 'Maximizes exposure to different inventory sources by bidding on all impression opportunities at the best available price.',
      renderContent: () => <EmptyContent />
    },
    {
      value: '11',
      label: 'Manual Bid',
      description: 'Bid strategy that always bids the same, less any fees.',
      locked: false,
      infoText: 'Bid strategy that always bids the same, less any fees.',
      renderContent: (formContext: FormContext) => <MaxCpmComponent formContext={formContext} />
    }
  ];
};

export const DEFAULT_BID_STRATEGY_EVENT_SET: BidStrategyEvent[] = [
  { id: 1613, name: 'Website Visits', url: '/api/event/1613/' },
  { id: 1614, name: 'App Downloads', url: '/api/event/1614/' },
  { id: 1615, name: 'install', url: '/api/event/1615/' },
  { id: 1616, name: 'completepurchase', url: '/api/event/1616/' },
  { id: 1617, name: 'leadgenerated', url: '/api/event/1617/' },
]; 

export const mockBidStrategyData = {
  bidStrategyEventSet: DEFAULT_BID_STRATEGY_EVENT_SET,
  tvsciq: 'ENABLED',
  recommendedBidRange: [12, 18]
}; 
