import React from 'react';
import { FirstCellStyled, SelectionElementRowStyled } from '../styles';
import { TableCell } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { TableLeftRowProps } from './types';
import { TableData, WithId } from '../types';

export const TableLeftRow = <Value, Data extends TableData<Value>>({
  data,
  index,
  isTargeted,
  renderCell,
  PlaceholderCell,
  onChange,
}: TableLeftRowProps<Value, Data>) => {
  return (
    <SelectionElementRowStyled
      data-testid={
        data
          ? `table-left-row-${(data as WithId<Value>).id}`
          : `table-left-row-placeholder-${index}`
      }
      className={data ? 'hover-row' : ''}
      targeted={!!data && isTargeted}
      highlighted={!!(index % 2)}
      onClick={() => {
        if (data) {
          onChange(data);
        }
      }}
    >
      <FirstCellStyled>
        {data ? (
          renderCell({ data })
        ) : (
          <PlaceholderCell data={data} type="left" />
        )}
      </FirstCellStyled>
      <TableCell>
        <AddCircleOutlineIcon
          className="hover-icon"
          color="primary"
          sx={{
            display: !data || !isTargeted ? 'none' : 'block',
          }}
        />
      </TableCell>
    </SelectionElementRowStyled>
  );
};
