import { useContext } from 'react';
import { useGetAllowedTargeting } from '@apis/allowedTargeting';
import { useGetAllBidStrategies } from '@apis/bidStrategy';
import {
  useGetAllCreatives,
  useGetCreativeLineItemTrigger,
  usePatchCreative,
  usePatchCreativeLineItem,
} from '@apis/creatives';
import {
  useAllDisplays,
  usePatchStaticDisplayCreative,
  useGetStaticCreativeLineItemTrigger,
  usePatchStaticCreativeLineItem,
} from '@apis/displays';
import { GROUP_TYPE, useGroups } from '@apis/groups';
import { useWizardSection } from '@components/Wizard';
import { useUser } from '@components/hooks';
import { useFlags } from '@hooks/flags';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { pick } from 'lodash';
import { Status } from '@constants';
import { useSnackbar } from 'notistack';
import { useGeneralNavigation } from '../../hooks/useGeneralNavigation';
import {
  removeEmptyValues,
  removeNullValues,
  handleErrors,
} from '../../utils';
import {
  transformToAdGroup,
  filter,
  mustIncludePostFields,
  mustIncludePatchFields,
} from '../utils';
import { bwSyncParams } from '@components/containers/WizardPage/constants';
import DialogContext from '@providers/DialogContext';
import { adServerOutOfSyncPrompt } from '@v2/components/ui/AdServerOutOfSyncPrompt';
import { targetingOverrideParams } from '../constants';
import { wizardGeneralErrorFields } from '../../constants';
import { fields } from '@components/CampaignWizard/AdGroupSettingsSection/formConfig';
import { fields as creativeFields } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedCreative/Manage/constants';
import { useGetBudgetPacingOptions } from '@components/hooks/apis/budgetPacingOptions';
import {
  getOutOfSyncItems,
  showOutOfSyncSubmitPrompt,
} from '../utils/general';
import { isWeightedDelivery } from '../utils/creatives';
import type { Campaign } from '@local-types/campaign';
import type { UseFormReturn } from 'react-hook-form';
import type { Fields } from '@local-types/general';
import { AdGroup } from '@local-types/group';

export const useGroupSectionSubmit = ({
  isDisplay,
  campaign,
  form,
  sectionKey,
  adGroup,
}: {
  isDisplay: boolean;
  campaign: Campaign;
  form: UseFormReturn<Fields<typeof fields>>;
  sectionKey: string;
  adGroup: AdGroup;
}) => {
  const { user } = useUser();
  const { flags, Flags } = useFlags();
  const { releaseCreativeStartEndDatetime: allowDateTime } = useLDFlags();
  const { setDialog } = useContext(DialogContext);
  const { enqueueSnackbar } = useSnackbar();
  const { state, toCampaignDetails } = useGeneralNavigation();
  const { data: allowedTargeting } = useGetAllowedTargeting();
  const { data: bidStrategies } = useGetAllBidStrategies();
  const {
    trigger: updateLineItemCreative,
    isMutating: isCreativePostMutating,
  } = usePatchCreativeLineItem();
  const {
    trigger: updateLineItemStaticCreative,
    isMutating: isStaticCreativePostMutating,
  } = usePatchStaticCreativeLineItem();
  const { data: budgetPacingOptions } = useGetBudgetPacingOptions();

  const isTargetingOverrideEnabled =
    flags[Flags.TARGETING_OVERRIDE_THRU_QUERY_PARAM_FEATURE];
  const shouldShowOutOfSyncSubmitPrompt = showOutOfSyncSubmitPrompt({
    adGroup,
    user,
    isTargetingOverrideEnabled,
  });

  const { invalidate: invalidateCreatives } = useGetAllCreatives({
    disabled: !!isDisplay,
  });
  const { invalidate: invalidateDisplay } = useAllDisplays({
    disabled: !isDisplay,
  });

  const {
    update: updateAdGroup,
    create: createAdGroup,
    isLoading: isAdGroupProcessing,
  } = useGroups(GROUP_TYPE.AD_GROUP, campaign?.id);

  const {
    update: updateStaticDisplayAdGroup,
    create: createStaticDisplayAdGroup,
    isLoading: isStaticDisplayAdGroupProcessing,
  } = useGroups(GROUP_TYPE.STATIC_GROUP, campaign?.id);

  const { mutate: invalidateAdGroupSync } = useGroups(
    GROUP_TYPE.AD_GROUP,
    campaign?.id,
    {
      ...(shouldShowOutOfSyncSubmitPrompt && { params: bwSyncParams }),
    }
  );

  const { mutate: invalidateStaticAdGroupSync } = useGroups(
    GROUP_TYPE.STATIC_GROUP,
    campaign?.id,
    {
      ...(shouldShowOutOfSyncSubmitPrompt && { params: bwSyncParams }),
    }
  );

  const { trigger: updateCreative } = usePatchCreative();
  const { trigger: getCreativeLineItem } = useGetCreativeLineItemTrigger();
  const { trigger: getStaticCreativeLineItem } =
    useGetStaticCreativeLineItemTrigger();

  const { trigger: updateStaticDisplayCreative } =
    usePatchStaticDisplayCreative();

  const { goToNext } = useWizardSection({
    key: sectionKey,
  });

  const handleSubmit = async (
    options: {
      updateAdGroupParams?: Record<string, string>;
    } = {}
  ) => {
    const values = form.getValues();
    const { updateAdGroupParams } = options;
    const { creativesAdditionalData, ...rest } = transformToAdGroup(values, {
      bidStrategies,
      budgetPacingOptions,
      campaign,
      adGroup,
      allowedTargeting,
      isDisplay,
      filter: filter(
        adGroup.temporary ? mustIncludePostFields : mustIncludePatchFields
      ),
    });

    const res = await (!adGroup?.temporary
      ? updateAdGroup({
          id: adGroup.id,
          params: updateAdGroupParams,
          ...rest,
        })
      : createAdGroup(rest));

    /** TODO: move creatives hooks to typescript. */
    /* @ts-expect-error js file doesn't recognize trigger params. */
    const lineItems = (await getCreativeLineItem(res.id)) as AdGroup[];

    const isCreativesDirty = form.formState.dirtyFields.creatives;

    if (isCreativesDirty) {
      await Promise.all(
        (creativesAdditionalData ?? []).map(
          async ({
            id,
            weighting,
            name,
            click_url,
            pixels,
            start_date,
            end_date,
          }) => {
            const updatedData = pick(
              lineItems.find(({ creative }) => creative === id),
              ['id', 'lineitem', 'creative', 'weighting']
            );

            await updateCreative({
              ...removeEmptyValues({
                name,
                pixels,
                click_url,
              }),
              id: updatedData.creative,
            });
            await updateLineItemCreative({
              id: updatedData.id,
              creative: updatedData.creative,
              lineitem: updatedData.lineitem,
              // only update weighting if it's weighted delivery and is different
              ...(weighting !== updatedData?.weighting &&
                isWeightedDelivery(values) && { weighting }),
              start_date,
              end_date,
            });
          }
        )
      );

      invalidateCreatives();
    }

    if (shouldShowOutOfSyncSubmitPrompt) {
      invalidateAdGroupSync();
    }

    if (state.singleEdit && campaign.status === Status.ACTIVE) {
      toCampaignDetails({ campaign });
    } else {
      goToNext({ checkDirty: false });
    }
  };

  const handleStaticDisplaySubmit = async (options = {}) => {
    const values = form.getValues();
    const { updateAdGroupParams } = options;
    const { creativesAdditionalData, ...rest } = transformToAdGroup(values, {
      bidStrategies,
      budgetPacingOptions,
      campaign,
      adGroup,
      allowedTargeting,
      isDisplay,
      filter: filter(
        adGroup.temporary ? mustIncludePostFields : mustIncludePatchFields
      ),
    });

    const res = await (!adGroup?.temporary
      ? updateStaticDisplayAdGroup({
          id: adGroup.id,
          params: updateAdGroupParams,
          ...rest,
        })
      : createStaticDisplayAdGroup({ id: adGroup.id, ...rest }));

    // temporarily disable getStaticCreativeLineItem until BE is ready

    /** TODO: move creatives hooks to typescript. */
    const lineItems = (
      allowDateTime
        ? /* @ts-expect-error js file doesn't recognize trigger params. */ await getStaticCreativeLineItem(
            res.id
          )
        : []
    ) as AdGroup[];

    if (res.id) {
      const isCreativesDirty = form.formState.dirtyFields.creatives;
      try {
        if (isCreativesDirty) {
          await Promise.all(
            res.creatives.map(async (staticDisplayCreativeId: number) => {
              const additionalData = creativesAdditionalData?.filter(
                ({ id }) => id === staticDisplayCreativeId
              )[0];

              await updateStaticDisplayCreative({
                ...removeNullValues({
                  ...additionalData,
                }),
                id: staticDisplayCreativeId,
              });

              const { start_date, end_date } = additionalData ?? {};
              const updatedData = pick(
                lineItems.find(
                  ({ creative }) => creative === staticDisplayCreativeId
                ),
                ['id', 'lineitem', 'creative']
              );

              if (allowDateTime) {
                await updateLineItemStaticCreative({
                  id: updatedData.id,
                  creative: staticDisplayCreativeId,
                  lineitem: updatedData.lineitem,
                  start_date,
                  end_date,
                });
              }
            })
          );

          invalidateDisplay();
        }

        if (shouldShowOutOfSyncSubmitPrompt) {
          invalidateStaticAdGroupSync();
        }

        if (state.singleEdit && campaign.status === Status.ACTIVE) {
          toCampaignDetails({ campaign });
        } else {
          goToNext({ checkDirty: false });
        }
      } catch (error) {
        console.error('Error updating static display creatives:', error);
      }
    }
  };

  const handleGeneralError = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' });
  };

  const submit = handleErrors(
    isDisplay ? handleStaticDisplaySubmit : handleSubmit,
    {
      setError: form.setError,
      generalError: handleGeneralError,
      generalErrorFields: [
        ...wizardGeneralErrorFields,
        fields.targeting.path,
        creativeFields.click_url.path,
        creativeFields.impression_tracking_pixel.path,
      ],
    }
  );

  const handleNormalSubmit = async () => {
    await submit();
  };

  const handleOutOfSyncSubmit = async () => {
    setDialog(
      adServerOutOfSyncPrompt({
        open: true,
        handleClose: () => {
          setDialog(null);
        },
        handleMerge: () => {
          submit();
        },
        handleOverwrite: () => {
          submit({ updateAdGroupParams: targetingOverrideParams });
        },
        outOfSyncItems: getOutOfSyncItems({ adGroup }),
      })
    );
  };

  return {
    submit: shouldShowOutOfSyncSubmitPrompt
      ? handleOutOfSyncSubmit
      : handleNormalSubmit,
    mutating:
      isCreativePostMutating ||
      isStaticCreativePostMutating ||
      isAdGroupProcessing ||
      isStaticDisplayAdGroupProcessing,
  };
};
