import { styled, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const InfoIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[8],
  marginLeft: theme.spacing(1.25),
  fontSize: '16px'
}));

export const OptionTitleWrapper = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center'
}); 
