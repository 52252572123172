import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { FiltersContextValue, FilterValue } from './types';
import { mapFilterKeysToQueryParams } from './paramMappers';

export const FiltersContext = createContext<FiltersContextValue | undefined>(undefined);

export const FiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<Record<string, FilterValue>>({
    status: 'ACTIVE,DRAFT,INACTIVE',
  });

  const addFilter = useCallback((key: string, value: FilterValue) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  }, []);

  const updateFilter = useCallback((key: string, value: FilterValue) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  }, []);

  const removeFilter = useCallback((key: string) => {
    setFilters(prev => {
      const { [key]: _, ...rest } = prev;
      return rest;
    });
  }, []);

  const clearFilters = useCallback(() => {
    setFilters({});
  }, []);

  const getQueryParams = useCallback((table: 'campaigns' | 'adGroups' | 'creatives') => {
    return mapFilterKeysToQueryParams(filters, table);
  }, [filters]);

  const value = useMemo(() => ({
    filters,
    addFilter,
    setFilters,
    updateFilter,
    removeFilter,
    clearFilters,
    getQueryParams
  }), [filters, addFilter, setFilters, updateFilter, removeFilter, clearFilters, getQueryParams]);

  return (
    <FiltersContext.Provider value={value}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = (): FiltersContextValue => {
  const context = useContext(FiltersContext);
  
  if (context === undefined) {
    throw new Error('useFilters must be used within FiltersProvider');
  }
  
  return context;
};

export default FiltersProvider;
