import { AdGroup } from '@local-types/group';
import { defaultTitle } from '../constants';

export const useGroupSectionTitles = ({ group }: { group: AdGroup }) => {
  const title = !group?.temporary ? group.name : defaultTitle;
  const subtitle = !group?.temporary ? `Ad Group ${group.id}` : null;
  const testId = `${group?.temporary ? 'temporary' : 'permanent'}-ad-group`;

  return {
    title,
    subtitle,
    testId,
  };
};
