import { useDomain } from '@hooks/domain';
import { useMemo } from 'react';
import { useFlags } from '../../../hooks';

export const useGetTableHeaders = () => {
  const domain = useDomain();
  const { flags } = useFlags();

  const headCells = useMemo(() => {
    const cells = [
      {
        id: 'id',
        numeric: true,
        sortable: true,
        label: 'ID',
        disablePadding: false,
      },
      {
        id: 'name',
        numeric: false,
        sortable: true,
        label: 'Campaign',
        disablePadding: false,
      },
      {
        id: 'start_date',
        numeric: false,
        sortable: true,
        label: 'Start date',
        disablePadding: false,
      },
      {
        id: 'end_date',
        numeric: false,
        sortable: true,
        label: 'End date',
        disablePadding: false,
      },
      {
        id: 'daily_budget',
        numeric: true,
        sortable: true,
        label: 'Daily Budget',
        disablePadding: false,
      },
      {
        id: 'spend',
        numeric: true,
        sortable: true,
        label: 'Spend',
        disablePadding: false,
      },
    ];

    if (domain.default) {
      cells.push({
        id: 'budgetRemaining',
        numeric: true,
        sortable: true,
        label: 'Budget remaining',
        disablePadding: false,
      });
    }

    cells.push(
      {
        id: 'conversions',
        numeric: true,
        sortable: true,
        label: 'Primary Outcome',
        titleTooltip: 'This is the primary outcome set for your Ad Account in Business Manager.',
        disablePadding: false,
      },
      {
        id: 'costPerVisit',
        numeric: true,
        sortable: true,
        label: 'Cost per visit',
        disablePadding: false,
      },
      {
        id: 'adServerCheck',
        sortable: false,
        label: 'Ad Server Check',
        disablePadding: false,
      },
      {
        id: 'status',
        numeric: false,
        sortable: true,
        label: 'Status',
        disablePadding: false,
      },
      {
        id: 'action',
        numeric: false,
        sortable: false,
        label: 'Action',
        disablePadding: false,
      },
    );

    return cells;
  }, [domain.default]);

  return useMemo(
    () =>
      headCells.filter(({ id }) => {
        if (id === 'adServerCheck') {
          return flags.SYNC_CHECKS_ENABLED;
        }
        return true;
      }),
    [flags.SYNC_CHECKS_ENABLED, headCells],
  );
};
