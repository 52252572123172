import {
  GeoItem,
  NormalizedGeoItems,
} from '@v2/components/campaign/GeoTargeter';

export const transformGeography = (geo: NormalizedGeoItems) =>
  Object.entries(geo).reduce(
    (acc, [, value]) => [
      ...acc,
      ...value.map(v => {
        return {
          ...v,
        };
      }),
    ],
    [] as GeoItem[]
  );
