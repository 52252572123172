import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useCallback, useMemo } from 'react';
import { BID_STRATEGIES } from '@v2/components/campaign/CampaignAdGroupSection/BidStrategy/constants';
import { BidStrategyFormValues } from '../types';

export interface BidStrategyFormOption {
  value: string | number;
  label: string;
  description: string;
  locked: boolean;
  infoText?: string;
}

export interface UseBidStrategyFormParams {
  bidStrategyOptions: BidStrategyFormOption[];
  onSubmit?: (data: BidStrategyFormValues) => void;
  defaultValues?: Partial<BidStrategyFormValues>;
}

export const useBidStrategyForm = ({
  bidStrategyOptions = [],
  onSubmit = () => {},
  defaultValues = {},
}: UseBidStrategyFormParams) => {
  const schema = useMemo(
    () =>
      yup.object({
        bidStrategyTarget: yup.mixed().when('bidStrategy', {
          is: (value: string) => {
            const option = bidStrategyOptions.find(
              opt => opt.value === value
            );
            return option?.label === BID_STRATEGIES.TARGET_COST_PER_OUTCOME;
          },
          then: yup
            .number()
            .typeError('Target Cost per Outcome must be a number')
            .required('Target Cost per Outcome is required when enabled')
            .positive('Target Cost per Outcome must be positive'),
          otherwise: yup.number().nullable(),
        }),
        bidStrategy: yup.string().required('Bid strategy is required'),
        maxCPMBid: yup
          .number()
          .nullable()
          .when('maxCPMBidEnabled', {
            is: true,
            then: yup
              .number()
              .typeError('Max CPM Bid must be a number')
              .required('Max CPM Bid is required when enabled')
              .positive('Max CPM Bid must be positive'),
            otherwise: yup.number().nullable(),
          })
          .when('bidStrategy', {
            is: (value: string) => {
              const option = bidStrategyOptions.find(
                opt => opt.value === value
              );
              return option?.label === BID_STRATEGIES.MANUAL_BID;
            },
            then: yup
              .number()
              .typeError('Max CPM Bid must be a number')
              .required('Max CPM Bid is required when enabled')
              .positive('Max CPM Bid must be positive'),
            otherwise: yup.number().nullable(),
          }),
        bidStrategyEvent: yup.mixed().when('bidStrategy', {
          is: (value: string) => {
            const option = bidStrategyOptions.find(
              opt => opt.value === value
            );
            return (
              option?.label === BID_STRATEGIES.MAX_OUTCOMES ||
              option?.label === BID_STRATEGIES.TARGET_COST_PER_OUTCOME
            );
          },
          then: yup.mixed().required('Conversion event is required'),
          otherwise: yup.mixed().nullable(),
        }),
        maxCPMBidEnabled: yup.boolean().default(false),
      }),
    [bidStrategyOptions]
  );

  const [isMaxCPMBidEnabled, setIsMaxCPMBidEnabled] = useState(
    defaultValues?.maxCPMBidEnabled || false
  );

  const resolver = yupResolver(schema) as unknown;
  const typedResolver =
    resolver as import('react-hook-form').Resolver<BidStrategyFormValues>;

  const methods = useForm<BidStrategyFormValues>({
    defaultValues: {
      bidStrategy: '',
      maxCPMBidEnabled: false,
      maxCPMBid: null,
      bidStrategyEvent: null,
      ...defaultValues,
    },
    mode: 'onChange',
    resolver: typedResolver,
  });

  const { control, handleSubmit, formState, setValue, watch } = methods;

  const { isValid } = formState;

  const bidStrategy = watch('bidStrategy');

  const selectedBidStrategy = useMemo(
    () => bidStrategyOptions.find(option => option.value === bidStrategy),
    [bidStrategyOptions, bidStrategy]
  );

  const handleCPMSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      setIsMaxCPMBidEnabled(checked);
      setValue('maxCPMBidEnabled', checked, {
        shouldDirty: true,
      });
      if (!checked) {
        setValue('maxCPMBid', null, { shouldDirty: true });
      }
    },
    [setValue]
  );

  const handleRemoveEvent = useCallback(() => {
    setValue('bidStrategyEvent', null);
  }, [setValue]);

  const submitHandler = useCallback(
    () => handleSubmit(onSubmit, e => console.error('Form error', e)),
    [handleSubmit, onSubmit]
  );

  return {
    methods,
    control,
    isValid,
    selectedBidStrategy,
    isMaxCPMBidEnabled,
    handleCPMSwitchChange,
    handleRemoveEvent,
    submitHandler,
  };
};
