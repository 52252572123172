import React, { useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@v2/components/ui/DataGrid/DataGrid';
import { defaultTheme_v2 } from '@themes/default_v2';
import { DateCellRenderer } from '@v2/components/ui/DataGrid/renderers/DateCellRenderer';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useCreativeLibrary } from './CreativeLibraryContext';
import { CreativeLibraryTitleCellRenderer } from './renderers/CreativeLibraryTitleCellRenderer';
import { CreativeLibraryAdGroupCellRenderer } from './renderers/CreativeLibraryAdGroupCellRenderer';
import { CreativeLibraryAssignCellRenderer } from './renderers/CreativeLibraryAssignCellRenderer';
import { CreativeQCStatusCellRenderer } from '@components/Creatives/renderers/CreativeQCStatusCellRenderer';
import { Scopes } from '@constants/scopes';
import { usePermissions } from '@components/hooks/permissions';
import { Button, Stack } from '@mui/material';
import { DataGridFilter } from '@v2/components/ui/DataGrid/DataGridFilter';
import { FileSizeCellRenderer } from '@v2/components/ui/DataGrid/renderers/FileSizeCellRenderer';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

export const CreativeLibraryTableV2 = () => {
  const {
    isLoading,
    creatives,
    count,
    setSelected,
    selected,
    filter,
    hasMore,
    setSize,
    setFilter,
    handleAssignSelected,
  } = useCreativeLibrary();

  const { hasPermission } = usePermissions();
  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);

  const columns = useMemo(
    () =>
      [
        {
          field: 'id',
          headerName: 'ID',
          width: 60,
          resizable: false,
          hideable: false,
        },
        {
          field: 'name',
          headerName: 'Creative Title',
          flex: 1,
          hideable: false,
          renderCell: CreativeLibraryTitleCellRenderer,
        },
        {
          field: 'adGroup',
          headerName: 'Ad Groups',
          flex: 1,
          renderCell: CreativeLibraryAdGroupCellRenderer,
          resizable: false,
          hideable: false,
        },
        {
          field: 'resolution',
          headerName: 'Resolution',
          minWidth: 90,
        },
        {
          field: 'file_size_bytes',
          headerName: 'File Size',
          minWidth: 90,
          align: 'right',
          renderCell: FileSizeCellRenderer,
        },
        {
          field: 'media_upload_date',
          headerName: 'Uploaded',
          renderCell: DateCellRenderer,
          minWidth: 120,
          resizable: false,
          hideable: false,
        },
        showQCFunctionality && {
          field: 'active',
          headerName: '',
          renderCell: CreativeQCStatusCellRenderer,
          width: 60,
          resizable: false,
          hideable: false,
        },
        {
          field: 'assigned',
          headerName: 'Assign',
          renderCell: CreativeLibraryAssignCellRenderer,
          width: 120,
          resizable: false,
          hideable: false,
        },
      ].filter(Boolean) as GridColDef[],
    []
  );

  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <Stack sx={{ height: '50vh' }}>
        <DataGridFilter
          filter={filter}
          onFilterChange={setFilter}
          placeholder="Search creatives..."
        >
          <Button
            disabled={selected.length === 0}
            color="secondary"
            onClick={handleAssignSelected}
            startIcon={<PlayCircleFilledIcon />}
            data-testid="assign-button"
            sx={{
              color: 'primary.main',
              borderStyle: 'solid',
              borderColor: 'grey.300',
              borderRadius: 0,
              borderLeftWidth: 1,
            }}
            variant="text"
            size="small"
          >
            Assign
          </Button>
        </DataGridFilter>
        <DataGrid
          hasHeader
          checkboxSelection
          loading={isLoading}
          rows={creatives}
          rowCount={count}
          columns={columns}
          disableRowSelectionOnClick
          rowSelectionModel={selected}
          onRowSelectionModelChange={newSelections => {
            setSelected([...newSelections] as number[]);
          }}
          scrollEndThreshold={200}
          onRowsScrollEnd={() => {
            if (hasMore && !isLoading) {
              setSize(size => size + 1);
            }
          }}
        />
      </Stack>
    </ThemeProvider>
  );
};
