import useOutcomes from '@apis/outcomes';
import useRevenue from '@apis/revenue';
import useRoas from '@apis/roas';
import useReach from '@apis/reach';
import useSpend from '@components/hooks/apis/spend';
import AdvertiserContext from '@components/AdvertiserContext';
import { useContext, useEffect } from 'react';
import { ReportingHomeData, UseGetReportingHomeDataProps } from '../types';

export const useGetReportingHomeData = ({ dateFilter }: UseGetReportingHomeDataProps) => {
  const adContext = useContext(AdvertiserContext);

  const {
    data: outcomes,
    isLoading: outcomeLoading,
    error: outcomeError,
    isValidating: outcomeIsValidating,
    mutate: mutateOutcomes,
  } = useOutcomes({ dateFilter });

  const {
    data: revenue,
    isLoading: revenueLoading,
    error: revenueError,
    isValidating: revenueIsValidating,
    mutate: mutateRevenue,
  } = useRevenue({ dateFilter });

  const {
    data: roas,
    isLoading: roasLoading,
    error: roasError,
    isValidating: roasIsValidating,
    mutate: mutateRoas,
  } = useRoas({ dateFilter })

  const {
    data: reach,
    isLoading: reachLoading,
    error: reachError,
    isValidating: reachIsValidating,
    mutate: mutateReach,
  } = useReach({ dateFilter });

  const {
    data: spend,
    isLoading: spendLoading,
    error: spendError,
    isValidating: spendIsValidating,
    mutate: mutateSpend,
  } = useSpend({ dateFilter })

  const isValidating: { [key: string]: boolean } = {
    outcomes: outcomeIsValidating,
    revenue: revenueIsValidating,
    roas: roasIsValidating,
    reach: reachIsValidating,
    spend: spendIsValidating,
  };

  const isLoading: { [key: string]: boolean } = {
    outcomes: outcomeLoading,
    revenue: revenueLoading,
    roas: roasLoading,
    reach: reachLoading,
    spend: spendLoading,
    all: spendLoading || outcomeLoading || revenueLoading || roasLoading || reachLoading,
  };

  useEffect(() => {
    if (isLoading.all) return;
    mutateOutcomes();
    mutateRevenue();
    mutateRoas();
    mutateReach();
    mutateSpend();
  }, [adContext]);

  const error = outcomeError || revenueError || roasError || reachError || spendError;

  const data: { [key: string]: ReportingHomeData | undefined } = {
    outcomes,
    revenue,
    roas,
    reach,
    spend,
  };

  return {
    data,
    error,
    isLoading,
    isValidating,
  };
};

export default useGetReportingHomeData;
