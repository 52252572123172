import { useState, useEffect, useMemo } from 'react';
import { getDateTime, checkForDatetimeGaps } from '../utils';
import { fields as manageFields } from '../constants';
import { fields } from '../../constants';
import { useGroupSectionContext } from '@components/WizardContainers/GroupSection/hooks/useGroupSectionContext';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { useFormContext } from 'react-hook-form';
import { type Moment } from 'moment';

export const useCreativeGapsCheck = () => {
  const [gaps, setGaps] = useState<ReturnType<typeof checkForDatetimeGaps>>(
    []
  );
  const {
    group: {
      start_date: groupStartDate,
      start_time: groupStartTime,
      end_date: groupEndDate,
      end_time: groupEndTime,
    },
  } = useGroupSectionContext();
  const { watch } = useFormContext();

  const draftCreatives = watch(fields.draftCreatives.path);

  const adGroupDatetimeRange: [Moment, Moment | null] = useMemo(
    () => [
      getDateTime(groupStartDate, groupStartTime) as Moment,
      getDateTime(groupEndDate, groupEndTime),
    ],
    [groupStartDate, groupStartTime, groupEndDate, groupEndTime]
  );

  const { releaseCreativeStartEndDatetime: allowDateTime } = useLDFlags();
  const datetimeFields = draftCreatives.flatMap(
    (_: unknown, index: number) => [
      `${fields.draftCreatives.path}.${index}.${manageFields.start_date.path}`,
      `${fields.draftCreatives.path}.${index}.${manageFields.end_date.path}`,
    ]
  );

  const flatDatetimes = watch(datetimeFields);
  const datetimes = flatDatetimes.reduce(
    (
      acc: [Moment | null, Moment | null][],
      _: Moment | null,
      i: number,
      arr: (Moment | null)[]
    ) => (i % 2 === 0 ? [...acc, [arr[i], arr[i + 1]]] : acc),
    []
  );

  const checkForGaps = () => {
    if (!allowDateTime) return [];
    return checkForDatetimeGaps(datetimes, adGroupDatetimeRange);
  };

  const flatDatetimesDep = flatDatetimes
    .map((dt: Moment | null) => dt?.format('YYYY-MM-DD HH:mm'))
    .join(',');

  useEffect(() => {
    setGaps(checkForGaps());
  }, [flatDatetimesDep, adGroupDatetimeRange]);

  return {
    gaps,
    setGaps,
  };
};
