import React, { useEffect, useRef } from 'react';
import {
  FirstCellVirtualizedStyled,
  VirtualizedHeaderRowStyled,
} from '../styles';

import { AutoSizer, Table } from 'react-virtualized';
import { useTableVirtualizedData } from '../hooks/useTableVirtualizedData';
import { useVirtualizedRenderer } from './hooks/useVirtualizedRenderer';
import { useTableSide } from '../hooks/useTableSide';
import { TableLeftProps } from './types';
import { TableData } from '../types';

export const TableLeftVirtualized = <Value, Data extends TableData<Value>>({
  data = [],
  dataName,
  actualAmount,
  onChange,
  renderCell,
  renderGroup,
  PlaceholderGroup,
  isTargeted,
  PlaceholderCell,
}: TableLeftProps<Value, Data>) => {
  const tableRef = useRef<Table>(null);

  const { evenData } = useTableSide(data, {
    actualAmount,
  });
  const { list, getRowHeight } = useTableVirtualizedData({
    data: evenData as Value[],
  });

  const rowRenderer = useVirtualizedRenderer<Value, Data>({
    onChange,
    renderCell,
    renderGroup,
    PlaceholderCell,
    PlaceholderGroup,
    isTargeted,
  });

  useEffect(() => {
    tableRef.current?.recomputeRowHeights();
  }, [list]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          width={width}
          height={height}
          headerHeight={74}
          rowHeight={getRowHeight}
          ref={tableRef}
          headerRowRenderer={props => (
            <VirtualizedHeaderRowStyled {...props}>
              <FirstCellVirtualizedStyled
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                {dataName}
              </FirstCellVirtualizedStyled>
            </VirtualizedHeaderRowStyled>
          )}
          rowCount={list.length}
          rowGetter={({ index }) => list[index]}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );
};
