import React from 'react';
import { EntityStatusType } from '@local-types';
import { Status } from '@constants';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box } from '@mui/material';

export const CampaignPauseActivateRenderer = ({
  status,
}: {
  status: EntityStatusType;
}) => {
  if (status === Status.ACTIVE) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <PauseCircleOutlineOutlinedIcon fontSize="small" />
        Pause Campaign
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
      <PlayCircleOutlineIcon fontSize="small" />
      Activate Campaign
    </Box>
  );
};
