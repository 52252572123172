import { GROUP_TYPE, useGroups } from '@apis/groups';

export const useWizardPageGroups = ({
  campaignId,
  suspense = false,
}: {
  campaignId?: number;
  suspense?: boolean;
}) => {
  const groups = useGroups(GROUP_TYPE.AD_GROUP, campaignId, {
    swrParams: {
      suspense,
    },
  });

  const retargetingGroups = useGroups(GROUP_TYPE.STATIC_GROUP, campaignId, {
    swrParams: {
      suspense,
    },
  });

  return {
    groups,
    retargetingGroups,
  };
};
