import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItemIcon, MenuItem, TextField } from '@mui/material';
import { useRBACContext } from '@providers/RBACContext';
import { useController } from 'react-hook-form';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { AdvertiserRoleLabelMap } from '@constants';

export const AdvertiserUserRoleSelect = ({
  onChange,
  value,
  allowDeleted,
}) => {
  const { roles } = useRBACContext();

  if (!roles.advertiser_roles?.length) return AdvertiserRoleLabelMap[value] || value;

  return (
    <TextField
      variant="standard"
      select
      color="secondary"
      onChange={onChange}
      value={value}
      SelectProps={{
        renderValue: innerValue => AdvertiserRoleLabelMap[innerValue] || AdvertiserRoleLabelMap[value] || innerValue,
      }}
    >
      {(roles?.advertiser_roles || [])?.map(item => (
        <MenuItem value={item.value} key={item.value}>
          <ListItemIcon>
            <PersonOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          {value !== item.value && <>Make </>}
          {item.label}
        </MenuItem>
      ))}

      {allowDeleted && (
        <MenuItem value="REMOVED">
          <Box style={{ color: '#fb8b99' }}>
            <ListItemIcon>
              <DeleteOutlineOutlinedIcon
                fontSize="small"
                style={{ color: '#fb8b99', fill: '#fb8b99' }}
              />
            </ListItemIcon>
            Remove User
          </Box>
        </MenuItem>
      )}
    </TextField>
  );
};

export const RHFAdvertiserUserRoleSelect = ({ name, control, ...rest }) => {
  const { field } = useController({
    control,
    name,
  });

  return <AdvertiserUserRoleSelect {...rest} {...field} />;
};


AdvertiserUserRoleSelect.propTypes = {
  allowDeleted: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

RHFAdvertiserUserRoleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  ...AdvertiserUserRoleSelect.propTypes,
};
