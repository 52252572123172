import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaults } from 'lodash';
import { Button, Grid, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { DefaultValues, FormFields } from './constants';
import { RHFSelect, RHFTextField } from '@components/form';
import stateOptions from '../../../../utilStates';

const GeneralInfoForm = ({ data, onSubmit }) => {
  const {
    control,
    formState,
    setError,
    handleSubmit,
    reset,
  } = useForm({
    values: defaults(data, DefaultValues),
    resolver: yupResolver(object().shape({
      [FormFields.NAME]: string(),
      [FormFields.ADDRESS_1]: string().required('Required'),
      [FormFields.ADDRESS_2]: string(),
      [FormFields.CITY]: string().required('Required'),
      [FormFields.STATE]: string().required('Required'),
      [FormFields.ZIP]: string().required('Required'),
      [FormFields.PHONE]: string().required('Required'),
      [FormFields.DBA]: string(),
    })),
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const handleCancel = () => {
    reset(data);
  };

  return (
    <Stack
      component="form"
      sx={{
        justifyContent: 'space-between',
        height: '100%',
      }}
      onSubmit={handleSubmit((data) => onSubmit(data, setError))}
    >
      <Grid container spacing={10}>
        <Grid item xs={6}>
          <Stack spacing={3}>
            <Typography variant="h4">Company Address</Typography>

            <RHFTextField
              disabled
              label="Legal Company Name"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockOutlinedIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              control={control}
              name={FormFields.NAME}
            />

            <RHFTextField
              label="Street Address"
              control={control}
              name={FormFields.ADDRESS_1}
            />

            <RHFTextField
              label="Street Address 2"
              control={control}
              name={FormFields.ADDRESS_2}
            />

            <RHFTextField
              label="City"
              control={control}
              name={FormFields.CITY}
            />

            <Stack
              direction="row"
              spacing={1}
              sx={{
                pt: 3,
                mb: 3,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <RHFSelect
                label="State"
                control={control}
                name={FormFields.STATE}
                sx={{ width: '65%' }}
              >
                {stateOptions.map(m => (
                  <MenuItem key={m.abbreviation} value={m.abbreviation}>
                    {m.name}
                  </MenuItem>
                ))}
              </RHFSelect>

              <RHFTextField
                label="Zip Code"
                control={control}
                name={FormFields.ZIP}
                sx={{ maxWidth: '30%' }}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Stack spacing={3}>
            <Typography variant="h4">Other Company Information</Typography>

            <RHFTextField
              label="Company Phone Number"
              control={control}
              name={FormFields.PHONE}
            />

            <RHFTextField
              label="DBA / Trade Name (optional)"
              control={control}
              name={FormFields.DBA}
            />
          </Stack>
        </Grid>
      </Grid>

      <Stack
        direction="row"
        spacing={2}
        sx={{
          textAlign: 'right',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {formState.isSubmitSuccessful && (
          <Typography component="span" sx={{ color: 'primary.light' }}>
            Your Settings have been saved!
          </Typography>
        )}

        <Button
          disabled={formState.isSubmitting}
          onClick={handleCancel}
          disableElevation
          disableRipple
          type="reset"
        >
          Cancel
        </Button>

        <Button
          disabled={formState.isSubmitting}
          disableElevation={formState.isSubmitSuccessful}
          disableRipple
          variant="contained"
          size="large"
          type="submit"
        >
          {formState.isSubmitting ? 'Saving...' : 'Save Settings'}
        </Button>
      </Stack>
    </Stack>
  );
};

GeneralInfoForm.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default GeneralInfoForm;
