import type { Creative } from '@local-types/creative';
import { fields } from '../formConfig';
import { weightingRotationOptions } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedCreative';
import type { Fields } from '@local-types/general';

export const mapCreativesToAdditionalData = (creatives: Creative[]) =>
  creatives.map(
    ({
      creative,
      lineitem,
      weighting,
      id,
      name,
      click_url,
      pixels,
      start_date,
      end_date,
    }) => ({
      id,
      creative,
      lineitem,
      weighting,
      click_url: click_url ? click_url : null,
      name,
      pixels,
      start_date: start_date ? start_date : null,
      end_date: end_date ? end_date : null,
    })
  );

export const isWeightedDelivery = (formData: Fields<typeof fields>) => {
  return (
    formData[fields.weightingRotation.path] ===
    weightingRotationOptions.weighted
  );
};
