import useSWRMutation from 'swr/mutation';
import useSWR, { useSWRConfig } from 'swr';

import { useCurrentSession } from '../currentSession';
import useSWRInfinite from 'swr/infinite';
import { useMemo } from 'react';

const buildCampaignCacheKey = (
  campaignId,
  currentAdvertiser,
  params = {}
) => {
  const url = `/campaigns/${campaignId}/`;

  return { url, advertiser: currentAdvertiser.id ?? '-', ...params };
};

export const useGetCampaign = (campaignId, options) => {
  const { get, apiIsReady, currentAdvertiser } = useCurrentSession();

  const fetcher = ({ url, params }) =>
    get(url, { params }).then(res => res.data);

  const swr = useSWR(
    apiIsReady && campaignId
      ? buildCampaignCacheKey(campaignId, currentAdvertiser)
      : null,
    fetcher,
    options
  );

  const { data, error, isLoading } = swr;

  return {
    campaign: data,
    error,
    isLoading,
  };
};

export const usePostCampaign = () => {
  const { post, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { update: updateCampaignsCache } = useGetCampaigns();
  const { mutate } = useSWRConfig();

  const postCampaign = ({ url }, { arg }) =>
    post(url, arg).then(res => res.data);

  const url = '/campaigns/';

  const options = {
    onSuccess: newCampaign => {
      const key = buildCampaignCacheKey(newCampaign.id, currentAdvertiser);
      updateCampaignsCache(newCampaign, true);
      mutate(key, newCampaign, { populateCache: true });
    },
    revalidate: false,
  };

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? { url } : null,
    postCampaign,
    options
  );

  return { trigger, isMutating };
};

export const usePatchCampaign = campaignId => {
  const { patch, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { update: updateCampaignsCache } = useGetCampaigns();
  const { mutate } = useSWRConfig();

  const updateCampaign = ({ url }, { arg }) =>
    patch(url, arg).then(res => res.data);

  const url = `/campaigns/${campaignId}/`;

  const options = {
    onSuccess: updatedCampaign => {
      const key = buildCampaignCacheKey(
        updatedCampaign.id,
        currentAdvertiser
      );
      updateCampaignsCache(updatedCampaign);
      mutate(key, updatedCampaign, { populateCache: true });
    },
    revalidate: false,
  };

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? { url } : null,
    updateCampaign,
    options
  );

  return { trigger, isMutating };
};

export const useDuplicateCampaign = () => {
  const { post, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { update: updateCampaignsCache } = useGetCampaigns();
  const { mutate } = useSWRConfig();

  const duplicateCampaign = async (
    key,
    { arg: { campaignId, data } }
  ) => {
    const url = `/campaigns/${campaignId}/duplicate/`;
    const response = await post(url, data);
    return response.data;
  };

  const options = {
    onSuccess: (newCampaign) => {
      // If the response includes an ID, update the cache
      if (newCampaign?.id) {
        const key = buildCampaignCacheKey(
          newCampaign.id,
          currentAdvertiser
        );
        updateCampaignsCache(newCampaign, true);
        mutate(key, newCampaign, { populateCache: true });
      }
    },
    revalidate: false,
  };

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? '/campaigns/duplicate/' : null,
    duplicateCampaign,
    options
  );

  return { trigger, isMutating };
};

export const useArchiveUnarchiveCampaign = () => {
  const { post, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { update: updateCampaignsCache } = useGetCampaigns();
  const { mutate } = useSWRConfig();

  const archiveUnarchiveCampaign = async (
    key,
    { arg: { campaign, isArchiving } }
  ) => {
    const url = isArchiving
      ? `/campaigns/${campaign?.id}/archive/`
      : `/campaigns/${campaign?.id}/unarchive/`;
    await post(url);
    return { campaign, isArchiving };
  };

  const options = {
    onSuccess: ({ campaign, isArchiving }) => {
      const updatedCampaign = { ...campaign, archived: isArchiving };
      const key = buildCampaignCacheKey(
        updatedCampaign.id,
        currentAdvertiser
      );
      updateCampaignsCache(updatedCampaign);
      mutate(key, updatedCampaign, { populateCache: true });
    },
    revalidate: false,
  };

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? '/campaigns/archive-unarchive/' : null,
    archiveUnarchiveCampaign,
    options
  );

  return { trigger, isMutating };
};

export const useGetCampaigns = (params, options) => {
  const { get, patch, apiIsReady, currentAdvertiser } = useCurrentSession();

  const { data, error, isLoading, isValidating, setSize, size, mutate } =
    useSWRInfinite(
      (index, previousPageData) => {
        if (!apiIsReady || (previousPageData && !previousPageData.next))
          return null;

        return {
          url: '/campaigns/',
          advertiser: currentAdvertiser.id ?? '-',
          params: {
            page_size: 25,
            ...(index && { page: index + 1 }),
            ordering: params.ordering ?? 'status,-start_date',
            ...params,
          },
        };
      },
      async ({ url, params }) => {
        const res = await get(url, params);
        // Transform data to match the V1 endpoint properties
        const transformedData = {
          ...res.data,
          results: res.data.results.map(campaign => ({
            ...campaign,
            advertiser_id: campaign.advertiser,
            advertiser: campaign.advertiser_url,
          })),
        };
        return transformedData;
      },
      {
        // Need revalidateFirstPage:true to pick up new campaigns after they are created
        revalidateFirstPage: true,
        revalidateOnFocus: false,
        revalidateOnMount: true,
        ...options,
      }
    );

  const items = useMemo(
    () => (data ? [].concat(...data.map(page => page?.results ?? [])) : []),
    [data]
  );

  const totalItems = data?.[0]?.count;

  // Remove a campaign from the cache (for when we perform a delete)
  const remove = campaignId => {
    if (campaignId) {
      return mutate(
        prevData => {
          return prevData.map(page => {
            return {
              ...page,
              results: page.results?.filter(
                campaign => campaignId !== campaign.id
              ),
            };
          });
        },
        { revalidate: false }
      );
    }
  };

  // Patch a campaign into the existing cache.  If we find the campaign in the cache, we updated it,
  // Otherwise we invalidate the data and reload
  const update = (campaign, revalidate = false) => {
    if (campaign) {
      return mutate(
        prevData => {
          if (prevData) {
            let found = false;

            const updatedData = prevData.map(page => {
              return {
                ...page,
                results:
                  page.results?.map(result => {
                    if (result.id === campaign.id) {
                      found = true;
                      return { ...result, ...campaign };
                    }
                    return result;
                  }) ?? null,
              };
            });

            // If we didn't find the campaign, we need to insert the new campaign into the results
            if (!found && prevData) {
              return prevData.map((page, index) => {
                if (index === 0) {
                  return { ...page, results: [campaign, ...page.results] };
                }
                return page;
              });
            }

            return updatedData;
          }
        },
        { revalidate }
      );
    }
  };

  const { trigger: patchCampaign } = useSWRMutation(
    apiIsReady ? { url: `/campaigns/` } : null,
    ({ url }, { arg: { id, body } }) => {
      return patch(`${url}${id}/`, body).then(res => res.data);
    },
    {
      onSuccess: data => {
        return update(data);
      },
      revalidate: false,
    }
  );

  return {
    items,
    data,
    setSize,
    size,
    error,
    isLoading,
    isValidating,
    mutate,
    update,
    remove,
    totalItems,
    patchCampaign,
    hasMore:
      totalItems === undefined ||
      (totalItems > 0 && items?.length < totalItems),
  };
};
