import { map } from 'lodash';
import { useGetAdGroup } from '@components/hooks/apis/adGroups';
import { AdGroupCreativeTableContext } from './AdGroupCreativeTableContext';
import { useGetCreativeLineItems } from '@components/hooks/apis/creatives';
import { AdGroupCreativeTableContextProviderProps } from './types';
import { useGetColumns } from './hooks/useGetColumns';

export const AdGroupCreativeTableContextProvider = ({
  children,
  campaignId,
  adGroupId,
  ...rest
}: AdGroupCreativeTableContextProviderProps) => {
  const { adGroup } = useGetAdGroup(adGroupId);
  const {
    data: creativeLineItems,
    isLoading,
  } = useGetCreativeLineItems(adGroupId, true);
  const { columns } = useGetColumns({ adGroup });

  const creatives = map(creativeLineItems, 'creative_object');

  return (
    <AdGroupCreativeTableContext.Provider
      value={{
        ...rest,
        adGroup,
        adGroupsCreatives: creatives,
        creativeLineItems: creativeLineItems ?? [],
        campaignId,
        adGroupId,
        rowCount: creativeLineItems?.length ?? 0,
        isLoading,
        columns,
      }}
    >
      {children}
    </AdGroupCreativeTableContext.Provider>
  );
};
