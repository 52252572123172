import useSWR from "swr";
import { useCurrentSession } from "../currentSession";
import useSWRMutation from "swr/mutation";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";

const usePostReportConfig = ({ onSuccess, accountId }: { onSuccess?: () => void, accountId?: number }) => {
  const { postV1, apiIsReady } = useCurrentSession();
  const fetcher = (url: string, { arg }: {
    arg: { accountId?: number }
  }) => {
    const { accountId } = arg;
    const finalUrl = `advertisers/${arg.accountId ?? accountId ?? 'me'}/reportconfig/`;
    return postV1(finalUrl, {}).then(res => res.data);
  };

  const {
    trigger,
    isMutating,
  } = useSWRMutation(
    apiIsReady ? `advertisers/${accountId ?? 'me'}/reportconfig/` : null,
    fetcher,
    { onSuccess }
  );

  return {
    postReportConfig: (data: { accountId?: number }) => trigger(data),
    isMutating,
  };
};

const useGetReportConfig = (accountId?: number) => {
  const [initialLoaded, setInitialLoaded] = useState(false);
  const { getV1, apiIsReady } = useCurrentSession();
  const { reportsAdmin } = useFlags();


  const fetcher = () =>
    getV1(`advertisers/${accountId ?? 'me'}/reportconfig/`).then(res => res.data);

  const {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  } = useSWR(
    apiIsReady && reportsAdmin ? "advertisers/me/reportconfig/" : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  useEffect(() => {
    if (data && !initialLoaded && !isLoading) {
      setInitialLoaded(true);
    }
  }, [data, isLoading])

  const formattedData = data ? {
    adAccount: data.ad_account,
    measurementLevel: data.last_level_applied,
    campaignPerformance: data.show_campaign_performance,
    pathToPurchase: data.show_path_to_purchase,
    postConversions: data.show_post_conversions,
    spikeLift: data.show_spike_lift,
    alwaysOnIncrementality: data.show_always_on_incrementality,
  } : null

  return {
    data: formattedData,
    isLoading,
    isValidating,
    error,
    mutate,
    initialLoaded,
  };
};

const usePatchReportConfig = ({ accountId, onSuccess }: { accountId?: number, onSuccess?: () => void }) => {
  const { patchV1, apiIsReady } = useCurrentSession();

  const fetcher = (url: string, { arg }: {
    arg: {
      measurementLevel: string,
      campaignPerformance: boolean,
      pathToPurchase: boolean,
      postConversions: boolean,
      spikeLift: boolean,
      alwaysOnIncrementality: boolean,
    }
  }) => {
    const formattedArgs = {
      "measurement_level": arg.measurementLevel,
      "show_campaign_performance": arg.campaignPerformance,
      "show_path_to_purchase": arg.pathToPurchase,
      "show_post_conversions": arg.postConversions,
      "show_spike_lift": arg.spikeLift,
      "show_always_on_incrementality": arg.alwaysOnIncrementality,
    }
    return patchV1(url, formattedArgs).then(res => res.data);
  }

  const {
    trigger,
    isMutating,
  } = useSWRMutation(
    apiIsReady ? `advertisers/${accountId ?? 'me'}/reportconfig/` : null,
    fetcher,
    {
      onSuccess,
    }
  )
  return {
    patchReportConfig: trigger,
    isMutating,
  };
}

export default { post: usePostReportConfig, get: useGetReportConfig, patch: usePatchReportConfig };
