import { Typography, Toolbar, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@v2/components/ui/DataGrid/DataGrid';
import { defaultTheme_v2 } from '@themes/default_v2';
import { Scopes } from '@constants/scopes';
import { useAdGroupCreativeTable } from './hooks/useAdGroupCreativeTable';
import { usePermissions } from '@components/hooks';
import { CreativeQCAlerts } from '@components/CreativeQCAlerts';

export const AdGroupCreativeTableV2 = () => {
  const {
    adGroupsCreatives,
    creativeLineItems,
    rowCount,
    isLoading,
    columns,
  } = useAdGroupCreativeTable();
  const { hasPermission } = usePermissions();
  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);

  return (
    <ThemeProvider theme={defaultTheme_v2}>
      {showQCFunctionality && (
        <CreativeQCAlerts
          email={'support@tvscientific.com'}
          items={adGroupsCreatives || []}
        />
      )}
      <Box
        border={1}
        borderColor="grey.300"
        p={6}
        pt={2}
        m={4}
        borderRadius="20px"
      >
        <Toolbar disableGutters>
          <Typography variant="h6" component="div">
            Creative
          </Typography>
        </Toolbar>
        <DataGrid
          loading={isLoading}
          rows={creativeLineItems}
          hideFooter
          rowCount={rowCount}
          columns={columns}
          disableRowSelectionOnClick
          initialState={{
            pinnedColumns: {
              left: ['id', 'name', 'active'],
            },
          }}
        />
      </Box>
    </ThemeProvider>
  );
};
