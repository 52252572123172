import { CircularProgress, Stack } from '@mui/material';
import { Info } from '@v2/components/campaign/Info';
import { useForm, type FieldValues, type Resolver } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditAdGroupDatesTimesProps } from '../constants';
import moment from 'moment';
import { useEffect } from 'react';
import { getSchema } from '../validations';
import { RHFDatePicker, RHFTimePicker } from '@components/form';
import { useBulkEdit, BulkEditDatesTimesFormData } from '../context';
import {
  formatDateTimeForApi,
  useBulkValidate,
} from '@components/hooks/apis/bulk';
import {
  EditAdGroupDatesTimesFormValues,
  FormattedDates,
} from '../constants/types';

function createValidationResolver<T extends FieldValues>(
  schema: yup.AnyObjectSchema
): Resolver<T> {
  return yupResolver(schema) as Resolver<T>;
}

export const EditAdGroupDatesTimes = ({
  alreadyStarted = false,
  setNextButtonState,
  selectedAdGroupIds = [],
  fieldValues,
  campaign,
  onValidationSuccess,
}: EditAdGroupDatesTimesProps) => {
  const schema = getSchema(alreadyStarted, campaign);

  const { setDatesTimesFormData, setValidationResult, setValidationState } =
    useBulkEdit();

  const { trigger: validateBulk, isMutating: isValidating } = useBulkValidate(
    {
      onSuccess: data => {
        setValidationResult(data);
        setValidationState(false, null);

        const allSuccess = data.results.every(
          result => result.status === 'SUCCESS'
        );

        setNextButtonState(allSuccess, {
          submit: () => {
            handleSubmit(onSubmit)();
          },
        });
      },
      onError: error => {
        setValidationState(false, error);
        setNextButtonState(false);
      },
    }
  );

  const getDefaultValues = () => {
    if (!fieldValues || fieldValues === 'None') return undefined;

    const parseToMoment = (dateTime: string | null | undefined) =>
      dateTime === null || dateTime === undefined ? null : moment(dateTime);

    return {
      startDate: parseToMoment(fieldValues.start_date),
      startTime: parseToMoment(fieldValues.start_date),
      endDate: parseToMoment(fieldValues.end_date),
      endTime: parseToMoment(fieldValues.end_date),
    };
  };

  const methods = useForm<EditAdGroupDatesTimesFormValues>({
    defaultValues: getDefaultValues(),
    mode: 'onChange',
    resetOptions: {
      keepDirtyValues: true,
    },
    resolver:
      createValidationResolver<EditAdGroupDatesTimesFormValues>(schema),
  });

  const dateTimeFields: Array<keyof EditAdGroupDatesTimesFormValues> = [
    'startDate',
    'startTime',
    'endDate',
    'endTime',
  ];

  const {
    handleSubmit,
    formState: { isValid, dirtyFields, isDirty },
    trigger,
    watch,
  } = methods;

  const [startDate, startTime, endDate, endTime] = watch(dateTimeFields);

  useEffect(() => {
    if (dateTimeFields.some(field => dirtyFields[field])) {
      trigger(dateTimeFields);
    }
  }, [startDate, startTime, endDate, endTime, trigger, dirtyFields]);

  useEffect(() => {
    if (isDirty) {
      const formData: BulkEditDatesTimesFormData = {
        startDate:
          typeof startDate === 'string' ? moment(startDate) : startDate,
        startTime:
          typeof startTime === 'string'
            ? startTime
            : startTime instanceof moment
              ? startTime.format('HH:mm:ss')
              : null,
        endDate: typeof endDate === 'string' ? moment(endDate) : endDate,
        endTime:
          typeof endTime === 'string'
            ? endTime
            : endTime instanceof moment
              ? endTime.format('HH:mm:ss')
              : null,
      };

      setDatesTimesFormData(formData);
    }
  }, [
    startDate,
    startTime,
    endDate,
    endTime,
    isDirty,
    setDatesTimesFormData,
  ]);

  const onSubmit = (formData: EditAdGroupDatesTimesFormValues) => {
    if (!selectedAdGroupIds || selectedAdGroupIds.length === 0) {
      setValidationState(
        false,
        new Error('No ad groups selected for validation')
      );
      return;
    }

    const formattedStartDateTime = formatDateTimeForApi(
      formData.startDate,
      formData.startTime
    );

    const formattedEndDateTime = formatDateTimeForApi(
      formData.endDate,
      formData.endTime
    );

    const formattedDates: FormattedDates = {
      end_date: formattedEndDateTime,
    };

    if (!alreadyStarted) {
      formattedDates.start_date = formattedStartDateTime;
    }

    setValidationState(true, null);

    try {
      const validationPromise = validateBulk({
        type: 'LINEITEM',
        select: {
          id__in: selectedAdGroupIds,
        },
        fields: formattedDates,
      });

      validationPromise.then(data => {
        const allSuccess = data.results.every(
          result => result.status === 'SUCCESS'
        );

        if (allSuccess && onValidationSuccess) {
          onValidationSuccess(formattedDates);
        }
      });
    } catch (error) {
      setValidationState(
        false,
        error instanceof Error ? error : new Error(String(error))
      );
    }
  };

  useEffect(() => {
    setNextButtonState(isValid && !isValidating, {
      submit: () => {
        handleSubmit(onSubmit)();
      },
    });
  }, [isValid, isValidating, handleSubmit, setNextButtonState, onSubmit]);

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ height: '100%', position: 'relative' }}
    >
      {isValidating && (
        <Stack
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            zIndex: 1,
          }}
        >
          <CircularProgress size={40} />
        </Stack>
      )}

      <Stack spacing={3} sx={{ alignItems: 'center', px: 3, py: 2 }}>
        {alreadyStarted && (
          <Info direction="column" type="info">
            Start Date and Start Time are locked because one or more of the
            selected ad groups have been active.
          </Info>
        )}

        <Stack
          direction="row"
          spacing={3}
          sx={{ justifyContent: 'center', width: '50%' }}
        >
          <RHFDatePicker<EditAdGroupDatesTimesFormValues>
            name="startDate"
            control={methods.control}
            label={alreadyStarted ? 'Multiple' : 'Start Date'}
            disabled={alreadyStarted || isValidating}
            lockIcon={alreadyStarted}
            sx={{ flex: 1 }}
            testId="start-date"
          />
          <RHFTimePicker<EditAdGroupDatesTimesFormValues>
            name="startTime"
            control={methods.control}
            label={alreadyStarted ? 'Multiple' : 'Start Time (ET)'}
            disabled={alreadyStarted || isValidating}
            lockIcon={alreadyStarted}
            sx={{ flex: 1 }}
            testId="start-time"
          />
        </Stack>

        <Stack
          direction="row"
          spacing={3}
          sx={{ justifyContent: 'center', width: '50%' }}
        >
          <RHFDatePicker<EditAdGroupDatesTimesFormValues>
            name="endDate"
            control={methods.control}
            label="End Date"
            clearable
            disabled={isValidating}
            sx={{ flex: 1 }}
            testId="end-date"
          />
          <RHFTimePicker<EditAdGroupDatesTimesFormValues>
            name="endTime"
            control={methods.control}
            label="End Time"
            clearable
            sx={{ flex: 1 }}
            disabled={!endDate || isValidating}
            testId="end-time"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
