import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Box, Chip, useTheme } from '@mui/material';
import { Display } from '@local-types';
import { Status } from '@constants';

export const DisplayStatusCellRenderer = (
  params: GridCellParams<Display, boolean>
) => {
  const theme = useTheme();
  const { palette } = theme;

  if (!params.row) {
    return null;
  }

  const { status } = params.row;
  const isArchived = status === Status.ARCHIVED;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
        gap: 2,
      }}
    >
      {isArchived && (
        <Chip
          label="Archived"
          data-testid="archived-creative"
          sx={{
            color: '#77858c',
            background: '#EDF1F2',
            width: 90,
          }}
        />
      )}

      {status === Status.ACTIVE && (
        <Chip
          label="Active"
          data-testid="active-creative"
          sx={{
            color: palette.green?.['6'],
            width: 90,
            background: palette.green?.['10'],
          }}
        />
      )}

      {status === Status.INACTIVE && (
        <Chip
          label="Inactive"
          data-testid="inactive-creative"
          sx={{
            width: 90,
            color: palette.blue?.['6'],
            background: palette.blue?.['10'],
          }}
        />
      )}

      {status === Status.PENDING && (
        <Chip
          label="Pending"
          data-testid="pending-creative"
          sx={{
            width: '100%',
            color: theme.palette.grey['6'],
            background: theme.palette.grey['11'],
          }}
        />
      )}
    </Box>
  );
};
