import { findIndex } from 'lodash';
import { getBaseColumns, startEndDateColumns } from '../constants';
import { UseGetColumnsProps } from '../types';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const useGetColumns = ({ adGroup }: UseGetColumnsProps) => {
  const { releaseCreativeStartEndDatetime: allowDateTime } = useFlags();

  const columns = getBaseColumns(adGroup);

  if (allowDateTime) {
    const uploadedColIndex = findIndex(columns, { field: 'media_upload_date' });
    columns.splice(uploadedColIndex + 1, 0, ...startEndDateColumns(adGroup));
  }

  return { columns };
};
