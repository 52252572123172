import moment, { type Moment } from 'moment';
import { GapWarning } from './types';

export const getDateTime = (
  date: Moment | string | null,
  time: Moment | string | null
) => {
  if (!date || !time) {
    return null;
  }
  const momentDate = moment(date);
  const momentTime = moment(time);

  return momentDate
    .set({
      hours: momentTime.hours(),
      minutes: momentTime.minutes(),
    })
    .second(0);
};

export const getMinDateTime = (
  date1: Moment | string | null,
  date2: Moment | string | null
) => {
  if (!date1 && !date2) {
    return null;
  }
  if (!date1) {
    return date2;
  }
  if (!date2) {
    return date1;
  }
  date1 = moment(date1).second(0);
  date2 = moment(date2).second(0);
  return date1.isBefore(date2) ? date1 : date2;
};

export const getMaxDateTime = (
  date1: Moment | string | null,
  date2: Moment | string | null
) => {
  if (!date1 && !date2) {
    return null;
  }
  if (!date1) {
    return date2;
  }
  if (!date2) {
    return date1;
  }
  date1 = moment(date1).second(0);
  date2 = moment(date2).second(0);
  return date1.isAfter(date2) ? date1 : date2;
};

/**
 * Checks for gaps in the given datetime ranges compared to the allowed ad group datetime range.
 * Ensures that no gaps exceed the maximum allowed gap between consecutive datetime ranges.
 *
 * @param {Array<[Moment | null, Moment | null]>} datetimes - An array of datetime pairs representing start and end times. Each pair may contain null values.
 * @param {[Moment, Moment | null]} adGroupDatetimeRange - The start and end datetime for the ad group, where the second value (end time) may be null.
 * @param {number} [maxAllowedGap=60] - The maximum allowed gap in minutes between consecutive datetime ranges.
 * @returns {boolean} - Returns true if a gap exceeding the max allowed gap is found, otherwise false.
 */
export const checkForDatetimeGaps = (
  datetimes: [Moment | null, Moment | null][],
  adGroupDatetimeRange: [Moment, Moment | null],
  maxAllowedGap: number = 60
): [Moment, Moment | null][] => {
  const gaps: [Moment, Moment | null][] = [];

  if (datetimes.length === 0) return gaps;

  const validRanges = datetimes.filter(
    ([start, end]) => !!start && !!end
  ) as [Moment, Moment][];

  // if some creative does not have both start and end datetime, it means:
  // 1. user has not complete the input, then do not check for gaps and do not
  // show warnings, or
  // 2. there's creative without datetime association, then there will be no gap
  if (validRanges.length !== datetimes.length) {
    return gaps;
  }

  const sortedRanges = [...validRanges].sort((a, b) => a[0].diff(b[0]));

  const leftMost = sortedRanges[0][0];
  let rightMost = sortedRanges[0][1];

  if (leftMost.diff(adGroupDatetimeRange[0], 'minutes') > maxAllowedGap) {
    gaps.push([adGroupDatetimeRange[0].clone(), leftMost.clone()]);
  }

  for (let i = 1; i < sortedRanges.length; i++) {
    const nextStart = sortedRanges[i][0];
    const nextEnd = sortedRanges[i][1];
    if (nextStart.diff(rightMost, 'minutes') > maxAllowedGap) {
      gaps.push([rightMost.clone(), nextStart.clone()]);
    }
    rightMost = moment.max(rightMost, nextEnd);
  }

  // if ad group has no end datetime, then there will be a gap between the
  // last creative and the end of the ad group
  if (!adGroupDatetimeRange[1]) {
    gaps.push([rightMost.clone(), null]);
  } else if (
    adGroupDatetimeRange[1].diff(rightMost, 'minutes') > maxAllowedGap
  ) {
    gaps.push([rightMost.clone(), adGroupDatetimeRange[1].clone()]);
  }

  return gaps;
};

export const convertGapsToReadableString = (
  gaps: [Moment, Moment | null][]
): GapWarning => {
  if (gaps.length === 1) {
    const [gapStart, gapEnd] = gaps[0];
    return {
      warning: 'Gap in creative scheduling',
      message: gapEnd
        ? `There is no creative scheduled to run ${gapStart.format(
            'MMM D, YYYY h:mm A'
          )} – ${gapEnd.format('MMM D, YYYY h:mm A')}.`
        : `There is no creative scheduled to run after ${gapStart.format(
            'MMM D, YYYY h:mm A'
          )}.`,
    };
  }
  return {
    warning: 'Multiple gaps in creative scheduling',
    message: 'Please confirm your creative start and end dates.',
  };
};
