import { styled, Typography } from '@mui/material';

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '14px',
  lineHeight: '1.448em',
  color: theme.palette.grey[1]
}));

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightLight,
  fontSize: '14px',
  lineHeight: '1.448em',
  color: theme.palette.grey[3],
  marginTop: theme.spacing(0.5)
})); 
