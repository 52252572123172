import { BulkItemResponse } from '@components/hooks/apis/bulk';
import { MockAdGroup } from '@components/tests/__mocks__';
import {
  faTriangleExclamation,
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

interface PartialSuccessProps {
  results?: BulkItemResponse[];
  adGroups?: MockAdGroup[];
}

export const PartialSuccess = ({
  results = [
    {
      status: 'SUCCESS',
      body: {
        id: 132,
        updated_fields: {},
      },
    },
    {
      status: 'ERROR',
      body: {
        id: 243,
        errors: {
          start_date: 'Invalid date',
        },
      },
    },
    {
      status: 'SUCCESS',
      body: {
        id: 354,
        updated_fields: {},
      },
    },
  ],
  adGroups = [
    {
      id: 132,
      name: 'Ad Group 1',
    },
    {
      id: 243,
      name: 'Ad Group 2',
    },
    {
      id: 354,
      name: 'Ad Group 3',
    },
  ],
}: PartialSuccessProps) => {
  const theme = useTheme();

  const successAdGroups = adGroups.filter(adGroup => {
    const resAdGroup = results.find(
      resAdGroup => resAdGroup.body.id === adGroup.id
    );
    return resAdGroup?.status === 'SUCCESS' || false;
  });

  const errorAdGroups = adGroups.filter(
    adGroup => !successAdGroups.includes(adGroup)
  );

  return (
    <Stack sx={{ alignItems: 'center' }} spacing={theme.spacing(4)}>
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        fontSize="80px"
        color={theme.palette.chart[6]}
      />
      <Typography variant="h4">
        {successAdGroups.length} of {adGroups.length} Ad Groups successfully
        updated
      </Typography>
      <Stack
        spacing={theme.spacing(1)}
        alignItems="start"
        sx={{ width: '100%' }}
      >
        <List>
          <Typography variant="h6" color={theme.palette.grey[2]}>
            Updated Ad Groups
          </Typography>
          {successAdGroups.map(adGroup => (
            <ListItem key={adGroup.id}>
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  fontSize="25px"
                  color={theme.palette.green[6]}
                />
              </ListItemIcon>
              <ListItemText
                primary={`${adGroup.name}`}
                secondary={`ID: ${adGroup.id}`}
              />
            </ListItem>
          ))}
        </List>
        <Divider sx={{ width: '100%' }} />
        <List sx={{ pt: 3 }}>
          <Typography variant="h6" color={theme.palette.grey[2]}>
            Not Updated Ad Groups
          </Typography>
          {errorAdGroups.map(adGroup => (
            <ListItem key={adGroup.id}>
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  fontSize="25px"
                  color={theme.palette.red[2]}
                />
              </ListItemIcon>
              <ListItemText
                primary={adGroup.name}
                secondary={`ID: ${adGroup.id}`}
              />
            </ListItem>
          ))}
        </List>
      </Stack>
    </Stack>
  );
};
