import React, { useEffect, useMemo } from 'react';
import { fields } from '../formConfig';
import { sections } from '@v2/components/campaign/CampaignAdGroupSection';
import { Stack, Typography } from '@mui/material';
import { subSections } from '@v2/components/campaign/CampaignAdGroupSection/constants';
import { entityStatus } from '../../constants';
import moment from 'moment';
import type { UseFormReturn } from 'react-hook-form';
import { Fields } from '@local-types/general';

export const useGroupSectionsInfo = ({
  form,
}: {
  form: UseFormReturn<Fields<typeof fields>>;
}) => {
  const [status, startDate, startTime = moment(), advancedCustomInventory] =
    form.watch([
      fields.status.path,
      fields.startDate.path,
      fields.startTime.path,
      fields.advancedCustomInventory.path,
    ]);
  const { errors } = form.formState;

  const [bundlesError, bidStrategyError, inventoryError] = [
    errors[fields.advancedCustomInventory.path],
    errors[fields.adGroupBidStrategy.path],
    errors[fields.inventory.path],
  ];

  const info = useMemo(() => {
    const inventory = [];
    const bidStrategy = [];
    const dates = [];

    if (inventoryError) {
      inventory.push({
        section: sections.geography,
        type: 'error',
        content: (
          <Typography variant="body2">{inventoryError.message}</Typography>
        ),
      });
    }

    if (bundlesError) {
      inventory.push({
        section: sections.inventory,
        type: 'error',
        content: (
          <Typography variant="body2">{bundlesError.message}</Typography>
        ),
      });
    }

    if (bidStrategyError) {
      bidStrategy.push({
        section: sections.bidStrategy,
        type: 'error',
        content: (
          <Typography variant="body2">{bidStrategyError.message}</Typography>
        ),
      });
    }

    if (status === entityStatus.active) {
      dates.push({
        section: sections.general,
        type: 'info',
        sub: subSections.general.dates,
        content: (
          <Stack>
            <Typography variant="body2">
              Starting date is disabled for active ad groups.
            </Typography>
          </Stack>
        ),
      });
    }

    return [...inventory, ...dates, ...bidStrategy];
  }, [
    bundlesError,
    bidStrategyError,
    status,
    startDate,
    startTime,
    inventoryError,
  ]);

  useEffect(() => {
    form.trigger(fields.advancedCustomInventory.path);
  }, [advancedCustomInventory]);

  return {
    info,
  };
};
