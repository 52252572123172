import { Tooltip } from '@mui/material';
import React from 'react';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { StyledInfoIcon } from './styles';
import { HintProps } from './types';

const Hint = ({ children, color, ...props }: HintProps) => {
  return (
    <Tooltip {...props} title={children} arrow placement="top">
      <StyledInfoIcon
        color={color}
        icon={faCircleInfo}
        data-testid="info-icon"
      />
    </Tooltip>
  );
};

export default Hint;
