import { faBinarySlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Stack, Typography, useTheme } from '@mui/material';

export const UpdateError = () => {
  const theme = useTheme();

  return (
    <Stack sx={{ alignItems: 'center' }} spacing={theme.spacing(4)}>
      <FontAwesomeIcon
        icon={faBinarySlash}
        fontSize="80px"
        color={theme.palette.red[1]}
      />
      <Typography variant="h4">Something went wrong.</Typography>
      <Typography>
        If the problem persists, please{' '}
        <Link
          href="mailto:support@tvscientific.com"
          underline="hover"
          sx={{
            color: theme.palette.blue[5],
            fontWeight: 'fontWeightMedium',
          }}
        >
          contact support
        </Link>
        .
      </Typography>
    </Stack>
  );
};
