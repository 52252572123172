import type {
  InventoryTargeting,
  InventoryTargetingStoreStructure,
} from '@local-types/inventory-targeting';
import { groupBy, isEmpty, map } from 'lodash';

export const prepareOsDeviceSelected = ({
  os = { blacklist: false, ids: [] },
  device = { blacklist: false, ids: [] },
  allowedTargeting = [],
}: {
  os: InventoryTargetingStoreStructure;
  device: InventoryTargetingStoreStructure;
  allowedTargeting: InventoryTargeting[];
}) => {
  const grouped = groupBy(allowedTargeting, 'type');

  const targetingOsKeys = map(grouped.OS, 'key');
  const targetingDeviceKeys = map(grouped.DEVICE, 'key');

  const osSelected = os.blacklist
    ? targetingOsKeys.filter(k => os.ids.includes(k))
    : os.ids ?? [];

  const deviceSelected = device.blacklist
    ? targetingDeviceKeys.filter(k => device.ids.includes(k))
    : device.ids ?? [];

  return {
    os: {
      blacklist: os.blacklist ?? false,
      ids: osSelected,
    },
    device: {
      blacklist: device.blacklist ?? false,
      ids: deviceSelected,
    },
  };
};

export const prepareOsDeviceForSave = ({
  os,
  device,
  allowedTargeting,
}: {
  os: InventoryTargetingStoreStructure;
  device: InventoryTargetingStoreStructure;
  allowedTargeting: InventoryTargeting[];
}) => {
  const grouped = groupBy(allowedTargeting, 'type');

  const targetingOsKeys = map(grouped.OS, 'key');
  const targetingDeviceKeys = map(grouped.DEVICE, 'key');

  const selectedOs = targetingOsKeys.filter(key => os.ids.includes(key));

  const selectedDevice = targetingDeviceKeys.filter(key =>
    device.ids.includes(key)
  );

  return {
    os: {
      blacklist: os.blacklist ?? false,
      ids: isEmpty(selectedOs) ? targetingOsKeys : selectedOs,
    },
    device: {
      blacklist: device.blacklist ?? false,
      ids: isEmpty(selectedDevice) ? targetingDeviceKeys : selectedDevice,
    },
  };
};
