import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useForm, useFieldArray, type Resolver } from 'react-hook-form';
import { FixedSizeList } from 'react-window';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  GridProps,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Item } from './styled';
import { IListFilterFormProps, IFormValues } from './types/ListFilterFormProps.types';
import { Row } from './Row';
import { VirtualizedRow } from './components/VirtualizedRow';

const itemSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  checked: yup.boolean().required(),
});

const schema = yup.object({
  items: yup.array().of(itemSchema),
  searchValue: yup.string(),
  selectedIds: yup.array(yup.number())
});

const formResolver = yupResolver(schema as yup.AnyObjectSchema) as Resolver<IFormValues>;

const ROW_HEIGHT = 36;


const ListFilterForm: React.FC<IListFilterFormProps & Omit<GridProps, 'onSubmit'>> = ({
  fieldName,
  data,
  onSubmit,
  onSearch,
  onLoadMore,
  showSearch = true,
  virtualized = true,
  ...rest
}) => {
  const isInitialized = useRef(false);
  const lastLoadedIndex = useRef(-1);

  const { control, handleSubmit, formState, register, watch, setValue } = useForm<IFormValues>({
    defaultValues: {
      items: [],
      searchValue: '',
      selectedIds: [],
    },
    resolver: formResolver
  });

  const { fields } = useFieldArray<IFormValues, 'items', 'internalId'>({
    control,
    name: "items",
    keyName: 'internalId',
  });

  const [searchValue, selectedIds] = watch([
    'searchValue',
    'selectedIds',
  ]);

  const [selectedFields, otherFields] = useMemo(() => {
    const selected = fields.filter(field => selectedIds.includes(field.id));
    const others = fields.filter(field => !selectedIds.includes(field.id));
    return [selected, others];
  }, [fields, selectedIds]);

  const handleCheckboxChange = (checked: boolean, itemId: number) => {
    const newSelectedIds = checked
      ? [...selectedIds, itemId]
      : selectedIds.filter((id: number) => id !== itemId);

    setValue('selectedIds', newSelectedIds, { shouldValidate: true });
  };

  const itemData = useMemo(
    () => ({
      items: otherFields,
      control,
      fields,
      handleCheckboxChange,
      searchValue: searchValue || '',
      hasMore: data.hasMore,
      isValidating: data.isValidating,
    }),
    [control, fields, handleCheckboxChange, otherFields, searchValue, data.hasMore, data.isValidating]
  );

  useEffect(() => {
    if (onSearch) {
      onSearch(searchValue);
    }
  }, [searchValue, onSearch]);

  useEffect(() => {
    if (data.selectedIds && !isInitialized.current) {
      setValue('selectedIds', data.selectedIds);
      isInitialized.current = true;
    }
  }, [data.selectedIds, setValue]);

  useEffect(() => {
    if (data.items) {
      setValue('items', data.items.map(item => ({
        ...item,
        checked: selectedIds.includes(item.id)
      })));
    }
  }, [data.items, selectedIds, setValue]);

  const getItemKey = useCallback((index: number) => {
    const item = otherFields[index];
    return item?.id.toString() ?? String(index);
  }, [otherFields]);



  return (
    <Grid
      component="form"
      container
      direction="column"
      spacing={1}
      sx={{
        padding: theme => theme.spacing(4, 0, 2),
        justifyContent: 'space-between',
        height: '100%',
        ...(rest.sx || {})
      }}
      onSubmit={handleSubmit(onSubmit)}
      {...rest}
    >
      <Grid xs={7} sx={{ width: "100%", display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
          <Stack component={Item} spacing={1} sx={{ flex: 1, display: 'flex', height: '100%', minHeight: 0 }}>
            <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {fieldName}
              </Typography>
            </Stack>

            {showSearch && (
              <TextField
                id="search-field"
                label={`Enter ${fieldName}`}
                variant="outlined"
                autoComplete="off"
                {...register('searchValue')}
                sx={{ backgroundColor: 'common.white' }}
              />
            )}

            {fields.length > 0 && (
              <Stack sx={{ flex: 1, height: '100%' }}>
                {selectedFields.length > 0 && (
                  <>
                    <List 
                      dense 
                      data-testid="selected-items-list"
                      sx={{ 
                        maxHeight: selectedFields.length > 4 ? 150 : 'none',
                        overflow: selectedFields.length > 4 ? 'auto' : 'visible'
                      }}
                    >
                      {selectedFields.map(item => (
                        <Row
                          key={item.internalId}
                          item={item}
                          control={control}
                          fields={fields}
                          handleCheckboxChange={handleCheckboxChange}
                          searchValue={searchValue}
                        />
                      ))}
                    </List>
                    <Divider sx={{ my: 2 }} />
                  </>
                )}

                <Box sx={{ flex: 1, minHeight: 0 }}>
                  <AutoSizer>
                    {({ height, width }) => (
                      <>
                        {otherFields.length > 0 && virtualized && (
                          <FixedSizeList
                            height={height}
                            width={width}
                            itemCount={otherFields.length + (data.hasMore ? 1 : 0)}
                            itemSize={ROW_HEIGHT}
                            itemKey={getItemKey}
                            itemData={itemData}
                            overscanCount={10}
                            onItemsRendered={({ visibleStopIndex }) => {
                              const loadTriggerIndex = Math.max(0, otherFields.length - 5);
                              
                              if (
                                onLoadMore && 
                                data.hasMore && 
                                !data.isValidating && 
                                visibleStopIndex >= loadTriggerIndex && 
                                visibleStopIndex > lastLoadedIndex.current
                              ) {
                                lastLoadedIndex.current = visibleStopIndex;
                                onLoadMore();
                              }
                            }}
                          >
                            {VirtualizedRow}
                          </FixedSizeList>
                        )}

                        {otherFields.length > 0 && !virtualized && (
                          <List data-testid="other-items-list">
                            {otherFields.map(item => (
                              <Row
                                key={item.internalId}
                                item={item}
                                control={control}
                                fields={fields}
                                handleCheckboxChange={handleCheckboxChange}
                              />
                            ))}
                          </List>
                        )}
                      </>
                    )}
                  </AutoSizer>
                </Box>
              </Stack>
            )}
            
            {fields.length === 0 && (
              <ListItem>
                <ListItemText
                  primary={data.isValidating || data.isLoading ? "Loading..." : "No results found"}
                  sx={{
                    textAlign: 'center',
                    color: 'text.secondary',
                    py: 2
                  }}
                />
              </ListItem>
            )}
          </Stack>
        </Box>
      </Grid>

      <Grid xs={2} sx={{ width: '100%' }}>
        <Stack direction="column" spacing={2.5}>
          <Divider flexItem sx={{ margin: 0 }} />

          <Item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              size="small"
              type="submit"
              disabled={!formState.isValid || formState.isSubmitting}
            >
              Apply
            </Button>
          </Item>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ListFilterForm;
