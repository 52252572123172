import React from 'react';
import { PropTypes } from 'prop-types';
import { Stack, styled } from '@mui/material';
import { CreativeDetails } from './CreativeDetails';

const ImageContentWrapperStyled = styled(Stack)({
  position: 'relative',
});

const ImageStyled = styled('img')({
  borderRadius: 0,
  width: '100%',
  height: '100%',
});

export const ImagePreview = ({ src, showDetails, creative, ...props }) => {
  const ImageThumbnail = (
    <ImageContentWrapperStyled {...props}>
      <ImageStyled src={src} />
    </ImageContentWrapperStyled>
  );
  const ImageThumbnailWithDetails = (
    <Stack direction="row" alignItems="center" spacing={3} {...props}>
      {ImageThumbnail}
      <CreativeDetails creative={creative} />
    </Stack>
  );
  return showDetails ? ImageThumbnailWithDetails : ImageThumbnail;
};

ImagePreview.propTypes = {
  ...Stack.propTypes,
  src: PropTypes.string,
};
