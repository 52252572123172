import { ReviewEditsProps } from '../../constants';
import React, { useEffect, useState } from 'react';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { Info } from '@v2/components/campaign/Info';
import { StyledDataGrid } from './styledComponents/StyledDataGrid';
import {
  fieldCellRenderer,
  fromCellRenderer,
  toCellRenderer,
} from './renderers';
import { Stack, Typography } from '@mui/material';
import { DataGrid } from '@v2/components/ui/DataGrid';

type DataGridColumnsType = React.ComponentProps<typeof DataGrid>['columns'];

const reviewEditsColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Ad Group',
    width: 450,
    sortable: false,
  },
  {
    field: 'field',
    headerName: 'Field',
    width: 120,
    sortable: false,
    renderCell: fieldCellRenderer,
  },
  {
    field: 'from',
    headerName: 'From',
    width: 200,
    sortable: false,
    renderCell: fromCellRenderer,
  },
  {
    field: 'to',
    headerName: 'To',
    width: 150,
    sortable: false,
    renderCell: toCellRenderer,
  },
] as DataGridColumnsType;

export const ReviewEdits = ({
  rows,
  invalidCount,
  handleSelectionChange,
}: ReviewEditsProps) => {
  const allRowsSelected: GridRowSelectionModel = rows.map(row => row.id);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>(allRowsSelected);

  useEffect(() => {
    handleSelectionChange(allRowsSelected);
  }, []);

  return (
    <Stack spacing={2} sx={{ px: 3, flex: 1 }}>
      {invalidCount > 0 && (
        <Info direction="column" type="error">
          <Typography variant="body1">
            {invalidCount} selected Ad Groups have errors.{' '}
            <Typography
              fontWeight="bold"
              sx={{ textDecoration: 'underline', display: 'inline' }}
            >
              Unselect the {invalidCount} Ad Groups to proceed
            </Typography>
            , click the back arrow to edit, or Cancel to exit.
          </Typography>
        </Info>
      )}
      <StyledDataGrid
        columns={reviewEditsColumns}
        rows={rows}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnResize
        disableColumnSorting
        disableColumnPinning
        disableColumnReorder
        hideFooter
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 160}
        onRowSelectionModelChange={newSelection => {
          setRowSelectionModel(newSelection);
          handleSelectionChange(newSelection);
        }}
        rowSelectionModel={rowSelectionModel}
        getRowClassName={params =>
          params.row.result?.body.errors ? 'row-theme--Error' : ''
        }
        sx={{ border: 0, px: 1 }}
      ></StyledDataGrid>
    </Stack>
  );
};
