import {
  CheckOutlined,
  CloseOutlined,
  RemoveCircleOutline,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import React from 'react';
import { ActionButtonStyled, AdvancedHeaderBoxStyled } from '../styles';
import { useCopy } from '@hooks/copy';
import { Copies } from '../constants';
import { TableRightHeaderProps } from './types';
import { TableData } from '../types';

export const TableRightHeader = <Value, Data extends TableData<Value>>({
  onRemoveAll,
  onExcludeAll,
  onIncludeAll,
  onRemoveAllHovered,
  settings,
  data = [],
}: TableRightHeaderProps<Value, Data>) => {
  const Copy = useCopy(Copies);

  return (
    <AdvancedHeaderBoxStyled>
      <Stack flex={1} alignItems="flex-start">
        <ActionButtonStyled
          disabled={!data.length}
          onClick={onRemoveAll}
          data-testid="advanced-remove-all"
          onMouseEnter={() => onRemoveAllHovered?.(true)}
          onMouseLeave={() => onRemoveAllHovered?.(false)}
          variant="text"
          color="primary"
        >
          <RemoveCircleOutline />
          {Copy.removeAll}
        </ActionButtonStyled>
      </Stack>
      {settings.includable && (
        <Stack direction="row" spacing={3}>
          <ActionButtonStyled
            data-testid="advanced-exclude-all"
            disabled={!data.length}
            onClick={onExcludeAll ?? (() => {})}
            variant="text"
            color="primary"
          >
            <CloseOutlined />
            {Copy.excludeAll}
          </ActionButtonStyled>
          <ActionButtonStyled
            data-testid="advanced-include-all"
            disabled={!data.length}
            actionType="success"
            onClick={onIncludeAll ?? (() => {})}
            variant="text"
            color="primary"
          >
            <CheckOutlined />
            {Copy.includeAll}
          </ActionButtonStyled>
        </Stack>
      )}
    </AdvancedHeaderBoxStyled>
  );
};
