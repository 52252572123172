import { MockAdGroup } from '@components/tests/__mocks__';
import { BulkEditFieldValue } from '../../constants';
import { BulkValidateResponse } from '@components/hooks/apis/bulk';
import { ReviewEditsRow } from '../../constants/types';
import { GridCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { toET } from '@components/WizardContainers/GroupSection/utils/general';
import { Theme } from '@mui/material';
import {
  DEFAULT_BID_STRATEGY_EVENT_SET,
  getBidStrategyOptions,
} from '../../constants/bidStrategyOptions';

export const convertISOToDateTime = (iso: string) => {
  const momentInET = toET(iso);
  const date = momentInET.format('MM/DD/YYYY');
  const time = momentInET.format('hh:mm A');

  return [date, time];
};

export const compileTableRows = (
  adGroups: MockAdGroup[],
  res: BulkValidateResponse,
  fieldValue: BulkEditFieldValue
): ReviewEditsRow[] => {
  return adGroups.map(adGroup => {
    const resAdGroup = res.results.find(
      resAdGroup => resAdGroup.body.id === adGroup.id
    );

    return {
      ...adGroup,
      result: resAdGroup,
      intended_edits: fieldValue,
    };
  });
};

export const getInvalidCount = (
  rows: ReviewEditsRow[],
  ids: GridRowSelectionModel
) => {
  let invalidCount = 0;
  ids.forEach(id => {
    const row = rows.find(row => row.id === id);
    if (row?.result?.status === 'ERROR' && row.result.body.errors) {
      invalidCount++;
    }
  });

  return invalidCount;
};

export const getCellVisibility = (params: GridCellParams) => {
  const intendedEdits = params.row.intended_edits ?? {};

  return {
    startDateVisible: 'start_date' in intendedEdits,
    endDateVisible: 'end_date' in intendedEdits,
    bidStrategyVisible: 'bid_strategy' in intendedEdits,
    bidStrategyEventVisible: 'bid_strategy_event' in intendedEdits,
    cpmVisible: 'cpm' in intendedEdits,
  };
};

export const getCellColors = (params: GridCellParams, theme: Theme) => {
  const error_fields = params.row.result?.body.errors;
  const startError = error_fields && 'start_date' in error_fields;
  const endError = error_fields && 'end_date' in error_fields;
  const bidStrategyError = error_fields && 'bid_strategy' in error_fields;
  const bidStrategyEventError =
    error_fields && 'bid_strategy_event' in error_fields;
  const cpmError = error_fields && 'cpm' in error_fields;

  const startColor = startError
    ? theme.palette.red[2]
    : theme.palette.text.primary;
  const endColor = endError
    ? theme.palette.red[2]
    : theme.palette.text.primary;
  const bidStrategyColor = bidStrategyError
    ? theme.palette.red[2]
    : theme.palette.text.primary;
  const bidStrategyEventColor = bidStrategyEventError
    ? theme.palette.red[2]
    : theme.palette.text.primary;
  const cpmColor = cpmError
    ? theme.palette.red[2]
    : theme.palette.text.primary;

  return [
    startColor,
    endColor,
    bidStrategyColor,
    bidStrategyEventColor,
    cpmColor,
  ];
};

export const getBidStrategyLabels = (
  params: GridCellParams,
  column: 'from' | 'to'
) => {
  const bidStrategyParam =
    column === 'to'
      ? params.row.intended_edits.bid_strategy
      : params.row.bid_strategy;
  const bidStrategyEventParam =
    column === 'to'
      ? params.row.intended_edits.bid_strategy_event
      : params.row.bid_strategy_event;

  const bidStrategyOptions = getBidStrategyOptions(
    DEFAULT_BID_STRATEGY_EVENT_SET
  );
  const bidStrategyLabel = bidStrategyOptions.find(
    option => parseInt(option.value as string, 10) === parseInt(bidStrategyParam as string, 10)
  )?.label;
  const bidStrategyEventLabel = DEFAULT_BID_STRATEGY_EVENT_SET.find(
    option => option.id === parseInt(bidStrategyEventParam, 10)
  )?.name;

  const titleCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return [bidStrategyLabel, titleCase(bidStrategyEventLabel || '')];
};
