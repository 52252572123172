/* eslint-disable @typescript-eslint/no-explicit-any */

import { last } from 'lodash';
import { useCallback, useEffect } from 'react';
import { Key } from 'swr';
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteResponse,
} from 'swr/infinite';
import { useCurrentSession } from '../currentSession';
import type { Page } from '@local-types/general';

export const useInfiniteGlobalCacheUpdate = <Data, Error>({
  infiniteMutate,
}: {
  infiniteMutate?: SWRInfiniteResponse<Data, Error>['mutate'];
}) => {
  return {
    trigger: async (key: Key, data: Data[]) => {
      const options = {
        revalidate: false,
        populateCache: true,
      };

      // Trigger revalidation to ensure data is updated
      return await infiniteMutate?.(data, options);
    },
  };
};

export const useInfiniteSizer = <T>(
  data: T[] | Page<T>[] | undefined,
  {
    isLoading,
    setSize,
  }: {
    isLoading: boolean;
    setSize: (pager: (prev: number) => number) => void;
  }
) => {
  useEffect(() => {
    if (!isLoading && last(data as Page<T>[])?.next) {
      setSize(prev => prev + 1);
    }
  }, [data, isLoading]);
};

export type UseLoadInfiniteOptions = {
  ignoreAdvertiser?: boolean;
  params?: {
    disabled?: boolean;
    v1?: boolean;
    queryProps?: Record<string, string | number | undefined>;
    noCacheProps?: Record<string, string | number | undefined>;
    [key: string]: any;
  };
};

export type UseLoadInfiniteConfiguration<T> = SWRInfiniteConfiguration<
  Page<T>
>;

export const useLoadInfinite = <T>(
  url: string,
  options: UseLoadInfiniteOptions = {},
  mutationOptions: UseLoadInfiniteConfiguration<T> = {}
) => {
  const {
    params: { disabled, v1, queryProps, noCacheProps, ...otherParams } = {},
    ignoreAdvertiser,
  } = options;

  const { apiIsReady, currentAdvertiser, get, getV1 } = useCurrentSession();

  const getter = v1 ? getV1 : get;

  const fetcher = ({
    url,
    params,
  }: {
    url: string;
    params: { advertiser?: number; [key: string]: any };
  }) => {
    // The advertiser param is only used to generate the cache key; it is not
    // needed here in the fetcher. We are simply removing it from the params.
    const { advertiser, ...innerParams } = params;
    return getter(url, {
      v1,
      ...innerParams,
      ...otherParams,
      ...noCacheProps,
    }).then(res => res.data);
  };

  const advertiser = currentAdvertiser.id;

  const { data, error, isLoading, isValidating, mutate, setSize, size } =
    useSWRInfinite<Page<T>>(
      (pageIndex, previousPageData) => {
        if (
          !url.length ||
          disabled ||
          !apiIsReady ||
          (!ignoreAdvertiser && !advertiser)
        ) return null;

        if (previousPageData && !previousPageData.next) return null;

        return {
          url,
          params: {
            advertiser,
            page: pageIndex + 1,
            ...queryProps,
          },
        };
      },
      fetcher,
      {
        ...mutationOptions,
      }
    );

  useInfiniteSizer<T>(data, { isLoading, setSize });

  const invalidate = useCallback(() => {
    return mutate();
  }, [mutate]);

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
    setSize,
    size,
    items: data
      ? [...(data?.map(page => page?.results) ?? [])].flat()
      : ([] as T[]),
    invalidate,
  };
};
