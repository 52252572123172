import { createTheme } from '@mui/material/styles';

export const defaultTheme = createTheme({
  typography: {
    fontFamily: "Noto Sans JP,Helvetica,Arial,sans-serif",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    fontWeightBold: 900,
    h1: {
      fontSize: "2.5rem",
      color: "#47505D",
      fontWeight: 700
    },
    h2: {
      fontSize: "1.875rem",
      color: "#181C20",
      fontWeight: 700
    },
    h3: {
      fontSize: "1.5rem",
      color: "#181C20",
      fontWeight: 700
    },
    h4: {
      fontSize: "1.25rem",
      color: "#031620",
      fontWeight: 700
    },
    h5: {
      fontSize: "0.875rem",
      color: "#181c20",
      fontWeight: 700
    },
    subtitle1: {
      fontSize: "1.5rem",
      color: "#727383"
    },
    body1: {},
    body2: {
      fontSize: "0.875rem",
      color: "#30363E"
    },
    paragraph: {
      fontSize: "0.75rem",
      color: "#47505d"
    },
    button: {
      textTransform: "none"
    },
    border: {
      overlay: '#fff',
    },
    background: {
      overlay: 'rgba(38, 47, 60, 0.95)',
    },
  },
  shape: {
    borderRadius: 8
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
        }
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiTabs: {
      defaultProps: {
        textColor: "inherit"
      },
      styleOverrides: {
        indicator: {
          backgroundColor: "#1dafff"
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: "#77858C",
          backgroundColor: "#EDF1F2",
          fontSize: "0.75rem"
        },
        colorPrimary: {
          color: "#0fbf84",
          backgroundColor: "rgba(122, 220, 187, 0.55)"
        }
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          '&, &:hover': {
            boxShadow: "0 5px 5px -3px hsla(0, 0%, 0%, 0.2), 0 3px 14px 2px hsla(0, 0%, 0%, 0.12), 0 8px 10px 1px hsla(0, 0%, 0%, 0.14)",
            textDecoration: "none",
          },
        }
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          background: "inherit",
          boxShadow: "0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)"
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontWeight: "normal",
          borderWidth: "1px",
          background: "#727383",
          color: "#fff",
          "&:hover, &.Mui-selected:hover": {
            color: "inherit",
            background: "#727383"
          },
          "&.Mui-selected": {
            color: "#fff",
            background: "#2873A2"
          },
          "&:not(:first-child)::before": {
            content: "\"\"",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: 0,
            width: "1px",
            height: "50%",
            backgroundColor: "#c0c8cc",
            marginLeft: "-1px",
            zIndex: 1
          }
        }
      }
    }
  },
  mixins: {},
  palette: {
    text: {
      primary: "#47505d",
      overlay: "#fff"
    },
    mode: "light",
    type: "light",
    secondary: {
      main: "#1dafff",
      light: "hsl(201, 100%, 56%)",
      dark: "#0d94df",
      contrastText: "#fff"
    },
    common: {
      green: "#06734e"
    },
    primary: {
      main: "#088C60",
      lightGreen: "#c2f2e2",
      light: "#0fbf84",
      dark: "#014831",
      contrastText: "#fff"
    },
    grey: {
      main: "#9aa0a6"
    },
    background: {
      overlay: "rgba(38, 47, 60, 0.95)"
    },
    border: {
      overlay: "#fff"
    }
  }
});
