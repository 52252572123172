import React from 'react';
import { AccordionProps } from './types';
import {
  AccordionStyled,
  AlertBoxStyled,
  AlertIconStyled,
  CheckIconStyled,
  FormControlLabelStyled,
  InfoIconStyled,
  LockIconStyled,
  RadioStyled,
  WrapperStyled,
} from './styles';
import { InfoBox } from '../InfoBox';
import { faCheck, faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import {
  AccordionDetails,
  AccordionSummary,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { TypographyElement } from '../TypographyElement';
import { InfoText } from '../InfoText';
import { ProTipBox } from '../ProTipBox';
import type { AccordionRecommendedOption } from '../types';

export const Accordion = ({
  expanded = false,
  option,
  trackingIsSetUp,
  ...props
}: AccordionProps) => {
  return (
    <WrapperStyled
      data-testid={`options-accordion-wrapper-${option.value}`}
      expanded={expanded}
    >
      {expanded && option.locked && (
        <Tooltip
          placement="top"
          data-testid={`options-accordion-alert-box-${option.value}`}
          title={option.lockedTooltipInfo}
          disableHoverListener={!option.lockedTooltipInfo}
          arrow
        >
          <AlertBoxStyled className="options-accordion-alert-box">
            <AlertIconStyled icon={faLockAlt} />
            <Typography component="span" variant="body2">
              {option.lockedInfo}
            </Typography>
          </AlertBoxStyled>
        </Tooltip>
      )}

      <InfoBox
        isLocked={option.locked}
        trackingIsSetUp={trackingIsSetUp}
        isRecommended={option.recommended}
        text={(option as AccordionRecommendedOption)?.recommendedInfo}
      />

      <AccordionStyled {...props} expanded={expanded}>
        <AccordionSummary
          data-testid={`options-accordion-summary-${option.value}`}
          expandIcon={null}
        >
          <FormControlLabelStyled
            data-testid={`options-accordion-label-${option.value}`}
            expanded={expanded}
            control={
              <RadioStyled
                checked={expanded && !option.locked}
                disabled={option.locked}
                data-testid={`options-accordion-radio-${option.value}`}
              />
            }
            value={option.value}
            label={
              <Stack direction="row" flex={1} alignItems="center" spacing={1}>
                <Stack maxWidth="100%">
                  <Stack flexDirection="row" alignItems="center">
                    <TypographyElement
                      color="grey.1"
                      sx={{
                        fontSize: '0.875rem',
                        fontWeight: '700',
                        marginRight: '8px',
                        width: 'auto',
                      }}
                    >
                      {option.label}
                    </TypographyElement>

                    {option.infoText && !expanded && (
                      <Tooltip
                        title={option.infoText}
                        arrow
                        disableInteractive
                        enterTouchDelay={0}
                      >
                        <InfoIconStyled icon={faCircleInfo} />
                      </Tooltip>
                    )}
                  </Stack>
                  <TypographyElement
                    color="grey.3"
                    sx={{
                      fontSize: '0.875rem',
                      fontWeight: '400',
                    }}
                  >
                    {option.description}
                  </TypographyElement>
                </Stack>
                <Stack>
                  {expanded ? (
                    option.locked ? null : (
                      <CheckIconStyled icon={faCheck} />
                    )
                  ) : option.locked ? (
                    <LockIconStyled icon={faLockAlt} />
                  ) : null}
                </Stack>
              </Stack>
            }
          />
        </AccordionSummary>

        <AccordionDetails
          data-testid={`options-accordion-details-${option.value}`}
          sx={{ padding: '0 45px' }}
        >
          {!option.locked && option.unlockedContent && (
            <TypographyElement
              data-testid={`options-accordion-locked-content-${option.value}`}
              color="grey.1"
            >
              {option.unlockedContent}
            </TypographyElement>
          )}

          {option.lockedContent && (
            <TypographyElement color="grey.1">
              {option.lockedContent}
            </TypographyElement>
          )}

          {option.infoText && <InfoText text={option.infoText} />}

          {option.proTip && <ProTipBox text={option.proTip} />}
        </AccordionDetails>
      </AccordionStyled>
    </WrapperStyled>
  );
};
