/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  flatMap,
  get,
  isArray,
  isObject,
  isString,
  map,
  values,
} from 'lodash';
import { entityStatus } from './constants';
import type { Nullable } from '@local-types/general';
import type { Error, ErrorDict, ErrorHandlerParams } from './types';
import { isAdServerError } from '@swr/utils';
import { AdGroup } from '@local-types/group';
import type { FieldValues, Path } from 'react-hook-form';

export const removeNullValues = <T extends Record<string, any>>(
  obj: T,
  filter = (_: string, v: any) => v
): Partial<T> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if ((value === null || value === undefined) && !filter(key, value)) {
      return acc;
    }
    return {
      ...acc,
      [key]: value,
    };
  }, {} as Partial<T>);
};

export const getDirtyValues = ({
  dirtyFields,
  values,
}: {
  dirtyFields: Record<string, boolean>;
  values: Record<string, any>;
}) => {
  const fieldNames = Object.entries(dirtyFields).reduce(
    (acc, [key, value]) => {
      if (value) {
        return [...acc, key];
      }
      return acc;
    },
    [] as string[]
  );
  const dirtyValues = fieldNames.map(key => get(values, key));
  return fieldNames.reduce((acc, key, index) => {
    return { ...acc, [key]: dirtyValues[index] };
  }, {});
};

export const removeEmptyValues = <T = Record<string, any>>(
  obj: Record<string, Nullable<T>> = {}
) => removeNullValues(obj, v => (isString(v) ? !!v : v));

export const convertToStatus = (entity: AdGroup | undefined) => {
  if (!entity) {
    return entityStatus.pending;
  }

  if (entity.draft) {
    return entityStatus.draft;
  }

  if (!entity.active && !entity.draft) {
    return entityStatus.paused;
  }

  return entityStatus.active;
};

const fetchErrors = (error: Error): string | string[] => {
  if (isArray(error)) {
    return map(error, fetchErrors).flat();
  }

  if (isObject(error)) {
    return flatMap(values(error), fetchErrors);
  }

  return error;
};

const processErrors = <T extends FieldValues>(
  errors: Error,
  {
    generalError,
    setError,
    generalErrorFields = [],
    mappedFields = {},
  }: ErrorHandlerParams<T>
) => {
  // Ad Server errors are being correctly handled by @swr/notification's
  // useNotification method and don't need to be handled here
  if (isAdServerError(errors)) return;

  Object.keys(errors).forEach(key => {
    if (
      generalErrorFields?.includes(key) &&
      typeof errors === 'object' &&
      key in errors
    ) {
      generalError?.((errors as ErrorDict)[key][0]);
    }

    const error = (errors as ErrorDict)[key];

    if (isObject(error) && !isArray(error)) {
      processErrors(error as Error, {
        generalError,
        setError,
        generalErrorFields,
        mappedFields,
      });
      return;
    }

    setError(
      (mappedFields[key] ?? key) as `root.${string}` | 'root' | Path<T>,
      {
        type: 'server',
        message: fetchErrors((errors as ErrorDict)[key]) as string,
      }
    );
  });
};

export const handleErrors =
  <T extends FieldValues>(
    cb: (...params: any[]) => Promise<any>,
    {
      setError,
      generalError,
      generalErrorFields = [],
      mappedFields = {},
    }: ErrorHandlerParams<T>
  ) =>
  async (...params: any[]) => {
    try {
      return await cb(...params);
    } catch (error) {
      const errors = (
        error as {
          response?: { data: Error };
        }
      )?.response?.data;
      if (errors) {
        processErrors(errors, {
          generalError,
          setError,
          generalErrorFields,
          mappedFields,
        });
      } else {
        throw error;
      }
    }
  };

export const getValues = (data = {}, keys = []) =>
  keys.reduce((acc, key) => [...acc, get(data, key)], []);
