export const isValidUrl = (url: string | undefined): boolean => {
  if (!url) return false;

  try {
    const parsedURL = new URL(url);

    if (!parsedURL.protocol.startsWith('https')) {
      return false;
    }

    if (!parsedURL.hostname.match(/\.(\w+)/)) {
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error validating URL', error);

    return false;
  }
};
