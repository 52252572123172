import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { FilterListProps } from './types';

const activeStyles = {
  backgroundColor: 'blue.11',
  '&::after': {
    content: '""',
    position: 'absolute',
    right: '-4px',
    top: 0,
    width: '4px',
    height: '100%',
    borderRadius: '0 2px 2px 0',
    backgroundColor: 'primary.main',
  }
};

const FilterList: React.FC<FilterListProps> = ({ items, selectedItem, onSelectItem }) => (
  <List disablePadding>
    {items.map((item) => (
      <ListItem
        key={item.id}
        component="button"
        onClick={() => onSelectItem(item)}
        sx={theme => ({
          padding: theme.spacing(0.75, 4),
          width: '100%',
          textAlign: 'left',
          border: 'none',
          background: 'none',
          cursor: 'pointer',
          position: 'relative',
          ...(selectedItem?.id === item.id && activeStyles),
          '&:hover': activeStyles,
        })}
      >
        <ListItemText
          primary={item.name}
          sx={{
            margin: 0,
            '.MuiListItemText-primary': {
              fontWeight: selectedItem?.id === item.id ? 'bold' : 'normal',
              fontSize: '0.875rem',
            },
          }}
        />
      </ListItem>
    ))}
  </List>
);

export default FilterList;
