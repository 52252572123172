import React from 'react';
import { Stack } from '@mui/material';
import {
  AdvancedVirtualizedElementRowStyled,
  FirstInnCellVirtualizedStyled,
} from '../styles';
import { IncludableData, TableData, VirtualizedData, WithId } from '../types';
import { StyledRemoveIcon } from './styles';
import clsx from 'clsx';
import { IncludeExcludeSwitch } from '@v2/components/ui/Switch';
import { TableRightRowProps } from './types';
import { VirtualizedRowType, VirtualizedTypeProperty } from '../constants';

export const TableRightRowVirtualized = <
  Value,
  Data extends TableData<Value>
>({
  data,
  index,
  isTargeted,
  renderCell,
  PlaceholderCell,
  isRemoveAllHovered,
  onChange,
  settings,
  onUpdate,
  ...props
}: TableRightRowProps<Value, Data>) => {
  const typedData = data as VirtualizedData<Value>;
  const isEmpty =
    !typedData ||
    typedData[VirtualizedTypeProperty] === VirtualizedRowType.EMPTY_ROW;

  return (
    <AdvancedVirtualizedElementRowStyled
      {...props}
      flexDirection="row"
      alignItems="center"
      rowType="remove"
      className={clsx(
        props.className,
        `${!isEmpty ? 'hover-row' : ''} ${
          isRemoveAllHovered ? 'remove-all-hover' : ''
        }`
      )}
      targeted={data && isTargeted}
      onClick={() => {
        if (data) {
          onChange(data);
        }
      }}
      highlighted={!!(index % 2)}
      key={(data as WithId<Data>)?.id ?? `placeholder-${index}`}
    >
      <FirstInnCellVirtualizedStyled>
        {!isEmpty ? (
          renderCell({ data })
        ) : (
          <PlaceholderCell type="right" data={data} />
        )}
      </FirstInnCellVirtualizedStyled>

      {settings.includable && settings.showSeparateSwitch && (
        <Stack
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {!isEmpty && (
            <IncludeExcludeSwitch
              defaultChecked
              disabled={!settings.separateInclExcl}
              onChange={e => {
                onUpdate({ ...data, included: e.target.checked });
              }}
              checked={(data as IncludableData<Data>).included}
            />
          )}
        </Stack>
      )}
      <Stack
        sx={{
          overflow: 'hidden',
        }}
      >
        <StyledRemoveIcon
          className="hover-icon"
          color="primary"
          sx={{
            maxWidth: '100%',
            display: isEmpty || !isTargeted ? 'none' : 'block',
            opacity: '33%',
          }}
        />
      </Stack>
    </AdvancedVirtualizedElementRowStyled>
  );
};
