import { createContext } from 'react';
import { AdGroupCreativeTableContextType } from './types';

export const AdGroupCreativeTableContext =
  createContext<AdGroupCreativeTableContextType>({
    campaignId: 0,
    adGroupId: 0,
    rowCount: 0,
    adGroup: null,
    adGroupsCreatives: [],
    creativeLineItems: [],
    isLoading: false,
    columns: [],
  });
