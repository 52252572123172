import { Divider, Stack, styled } from '@mui/material';

export const FormContainer = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(0),
}));

export const FormWrapperStyled = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4, 3, 2, 3),
  flex: 1,
  overflowY: 'auto',
}));

export const StyledFormDivider = styled(Divider)(({ theme }) => ({
  '&.MuiDivider-root': {
    borderColor: theme.palette.grey[6],
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2.5),
  },
}));
