/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect } from 'react';
import AdvertisersContext from '@providers/AdvertisersContext';
import { useCurrentSession } from '@hooks/currentSession';
import AdvertiserContext from '@components/AdvertiserContext';
import { useAdvertisers } from '@hooks/advertisers';
import { useGetCurrentAdvertiser } from '@apis/currentAdvertiser';

/**
 * Watches for changes to the advertisers or advertiser data loaded from the api and updates
 * the app accordingly.
 */
export const useWatchAdvertiser = () => {
  const { data: advertiserData } = useGetCurrentAdvertiser();
  const advertisersContext = useContext(AdvertisersContext);
  const {
    currentAdvertiser,
    setCurrentAdvertiser,
    updateCurrentAdvertiser: updateCurrentSessionAdvertiser,
    apiIsReady,
  } = useCurrentSession();

  const { advertisers } = advertisersContext || { advertisers: [] };

  const { updateCurrentAdvertiser } = useAdvertisers();

  const cachedId = parseInt(
    localStorage.getItem('AdvertiserContext') || '0',
    10
  );
  const adContext = useContext(AdvertiserContext);
  const isNbcuSubdomain = window.location.href.includes('peacock');
  const url = new URL(window.location.href);
  const isChangingSubdomain = url.searchParams.get('advertiser');

  // Store the cached ad account id in the current session and advertiser context
  useEffect(() => {
    if (cachedId && advertisers === null) {
      updateCurrentSessionAdvertiser({ id: cachedId });
      adContext.updateAdvertiser({ id: cachedId });
    }
  }, [cachedId, advertisers]);

  // If user has no cached ad account id, use the first one in their subdomain
  useEffect(() => {
    if (!cachedId && apiIsReady && advertisers && !isChangingSubdomain) {
      const advertiser = cachedId
        ? advertisers?.find((a: any) => a.id === cachedId)
        : advertisers?.find((a: any) => a.is_nbcu_tenant === isNbcuSubdomain);

      if (advertiser) {
        setCurrentAdvertiser(advertiser);
      }
    }
  }, [cachedId, apiIsReady, advertisers, isChangingSubdomain]);

  // Update the current advertiser after fetching its data on load
  useEffect(() => {
    if (advertiserData && cachedId && apiIsReady) {
      updateCurrentAdvertiser({ ...advertiserData });
    }
  }, [advertiserData, cachedId, apiIsReady]);

  useEffect(() => {
    if (!currentAdvertiser?.id) {
      return;
    }

    const {
      active,
      advertiserevent_set,
      billing_method,
      bidstrategyevent_set,
      bidstrategy_set,
      campaign_objective_types,
      category,
      cost_model,
      deductive_exposure_lid,
      deductive_outcome_lid,
      default_payment_profile,
      domain,
      id,
      invoice_approved,
      looker_experience,
      name,
      path_to_purchase_validated,
      cs_owner,
      primary_org,
      url,
      tvsciq,
      org_role,
      adv_role,
      billing_account_is_valid,
      has_sequential_events,
      has_spike_lift,
    } = currentAdvertiser;

    // Add Advertiser Context ID to localStorage
    localStorage.setItem('AdvertiserContext', id.toString());

    // Update the App Advertiser Context
    adContext.updateAdvertiser({
      active,
      advertiserevent_set,
      billing_method,
      bidstrategyevent_set,
      bidstrategy_set,
      campaign_objective_types,
      category,
      cost_model,
      default_payment_profile,
      domain,
      deductive_exposure_lid,
      id,
      invoice_approved,
      looker_experience,
      name,
      deductive_outcome_lid,
      path_to_purchase_validated,
      cs_owner,
      primary_org,
      url,
      tvsciq,
      org_role,
      adv_role,
      billing_account_is_valid,
      has_sequential_events,
      has_spike_lift,
    });
  }, [currentAdvertiser]);
};
