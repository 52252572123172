import { pacingIsAsFastAsPossible } from '@v2/components/campaign/PacingFieldsGroup/utils';
import { fields } from '../formConfig';
import type { Fields } from '@local-types/general';
import type {
  AdGroup,
  Pacing,
  PacingBehavior,
  PacingCatchup,
} from '@local-types/group';
import { BudgetPacingOptions } from '@v2/components/campaign/PacingFieldsGroup';

export const getPacingFromFormData = ({
  budgetPacingOptions,
  formData,
}: {
  budgetPacingOptions?: BudgetPacingOptions;
  formData: Fields<typeof fields>;
}) => {
  const {
    [fields.adGroupPacingEnabled.path]: pacingEnabled,
    [fields.adGroupPacing.path]: pacing,
    [fields.adGroupPacingBehavior.path]: behavior,
    [fields.adGroupPacingCatchupBehavior.path]: catchup,
  } = formData;

  if (!pacingEnabled)
    return {
      budget_pacing_options: {
        pacing: 'DAILY' as Pacing,
        behavior: 'EVEN' as PacingBehavior,
        catchup: 'EVEN' as PacingCatchup,
      },
    };

  const pacingIsAfap = pacingIsAsFastAsPossible({
    budgetPacingOptions,
    pacing,
  });

  return {
    budget_pacing_options: {
      pacing: pacing as Pacing,
      behavior: pacingIsAfap ? null : (behavior as PacingBehavior),
      catchup: pacingIsAfap ? null : (catchup as PacingCatchup),
    },
  };
};

export const transformFreqCapFields = (adGroup: AdGroup) => {
  return {
    [fields.configureAdGroupFrequencyCapEnabled.path]:
      adGroup?.freq_caps?.length > 0,
    [fields.configureAdGroupFrequencyCap.path]: adGroup?.freq_caps,
  };
};
