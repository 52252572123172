import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, DialogTitle, IconButton, Link, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  formValuesToApi,
  getInitialValues,
  validationSchema,
} from './formConfig';
import { RHFTextField } from '@components/form';
import AdvertiserContext from '../../AdvertiserContext';
import ModalWrapper from '../../ModalWrapper';
import { useAPI } from '../../hooks/api';
import { useOrg } from '../../hooks';
import { useRBACContext } from '../../../providers/RBACContext';

const FormContainer = styled(Box)(() => ({
  display: 'flex',
  maxWidth: '480px',
  width: '100%',
  margin: '0 auto',
  flexDirection: 'column',
  gap: '36px'
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme: { spacing } }) => ({
  marginTop: -40,
  marginBottom: spacing(8),
  borderBottom: '1px solid #e5e7eb',
}));

const LearnLink = styled(Link)(() => ({
  display: 'inline',
  verticalAlign: 'bottom',
  fontSize: '0.725rem',
}));

const InviteUser = ({ onClose, onSuccess, onViewRoles }) => {
  const adContext = useContext(AdvertiserContext);
  const orgContext = useOrg();
  const { roles } = useRBACContext()

  const { usePost } = useAPI();

  const [status, setStatus] = useState('');
  const isLoading = status === 'loading';

  const { control, handleSubmit } = useForm({
    defaultValues: getInitialValues(),
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async values => {
    setStatus('loading');

    try {
      const payload = formValuesToApi(values);
      payload.organization = adContext.primary_org;
      const { data } = await usePost('/organization_users/invite', payload);
      onSuccess({
        ...data,
        user_first_name: payload.first_name,
        user_last_name: payload.last_name,
      });
    } catch (error) {
      setStatus('error', error);
    }
  }, err => console.error(err));

  return (
    <div noValidate onSubmit={onSubmit}>
      <ModalWrapper
        isOpen
        hasSubmit
        submit="Invite New User"
        isLoading={isLoading}
        testId="invite-user-btn"
        maxWidth="md"
        onClose={onClose}
      >
        <StyledDialogTitle>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            pt={1}
          >
            <Box>
              <Box mb={1}>
                <Typography variant="h3">
                  Invite a new user to {orgContext?.org?.name}
                </Typography>
              </Box>

              <Typography
                xs={{
                  color: '#5c6c73',
                  fontSize: '0.725rem'
                }}
                variant="body2"
              >
                You are inviting a new user to the {orgContext?.org?.name} Organization.
                Learn more about &nbsp;

                <LearnLink
                  color="secondary"
                  component="button"
                  variant="body2"
                  onClick={(e) => {
                    e.preventDefault();
                    onViewRoles();
                  }}
                  underline="hover"
                >
                  User Roles & Privileges
                </LearnLink>
                .
              </Typography>
            </Box>

            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </StyledDialogTitle>

        <FormContainer>
          <RHFTextField
            label="First Name"
            placeholder="First Name"
            name="firstName"
            control={control}
          />

          <RHFTextField
            label="Last Name"
            placeholder="Last Name"
            name="lastName"
            control={control}
          />

          <RHFTextField
            label="Email Address"
            placeholder="Email Address"
            name="email"
            control={control}
          />

          <RHFTextField
            label="Mobile Phone"
            placeholder="Mobile Phone (optional)"
            name="phone"
            control={control}
          />

          <RHFTextField
            select
            label="Select Org User Role"
            name="role"
            clearable
            control={control}
          >
            {(roles?.organization_roles || []).map(r => (
              <MenuItem key={r.value} value={r.value}>
                {r.label}
              </MenuItem>
            ))}
          </RHFTextField>
        </FormContainer>
      </ModalWrapper>
    </div>
  );
};

InviteUser.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onViewRoles: PropTypes.func,
};

export default InviteUser;
