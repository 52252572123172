import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormContext } from 'react-hook-form';
import { fields } from '../../constants';

export const useAudience = ({
  campaignName,
  isDisplay,
}: {
  campaignName: string;
  isDisplay: boolean;
}) => {
  const { releaseDragAdvancedTargeting } = useFlags();
  const { control, watch } = useFormContext();

  const [audience] = watch([fields.advancedAudience.path]);

  return {
    audience: isDisplay
      ? [{ audience_name: campaignName, included: true }, ...audience]
      : audience,
    control,
    showAdvancedTargeting: !isDisplay || releaseDragAdvancedTargeting,
  };
};
