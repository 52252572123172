import { GridCellParams } from '@mui/x-data-grid-pro';

// Renders a string formatted as a currency
export const FileSizeCellRenderer = (params: Partial<GridCellParams>) => {
  let size = (params.value as string | number) ?? 0;

  if (!size) {
    return '-';
  }

  if (typeof size === 'string') {
    size = parseFloat(size);
  }

  return `${(size / 1000000).toFixed(2)} MB`;
};
