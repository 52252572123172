import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Link,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { AddUserToAdAccountModal } from './AddUserToAdAccountModal';
import { useAPI } from '../../hooks/api';
import { StatusChip } from '../shared/StatusChip';
import ModalContext from '../../../providers/ModalContext';
import LoadingSpinner from '../../ui/LoadingSpinner';
import { AdvertiserUserRoleSelect } from '@components/form';
import { usePermissions } from '../../hooks';
import { RolesAndPrivileges } from '../../modals';
import { Scopes } from '../../../constants';


export const AdAccountUsersList = ({ currentAccount }) => {
  const { setModal } = useContext(ModalContext);
  const { useGet, usePost, usePatch, useGetAll } = useAPI();
  const { hasPermission } = usePermissions();
  const allowGrantAccess = hasPermission([Scopes.CAN_UPDATE_ADVERTISER])

  const [adAccounts, setAdAccounts] = useState(null);
  const [organizationUsers, setOrganizationUsers] = React.useState(null);
  const [usersRole, setUsersRole] = useState({});
  const [isAddDialogOpen, setIsAddDialogOpen] = React.useState(false);

  useEffect(() => {
    if (!adAccounts) return
    setUsersRole(Object.fromEntries(adAccounts.map(acct => [acct.id, acct.role])));
  }, [adAccounts]);

  useEffect(() => {
    fetchAdAccountUsers();
    fetchOrganizationUsers();
  }, []);

  const fetchOrganizationUsers = async () => {
    try {
      return await useGetAll('/organization_users/management', [], items => {
        setOrganizationUsers(items);
      });
    } catch (error) {
      console.error('error in getting org users', error);
    }
  };

  const handleUserRoleAndPrivilagesClick = event => {
    event.preventDefault();

    setModal({
      isOpen: true,
      component: RolesAndPrivileges,
    });
  };

  const handleSubmitAddUser = async ({ selectedUsers }) => {
    const data = Object.values(selectedUsers);
    const filteredUsers = data.filter(user => selectedUsers[user.id]?.selected && selectedUsers[user.id].role);

    try {
      const promises = filteredUsers.map(item => {

        if (item.advertiserUserId) {
          return usePatch(`/advertiser_users/${item.advertiserUserId}`, {
            role: item.role,
            status: 'ACTIVE',
          });
        }

        return usePost('/advertiser_users', {
          advertiser: item.advertiser,
          user: item.user,
          role: item.role,
        });
      });

      await Promise.all(promises);
      await Promise.all([fetchAdAccountUsers(), fetchOrganizationUsers()]);

      setIsAddDialogOpen(false);
    } catch (error) {
      console.error('error in adding user', error);
    }
  };

  const handleAddUser = () => {
    setIsAddDialogOpen(true);
  };

  const handleCloseAddUser = () => {
    setIsAddDialogOpen(false);
  };

  const displayAdAccountUsers = useMemo(
    () => adAccounts?.sort(acct => acct.status === 'REMOVED' ? 1 : -1),
    [adAccounts],
  );

  const users = useMemo(
    () =>
      organizationUsers
        ?.filter(user => !displayAdAccountUsers?.find(acct => acct.user === user.user))
        ?.map(user => {
          const advertiserUser = displayAdAccountUsers?.find(acct => acct.user === user.user);

          return {
            id: user.id,
            name: `${user.user_first_name} ${user.user_last_name}`,
            email: user.user_email,
            user: user.user,
            advertiser: currentAccount.advertiser,
            advertiserUserId: advertiserUser?.id,
            role: advertiserUser?.role || 'READ_ONLY',
          };
        }),
    [organizationUsers, displayAdAccountUsers, currentAccount],
  );

  async function fetchAdAccountUsers() {
    try {
      const accts = await useGet('/advertiser_users/for_org/');

      if (!accts) return;

      const filteredAccts = accts.filter(
        acct => acct.advertiser === currentAccount.advertiser
      );
      setAdAccounts(filteredAccts);

      return accts;
    } catch (err) {
      console.error('Error fetching ad account users', err);
      throw err;
    }
  }

  const handleChangeRole = async (event, acct) => {
    const { value } = event.target;

    try {
      const res =
        value !== 'REMOVED'
          ? await usePatch(`/advertiser_users/${acct.id}/`, {
            role: event.target.value,
            status: 'ACTIVE',
          })
          : await usePatch(`/advertiser_users/${acct.id}/`, {
            status: event.target.value,
          });

      if (res) {
        setUsersRole(prev => ({
          ...prev,
          [acct.id]: event.target.value,
        }));
      }

      fetchAdAccountUsers();
    } catch (err) {
      console.error('Error updating ad account user role', err);
      throw err;
    }
  };

  if (adAccounts === null || users === null) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="right">Primary Organization</TableCell>
              <TableCell align="right">Ad Account Role</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {displayAdAccountUsers?.map(acct => (
              <TableRow hover key={acct.id}>
                <TableCell component="th" scope="row">
                  {`${acct.user_first_name} ${acct.user_last_name}`}
                </TableCell>

                <TableCell>{acct.user_email}</TableCell>

                <TableCell align="right">{acct.org_name}</TableCell>

                {/* <TableCell align="right">{acct.role}</TableCell> */}
                <TableCell align="right">
                  <AdvertiserUserRoleSelect
                    onChange={e => handleChangeRole(e, acct)}
                    value={usersRole[acct.id] || acct.role}
                    allowDeleted
                  />
                </TableCell>

                <TableCell align="right">
                  <StatusChip status={acct.status} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={1} ml={1}>
        <Button
          data-testid="add-users-btn"
          color="secondary"
          size="small"
          startIcon={<AddCircleOutlineIcon fontSize="small" />}
          onClick={handleAddUser}
          disabled={!allowGrantAccess}
        >
          Add Users
        </Button>
      </Box>

      {/* TODO: Call this modal in Modal Context */}
      {isAddDialogOpen && (
        <AddUserToAdAccountModal
          title={`Add Users to ${currentAccount.ad_account_name}`}
          description={
            <Box display="inline-flex" alignItems="center">
              <p>
                You are inviting users to access the{' '}
                <b>{currentAccount.ad_account_name}</b> Ad Account. Learn more
                about
              </p>
              <Button
                component={Link}
                onClick={handleUserRoleAndPrivilagesClick}
                color="secondary"
              >
                User Roles & Privileges
              </Button>
              .
            </Box>
          }
          data={users}
          onSubmit={handleSubmitAddUser}
          onClose={handleCloseAddUser}
        />
      )}
    </Box>
  );
};

AdAccountUsersList.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  categories: PropTypes.array,
  billingAccounts: PropTypes.array,
};
