import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { RHFCheckbox, RHFTextField } from '@components/form';
import { pick } from 'lodash';
import { acceptInviteSchema } from './validations';
import { yupResolver } from '@hookform/resolvers/yup';

const AcceptInviteForm = ({ platformInvite, onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(acceptInviteSchema),
    defaultValues: {
      accepted_terms: false,
      ...pick(platformInvite, ['first_name', 'last_name', 'email']),
      organization_name: platformInvite.context?.legal_company_name ?? platformInvite.context?.organization_name ?? '',
    },
  });

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      data-testid="accept-invite-form"
    >
      <Stack spacing={4} sx={{}}>
        <Typography variant="h4">Create your account</Typography>

        <Stack spacing={3}>
          <RHFTextField
            label="Organization Name"
            placeholder="Enter an Organization Name"
            name="organization_name"
            control={control}
            data-testid="organization_name"
          />

          <RHFTextField
            disabled={!!platformInvite?.email}
            label="Email Address"
            placeholder="Enter An Email Address"
            name="email"
            control={control}
            data-testid="email"
          />

          <RHFTextField
            label="First Name"
            placeholder="First Name"
            name="first_name"
            control={control}
            data-testid="first_name"
          />

          <RHFTextField
            label="Last Name"
            placeholder="Last Name"
            name="last_name"
            control={control}
            data-testid="last_name"
          />
        </Stack>

        <Box>
          <RHFCheckbox
            name="accepted_terms"
            data-testid="accepted_terms"
            label={
              <Typography>
                I have read and accept the&nbsp;
                <Link
                  sx={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.tvscientific.com/platform-master-agreement-demand/"
                >
                  Terms &amp; Conditions
                </Link>
              </Typography>
            }
            control={control}
          />
        </Box>
        <Button
          disabled={isSubmitting}
          color="secondary"
          size="large"
          data-testid="activate_account_button"
          fullWidth
          variant="contained"
          type="submit"
        >
          Activate Account
        </Button>
      </Stack>
    </form>
  );
};

AcceptInviteForm.propTypes = {
  platformInvite: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string,
    context: PropTypes.shape({
      organization_name: PropTypes.string,
      legal_company_name: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default AcceptInviteForm;
