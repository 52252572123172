import { fieldNames } from '@components/modals/BulkEditModal/constants/constants';
import { Stack, useTheme } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { MediumTypography } from '../components';
import { getCellColors, getCellVisibility } from '../utils';

export const fieldCellRenderer = (params: GridCellParams) => {
  const {
    startDateVisible,
    endDateVisible,
    bidStrategyVisible,
    bidStrategyEventVisible,
    cpmVisible,
  } = getCellVisibility(params);

  const theme = useTheme();

  const [
    startColor,
    endColor,
    bidStrategyColor,
    bidStrategyEventColor,
    cpmColor,
  ] = getCellColors(params, theme);

  return (
    <Stack spacing={theme.spacing(3)} sx={{ whiteSpace: 'normal' }}>
      {startDateVisible && (
        <>
          <MediumTypography
            text={fieldNames['start_date']}
            color={startColor}
          />
          <MediumTypography
            text={fieldNames['start_time']}
            color={startColor}
          />
        </>
      )}
      {endDateVisible && (
        <>
          <MediumTypography text={fieldNames['end_date']} color={endColor} />
          <MediumTypography text={fieldNames['end_time']} color={endColor} />
        </>
      )}
      {bidStrategyVisible && (
        <MediumTypography
          text={fieldNames['bid_strategy']}
          color={bidStrategyColor}
        />
      )}
      {bidStrategyEventVisible && (
        <MediumTypography
          text={fieldNames['bid_strategy_event']}
          color={bidStrategyEventColor}
        />
      )}
      {cpmVisible && (
        <MediumTypography text={fieldNames['cpm']} color={cpmColor} />
      )}
    </Stack>
  );
};
