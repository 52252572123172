import { StatusDisplayNames, Status } from '@constants/status';
import {
  FormControl,
  Box,
  Checkbox,
  Divider,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { EntityStatusType } from '@local-types';

export const StatusSelect = ({
  regularStatuses,
  chosenStatuses,
  onChange,
  statusDisplayNames = StatusDisplayNames,
}: {
  regularStatuses: EntityStatusType[];
  chosenStatuses: EntityStatusType[];
  onChange: Dispatch<SetStateAction<EntityStatusType[]>>;
  statusDisplayNames?: Record<string, string>;
}) => {
  const handleStatusesChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    const nextStatuses = value as (EntityStatusType | string)[];

    // If "Select All" is clicked
    if (nextStatuses[nextStatuses.length - 1] === 'all') {
      // If all regular statuses are currently selected, clear them
      // Otherwise, select all regular statuses
      const hasAllRegularStatuses = regularStatuses.every(status =>
        chosenStatuses.includes(status)
      );
      // Keep the archived status selection
      const keepArchivedStatus = chosenStatuses.includes(Status.ARCHIVED)
        ? [Status.ARCHIVED]
        : [];

      onChange(
        hasAllRegularStatuses
          ? keepArchivedStatus
          : [...regularStatuses, ...keepArchivedStatus]
      );
      return;
    }

    onChange(nextStatuses as EntityStatusType[]);
  };

  return (
    <FormControl variant="outlined" sx={{ margin: 1, minWidth: 120 }}>
      <InputLabel id="status-multiple-checkbox-label">Status</InputLabel>
      <Select
        variant="standard"
        multiple
        color="secondary"
        id="status-multiple-checkbox"
        labelId="status-multiple-checkbox-label"
        label="Status"
        value={chosenStatuses}
        onChange={handleStatusesChange}
        renderValue={selected => {
          const hasAllRegularStatuses = regularStatuses.every(value =>
            selected.includes(value)
          );
          const hasArchivedStatus = selected.includes(Status.ARCHIVED);
          const selectedValues = selected
            .map(status => StatusDisplayNames[status])
            .join(', ');

          const displayText = hasAllRegularStatuses
            ? hasArchivedStatus
              ? 'All, Archived'
              : 'All'
            : selectedValues;

          return `Showing [${displayText}] status`;
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 7.5 + 8,
              width: 250,
            },
          },
        }}
      >
        <MenuItem value="all">
          <Checkbox
            checked={regularStatuses.every(status =>
              chosenStatuses.includes(status)
            )}
            indeterminate={
              regularStatuses.some(status =>
                chosenStatuses.includes(status)
              ) &&
              !regularStatuses.every(status =>
                chosenStatuses.includes(status)
              )
            }
          />
          <ListItemText primary="Select All" />
        </MenuItem>
        {regularStatuses.map(status => (
          <MenuItem key={status} value={status}>
            <Checkbox checked={chosenStatuses.includes(status)} />
            <ListItemText primary={statusDisplayNames[status]} />
          </MenuItem>
        ))}
        <Box>
          <Divider
            sx={{
              borderBottomWidth: '2px',
              borderBottomColor: 'grey.main',
              marginY: '8px',
            }}
            flexItem
          />
          <MenuItem
            key={Status.ARCHIVED}
            value={Status.ARCHIVED}
            onClick={() => {
              const nextStatuses = chosenStatuses.includes(Status.ARCHIVED)
                ? chosenStatuses.filter(status => status !== Status.ARCHIVED)
                : [...chosenStatuses, Status.ARCHIVED];
              onChange(nextStatuses);
            }}
            data-testid="archived-status"
          >
            <Checkbox checked={chosenStatuses.includes(Status.ARCHIVED)} />
            <ListItemText primary={statusDisplayNames[Status.ARCHIVED]} />
          </MenuItem>
        </Box>
      </Select>
    </FormControl>
  );
};
