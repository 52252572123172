import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { Fragment } from 'react';
import {
  AdvancedTableStyled,
  FirstCellStyled,
  LastCellStyled,
  TableContainerStyled,
} from '../styles';
import { GroupedData, TableData, WithId } from '../types';
import { TableRightRow } from './TableRightRow';
import { useTableSide } from '../hooks/useTableSide';
import { TableRightProps } from './types';
import { isNull } from 'lodash';

export const TableRight = <Value, Data extends TableData<Value>>({
  data = [],
  onChange,
  onUpdate,
  renderCell,
  renderGroup,
  isTargeted,
  actualAmount,
  PlaceholderCell,
  PlaceholderGroup,
  settings,
  isRemoveAllHovered,
}: TableRightProps<Value, Data>) => {
  const { evenData } = useTableSide(data, {
    actualAmount,
  });
  return (
    <TableContainerStyled>
      <AdvancedTableStyled>
        <TableHead>
          <TableRow>
            <FirstCellStyled>Selected</FirstCellStyled>
            {settings.includable && <TableCell>Incl/Excl</TableCell>}
            {!settings.includable ||
            (settings.includable && settings.showSeparateSwitch) ? (
              <LastCellStyled />
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody data-testid="advanced-right-table-body">
          {!isNull(evenData[0]) &&
          'groupName' in (evenData[0] as TableData<Value>[])
            ? (evenData as GroupedData<Value>[]).map((v, i) => (
                <Fragment key={`${v.groupName}-${i}`}>
                  <TableRow data-testid={`table-right-group-${v.groupName}`}>
                    {renderGroup ? (
                      renderGroup({
                        data: v,
                        type: 'right',
                      })
                    ) : (
                      <PlaceholderGroup type={'right'} data={v} />
                    )}
                  </TableRow>
                  {v.data.map((d, j) => (
                    <TableRightRow
                      data-testid="advanced-right-table-row"
                      key={
                        (data as WithId<Data>).id ??
                        `placeholder-${v.groupName}-${i}-${j}`
                      }
                      data={d}
                      index={j}
                      isTargeted={v && isTargeted}
                      onChange={onChange}
                      onUpdate={onUpdate}
                      PlaceholderCell={PlaceholderCell}
                      renderCell={renderCell}
                      settings={settings}
                      isRemoveAllHovered={isRemoveAllHovered}
                    />
                  ))}
                </Fragment>
              ))
            : evenData.map((v, i) => (
                <TableRightRow
                  key={(v as WithId<Value>)?.id ?? `placeholder-${i}`}
                  data={v as Value}
                  index={i}
                  isRemoveAllHovered={isRemoveAllHovered}
                  onChange={onChange}
                  onUpdate={onUpdate}
                  renderCell={renderCell}
                  isTargeted={isTargeted}
                  PlaceholderCell={PlaceholderCell}
                  settings={settings}
                />
              ))}
        </TableBody>
      </AdvancedTableStyled>
    </TableContainerStyled>
  );
};
