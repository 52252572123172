import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

enum DateFilterEnum {
  LAST_30_DAYS = '30',
  CURRENT_MONTH = 'MONTH',
}

const DateFilter = ({ value, setValue }: { value: '30' | 'MONTH', setValue: (value: '30' | 'MONTH') => void }) => {

  const handleDateFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: DateFilterEnum | null) => {
    if (newValue === null) return;
    setValue(newValue);
  }

  return (
    <ToggleButtonGroup
      value={value}
      exclusive={true}
      onChange={handleDateFilterChange}
      sx={{
        boxShadow: 'none',
        marginRight: '8px',
        border: 'none',
        '& .MuiToggleButton-root': {
          border: 'none',
          backgroundColor: 'white',
          color: 'black',
          padding: '8px 16px',
          boxShadow: '-2px 5px 8px -2px #00000057',
          transition: 'box-shadow 0.1s ease-in-out',
          '&::before': {
            display: 'none',
          },
          '&:hover': {
            backgroundColor: '#D9F1FF',
          },
          '&.Mui-selected': {
            boxShadow: 'none',
            backgroundColor: '#D9F1FF',
            color: 'black',
            '&:hover': {
              backgroundColor: '#D9F1FF',
            },
          },
        },
      }}
    >
      <ToggleButton
        disableRipple={true}
        value="30"
      >
        Last 30 Days
      </ToggleButton>
      <ToggleButton
        disableRipple={true}
        value="MONTH"
      >
        Current Month
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default DateFilter;
