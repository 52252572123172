import { Stack } from '@mui/material';
import { advancedModals } from '@v2/components/campaign/CampaignAdGroupSection';
import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import { FormProvider } from 'react-hook-form';
import { fields } from '../../CampaignWizard/AdGroupSettingsSection/formConfig';
import { sectionProps } from '../../Wizard/types';
import { GroupSectionContext, labels } from './constants';
import { useGroupSection } from './hooks/useGroupSection';

const AdGroupInfoPane = lazy(() =>
  import('./panes/AdGroupInfoPane/AdGroupInfoPane')
);
const AdGroupAdvancedDeliveryPane = lazy(() =>
  import('./panes/AdGroupAdvancedDeliveryPane/AdGroupAdvancedDeliveryPane')
);
const AdGroupAdvancedAudiencePane = lazy(() =>
  import('./panes/AdGroupAdvancedAudiencePane/AdGroupAdvancedAudiencePane')
);
const AdGroupZipCodesPane = lazy(() =>
  import('./panes/AdGroupZipCodesPane/AdGroupZipCodesPane')
);
const AdGroupCustomInventoryPane = lazy(() =>
  import('./panes/AdGroupCustomInventoryPane/AdGroupCustomInventoryPane')
);
const AdGroupAdvancedInventoryPane = lazy(() =>
  import('./panes/AdGroupAdvancedInventoryPane/AdGroupAdvancedInventoryPane')
);

const AdGroupAdvancedCreativesPane = lazy(() =>
  import('./panes/AdGroupAdvancedCreativesPane/AdGroupAdvancedCreativesPane')
);

const GroupSection = ({
  group: adGroup,
  campaignId,
  sectionKey,
  isDisplay,
  onDelete,
}) => {
  const {
    ref,
    form,
    sectionsInfo,
    mutating,
    campaign,
    bundles,
    isActivePane,
    submit,
    submitCustomInventory,
    submitCreatives,
    submitZipCodes,
    submitAudiences,
    submitAdvancedInventory,
    submitAdvancedDelivery,
    resetZipCodes,
    inventoryAvailability,
    activePane,
  } = useGroupSection({
    sectionKey,
    campaignId,
    adGroup,
    isDisplay,
  });

  return (
    <GroupSectionContext.Provider
      value={{
        group: form.watch(),
        campaign,
      }}
    >
      <Stack ref={ref}>
        <FormProvider {...form}>
          {(!activePane || isActivePane(labels.nameDates.value)) && (
            <AdGroupInfoPane
              key="info"
              bundles={bundles}
              form={form}
              adGroup={adGroup}
              sectionsInfo={sectionsInfo}
              onDelete={onDelete}
              onSubmit={submit}
              mutating={mutating}
              campaign={campaign}
              isDisplay={isDisplay}
              inventoryAvailability={inventoryAvailability}
            />
          )}
          {isActivePane(labels.bidStrategy.value) && (
            <AdGroupAdvancedDeliveryPane
              key={advancedModals.bidStrategy}
              onSubmit={submitAdvancedDelivery}
              data={{
                frequencyCap: form.getValues(
                  fields.configureAdGroupFrequencyCap.path
                ),
                dayparting: form.getValues(
                  fields.configureAdGroupDayparting.path
                ),
                pacing: {
                  [fields.adGroupPacingEnabled.path]: form.getValues(
                    fields.adGroupPacingEnabled.path
                  ),
                  [fields.adGroupPacing.path]: form.getValues(
                    fields.adGroupPacing.path
                  ),
                  [fields.adGroupPacingBehavior.path]: form.getValues(
                    fields.adGroupPacingBehavior.path
                  ),
                  [fields.adGroupPacingCatchupBehavior.path]: form.getValues(
                    fields.adGroupPacingCatchupBehavior.path
                  ),
                },
              }}
            />
          )}
          {isActivePane(labels.audience.value) && (
            <AdGroupAdvancedAudiencePane
              key={advancedModals.audience}
              selected={form.getValues(fields.advancedAudience.path)}
              audienceExtension={form.getValues(
                fields.audienceExtension.path
              )}
              onSubmit={submitAudiences}
              adGroup={adGroup}
              inventoryAvailability={inventoryAvailability}
            />
          )}
          {isActivePane(labels.geography.value) && (
            <AdGroupZipCodesPane
              key={advancedModals.geography}
              data={form.getValues(fields.zipcodes.path)}
              adGroup={adGroup}
              onSubmit={submitZipCodes}
              onClose={resetZipCodes}
            />
          )}
          {isActivePane(labels.customInventory.value) && (
            <AdGroupCustomInventoryPane
              key={advancedModals.customInventory}
              bundles={bundles}
              campaign={campaign}
              selected={form.getValues(fields.advancedCustomInventory.path)}
              onSubmit={submitCustomInventory}
              adGroup={adGroup}
            />
          )}
          {isActivePane(labels.inventory.value) && (
            <AdGroupAdvancedInventoryPane
              key={advancedModals.inventory}
              adGroup={adGroup}
              selected={form.getValues(fields.advancedInventory.path)}
              onSubmit={submitAdvancedInventory}
            />
          )}
          {isActivePane(labels.creative.value) && (
            <AdGroupAdvancedCreativesPane
              key={advancedModals.creative}
              adGroup={adGroup}
              isDisplay={isDisplay}
              creatives={form.getValues(fields.creatives.path)}
              onSubmit={submitCreatives}
            />
          )}
        </FormProvider>
      </Stack>
    </GroupSectionContext.Provider>
  );
};

GroupSection.propTypes = {
  isDisplay: PropTypes.bool,
  ...sectionProps,
};

export default GroupSection;
