import React from 'react';
import { Stack } from '@mui/material';
import {
  StyledPromptContentTypography,
  StyledList,
  StyledListItem,
} from './styles';

export const Content = () => {
  return (
    <Stack>
      <StyledPromptContentTypography variant="body2">
        How to fix:
        <StyledList>
          <StyledListItem>
            <strong>Overwrite Ad Server</strong> will completely replace all
            targeting with the settings you just saved.
          </StyledListItem>
          <StyledListItem>
            <strong>Merge with Ad Server</strong> will attempt to combine new
            and old settings.
          </StyledListItem>
        </StyledList>
      </StyledPromptContentTypography>
    </Stack>
  );
};
