import { styled, AccordionSummary } from '@mui/material';

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(2),
  minHeight: 'unset',
  margin: 0,
  '&.Mui-expanded': {
    minHeight: 'unset',
    margin: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    }
  }
})); 
